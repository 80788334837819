/* eslint-disable react/forbid-prop-types */
import { React } from 'react';

import Skeleton from 'react-loading-skeleton';

import {
  TableCell,
  TableUser,
  TableUserImageContainer,
  TableUserInfo,
} from './ManageTeamStyle';

export default function TeamRowSkeleton() {
  return (
    <tr>
      <TableCell>
        <TableUser>
          <TableUserImageContainer>
            <Skeleton circle width={30} height={30} />
          </TableUserImageContainer>
          <TableUserInfo
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Skeleton width={100} />
            <Skeleton width={150} />
          </TableUserInfo>
        </TableUser>
      </TableCell>
      <TableCell>
        <Skeleton width={100} />
      </TableCell>
      <TableCell>
        <Skeleton width={100} />
      </TableCell>
      <TableCell>
        <Skeleton width={145} />
      </TableCell>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Skeleton width={50} />
      </td>
    </tr>
  );
}
