/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';

import PaymentMethod from './PaymetMethod';

import { GET_PAYMENT_METHODS_FOR_USER } from '../../../GraphQl/Queries/usersQueries';

export default function PayMethodsCard({ userId }) {
  console.log('uid is ', userId);
  const { loading, data: userData } = useQuery(GET_PAYMENT_METHODS_FOR_USER, {
    variables: { id: userId },
  });

  return (
    <>
      {!loading &&
        (!userData?.user?.payment_methods ||
          userData?.user?.payment_methods?.length === 0) && (
          <div>No Payment Methods Linked</div>
        )}

      {!loading &&
        userData?.user?.payment_methods?.length !== 0 &&
        userData?.user?.payment_methods?.map((paymentMethod) => (
          <PaymentMethod
            paymentMethod={paymentMethod}
            key={paymentMethod.id}
            default_payment_method={userData?.user?.default_payment_method}
            userId={userId}
          />
        ))}

      {loading && <Skeleton count={3} />}
    </>
  );
}

PayMethodsCard.propTypes = {
  userId: PropTypes.string,
};

PayMethodsCard.defaultProps = {
  userId: null,
};
