/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import { LeftCircleOutlined, PropertySafetyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { XCircleIcon } from '@heroicons/react/outline';
import moment from 'moment';
import { SpinnerCircular } from 'spinners-react';
import { Default, Mobile } from '../../Devices';
import {
  Card,
  TopButton,
  Button,
  Page,
  Section,
  // BlankDiv,
} from '../../../CoreStyles/GeneralStyles';

import BackButton from '../../Common/BackButton';
import {
  // Td,
  SummaryPageH2,
  SummaryPageWrapper,
  SummaryHeader,
  ResizableBox,
  AmountContainer,
  BoxDragger,
  BoxResizer,
  SummaryFilterRow,
  LoadingContainer,
  LoadingText,
  FilterChip,
  FilterChipPrimaryText,
} from './SummaryPageStyles';
import Table from './Components/Table';
import DoughnutChart from './Components/Doughnut';
import PieChart from './Components/PieChart';
import Amount from './Components/Amount';
import BarChart from './Components/Bar';
import CustomChart from './Components/CustomChart';

import DropdownMenu from '../../Generics/Dropdown';

import { SummaryAmount, SummaryTitle } from './SummaryCardStyle';
// eslint-disable-next-line import/no-named-as-default
import GET_SUMMARY_DATA from '../../../GraphQl/Queries/summaryQuery';
import { GET_ORGANIZATION_MEMBERS } from '../../../GraphQl/Queries/organizationsQuery';

import {
  currencyFormatter,
  getQuarterRange,
  isAllowed,
  OrgContext,
} from '../../../Utils';

import { pageData as InProgressPageData } from './Configs/InProgress';
import { pageData as TotalCollectedPageData } from './Configs/TotalCollected';
import { pageData as AmountOwnedPageData } from './Configs/AmountOwned';
import { pageData as RecentActivityPageData } from './Configs/RecentActivity';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const types = {
  TOTAL_COLLECTED: {
    title: 'Total Collected',
  },
  AMOUNT_OWED: {
    title: 'Amout Owed',
  },
  IN_PROGRESS: {
    title: 'In Progress',
  },
  RECENT_ACTIVITY: {
    title: 'Recent Activity',
  },
};

const dateRangeOptions = {
  'last-7': {
    title: 'Last 7 Days',
    fromDate: moment().subtract(7, 'd').toDate(),
    toDate: new Date(),
  },
  'last-14': {
    title: 'Last 14 Days',
    fromDate: moment().subtract(14, 'd').toDate(),
    toDate: new Date(),
  },
  'last-30': {
    title: 'Last 30 Days',
    fromDate: moment().subtract(30, 'd').toDate(),
    toDate: new Date(),
  },
  'last-60': {
    title: 'Last 60 Days',
    fromDate: moment().subtract(60, 'd').toDate(),
    toDate: new Date(),
  },
  'this-quarter': {
    title: `This Quarter (Q${moment().quarter()})`,
    fromDate: getQuarterRange(moment().quarter()).start.toDate(),
    toDate: getQuarterRange(moment().quarter()).end.toDate(),
  },
  'this-year': {
    title: `This Year (${moment().year()})`,
    fromDate: moment().startOf('year').toDate(),
    toDate: new Date(),
  },
};

const ResizeHandle = React.forwardRef((props, ref) => (
  <BoxResizer
    editing={props.editing}
    {...props}
    ref={ref}
    className="react-resizable-handle"
  />
));

function buildComponent(key, component, data, editing, resizeChange) {
  if (component.type === 'table') {
    return (
      <ResizableBox editing={editing} key={key}>
        <Table key={key} data={data} columns={component.columns} />
      </ResizableBox>
    );
  }

  if (component.type === 'amount') {
    return (
      <ResizableBox editing={editing} key={key}>
        <AmountContainer>
          <Amount data={data} valueOptions={component.valueOptions} />
        </AmountContainer>
      </ResizableBox>
    );
  }

  if (component.type === 'chart') {
    return (
      <ResizableBox editing={editing} key={key}>
        <Card style={{ height: '100%' }}>
          <CustomChart
            data={data}
            valueOptions={component.valueOptions}
            groupBy={component.groupBy}
            resize={resizeChange}
            chartOptions={component.chartOptions}
          />
        </Card>
      </ResizableBox>
    );
  }
  return null;
}

export function SummaryPage(props) {
  const orgData = useContext(OrgContext);
  const allProps = props;
  const type = allProps?.match?.params?.type;
  console.log(allProps, 'gewtyr');

  const [dateRange, setDateRange] = useState(
    type === 'RECENT_ACTIVITY' ? 'last-14' : 'this-quarter'
  );

  const [editing, setEditing] = useState(false);
  const [resizeChange, setResizeChange] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [members, setMembers] = useState({});
  const [filteredUser, setFilteredUser] = useState(null);

  const { error, loading, data } = useQuery(GET_SUMMARY_DATA, {
    variables: {
      type,
      startDate: dateRangeOptions[dateRange].fromDate,
      endDate: dateRangeOptions[dateRange].toDate,
      userId: filteredUser,
    },
  });

  const { data: orgUsersData } = useQuery(GET_ORGANIZATION_MEMBERS, {
    variables: { id: '1' },
    fetchPolicy: 'cache-and-network',
  });

  console.log(error, loading, data, 'truyturuyr');
  const summaryData = data?.summary;

  function getTickInterval(first, second) {
    const a = moment(first);
    const b = moment(second);

    const range = Math.abs(a.diff(b, 'days'));

    console.log('rnage is ', range);

    if (range < 30) {
      // Less than 30 days, we show days
      return 24 * 3600 * 1000;
    }

    if (range < 60) {
      // Less than 60 days, we show weeks
      return 24 * 3600 * 1000 * 7;
    }

    // We show months
    return 86400000 * 30;
  }

  useEffect(() => {
    let pageDataTemp = null;

    if (summaryData?.type === 'IN_PROGRESS') {
      // Let's apply filters
      pageDataTemp = _.cloneDeep(InProgressPageData);
    }

    if (summaryData?.type === 'TOTAL_COLLECTED') {
      pageDataTemp = _.cloneDeep(TotalCollectedPageData);
    }

    if (summaryData?.type === 'AMOUNT_OWED') {
      pageDataTemp = _.cloneDeep(AmountOwnedPageData);
    }

    if (summaryData?.type === 'RECENT_ACTIVITY') {
      pageDataTemp = _.cloneDeep(RecentActivityPageData);
    }

    // Let's apply filters to components
    // Starting with the data
    if (pageDataTemp) {
      Object.values(pageDataTemp.components).forEach((component) => {
        if (component.type === 'chart') {
          if (component.chartOptions?.xAxis) {
            component.chartOptions.xAxis.min =
              dateRangeOptions[dateRange].fromDate;
            component.chartOptions.xAxis.max =
              dateRangeOptions[dateRange].toDate;

            component.chartOptions.xAxis.tickInterval = getTickInterval(
              dateRangeOptions[dateRange].fromDate,
              dateRangeOptions[dateRange].toDate
            );
          }
        }
      });
    }

    setPageData(pageDataTemp);
  }, [summaryData?.type, dateRange]);

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce(
      (obj, item) => ({
        ...obj,
        [item[key]]: item,
      }),
      initialValue
    );
  };

  useEffect(() => {
    if (orgUsersData?.organization?.members) {
      setMembers(
        convertArrayToObject(orgUsersData?.organization?.members, 'id')
      );
    }
  }, [orgUsersData]);

  if (!isAllowed(orgData?.current_user_role, 'summary.read')) {
    return <div>Access deined</div>;
  }

  return (
    <Page gridTemplateColumns="25% 25% 25% 25%" singleColumnWide typeTwo>
      <div style={{ margin: '0px 18px' }}>
        <BackButton path="/" text="Dashboard" />
      </div>

      <Section padding="0px" style={{ gridArea: 'main' }} width="100%">
        <div style={{ margin: '0px 18px' }}>
          <SummaryHeader>
            <SummaryPageH2>{types[summaryData?.type]?.title}</SummaryPageH2>
          </SummaryHeader>
          <SummaryFilterRow>
            {type !== 'AMOUNT_OWED' && type !== 'IN_PROGRESS' && (
              <DropdownMenu
                style={{ marginRight: '10px' }}
                value={dateRange}
                options={dateRangeOptions}
                button={
                  <FilterChip>
                    <FilterChipPrimaryText>Date Range</FilterChipPrimaryText>
                    &nbsp;is&nbsp;
                    <FilterChipPrimaryText>
                      {dateRangeOptions[dateRange].title}
                    </FilterChipPrimaryText>
                  </FilterChip>
                }
                onChange={(newValue) => {
                  setDateRange(newValue);
                }}
              />
            )}

            <DropdownMenu
              titleKey="name"
              value={filteredUser}
              options={members}
              onChange={(newValue) => {
                console.log('newvalue: ', newValue);
                setFilteredUser(newValue);
              }}
              button={
                filteredUser ? (
                  <FilterChip>
                    <FilterChipPrimaryText>User</FilterChipPrimaryText>
                    &nbsp;is&nbsp;
                    <FilterChipPrimaryText>
                      {members[filteredUser].name}
                    </FilterChipPrimaryText>
                    <XCircleIcon
                      onClick={() => {
                        setFilteredUser(null);
                      }}
                      className="filter-clear-icon"
                    />
                  </FilterChip>
                ) : (
                  <FilterChip>Filter by User</FilterChip>
                )
              }
              searchEnabled
            />
          </SummaryFilterRow>
        </div>
        {(!pageData || loading) && (
          <LoadingContainer>
            <LoadingText>Generating Report</LoadingText>
            <SpinnerCircular
              size={65}
              thickness={81}
              speed={162}
              color="rgba(12, 108, 255, 1)"
              secondaryColor="rgba(0, 0, 0, 0.33)"
            />
          </LoadingContainer>
        )}

        {pageData && !loading && (
          <ResponsiveReactGridLayout
            breakpoints={{ lg: 1200 }}
            cols={{ lg: 50 }}
            className="layout"
            layouts={{ lg: pageData.layout }}
            rowHeight={1}
            width={1200}
            isDraggable={false}
            isResizable={false}
          >
            {Object.keys(pageData.components).map((key) =>
              buildComponent(
                key,
                pageData.components[key],
                summaryData,
                editing,
                resizeChange
              )
            )}
          </ResponsiveReactGridLayout>
        )}
      </Section>
    </Page>
  );
}
SummaryPage.protoTypes = {
  allProps: PropTypes.element,
};
SummaryPage.defaultProps = {
  allProps: null,
};

export default SummaryPage;
