import { gql } from '@apollo/client';

export const UPDATE_ORG_MUTATION = gql`
  mutation updateOrg(
    $id: String!
    $name: String
    $entity_type: String
    $platform_name: String
    $platform_logo: String
    $subdomain: String
    $custom_domain: String
    $custom_domain_enabled: Boolean
    $projects_vocabulary: String
    $login_message_text: String
    $signup_message_text: String
    $help_options: HelpOptionsInput
    $timezone: String
    $default_email_time: Int
    $utc_offset: Int
  ) {
    updateOrg(
      id: $id
      input: {
        name: $name
        entity_type: $entity_type
        platform_name: $platform_name
        platform_logo: $platform_logo
        subdomain: $subdomain
        custom_domain: $custom_domain
        login_message_text: $login_message_text
        signup_message_text: $signup_message_text
        custom_domain_enabled: $custom_domain_enabled
        projects_vocabulary: $projects_vocabulary
        help_options: $help_options
        timezone: $timezone
        utc_offset: $utc_offset
        default_email_time: $default_email_time
      }
    ) {
      id
      name
      entity_type
      platform_name
      platform_logo
      projects_vocabulary
      login_message_text
      signup_message_text
      custom_domain_enabled
      subdomain
      custom_domain
      timezone
      utc_offset
      default_email_time
      help_options {
        email {
          enabled
          value
        }
        phone {
          enabled
          value
        }
      }
    }
  }
`;

export const ADD_USER_TO_ORG = gql`
  mutation addUserToOrg($userId: String!, $orgId: String!, $role: String!) {
    addUserToOrg(userId: $userId, orgId: $orgId, role: $role) {
      id
      user_id
      organization_id
      createdAt
      role
    }
  }
`;

export const UPDATE_USER_ROLE_FOR_ORG = gql`
  mutation updateUserRole($userId: String!, $orgId: String!, $role: String!) {
    updateUserRole(userId: $userId, orgId: $orgId, role: $role) {
      id
      user_id
      organization_id
      createdAt
      role
    }
  }
`;

export const REMOVE_USER_FROM_ORG = gql`
  mutation removeUserFromOrg($userId: String!, $orgId: String!) {
    removeUserFromOrg(userId: $userId, orgId: $orgId) {
      id
      name
    }
  }
`;

export const INVITE_USER_TO_ORG = gql`
  mutation inviteUserToOrg($email: String!, $orgId: String!, $role: String!) {
    inviteUserToOrg(email: $email, orgId: $orgId, role: $role) {
      ... on OrganizationMembership {
        id
        user_id
        organization_id
        createdAt
        role
      }
      ... on OrganizationInvite {
        id
        organization_id
        email
        createdAt
        invited_by_user
        role
      }
    }
  }
`;

export const REVOKE_INVITE_FROM_ORG = gql`
  mutation revokeInviteFromOrg($id: String!) {
    revokeInvite(id: $id) {
      id
      organization_id
      email
      createdAt
      invited_by_user
      role
    }
  }
`;

export const UPDATE_ORG_IMAGE = gql`
  mutation updateOrgImage($id: String, $file: Upload!) {
    updateOrgImage(id: $id, file: $file) {
      link
      thumb_link
    }
  }
`;

export default UPDATE_ORG_MUTATION;
