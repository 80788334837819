/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { message } from 'antd';
import { Button, Page, Input } from '../../CoreStyles/GeneralStyles';
import { validateForm } from '../../Utils';
import auth from '../../Auth';
import {
  InputContainer,
  InputTitle,
  // TextField,
} from '../../CoreStyles/InputStyles';
import {
  AuthLogo,
  AuthContainer,
  H1,
  InfoContainer,
  CardContainer,
  CardContainerLeft,
  CardContainerRight,
  PromoContainer,
  FooterContainer,
  PoweredbyContainer,
  PoweredByLogo,
  AuthCard,
  MobilePoweredbyContainer,
  AuthLink,
  AuthButtonContainer,
} from './AuthStyle';

import { SignUpLinkContainer, SignUpLink } from './LoginStyle';

import AloaPayLogoWhite from '../../images/aloapay-logo-white.svg';
import AloaPayLogoDark from '../../images/aloapay-logo.svg';

export default function Login({ history, orgData }) {
  console.log('org data is ', orgData);
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const inputValid = validateForm(state);

    if (inputValid) {
      try {
        await auth.login(state.email, state.password);
        history.push('/');
      } catch (error) {
        message.error(error.message);
      }
    } else {
      // TODO: Improve validation style and messages
      message.error('Input invalid');
    }
  };

  return (
    <Page backgroundColor="#F3F3F4" centered>
      <AuthContainer>
        <AuthLogo src={orgData?.platform_logo_thumb} />
        <AuthCard>
          <CardContainer>
            <CardContainerLeft>
              <H1>Welcome Back 👋</H1>

              <InfoContainer>
                <InputContainer>
                  <InputTitle>Email</InputTitle>
                  {/* <TextField /> */}
                  <Input
                    placeholder="abc@gmail.com"
                    type="text"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <InputTitle>Password</InputTitle>
                  {/* <TextField type="password" /> */}
                  <Input
                    placeholder="abc@123"
                    type="password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                  />
                </InputContainer>
              </InfoContainer>
              <AuthButtonContainer>
                <Button marginTop="10px" onClick={handleSubmit}>
                  Login
                </Button>
                <AuthLink href="/forgotpassword">Forgot Password?</AuthLink>
              </AuthButtonContainer>
              <SignUpLinkContainer>
                Or
                <SignUpLink href="/signup">Create An Account</SignUpLink>
              </SignUpLinkContainer>
            </CardContainerLeft>
            <CardContainerRight>
              {orgData?.login_message_text && (
                <PromoContainer>{orgData?.login_message_text}</PromoContainer>
              )}
              {!orgData?.login_message_text && (
                <PromoContainer>
                  {orgData?.platform_name} uses AloaPay for secure and
                  simplified billing
                </PromoContainer>
              )}
              <FooterContainer>
                <PoweredbyContainer>
                  Powered by
                  <a href="https://aloapay.com">
                    <PoweredByLogo src={AloaPayLogoWhite} />
                  </a>
                </PoweredbyContainer>
              </FooterContainer>
            </CardContainerRight>
          </CardContainer>
        </AuthCard>
        <MobilePoweredbyContainer>
          Powered by
          <a href="https://aloapay.com">
            <PoweredByLogo src={AloaPayLogoDark} />
          </a>
        </MobilePoweredbyContainer>
      </AuthContainer>
    </Page>
  );
}

Login.propTypes = {
  history: PropTypes.instanceOf(History).isRequired,
  orgData: PropTypes.any,
};
