import styled from 'styled-components';
import { Card } from '../../CoreStyles/GeneralStyles';

export const AuthLogo = styled.img`
  height: 40px;
  display: block;
  margin: auto;
  margin-bottom: 23px;
`;

export const H1 = styled.h1`
  font-family: 'Avenir Next';
  font-weight: 400;
`;
export const P = styled.p``;

export const AuthContainer = styled.div`
  font-weight: 400;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    top: 15px;
    position: absolute;
  }
`;

export const AuthCard = styled(Card)`
  margin: auto;
  padding: 35px 45px;
  @media (max-width: 768px) {
    padding: 25px 30px;
    width: 95%;
  }
`;

export const CardContainer = styled.div`
  display: flex;
`;

export const CardContainerLeft = styled.div`
  margin-right: 40px;
  @media (max-width: 768px) {
    margin-right: 0px;
    width: 100%;
  }
`;

export const PromoContainer = styled.div`
  margin-right: 30px;
  font-size: 16px;
`;

export const PoweredbyContainer = styled.div`
  margin-right: 30px;
  font-size: 12px;
  display: flex;
`;

export const MobilePoweredbyContainer = styled(PoweredbyContainer)`
  margin-top: 25px;
  font-size: 11px;
  margin-top: 25px;
  color: #7b7b7b;
  font-weight: 400;

  justify-content: center;

  @media (min-width: 1025px) {
    display: none;
  }
`;

export const PoweredByLogo = styled.img`
  margin-left: 8px;
  height: 22px;
  cursor: pointer;
`;

export const FooterContainer = styled.div``;

export const CardContainerRight = styled.div`
  background-color: #54617e;
  margin: -35px -45px;
  margin-left: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #ffffff;
  padding: 50px;
  width: 400px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  width: 300px;
  margin-top: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PasswordContainer = styled.div`
  width: 300px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AuthButtonContainer = styled.div``;

export const AuthLink = styled.a`
  color: #0c6cff;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 12px;
  font-weight: 400;
  font-size: 12px;
`;
