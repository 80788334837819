/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { Button, Form, message } from 'antd';
import { useMutation } from '@apollo/client';
import { createSetupIntentMutation } from '../../../../../GraphQl/Mutations/createPaymentMethods';

/**
 * TODO - Work in progress
 */

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#888',
      color: '#888',
      background: '#fff',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#aaa',
      },
    },
    invalid: {
      // iconColor: '#ffc7ee',
      // color: '#ffc7ee',
    },
  },
};

export default function CreditCardForm({ user, ccReveived }) {
  const [, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const [createSetupIntent, { error }] = useMutation(
    createSetupIntentMutation,
    {
      onCompleted: (result) => {
        setClientSecret(result.createSetupIntent.clientSecret);
      },
      onError: (errors) => {
        console.error(errors);
        message.error(error);
      },
    }
  );

  useEffect(() => {
    createSetupIntent({
      variables: {
        customerId: user.customer_id,
      },
    });
  }, []);

  const handleSubmit = async (ev) => {
    console.log('Getting here....');

    setLoading(true);
    ev.preventDefault();

    const cardElement = elements.getElement(CardElement);

    const { paymentMethod, paymentMethodError } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: user.name,
        },
      },
    });

    console.log('Stripe result', result);

    if (paymentMethodError) {
      console.log(paymentMethod);
    } else {
      ccReveived(paymentMethod);
      message.success('Payment method added!');
    }
  };

  return (
    <Form
      style={{ borderBottom: '0px', marginTop: '20px' }}
      onSubmit={handleSubmit}
    >
      <ElementContainer>
        <CardElement options={CARD_OPTIONS} />
      </ElementContainer>
      <AddCCButton type="primary" onClick={handleSubmit}>
        Add Credit Card
      </AddCCButton>
      <ClearFix />
    </Form>
  );
}

const ElementContainer = styled.div`
  background: #fff;
  padding: 20px;
  border: 1px solid rgba(186, 186, 186, 0.25);
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(186, 186, 186, 0.25);
`;

const AddCCButton = styled(Button)`
  float: right;
  margin-top: 30px;
  border-radius: 5px;
`;

const ClearFix = styled.div`
  clear: both;
`;
