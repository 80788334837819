import styled, { css } from 'styled-components';

export const BankAccountTextWrapper = styled.span`
  margin-bottom: 5px;
  margin-top: 10px;
  display: block;
  font-size: 20px;
`;

export const ProcessingFeeText = styled.span`
  display: block;
  font-size: 16px;
  color: #989898;
`;

export const FooterWrapper = styled.span`
  margin-top: 15px;
  font-size: 15px;
  color: #838383;
  display: flex;
  align-items: top;
  align-items: flex-start;
  margin-top: 25px;
`;

export const LockIconWrapper = styled.span`
  margin-right: 10px;
  position: absolute;
`;

export const FooterText = styled.span`
  display: block;
`;

export const PaymentMethodOptionsContainer = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
`;

export const PaymentMethodOptionContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: ${(props) => (props.width ? props.width : '')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '')};
  @media screen and (max-width: 757px) {
    ${(props) =>
      props.width === '50%' &&
      css`
        width: 100%;
        margin-bottom: 25px;
      `}
  }
`;
