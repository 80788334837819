import styled, { css } from 'styled-components';
import {
  Card,
  // BlankDiv,
} from '../../../CoreStyles/GeneralStyles';

// export const TableWrapper = styled.div`
//   background: #ffffff;
//   border: 1.02823px solid #dddddd;
//   box-sizing: border-box;
//   border-radius: 8.22581px;
//   padding: 15px;
//   width: 100%;
//   margin: 20px 0;
// `;

// export const TableBody = styled.tbody``;
// export const TableRow = styled.tr``;
// export const Td = styled.td``;
// export const TableHeadData = styled.th``;
export const TeamHeaderRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BillingWrapper = styled.div``;
export const UnorderList = styled.ul``;
export const List = styled.li``;
export const SummaryPageTable = styled.table`
  width: 100%;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #3c3c3c;
`;
export const SummaryPageHead = styled.thead`
  background: #f7f7f8;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 8px;
`;
export const SummaryPageH2 = styled.h2`
  margin-right: 10px;
`;
export const SummaryPageWrapper = styled.div`
  flex: 1;
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 30px;
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;
export const MobileViewWrapper = styled.div`
  margin-top: 20px;
`;
export const BlankDiv = styled.div``;

export const SummaryHeader = styled.div`
  display: flex;
  align-items center;
  justify-content: space-between;

  margin-bottom: 10px;
`;

export const SummaryFilterRow = styled.div`
  display: flex;
  align-items center;
 
  /* border: 1px solid #e8e8e8; */
  border-radius: 6px;
`;

export const SummaryGridView = styled.div`
  display: grid;

  grid-template-columns: 22.5% 22.5% 22.5% 22.5%;
  grid-template-rows: auto;

  grid-gap: 3.3%;
  grid-template-areas:
    'pie pie bar bar'
    'table table table table';
`;

export const AmountContainer = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #00a635;
  flex-direction: column;

  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const ResizableCard = styled(Card)`
  display: flex;

  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
`;

export const ResizableBox = styled.div`
  display: flex;

  padding: 8px;

  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;

  ${(props) =>
    props.editing &&
    css`
      border-radius: 5px;
      border: 2px dashed #7485ff;
    `}
`;

export const BoxDragger = styled.div`
  visibility: hidden;

  ${(props) =>
    props.editing &&
    css`
      ${ResizableBox}:hover & {
        visibility: visible;
      }
    `}

  ${(props) =>
    !props.editing &&
    css`
      ${ResizableBox}:hover & {
        visibility: hidden;
      }
    `}
`;

export const BoxResizer = styled.div`
  visibility: hidden;

  ${(props) =>
    props.editing &&
    css`
      ${ResizableBox}:hover & {
        visibility: visible;
      }
    `}

  ${(props) =>
    !props.editing &&
    css`
      ${ResizableBox}:hover & {
        visibility: hidden;
      }
    `}
`;

export const AmountTitle = styled.div`
  font-size: 20px;
  color: #000000;
  margin-top: 20px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 50px;
`;

export const LoadingText = styled.div`
  font-size: 25px;
  color: #000000;
  margin-bottom: 20px;
`;

export const FilterChip = styled.div`
  padding: 9px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  color: #6b6b6b;
`;

export const FilterChipPrimaryText = styled.span`
  color: #000000;
`;

export const AddFilterButton = styled.div`
  padding: 9px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  cursor: pointer;
  color: #6b6b6b;
`;
