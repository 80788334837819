/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useHistory } from 'react-router-dom';
import { currencyFormatter } from '../../Utils';
import Avatar from '../Generics/Avatar';

import {
  CardButton,
  TableNameTitle,
  MobileUser,
  TotalSpent,
  SearchIcon,
} from '../../CoreStyles/GeneralStyles';
import UserButton from '../../images/user-button.svg';
import { Default, Mobile } from '../Devices';

import {
  TableUser,
  TableUserImageContainer,
  TableUserInfoPrimary,
  TableUserInfo,
  TableChip,
  TableUserInfoSecondary,
} from '../Settings/ManageTeam/ManageTeamStyle';

import { TableCell, TableRow } from '../../CoreStyles/TableStyles';

export default function UsersRowCard({ user, index }) {
  const history = useHistory();

  return (
    <>
      <Default>
        <TableRow
          onClick={() => {
            history.push(`/users/${user.id}`);
          }}
          gray={!(index % 2 === 0)}
          interactive
        >
          <TableCell>
            <TableUser>
              <TableUserImageContainer>
                <Avatar user={user} marginRight="10px" />
              </TableUserImageContainer>
              <TableUserInfo>
                <TableUserInfoPrimary>{user.name}</TableUserInfoPrimary>
                <TableUserInfoSecondary>{user.email}</TableUserInfoSecondary>
              </TableUserInfo>
            </TableUser>
          </TableCell>

          <TableCell> {user.company}</TableCell>
          <TableCell> {user.projects?.length}</TableCell>
          <TableCell>
            {' '}
            <TotalSpent>
              {currencyFormatter.format(user?.total_spend || 0)}
            </TotalSpent>{' '}
          </TableCell>
          <TableCell>
            {' '}
            {user.last_login && moment(user.last_login).fromNow()}
            {!user.last_login && <span>N/A</span>}
          </TableCell>
          <TableCell>
            {user.last_login && <TableChip>Active</TableChip>}
            {!user.last_login && <TableChip blue>Inactive</TableChip>}
          </TableCell>
        </TableRow>
      </Default>
      <Mobile>
        <CardButton
          width="100%"
          marginTop="4%"
          padding="15px 0px 10px 15px"
          noBorder
          hover
          shadow
        >
          <TableUser>
            <TableUserImageContainer>
              <Avatar user={user} marginRight="10px" />
            </TableUserImageContainer>
            <TableUserInfo>
              <TableUserInfoPrimary>{user.name}</TableUserInfoPrimary>
            </TableUserInfo>
          </TableUser>
          <SearchIcon
            float="right"
            height="28px"
            marginTop="2.5%"
            marginRight="4%"
            src={UserButton}
          />
          <MobileUser float="right" marginLeft="0">
            <TableNameTitle>
              {user.customerName}
              <TableNameTitle float="right" marginRight="30px">
                {user.projects}
              </TableNameTitle>
            </TableNameTitle>
            <TableNameTitle color="#858585">
              {user.company}
              <TotalSpent float="right" marginRight="30px">
                {user.totalSpent}
              </TotalSpent>
            </TableNameTitle>
          </MobileUser>
        </CardButton>
      </Mobile>
    </>
  );
}

UsersRowCard.propTypes = {
  user: PropTypes.object,
  index: PropTypes.number,
};

UsersRowCard.defaultProps = {
  user: null,
  index: null,
};
