/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { TotalSpent } from '../../CoreStyles/GeneralStyles';

import { Default } from '../Devices';

import {
  TableUser,
  TableUserImageContainer,
  TableUserInfo,
} from '../Settings/ManageTeam/ManageTeamStyle';

import { TableCell, TableRow } from '../../CoreStyles/TableStyles';

export default function UserRowSkeleton({ index }) {
  return (
    <>
      <Default>
        <TableRow gray={!(index % 2 === 0)} interactive>
          <TableCell>
            <TableUser>
              <TableUserImageContainer>
                <Skeleton circle width={30} height={30} />
              </TableUserImageContainer>
              <TableUserInfo
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Skeleton width={150} />
                <Skeleton width={200} />
              </TableUserInfo>
            </TableUser>
          </TableCell>

          <TableCell>
            {' '}
            <Skeleton width={100} />
          </TableCell>
          <TableCell>
            {' '}
            <Skeleton width={100} />
          </TableCell>
          <TableCell>
            {' '}
            <TotalSpent>
              <Skeleton width={75} />
            </TotalSpent>{' '}
          </TableCell>
          <TableCell>
            <Skeleton width={50} />
          </TableCell>
          <TableCell>
            <Skeleton width={50} />
          </TableCell>
        </TableRow>
      </Default>
    </>
  );
}
