/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const ModalCloseButton = styled.div`
  color: #6d6d6d;
  cursor: pointer;
`;

export const ModalBackButton = styled.div`
  color: #0d6cff;
  cursor: pointer;
  margin-right: 30px;
  display: flex;
  align-items: center;
`;

export const HelpOptionsContainer = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
`;

export const HelpOptionContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: ${(props) => (props.width ? props.width : '')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '')};
  @media screen and (max-width: 757px) {
    ${(props) =>
      props.width === '50%' &&
      css`
        width: 100%;
        margin-bottom: 25px;
      `}
  }
`;

export const PrimaryText = styled.span`
  margin-bottom: 5px;
  margin-top: 10px;
  display: block;
  font-size: 20px;
`;
export const SecondaryText = styled.span`
  display: block;
  font-size: 16px;
  color: #989898;
`;

export const HelpCard = styled.div`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(165, 165, 165, 0.25);
  border-radius: 10px;
  padding: ${(props) => (props.padding ? props.padding : '16px 18px')};
  width: ${(props) => (props.width ? props.width : '')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '')};
  @media screen and (max-width: 767px) {
    border-radius: 7px;
  }

  cursor: pointer;
  ${(props) =>
    props.methodList &&
    css`
      border: none;
      box-shadow: none;
      padding: 16px 18px 16px 30px;
      margin-right: ${() => (props.marginRight ? props.marginRight : '0')};
      margin-left: ${() => (props.marginLeft ? props.marginLeft : '0')};
    `}

  &:hover {
    background-color: #ececec;

    .add-payment-method-icon {
      color: #2b2b2b;
      background-color: #f9f9f9;
    }
  }
`;
