import { makeVar } from '@apollo/client';
import dialogTypes from './model';

const defaultDialogState = {
  modal: false,
  inline: false,
  type: null,
  title: null,
  callback: null,
  context: null,
};

export const dialogState = makeVar(defaultDialogState);

export const update = (newValues) => {
  const state = dialogState();
  dialogState({
    ...state,
    ...newValues,
  });
};

export const showConfirmPayment = (invoice, paymentMethod, callback) => {
  update({
    modal: !dialogState().inline,
    type: dialogTypes.confirm_payment,
    callback: callback ?? dialogState().callback,
    context: {
      invoice,
      paymentMethod,
    },
  });
};

export const showSelectMethod = (context, callback) => {
  update({
    modal: !dialogState().inline,
    callback: callback ?? dialogState().callback,
  });
};

export const showAddMethod = (context, callback) => {
  update({
    modal: !dialogState().inline,
    type: dialogTypes.add_method,
    callback: callback ?? dialogState().callback,
    context: {
      ...dialogState().context,
      ...context,
    },
  });
};

export const showAddBankAccount = (
  linkToken,
  receivedRedirectUri,
  callback
) => {
  update({
    modal: !dialogState().inline,
    type: dialogTypes.add_bank_account,
    callback: callback ?? dialogState().callback,
    context: {
      ...dialogState().context,
      linkToken,
      receivedRedirectUri,
    },
  });
};

export const showAddCreditCard = (context, callback) => {
  update({
    modal: !dialogState().inline,
    type: dialogTypes.add_credit_card,
    callback: callback ?? dialogState().callback,
    context: {
      ...dialogState().context,
      ...context,
    },
  });
};

export const showRefund = (context, callback) => {
  update({
    modal: true,
    inline: false,
    type: dialogTypes.refund,
    callback: callback ?? dialogState().callback,
    context,
  });
};

export const showConfirmAutoPay = (context, callback) => {
  update({
    modal: true,
    inline: false,
    type: dialogTypes.confirm_autopay,
    callback: callback ?? dialogState().callback,
    context,
  });
};

export const showDispute = (context, callback) => {
  update({
    modal: true,
    inline: false,
    type: dialogTypes.dispute,
    callback: callback ?? dialogState().callback,
    context,
  });
};

export const showModal = () => {
  update({ modal: true, inline: false });
};

export const hideModal = () => {
  update({ modal: false });
};

export const setInline = (inline) => {
  update({ inline });
};

export const setDialogType = (type) => {
  update({ type });
};

export const clearPaymentOAuthContext = () => {
  const { context } = dialogState();
  delete context.linkToken;
  delete context.receivedRedirectUri;

  update({
    context,
  });
};

export const showInvoice = (invoice, callback) => {
  update({
    modal: true,
    type: dialogTypes.pdf,
    callback: callback ?? dialogState().callback,
    context: {
      invoice,
      type: 'invoice',
    },
  });
};

export const showReceipt = (invoice, callback) => {
  update({
    modal: true,
    type: dialogTypes.pdf,
    callback: callback ?? dialogState().callback,
    context: {
      invoice,
      type: 'receipt',
    },
  });
};
