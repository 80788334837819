/* eslint-disable react/forbid-prop-types */
import { React } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableCard,
  TableHeader,
  TableHead,
  TableBody,
  Table,
  TableFooter,
  FooterLeft,
  TableCell,
  TableRow,
  TableLink,
} from '../../../../CoreStyles/TableStyles';

import { currencyFormatter } from '../../../../Utils';

function SummaryTable({ data, style, columns }) {
  const history = useHistory();

  function getTableHeaderForValue(value) {
    if (value === 'name') {
      return <TableHeader>Invoice Name</TableHeader>;
    }

    if (value === 'amount') {
      return <TableHeader>Amount</TableHeader>;
    }

    if (value === 'project') {
      return <TableHeader>Project</TableHeader>;
    }

    if (value === 'last_status_change_date') {
      return <TableHeader>Collected Date</TableHeader>;
    }

    if (value === 'due_date') {
      return <TableHeader>Invoice Date</TableHeader>;
    }

    return null;
  }
  function getTableCellForValue(invoice, value) {
    console.log('value is ', value);
    if (value === 'name') {
      return (
        <TableCell bold>
          <TableLink
            onClick={() => {
              history.push(`/project/${invoice.project.id}`);
            }}
          >
            {invoice.name}
          </TableLink>
        </TableCell>
      );
    }

    if (value === 'amount') {
      return <TableCell>{currencyFormatter.format(invoice.amount)}</TableCell>;
    }

    if (value === 'project') {
      return (
        <TableCell>
          {' '}
          <TableLink
            onClick={() => {
              history.push(`/project/${invoice.project.id}`);
            }}
          >
            {invoice.project?.name}
          </TableLink>
        </TableCell>
      );
    }

    if (value === 'last_status_change_date') {
      return (
        <TableCell>
          {moment(invoice.last_status_change_date).format('ll')}
        </TableCell>
      );
    }

    if (value === 'due_date') {
      return <TableCell>{moment(invoice.due_date).format('ll')}</TableCell>;
    }

    return null;
  }

  return (
    <TableCard style={style}>
      <Table>
        <TableHead>
          <tr>{columns.map((value) => getTableHeaderForValue(value))}</tr>
        </TableHead>
        <TableBody>
          {data &&
            data.invoices.map((invoice, index) => {
              const isGray = index % 2 === 0;
              return (
                <TableRow gray={!isGray}>
                  {columns.map((value) => getTableCellForValue(invoice, value))}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TableFooter>
        <FooterLeft>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">1</span> to{' '}
            <span className="font-medium">{data?.invoices?.length || 0}</span>{' '}
            of{' '}
            <span className="font-medium"> {data?.invoices?.length || 0}</span>{' '}
            results
          </p>
        </FooterLeft>
      </TableFooter>
    </TableCard>
  );
}

SummaryTable.propTypes = {
  data: PropTypes.any,
  style: PropTypes.any,
  columns: PropTypes.any,
};

SummaryTable.defaultProps = {
  data: null,
  style: null,
  columns: [],
};

export default SummaryTable;
