import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentDownloadIcon,
} from '@heroicons/react/outline';
import { Page, Section, Button } from '../../../CoreStyles/GeneralStyles';
import BackButton from '../../Common/BackButton';
import ModalPopUp from '../../Common/ModalPopUp';
import { GET_INVOICE_DATA } from '../../../GraphQl/Queries/invoiceQuery';
import {
  DILCard,
  DILTitle,
  DILCardDueDate,
  DILViewMore,
  DILCardAmount,
  DILDetails,
  DILLineItems,
  DILCharge,
  DILChargeDescription,
  DILQuantity,
  DILRate,
  DILTotal,
  DILTotalLine,
  DILHeader,
  DILCardHeader,
  DILPageHeader,
  DILBody,
  DILStatus,
  DILButton,
} from './DeepLinkedInvoiceStyle';
import AppDialogContent from '../../AppDialog/Content';
import { showDispute, showReceipt } from '../../AppDialog/state';
import { currencyFormatter } from '../../../Utils';
import PDFViewer from '../../AppDialog/PDFs';

import { INVOICE_STATUS_OPTIONS } from '../../../Enums';

export function DeepLinkedInvoice() {
  const { id } = useParams();

  const [expanded, setExpanded] = useState(false);
  const [showPdf, setShowPdf] = useState(false);

  const { data: invoiceQueryData, loading } = useQuery(GET_INVOICE_DATA, {
    variables: { id },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!invoiceQueryData?.invoice) {
    return <div>Invalid invoice</div>;
  }

  const invoice = invoiceQueryData?.invoice;

  return (
    <Page
      gridTemplateColumns="25% 25% 25% 25%"
      singleColumnNarrow
      typeTwo
      style={{ width: '500px', marginRight: 'auto', marginLeft: 'auto' }}
    >
      <ModalPopUp
        className="payment-modal"
        width={800}
        visible={showPdf}
        onCancel={() => setShowPdf(false)}
        title={null}
        closeIcon={null}
        header={null}
        closable={false}
        maskClosable={false}
        centered
        isOk={() => setShowPdf(false)}
        isCancel={() => setShowPdf(false)}
        footer={null}
        content={
          <PDFViewer
            hideHeader
            type="invoice"
            invoice={invoice}
            close={() => setShowPdf(false)}
          />
        }
      />
      <DILPageHeader style={{ gridArea: 'header' }}>
        <BackButton
          text="Back to Project"
          path={`/project/${invoice.project_id}`}
        />
        <Button thin withRightIcon onClick={() => setShowPdf(true)}>
          <DocumentDownloadIcon className="thin-button-left-icon" />
          Invoice PDF
        </Button>
      </DILPageHeader>
      <Section padding="0px" style={{ gridArea: 'main' }} width="100%">
        <DILCard>
          <DILCardHeader>
            <DILTitle>{invoice.name}</DILTitle>
            <DILCardAmount>
              {currencyFormatter.format(invoice.amount)}
            </DILCardAmount>
          </DILCardHeader>

          <DILCardDueDate>
            Due {moment(invoice.due_date).format('MMM D YYYY')}
          </DILCardDueDate>

          <DILStatus color={INVOICE_STATUS_OPTIONS[invoice.status]?.colorKey}>
            {' '}
            {INVOICE_STATUS_OPTIONS[invoice.status]?.title}
          </DILStatus>

          {expanded && (
            <DILBody>
              <DILDetails>
                <DILHeader>
                  <tr>
                    <DILChargeDescription>Description</DILChargeDescription>
                    <DILQuantity>Qty</DILQuantity>
                    <DILRate>Rate</DILRate>
                    <DILTotal>Amount</DILTotal>
                  </tr>
                </DILHeader>

                <DILLineItems>
                  {invoice.line_items.map((lineItem) => (
                    <DILCharge key={lineItem.id}>
                      <DILChargeDescription>
                        {lineItem.description}
                      </DILChargeDescription>
                      <DILQuantity>{lineItem.quantity}</DILQuantity>
                      <DILRate>{lineItem.rate}</DILRate>
                      <DILTotal>
                        {' '}
                        {currencyFormatter.format(
                          (lineItem.quantity || 0) * (lineItem.rate || 0) * 100
                        )}
                      </DILTotal>
                    </DILCharge>
                  ))}
                </DILLineItems>

                <DILTotalLine>
                  <DILChargeDescription>Total</DILChargeDescription>
                  <DILQuantity />
                  <DILRate />
                  <DILTotal>
                    {currencyFormatter.format(invoice.amount)}
                  </DILTotal>
                </DILTotalLine>
              </DILDetails>
            </DILBody>
          )}

          <DILViewMore>
            {' '}
            <Button
              onClick={() => {
                setExpanded(!expanded);
              }}
              thin
              withRightIcon
              centered
            >
              {expanded && <span>Hide Details</span>}
              {!expanded && <span>View Details</span>}
              {expanded && <ChevronUpIcon className="thin-button-right-icon" />}
              {!expanded && (
                <ChevronDownIcon className="thin-button-right-icon" />
              )}
            </Button>
          </DILViewMore>
        </DILCard>
        <DILCard>
          {invoice.status === 'pending_approval' && (
            <AppDialogContent inlineContext={{ invoice }} />
          )}
          {(invoice.status === 'complete' || invoice.status === 'refunded') && (
            <DILButton
              primary
              onClick={() => {
                showReceipt(invoice);
              }}
            >
              Download Receipt
            </DILButton>
          )}

          {invoice.status === 'refunded' && (
            <DILButton primary onClick={() => showReceipt(invoice)}>
              View Refund Receipt
            </DILButton>
          )}

          {invoice.status === 'complete' && (
            <DILButton
              secondary
              onClick={() => {
                showDispute({ invoice });
              }}
            >
              Dispute Charge
            </DILButton>
          )}
        </DILCard>
        {invoice.status === 'pending_approval' && (
          <DILCard style={{ padding: '30px' }}>
            By clicking the Pay Button above, you approve the invoice and
            authorize release of payment
          </DILCard>
        )}
      </Section>
    </Page>
  );
}

export default DeepLinkedInvoice;
