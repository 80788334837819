/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

import { cardOptions } from '../../../Enums';
import BankIcon from '../../../images/bank_icon.svg';

import SelectPaymentMethod from '../../AppDialog/Payments/SelectPaymentMethod';

import {
  PaymentMethodSelectorContainer,
  PaymentMethodText,
  PaymentMethodInfo,
  PaymentMethodImage,
  PaymentMethodSelectorContainerContent,
} from './PaymentMethodSelectorStyle';

function PaymentMethodSelector({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  user,
  onClick,
}) {
  const [selectActive, setSelectActive] = useState(false);

  if (selectActive) {
    return (
      <PaymentMethodSelectorContainer style={{ display: 'block' }}>
        <PaymentMethodSelectorContainerContent
          onClick={() => setSelectActive(false)}
        >
          <PaymentMethodText>Select Payment Method</PaymentMethodText>
          <ChevronUpIcon className="payment-chevron" />
        </PaymentMethodSelectorContainerContent>

        <SelectPaymentMethod
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={(paymentMethod) => {
            setSelectedPaymentMethod(paymentMethod);
            setSelectActive(false);
          }}
          user={user}
        />
      </PaymentMethodSelectorContainer>
    );
  }

  return (
    <>
      <PaymentMethodSelectorContainer
        onClick={() => {
          setSelectActive(true);

          onClick();
        }}
      >
        <PaymentMethodInfo>
          {selectedPaymentMethod?.type === 'card' && (
            <PaymentMethodImage
              src={cardOptions[selectedPaymentMethod?.card_brand].icon}
              alt="Visa Icon"
            />
          )}

          {selectedPaymentMethod?.type === 'bank_account' && (
            <PaymentMethodImage src={BankIcon} alt="Visa Icon" />
          )}
          <PaymentMethodText>
            {' '}
            {selectedPaymentMethod?.type === 'card' &&
              cardOptions[selectedPaymentMethod?.card_brand].title}
            {selectedPaymentMethod?.type === 'bank_account' && 'Bank'} Ending in{' '}
            {selectedPaymentMethod?.last4}
          </PaymentMethodText>
        </PaymentMethodInfo>
        <ChevronDownIcon className="payment-chevron" />
      </PaymentMethodSelectorContainer>
    </>
  );
}

PaymentMethodSelector.propTypes = {
  selectedPaymentMethod: PropTypes.element,
  user: PropTypes.element,
  setSelectedPaymentMethod: PropTypes.func,
  onClick: PropTypes.func,
};

PaymentMethodSelector.defaultProps = {
  selectedPaymentMethod: null,
  setSelectedPaymentMethod: null,
  user: null,
  onClick: null,
};

export default PaymentMethodSelector;
