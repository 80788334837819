/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const ADJUST_CREDIT_BALANCE = gql`
  mutation adjustCreditBalance($credit_balance_input: CreditBalanceInput!) {
    adjustCreditBalance(input: $credit_balance_input) {
      user_id
      organization_id
      balance
    }
  }
`;
