/* eslint-disable camelcase */
import React from 'react';
import AvatarList from 'ant-design-pro/lib/AvatarList';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import {
  AmountValue,
  StatusContainer,
  StatusBubble,
  ProgressTitle,
  ViewMoreButton,
  ViewMoreArrow,
  ViewMoreText,
  ProjectRowContainer,
  ProjectNameTitle,
  ProjectRowHeader,
  ProjectRowFooter,
  BlankDiv,
  ProjectRowSpan,
  ProjectName,
} from './ProjectRowCardStyle';
import { Card } from '../../CoreStyles/GeneralStyles';
import ViewMoreArrowSvg from '../../images/view-more-arrow.svg';

import { Mobile, Default } from '../Devices';

import { currencyFormatter } from '../../Utils';

// import { vocabulary } from '../../Enums';

export function ProjectRowCard(props) {
  const { projectItem, projects_vocabulary, current_user_role } = props;

  console.log('projects', projects_vocabulary);
  // const invoiceCompleted =
  //   projectItem &&
  //   projectItem?.invoices.filter((item) => item.status === 'Project Complete');
  console.log(projectItem, 'djsfgjsdf');

  const history = useHistory();

  const collaboratorAvatars = [];

  projectItem.users?.forEach((user) => {
    collaboratorAvatars.push(
      <AvatarList.Item
        tips={user.name}
        src={user.image_thumb}
        onClick={() => {
          history.push(`/users/${user.id}`);
        }}
      />
    );
  });

  function getStatus() {
    const projectStatus = projectItem.status;

    if (projectStatus.status === 'not_started') {
      return (
        <StatusContainer gray>
          Not Started
          <StatusBubble gray />
        </StatusContainer>
      );
    }

    if (projectStatus.status === 'in_progress') {
      return (
        <StatusContainer blue>
          Work In Progress
          <StatusBubble blue />
        </StatusContainer>
      );
    }

    if (projectStatus.status === 'pending_approval') {
      return (
        <StatusContainer orange>
          {projectStatus.invoice_count || 0} Invoice Outstanding
          <StatusBubble orange />
          {current_user_role !== 'client' && (
            <AmountValue>
              ({currencyFormatter.format(projectStatus.total_amount || 0)})
            </AmountValue>
          )}
        </StatusContainer>
      );
    }

    if (projectStatus.status === 'complete ') {
      return (
        <StatusContainer green>
          Project Complete
          <StatusBubble green />
        </StatusContainer>
      );
    }

    return (
      <StatusContainer gray>
        Not Started
        <StatusBubble gray />
      </StatusContainer>
    );
  }

  return (
    <>
      {projectItem ? (
        <Card
          onClick={() => {
            history.push(`/project/${projectItem.id}`);
          }}
          hover
          pointer
          width="100%"
          marginBottom="10px"
        >
          <Default>
            <ProjectRowContainer>
              <ProjectNameTitle>
                <ProjectName>{projectItem.name}</ProjectName>
                {current_user_role !== 'client' && (
                  <AmountValue>
                    ({currencyFormatter.format(projectItem.total_size || 0)})
                  </AmountValue>
                )}
              </ProjectNameTitle>
              <AvatarList
                maxLength={projectItem?.users?.length}
                excessItemsStyle={{
                  color: '#000000',
                  backgroundColor: '#F0F0F0',
                  fontSize: '10px',
                }}
                size="small"
              >
                {collaboratorAvatars}
              </AvatarList>

              {getStatus()}

              <ProgressTitle>
                {/* {invoiceCompleted?.length}/{projectItem?.invoices?.length}{' '} */}
                {projectItem.total_invoices_complete || 0}/
                {projectItem.total_invoices_count || 0} Complete
              </ProgressTitle>

              <ViewMoreButton
                onClick={() => {
                  history.push(`/project/${projectItem.id}`);
                }}
              >
                <ViewMoreText>View</ViewMoreText>
                <ViewMoreArrow src={ViewMoreArrowSvg} />
              </ViewMoreButton>
            </ProjectRowContainer>
          </Default>

          <Mobile>
            <ProjectRowContainer>
              <ProjectRowHeader>
                <ProjectNameTitle>{projectItem?.name}</ProjectNameTitle>
                <StatusContainer orange>
                  $2,000 owed
                  <StatusBubble orange />
                </StatusContainer>
              </ProjectRowHeader>
              <ProjectRowFooter>
                <AvatarList
                  maxLength={3}
                  excessItemsStyle={{
                    color: '#000000',
                    backgroundColor: '#F0F0F0',
                    fontSize: '10px',
                  }}
                  size="mini"
                >
                  {collaboratorAvatars}
                </AvatarList>

                <ViewMoreButton
                  onClick={() => {
                    history.push(`/project/${projectItem?.id}`);
                  }}
                >
                  <ViewMoreText>View</ViewMoreText>
                  <ViewMoreArrow src={ViewMoreArrowSvg} />
                </ViewMoreButton>
              </ProjectRowFooter>
            </ProjectRowContainer>
          </Mobile>
        </Card>
      ) : (
        [1, 2, 3, 4].map(() => (
          <>
            <Default>
              <Card width="100%" marginBottom="10px">
                <ProjectRowContainer style={{ alignItems: 'center' }}>
                  <BlankDiv>
                    <ProjectRowSpan marginRight="10px">
                      <Skeleton width={100} />
                    </ProjectRowSpan>
                    <ProjectRowSpan>
                      <Skeleton width={60} />
                    </ProjectRowSpan>
                  </BlankDiv>
                  <BlankDiv>
                    <Skeleton circle width={30} height={30} count={3} />
                  </BlankDiv>

                  <BlankDiv>
                    <ProjectRowSpan marginRight="10px">
                      <Skeleton width={100} />
                    </ProjectRowSpan>
                    <ProjectRowSpan>
                      <Skeleton width={70} />
                    </ProjectRowSpan>
                  </BlankDiv>
                  <BlankDiv>
                    <Skeleton width={100} />
                  </BlankDiv>
                  <BlankDiv>
                    <Skeleton width={50} />
                  </BlankDiv>
                </ProjectRowContainer>
              </Card>
            </Default>

            <Mobile>
              <Card width="100%" marginBottom="10px">
                <ProjectRowContainer>
                  <ProjectRowHeader>
                    <ProjectNameTitle>
                      <Skeleton width={100} />
                    </ProjectNameTitle>
                    <StatusContainer>
                      <ProjectRowSpan marginRight="5px">
                        <Skeleton width={100} />
                      </ProjectRowSpan>
                      <ProjectRowSpan>
                        <Skeleton circle width={15} height={15} />
                      </ProjectRowSpan>
                    </StatusContainer>
                  </ProjectRowHeader>
                  <ProjectRowFooter>
                    <ProjectRowSpan>
                      <Skeleton circle width={20} height={20} count={3} />
                    </ProjectRowSpan>
                    <ViewMoreButton>
                      <Skeleton width={50} />
                    </ViewMoreButton>
                  </ProjectRowFooter>
                </ProjectRowContainer>
              </Card>
            </Mobile>
          </>
        ))
      )}
    </>
  );
}
ProjectRowCard.propTypes = {
  projectItem: PropTypes.string,
  id: PropTypes.string,
  projects_vocabulary: PropTypes.string,
  current_user_role: PropTypes.string,
};
ProjectRowCard.defaultProps = {
  projectItem: '',
  id: '',
  projects_vocabulary: 'projects',
  current_user_role: 'client',
};
export default ProjectRowCard;
