/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable dot-notation */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useQuery } from '@apollo/client';
import { InputGroup, Label } from '../../../CoreStyles/GeneralStyles';
import DiscountIcon from '../../../images/discount-icon.svg';

import {
  PayButton,
  FinePrintText,
} from '../Payments/ConfirmPaymentScreenStyle';
import PaymentMethodSelector from '../../Common/PaymentMethodSelector';
import { EnrollBody, EnrollImage, EnrollDescription } from './AutoPayCardStyle';

import {
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
} from '../Payments/UserPaymentModalStyle';
import { hideModal, showConfirmAutoPay, showSelectMethod } from '../state';
import { GET_PROJECT_FEES } from '../../../GraphQl/Queries/projectQueries';

export default function ConrimAutoPay({
  selectedPaymentMethod,
  projectId,
  setSelectedPaymentMethod,
  user,
  updateAutoPaySettings,
}) {
  const { data: feesData } = useQuery(GET_PROJECT_FEES, {
    variables: {
      id: projectId,
    },
  });

  return (
    <div>
      <ModalHeader>
        <ModalTitle>Enroll in AutoPay</ModalTitle>
        <ModalCloseButton onClick={hideModal}>Close</ModalCloseButton>
      </ModalHeader>
      {feesData?.project?.fees?.auto_pay?.enabled && (
        <EnrollBody>
          <EnrollImage src={DiscountIcon} />
          <EnrollDescription>
            Save {feesData?.project?.fees?.auto_pay?.fee}% extra when you enroll
            in AutoPay
          </EnrollDescription>
        </EnrollBody>
      )}

      <InputGroup>
        <Label>Payment Method </Label>
        <PaymentMethodSelector
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={(paymentMethod) => {
            setSelectedPaymentMethod(paymentMethod);
            showConfirmAutoPay({
              paymentMethod,
              projectId,
              user,
              updateAutoPaySettings,
            });
          }}
          user={user}
          onClick={() => {
            showSelectMethod({}, (paymentMethod) =>
              showConfirmAutoPay({
                paymentMethod,
                projectId,
                user,
                updateAutoPaySettings,
              })
            );
          }}
        />
      </InputGroup>
      <PayButton
        onClick={() => {
          updateAutoPaySettings({
            variables: {
              id: projectId,
              auto_pay_settings: {
                enabled: true,
                payer: user.id,
                payment_method: selectedPaymentMethod.id,
              },
            },
          }).then(() => {
            hideModal();
          });
        }}
      >
        Enroll
      </PayButton>
      <FinePrintText>
        By clicking “Entroll” you authorize release of payment for future
        invoices.
      </FinePrintText>
    </div>
  );
}
