import { gql } from '@apollo/client';

export const GET_INVOICE_DATA = gql`
  query invoice($id: String!) {
    invoice(id: $id) {
      id
      name
      amount
      due_date
      invoice_date
      status
      description
      decline_reason
      project_id
      recurring
      net_d
      date_created
      line_items {
        id
        rate
        description
        quantity
      }
      adjustments {
        type
        amount
        percentage
        description
      }
    }
  }
`;

export const GET_INVOICE_PAYMENTS = gql`
  query invoicePayments($id: String!) {
    getPaymentsForInvoice(invoiceId: $id) {
      id
      payment_method
      charges {
        id
        payment_id
        external_id
        payment_method
        amount
        status
      }
      invoice
      date
      user_id
      status
      amount
      credits_used
      total_spend
      credits_charged
    }
  }
`;
export default GET_INVOICE_DATA;
