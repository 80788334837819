/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { DocumentAddIcon } from '@heroicons/react/outline';
import {
  SectionTitle,
  SectionContent,

  // SkeletonSpan,
  // Div,
  Button,
  Page,
  Section,
} from '../../CoreStyles/GeneralStyles';
import ProjectInfo from './ProjectInfo';
import ModalPopUp from '../Common/ModalPopUp';

import ProjectSettings from './ProjectSettings';
import { NewInvoiceWrapper } from './indexStyle';
import { GET_PROJECTS_DATA } from '../../GraphQl/Queries/projectQueries';
import NewInvoice from './NewInvoice';
import InvoiceCard from '../Invoice/InvoiceCard';
import AutoPayCard from './AutoPayCard';
import RecurringInvoice from '../Invoice/RecurringInvoice';
import InvoiceSection from '../Invoice/InvoiceSection';

import BackButton from '../Common/BackButton';

import { isMobile } from '../Devices';

import { vocabulary, INVOICE_STATUS_OPTIONS } from '../../Enums';
import { isAllowed, OrgContext } from '../../Utils';

export const Project = (props) => {
  const orgData = useContext(OrgContext);
  const allprops = props;
  const id = allprops?.match?.params?.id;

  const { loading: projectLoading, data } = useQuery(GET_PROJECTS_DATA, {
    variables: { id },
  });

  // const projectData =
  //   data && data?.projects.filter((item) => item.id === projectId);

  const [newInvoice, setNewInvoice] = useState(false);

  const [projectEditorVisible, setProjectEditorVisible] = useState(false);

  const handleNewInvoice = () => {
    setNewInvoice(true);
  };
  const handleNewInvoiceCancel = (value) => {
    setNewInvoice(value);
  };

  const [openSections, setOpenSections] = useState(['pending_approval']);

  return (
    <>
      <Page gridTemplateColumns="350px 500px" doubleColumnLarge typeOne>
        <BackButton
          path="/"
          text={`All
              ${
                vocabulary[orgData?.projects_vocabulary]?.plural_cap ||
                'Projects'
              }`}
        />

        <Section padding="0px" style={{ gridArea: 'sidebar' }}>
          <div style={{ position: 'sticky', top: '30px' }}>
            <SectionTitle>
              {' '}
              {vocabulary[orgData?.projects_vocabulary]?.singular_cap ||
                'Project'}{' '}
              Info
            </SectionTitle>
            <SectionContent>
              <ProjectInfo
                projectVocab={orgData?.projects_vocabulary}
                projectInfoData={data}
                projectEditorVisible={projectEditorVisible}
                setProjectEditorVisible={setProjectEditorVisible}
                projectRole={data?.project?.current_user_role || 'viewer'}
                orgRole={orgData?.current_user_role || 'client'}
              />
            </SectionContent>
            <SectionContent style={{ marginTop: '20px' }}>
              <AutoPayCard
                autoPaySettings={data?.project?.auto_pay_settings}
                payers={data?.project?.users?.filter(
                  (user) => user.role === 'payer'
                )}
                projectId={data?.project?.id}
              />
            </SectionContent>
            {data?.project?.recurring_invoices &&
              data?.project?.recurring_invoices?.length > 0 && (
                <>
                  <SectionTitle style={{ marginTop: '20px' }}>
                    Recurring Invoices
                  </SectionTitle>
                  <SectionContent>
                    {data?.project?.recurring_invoices.map((invoice) => (
                      <RecurringInvoice
                        role={data?.project?.current_user_role}
                        invoice={invoice}
                      />
                    ))}
                  </SectionContent>
                </>
              )}
          </div>
        </Section>
        <Section padding="0px" style={{ gridArea: 'main' }}>
          <SectionContent>
            <>
              {projectLoading && (
                <>
                  <InvoiceCard />
                  <InvoiceCard />
                </>
              )}

              {!projectLoading && data && data?.project?.invoices && (
                <div>
                  {Object.keys(INVOICE_STATUS_OPTIONS).map((option) => (
                    <InvoiceSection
                      invoices={data?.project?.invoices}
                      type={option}
                      role={data?.project?.current_user_role}
                      openSections={openSections}
                      setOpenSections={setOpenSections}
                      fees={data?.project?.fees}
                    />
                  ))}
                </div>
              )}

              {!projectLoading &&
                data?.project?.invoices &&
                data?.project?.invoices.length > 0 && (
                  <NewInvoiceWrapper>
                    {data?.project?.current_user_role === 'admin' ||
                    data?.project?.current_user_role === 'owner' ||
                    isAllowed(orgData?.current_user_role, 'projects.create') ? (
                      <Button primary onClick={handleNewInvoice}>
                        New Invoice
                      </Button>
                    ) : null}
                  </NewInvoiceWrapper>
                )}

              {data?.project?.current_user_role === 'admin' ||
                data?.project?.current_user_role === 'owner' ||
                (isAllowed(orgData?.current_user_role, 'projects.create') &&
                  projectLoading &&
                  data &&
                  !data?.project?.invoices) ||
                (data?.project?.invoices.length === 0 && (
                  <div className="text-center">
                    <DocumentAddIcon
                      style={{ height: '50px', margin: 'auto' }}
                    />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No Invoices
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a new invoice.
                    </p>
                    <div className="mt-6">
                      <Button primary onClick={handleNewInvoice}>
                        New Invoice
                      </Button>
                    </div>
                  </div>
                ))}
            </>
          </SectionContent>
        </Section>
      </Page>

      <ModalPopUp
        className="common-modal"
        width={isMobile() ? '90%' : '60%'}
        style={{ maxWidth: '600px', top: isMobile() ? '10px' : '50px' }}
        title={`${
          vocabulary[orgData?.projects_vocabulary]?.singular_cap || 'Project'
        } Settings`}
        closeIcon="Close"
        closable={projectEditorVisible}
        // maskClosable={false}
        centered
        visible={projectEditorVisible}
        isCancel={() => setProjectEditorVisible(false)}
        footer={null}
        content={
          <ProjectSettings
            projectVocab={orgData?.projects_vocabulary}
            projectInfoData={data}
            handleClose={() => {
              setProjectEditorVisible(false);
            }}
          />
        }
      />

      <ModalPopUp
        className="common-modal"
        width={isMobile() ? '90%' : '60%'}
        style={{ maxWidth: '600px', top: isMobile() ? '10px' : '50px' }}
        title="New Invoice"
        closeIcon="Close"
        closable={newInvoice}
        // maskClosable={false}
        centered
        visible={newInvoice}
        isOk={handleNewInvoice}
        isCancel={() => handleNewInvoiceCancel(false)}
        footer={null}
        content={
          <NewInvoice
            projectInfoData={data}
            onClose={() => {
              handleNewInvoiceCancel(false);
            }}
            onCreation={(status) => {
              const openSectionsCopy = openSections.slice();
              openSectionsCopy.push(status);
              setOpenSections(openSectionsCopy);
            }}
          />
        }
      />
    </>
  );
};
export default Project;
