import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowRightIcon,
} from '@heroicons/react/outline';
import { useQuery } from '@apollo/client';
import { currencyFormatter } from '../../Utils';
import { SectionTitle, Button, Card } from '../../CoreStyles/GeneralStyles';

import {
  ProjectRow,
  ProjectName,
  ProjectRole,
  ViewButton,
  ProjectMeta,
} from './UserProjCardStyle';

import { GET_PAYMENTS_FOR_USER } from '../../GraphQl/Queries/usersQueries';

export default function PayHistory({ userId }) {
  const [expanded, setExpanded] = useState(false);
  const { loading, data: paymentHistory } = useQuery(GET_PAYMENTS_FOR_USER, {
    variables: { userId },
  });

  const history = useHistory();
  console.log('eeee: ', paymentHistory?.getPaymentsForUser);
  return (
    <>
      <SectionTitle marginTop="3%" marginBottom="1%">
        Payment History
      </SectionTitle>
      <Card display="grid" padding="20px 25px" marginBottom="20px">
        {!loading &&
          (!paymentHistory?.getPaymentsForUser ||
            paymentHistory?.getPaymentsForUser?.length === 0) && (
            <div>No Payments Made</div>
          )}

        {!loading &&
          paymentHistory?.getPaymentsForUser?.length !== 0 &&
          paymentHistory?.getPaymentsForUser
            ?.slice(
              0,
              expanded ? paymentHistory?.getPaymentsForUser?.length : 3
            )
            .map((payment) => (
              <ProjectRow key={payment.id}>
                <ProjectMeta>
                  <ProjectName>
                    {' '}
                    {currencyFormatter.format(payment.total_spend || 0)}
                  </ProjectName>
                  <ProjectRole>
                    {' '}
                    {moment(payment?.date).format('MMM Do YYYY (h:mm a)')}
                  </ProjectRole>
                </ProjectMeta>

                <ViewButton
                  onClick={() => {
                    history.push(`/invoice/${payment.invoice}`);
                  }}
                >
                  View Invoice
                  <ArrowRightIcon className="thin-button-right-icon" />
                </ViewButton>
              </ProjectRow>
            ))}

        {loading && <Skeleton count={3} />}

        {!loading &&
          paymentHistory?.getPaymentsForUser &&
          paymentHistory?.getPaymentsForUser?.length > 3 && (
            <Button
              onClick={() => {
                setExpanded(!expanded);
              }}
              thin
              withRightIcon
              centered
            >
              {expanded && <span>View Less</span>}
              {!expanded && <span>View More</span>}
              {expanded && <ChevronUpIcon className="thin-button-right-icon" />}
              {!expanded && (
                <ChevronDownIcon className="thin-button-right-icon" />
              )}
            </Button>
          )}
      </Card>
    </>
  );
}

PayHistory.propTypes = {
  userId: PropTypes.string,
};

PayHistory.defaultProps = {
  userId: null,
};
