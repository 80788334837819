/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ProjectRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ProjectMeta = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectName = styled.div`
  font-size: 15px;
  color: #212121;
  font-weight: 400;
`;

export const ProjectRole = styled.div`
  font-size: 14px;
  color: #848484;
`;

export const ViewButton = styled.div`
  color: #0c6bff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &:hover {
    color: #000000;
  }
`;
