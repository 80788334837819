import { gql } from '@apollo/client';

export const GET_PROJECTS_DATA = gql`
  query project($id: String!) {
    project(id: $id) {
      id
      name
      date_started
      auto_pay_settings {
        enabled
        payer
        payment_method
      }
      email_settings {
        completion_emails_enabled
        reminder_emails_enabled
      }
      total_size
      total_invoices_complete
      total_invoices_count
      invoice_order {
        sort_by
        direction
      }
      fees {
        early_pay {
          enabled
          fee
          days
        }
        late_pay {
          enabled
          fee
          days
        }
        auto_pay {
          enabled
          fee
          days
        }
      }
      status {
        status
        invoice_count
        total_amount
      }
      recurring_invoices {
        id
        name
        amount
        billing_start
        enabled
        date_created
        recurrence
        line_items {
          id
          rate
          quantity
          description
        }
      }
      invoices {
        id
        name
        amount
        due_date
        invoice_date
        status
        description
        decline_reason
        project_id
        recurring
        date_created
        line_items {
          id
          rate
          quantity
          description
        }
      }
      current_user_role
      payers {
        id
        name
        email
        role
        customer_id
        image
        image_thumb
        payment_methods {
          id
          last4
          type
          exp_month
          exp_year
          card_brand
          bank_name
        }
      }
      users {
        id
        name
        email
        role
        customer_id
        image
        image_thumb
        payment_methods {
          id
          last4
          type
          exp_month
          exp_year
          card_brand
          bank_name
        }
      }
      user_invites {
        id
        email
        role
      }
    }
  }
`;

export const GET_AUTOPAY_SETTINGS = gql`
  query autoPaySettings($id: String!) {
    project(id: $id) {
      id
      auto_pay_settings {
        enabled
        payer
        payment_method
      }
      current_user_role
      fees {
        early_pay {
          enabled
          fee
          days
        }
        late_pay {
          enabled
          fee
          days
        }
      }
      users {
        id
        name
        email
        role
        customer_id
        image
        image_thumb
        payment_methods {
          id
          last4
          type
          exp_month
          exp_year
          card_brand
          bank_name
        }
      }
    }
  }
`;

export const GET_PROJECT_FEES = gql`
  query projectFees($id: String!) {
    project(id: $id) {
      id
      fees {
        early_pay {
          enabled
          fee
          days
        }
        late_pay {
          enabled
          fee
          days
        }
        auto_pay {
          enabled
          fee
          days
        }
      }
    }
  }
`;

export const GET_PROJECT_PAYERS = gql`
  query projectPayers($id: String!) {
    project(id: $id) {
      id
      payers {
        id
        name
        email
        role
        customer_id
        image
        image_thumb
        payment_methods {
          id
          last4
          type
          exp_month
          exp_year
          card_brand
          bank_name
        }
      }
    }
  }
`;

export default GET_PROJECTS_DATA;
