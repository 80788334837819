import moment from 'moment';
import _ from 'lodash';
import { createContext } from 'react';
import roles from '../Roles/defaultOrgRoles';

const TOKEN_KEY = 'jwt';

export const isLoggedIn = () => localStorage.getItem(TOKEN_KEY) != null;

export const emailValidation = (value) => {
  const regexVal =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regexVal.test(value);
};

export const passwordValidation = (value) => {
  const regexVal = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return regexVal.test(value);
};

export const domainValidation = (value) => {
  const regexVal =
    /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  return regexVal.test(value);
};

export const id = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const validateForm = (stateValue) => {
  let valueCheck = true;
  let valid = true;
  Object.entries(stateValue).forEach(([key, value]) => {
    if (key !== 'errors') {
      if (value !== 'undefined' && value.length !== 0) {
        valueCheck = valueCheck && true;
      } else {
        valueCheck = valueCheck && false;
      }
    } else {
      Object.values(stateValue.errors).forEach((val) => {
        if (val !== 'undefined' && val?.length === 0) {
          valid = valid && true;
        } else {
          valid = valid && false;
        }
      });
    }
  });
  const finalValid = valid && valueCheck;
  return finalValid;
};

// Create our number formatter.
export const currencyFormatter = {
  format: (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value / 100);
  },
};

export function getQuarterRange(quarter) {
  const start = moment().quarter(quarter).startOf('quarter');

  const end = moment().quarter(quarter).endOf('quarter');

  return { start, end };
}

export function calculateFees(fees, invoice, showUpcoming = false) {
  if (fees?.early_pay?.enabled === true) {
    const deadline = moment(invoice.invoice_date).add(
      fees.early_pay.days,
      'days'
    );

    if (new Date() < deadline.toDate()) {
      // We paid x days after the invoice date, we apply the ealy fee
      return {
        type: 'early',
        percentage: fees.early_pay.fee,
        value: invoice.amount * (fees.early_pay.fee / 100),
        newAmount: invoice.amount * (1 - fees.early_pay.fee / 100),
        daysUntilDeadline: moment(deadline).diff(moment(), 'days'),
      };
    }
  }

  if (fees?.late_pay?.enabled === true) {
    const deadline = moment(invoice.due_date).add(fees.late_pay.days, 'days');

    if (new Date() > deadline.toDate()) {
      // We paid x days after deadline, we apply late fee
      return {
        type: 'late',
        percentage: fees.late_pay.fee,
        value: invoice.amount * (fees.late_pay.fee / 100),
        newAmount: invoice.amount * (1 + fees.late_pay.fee / 100),
        daysLate: Math.abs(moment(deadline).diff(moment(), 'days')),
      };
    }

    // If we are within 3 days of the deadline, return a warning
    if (
      showUpcoming &&
      Math.abs(moment(deadline).diff(moment(), 'days')) <= 3
    ) {
      return {
        type: 'upcoming',
        percentage: fees.late_pay.fee,
        value: invoice.amount * (fees.late_pay.fee / 100),
        newAmount: invoice.amount * (1 + fees.late_pay.fee / 100),
        daysUntilDeadline: Math.abs(moment(deadline).diff(moment(), 'days')),
      };
    }
  }

  return null;
}

export function isAllowed(orgRole, actionPath) {
  return _.get(roles[orgRole]?.permissions, actionPath, false);
}

export const OrgContext = createContext();

export const getOrgSubdomain = () => {
  if (window.location.host.includes('localhost')) {
    // Return default subdomain for local development
    return 'growthbird';
  }

  const subdomain = window.location.host.split('.')[0];
  return subdomain;
};
