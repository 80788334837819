import React, { useState, useContext } from 'react';

import { PropTypes } from 'prop-types';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { Button, Card } from '../../CoreStyles/GeneralStyles';
import UpdateInvoice from './UpdateInvoices';
import {
  InvoiceContainer,
  InvoiceHeader,
  InvoiceHeaderLeft,
  InvoiceHeaderRight,
  InvoiceBody,
  InvoiceAmount,
  InvoiceFooter,
  InvoiceFooterLeft,
  InvoiceFooterRight,
} from './InvoiceCardStyle';
import {
  DILDetails,
  DILLineItems,
  DILCharge,
  DILChargeDescription,
  DILQuantity,
  DILRate,
  DILTotal,
  DILTotalLine,
  DILHeader,
  DILBody,
} from './DeepLinkedInvoice/DeepLinkedInvoiceStyle';
import {
  ProjectPrimaryText,
  ProjectSecondaryText,
} from '../Project/ProjectStyle';
import {
  StatusContainer,
  StatusBubble,
} from '../Dashboard/ProjectRowCardStyle';
import ModalPopUp from '../Common/ModalPopUp';
import {
  BlankSpan,
  SkeletonWrapper,
  InvoiceStatusWrapper,
} from './PayeeInvoiceCardStyle';
import { INVOICE_STATUS_OPTIONS } from '../../Enums';
import { isMobile } from '../Devices';
import {
  currencyFormatter,
  isAllowed,
  OrgContext,
  calculateFees,
} from '../../Utils';
import {
  showConfirmPayment,
  showRefund,
  showDispute,
  showInvoice,
  showReceipt,
  showModal,
} from '../AppDialog/state';

export function InvoiceCard({ Invoice, role, fees }) {
  const orgData = useContext(OrgContext);
  const [editInvoice, setEditInvoice] = useState(false);

  const handleEditInvoiceOPen = () => {
    setEditInvoice(true);
  };
  const handleEditInvoiceClose = () => {
    setEditInvoice(false);
  };

  const dueDate = Invoice && new Date(Invoice.due_date);

  const [expanded, setExpanded] = useState(false);

  const feeObject = calculateFees(fees, Invoice, true);

  return (
    <>
      {Invoice ? (
        <Card width="100%" marginBottom="15px" padding="25px">
          <InvoiceContainer>
            <InvoiceHeader>
              <InvoiceHeaderLeft>
                <ProjectPrimaryText marginBottom="3px" fontSize="17px">
                  {Invoice.name} {Invoice.invoice_date?.toString()}
                </ProjectPrimaryText>
                <ProjectSecondaryText fontSize="14px">
                  Due {moment(dueDate).format('MMM D YYYY')}
                </ProjectSecondaryText>
              </InvoiceHeaderLeft>
              <InvoiceHeaderRight>
                <StatusContainer
                  color={INVOICE_STATUS_OPTIONS[Invoice.status]?.color}
                  marginBottom="5px"
                >
                  {INVOICE_STATUS_OPTIONS[Invoice.status]?.title}
                  <StatusBubble
                    color={INVOICE_STATUS_OPTIONS[Invoice.status]?.color}
                  />
                </StatusContainer>
                <InvoiceAmount>
                  {currencyFormatter.format(Invoice.amount)}
                </InvoiceAmount>
              </InvoiceHeaderRight>
            </InvoiceHeader>
            <InvoiceBody>
              {' '}
              {Invoice.status === 'pending_approval' && feeObject && (
                <DILBody>
                  {feeObject.type === 'early' && (
                    <span>
                      Pay within {feeObject.daysUntilDeadline} days to get a{' '}
                      {feeObject.percentage}% discount 🙌
                    </span>
                  )}

                  {feeObject.type === 'upcoming' && (
                    <span>
                      Pay within {feeObject.daysUntilDeadline} days to avoid a{' '}
                      {feeObject.percentage}% late fee 👀
                    </span>
                  )}

                  {feeObject.type === 'late' && (
                    <span>
                      This invoice was due {feeObject.daysLate} days ago. A{' '}
                      {feeObject.percentage}% late fee has been applied.
                    </span>
                  )}
                </DILBody>
              )}
              {expanded && (
                <DILBody>
                  <DILDetails>
                    <DILHeader>
                      <tr>
                        <DILChargeDescription>Description</DILChargeDescription>
                        <DILQuantity>Qty</DILQuantity>
                        <DILRate>Rate</DILRate>
                        <DILTotal>Amount</DILTotal>
                      </tr>
                    </DILHeader>

                    <DILLineItems>
                      {Invoice.line_items.map((lineItem) => (
                        <DILCharge key={lineItem.id}>
                          <DILChargeDescription>
                            {lineItem.description}
                          </DILChargeDescription>
                          <DILQuantity>{lineItem.quantity}</DILQuantity>
                          <DILRate>{lineItem.rate}</DILRate>
                          <DILTotal>
                            {' '}
                            {currencyFormatter.format(
                              (lineItem.quantity || 0) *
                                (lineItem.rate || 0) *
                                100
                            )}
                          </DILTotal>
                        </DILCharge>
                      ))}
                    </DILLineItems>

                    <DILTotalLine>
                      <DILChargeDescription>Total</DILChargeDescription>
                      <DILQuantity />
                      <DILRate />
                      <DILTotal>
                        {currencyFormatter.format(Invoice.amount)}
                      </DILTotal>
                    </DILTotalLine>
                  </DILDetails>
                </DILBody>
              )}
            </InvoiceBody>
            <InvoiceFooter>
              <InvoiceFooterLeft>
                {(Invoice.status === 'complete' ||
                  Invoice.status === 'pending_approval') && (
                  <Button
                    padding="0px"
                    marginRight="15px"
                    thin
                    onClick={() => showInvoice(Invoice)}
                  >
                    Invoice PDF
                  </Button>
                )}
                {(Invoice.status === 'complete' ||
                  Invoice.status === 'refunded') && (
                  <Button
                    padding="0px"
                    marginRight="15px"
                    thin
                    onClick={() => showReceipt(Invoice)}
                  >
                    Receipt
                  </Button>
                )}

                {Invoice.status === 'refunded' && (
                  <Button
                    padding="0px"
                    thin
                    onClick={() => showReceipt(Invoice)}
                  >
                    View Refund Receipt
                  </Button>
                )}
              </InvoiceFooterLeft>
              <InvoiceFooterRight>
                <Button
                  padding="0px"
                  thin
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  {expanded && <span>Hide Details</span>}
                  {!expanded && <span>View Details</span>}
                </Button>
                {role === 'payer' && (
                  <>
                    {Invoice.status === 'pending_approval' && (
                      <>
                        <Button secondary marginRight="10px" marginLeft="15px">
                          Reject
                        </Button>
                        <Button
                          onClick={() => {
                            showModal();
                            showConfirmPayment(Invoice);
                          }}
                          primary
                        >
                          Approve and Pay
                        </Button>{' '}
                      </>
                    )}
                    {Invoice.status === 'complete' && (
                      <>
                        <Button
                          onClick={() => {
                            showDispute({ invoice: Invoice });
                          }}
                          secondary
                          marginLeft="15px"
                        >
                          Dispute Charge
                        </Button>
                      </>
                    )}
                  </>
                )}

                {(role === 'admin' ||
                  role === 'owner' ||
                  isAllowed(
                    orgData?.current_user_role,
                    'projects.invoices.edit'
                  )) && (
                  <Button
                    marginLeft="15px"
                    dashed
                    onClick={handleEditInvoiceOPen}
                  >
                    Edit
                  </Button>
                )}

                {Invoice.status === 'complete' &&
                  isAllowed(
                    orgData?.current_user_role,
                    'client_management.issue_refunds.edit'
                  ) && (
                    <Button
                      marginLeft="15px"
                      dashed
                      onClick={() => {
                        showRefund({
                          invoice: Invoice,
                        });
                      }}
                    >
                      Refund
                    </Button>
                  )}
              </InvoiceFooterRight>
            </InvoiceFooter>
          </InvoiceContainer>
        </Card>
      ) : (
        [1, 2].map(() => (
          <Card width="100%" marginBottom="15px">
            <InvoiceContainer>
              <InvoiceHeader>
                <InvoiceHeaderLeft>
                  <ProjectPrimaryText>
                    <Skeleton width={150} />
                  </ProjectPrimaryText>
                  <ProjectSecondaryText>
                    <Skeleton width={80} />
                  </ProjectSecondaryText>
                </InvoiceHeaderLeft>
                <InvoiceHeaderRight>
                  <StatusContainer>
                    <Skeleton width={100} />
                  </StatusContainer>
                  <InvoiceAmount>
                    <Skeleton width={80} />
                  </InvoiceAmount>
                </InvoiceHeaderRight>
              </InvoiceHeader>
              <InvoiceBody>
                <Skeleton width={250} />
              </InvoiceBody>
              <InvoiceStatusWrapper>
                <Skeleton width={80} />
                <SkeletonWrapper>
                  <Skeleton width={60} />
                </SkeletonWrapper>
                <BlankSpan>
                  <Skeleton width={100} />
                </BlankSpan>
              </InvoiceStatusWrapper>
            </InvoiceContainer>
          </Card>
        ))
      )}
      <ModalPopUp
        className="common-modal"
        width={isMobile() ? '90%' : '60%'}
        style={{ maxWidth: '600px', top: isMobile() ? '10px' : '50px' }}
        title="Update Invoice"
        closeIcon="Close"
        closable={editInvoice}
        // maskClosable={false}
        centered
        visible={editInvoice}
        isOk={handleEditInvoiceOPen}
        isCancel={handleEditInvoiceClose}
        footer={null}
        content={
          <UpdateInvoice
            Invoice={Invoice}
            onClose={() => {
              setEditInvoice(false);
            }}
          />
        }
      />
    </>
  );
}
InvoiceCard.propTypes = {
  Invoice: PropTypes.element,
  fees: PropTypes.element,
  role: PropTypes.string,
};

InvoiceCard.defaultProps = {
  Invoice: null,
  fees: null,
  role: 'viewer',
};
export default InvoiceCard;
