/* eslint-disable react/prop-types */
import React from 'react';
import styled, { css } from 'styled-components';
import { TrashIcon } from '@heroicons/react/outline';
// eslint-disable-next-line import/prefer-default-export
export const LineItemInput = styled.input`
  width: 100%;
  border: 1px solid transparent;

  padding: 8px 10px;

  border-radius: 6px;

  &:hover {
    border: 1px dashed #c9c9c9;
  }

  &:focus {
    border: 1px solid #177afa;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: #c3c3c6;
      cursor: not-allowed;
      background-color: transparent;
      &:hover {
        color: #c3c3c6;
      }
    `}
`;

const HeroTrashIcon = ({ className }) => <TrashIcon className={className} />;
export const TrashIconImage = styled(HeroTrashIcon)`
  height: 20px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  ${(props) =>
    props.disabled &&
    css`
      color: #c3c3c6;
      cursor: not-allowed;
      &:hover {
        color: #c3c3c6;
      }
    `}
`;
