import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChevronLeftIcon } from '@heroicons/react/solid';

import { TopButton } from '../../CoreStyles/GeneralStyles';

export default function BackButton({ path, text }) {
  const history = useHistory();

  return (
    <TopButton
      onClick={() => {
        history.push(path);
      }}
    >
      <ChevronLeftIcon />
      {text}
    </TopButton>
  );
}

BackButton.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
};

BackButton.defaultProps = {
  text: null,
  path: null,
};
