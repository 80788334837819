import React from 'react';
import { Modal } from 'antd';
import { PropTypes } from 'prop-types';

export const ModalPopUp = (props) => {
  const {
    visible,
    isCancel,
    isOk,
    title,
    width,
    footer,
    content,
    centered,
    className,
    closeIcon,
    closable,
    destroyOnClose,
    maskClosable,
    style,
  } = props;
  return (
    <>
      <Modal
        title={title}
        centered={centered}
        visible={visible}
        onOk={isOk}
        onCancel={isCancel}
        footer={footer}
        width={width}
        className={className}
        closeIcon={closeIcon}
        closable={closable}
        destroyOnClose={destroyOnClose}
        maskClosable={maskClosable}
        style={style}
        // bodyStyle={}
      >
        {content}
      </Modal>
    </>
  );
};

ModalPopUp.propTypes = {
  title: PropTypes.string,
  centered: PropTypes.bool,
  visible: PropTypes.bool,
  width: PropTypes.number,
  isOk: PropTypes.func,
  isCancel: PropTypes.func,
  footer: PropTypes.element,
  content: PropTypes.node,
  className: PropTypes.string,
  closeIcon: PropTypes.string,
  closable: PropTypes.bool,
  destroyOnClose: PropTypes.element,
  maskClosable: PropTypes.bool,
  style: PropTypes.element,
};

ModalPopUp.defaultProps = {
  title: '',
  centered: false,
  visible: false,
  width: 100,
  isOk: () => {},
  isCancel: () => {},
  footer: null,
  content: null,
  className: '',
  closeIcon: '',
  closable: false,
  destroyOnClose: null,
  maskClosable: true,
  style: {},
};
export default ModalPopUp;
