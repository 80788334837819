import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { ArrowCircleLeftIcon } from '@heroicons/react/outline';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dialogState, showAddMethod } from '../../state';
import {
  ModalBackButton,
  ModalHeader,
  ModalTitle,
} from '../UserPaymentModalStyle';
import { Button } from '../../../../CoreStyles/GeneralStyles';
import { GET_CURRENT_USER } from '../../../../GraphQl/Queries/usersQueries';
import { GET_LINK_TOKEN } from '../../../../GraphQl/Mutations/getLinkToken';
import PlaidLink from './PlaidLink';

export default function AddBankAccount({ onComplete }) {
  const state = useReactiveVar(dialogState);

  const [getLinkToken] = useMutation(GET_LINK_TOKEN);
  const { data } = useQuery(GET_CURRENT_USER);

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(state.context.linkToken);

  const addNonPlaidBank = (input) => {
    console.log('Received input: ', input);
  };

  useEffect(() => {
    if (loading && !state.context.linkToken) {
      getLinkToken({
        variables: {
          userId: data?.currentUser.id,
          // TODO: Pull this url from config
          redirectUri: 'http://localhost:3000/plaid-oauth',
        },
      }).then((tokenResponse) => {
        setToken(tokenResponse?.data?.getLinkToken?.link_token);
        setLoading(false);
      });
    }
  }, [state.context.linkToken]);

  return (
    <div className="clearfix">
      <ModalHeader>
        <ModalTitle>
          <ModalBackButton onClick={() => showAddMethod()}>
            <ArrowCircleLeftIcon className="modal-back-icon" />
            <span>Back</span>
          </ModalBackButton>
          Add Bank Account
        </ModalTitle>
      </ModalHeader>

      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      >
        <PlaidButtonContainer>
          {token ? (
            <PlaidLink
              token={token}
              context={state.context}
              setLoading={setLoading}
              callback={onComplete}
            />
          ) : (
            <Button type="primary" disabled>
              Connect a Bank Account
            </Button>
          )}
        </PlaidButtonContainer>

        <div className="plaid-msg-div">
          We use Plaid for instant bank account verification. If you do not see
          your bank in the dialog opened above, click below to add your bank.
        </div>

        <NonPlaidBankContainer>
          <Button
            secondary
            className="non-plaid-bank-button"
            onClick={addNonPlaidBank}
          >
            I don&apos;t see my bank
          </Button>
        </NonPlaidBankContainer>
      </Spin>
    </div>
  );
}

AddBankAccount.propTypes = {
  onComplete: PropTypes.func,
};

AddBankAccount.defaultProps = {
  onComplete: () => {},
};

const PlaidButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 20px;
`;

const NonPlaidBankContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
`;
