/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Label,
  Table,
  TableHead,
  TableBody,
  Tr,
  Th,
  Td,
  TableWrapper,
  P,
  Button,
} from '../../CoreStyles/GeneralStyles';
import { FooterWrapper, SubTotalAmount } from './UpdateInvoiceStyle';

import { LineItemInput, TrashIconImage } from './LineItemEditorStyle';

import { id, currencyFormatter } from '../../Utils';

function LineItemEditor({ lineItems, setLineItems, editable }) {
  function addLineItem() {
    const lineItemsClone = _.cloneDeep(lineItems);

    lineItemsClone.push({
      id: id(),
      description: '',
      rate: 0,
      quantity: 0,
    });

    setLineItems(lineItemsClone);
  }

  function deleteLineItem(index) {
    const lineItemsClone = _.cloneDeep(lineItems);

    lineItemsClone.splice(index, 1);
    setLineItems(lineItemsClone);
  }

  function updateLineItem(index, key, newValue) {
    const lineItemsClone = _.cloneDeep(lineItems);

    lineItemsClone[index][key] = newValue;

    setLineItems(lineItemsClone);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reoderedLineItems = reorder(
      lineItems,
      result.source.index,
      result.destination.index
    );

    setLineItems(reoderedLineItems);
  }

  return (
    <div>
      {' '}
      <Label>Line Item</Label>
      <TableWrapper className="table-responive d-mob-none table-design2">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <Table ref={provided.innerRef} cellpadding="5">
                <TableHead>
                  <Tr>
                    <Th>Description</Th>
                    <Th>Qty</Th>
                    <Th>Rate</Th>
                    <Th>Total</Th>
                    <Th />
                  </Tr>
                </TableHead>
                <TableBody>
                  {/* description would be array */}

                  {lineItems?.length !== 0
                    ? lineItems &&
                      lineItems.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <Tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Td style={{ width: '400px' }}>
                                <LineItemInput
                                  placeholder="Line Item Description"
                                  onChange={(event) => {
                                    updateLineItem(
                                      index,
                                      'description',
                                      event.target.value
                                    );
                                  }}
                                  value={item.description}
                                />
                              </Td>
                              <Td style={{ width: '100px' }}>
                                {' '}
                                <LineItemInput
                                  type="number"
                                  onChange={(event) => {
                                    updateLineItem(
                                      index,
                                      'quantity',
                                      parseInt(event.target.value, 10)
                                    );
                                  }}
                                  value={item.quantity}
                                  disabled={!editable}
                                />
                              </Td>
                              <Td style={{ width: '100px' }}>
                                {' '}
                                <LineItemInput
                                  type="number"
                                  onChange={(event) => {
                                    updateLineItem(
                                      index,
                                      'rate',
                                      parseInt(event.target.value, 10)
                                    );
                                  }}
                                  value={item.rate}
                                  disabled={!editable}
                                />
                              </Td>
                              <Td
                                style={{
                                  width: '100px',
                                  padding: '8px 10px',
                                }}
                              >
                                {currencyFormatter.format(
                                  (item.quantity || 0) * (item.rate || 0) * 100
                                )}
                              </Td>
                              <Td style={{ width: '50px' }}>
                                <TrashIconImage
                                  onClick={() => {
                                    deleteLineItem(index);
                                  }}
                                  // eslint-disable-next-line no-return-assign

                                  disabled={!editable}
                                />
                              </Td>
                            </Tr>
                          )}
                        </Draggable>
                      ))
                    : [1].map(() => (
                        <Tr>
                          <Td style={{ padding: '20px' }}>No Line Items</Td>
                        </Tr>
                      ))}
                  {provided.placeholder}
                </TableBody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </TableWrapper>
      <FooterWrapper>
        <Button
          onClick={() => {
            addLineItem();
          }}
          thin
          padding="0"
          disabled={!editable}
        >
          Add line item
        </Button>

        <P>
          Subtotal
          <SubTotalAmount>
            {currencyFormatter.format(
              lineItems.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.quantity * currentValue.rate,
                0
              ) * 100
            )}
          </SubTotalAmount>
        </P>
      </FooterWrapper>
    </div>
  );
}

LineItemEditor.propTypes = {
  lineItems: PropTypes.element,
  setLineItems: PropTypes.func,
  editable: PropTypes.bool,
};
LineItemEditor.defaultProps = {
  lineItems: [],
  setLineItems: null,
  editable: true,
};

export default LineItemEditor;
