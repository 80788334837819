import styled from 'styled-components';

export const SkeletonWrapper = styled.span`
  margin-right: 10px;
`;
export const BlankSpan = styled.span``;
export const InvoiceStatusWrapper = styled.div`
  text-align: right;
`;
export default { SkeletonWrapper, BlankSpan, InvoiceStatusWrapper };
