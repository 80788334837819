/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const InvoiceTitle = styled.div`
  font-size: 16px;
  margin-right: 10px;
`;

export const InvoiceStatusActive = styled.div`
  padding: 3px 11px;
  color: #065f46;
  font-size: 12px;
  font-weight: 500;
  border-radius: 6px;
  background-color: #d1fae5;
`;

export const InvoiceStatusPaused = styled.div`
  padding: 3px 11px;
  color: rgb(146, 64, 14);
  font-size: 12px;
  font-weight: 500;
  border-radius: 6px;
  background-color: rgb(254, 243, 199);
`;

export const InvoiceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const InvoiceHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const InvoiceHeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const InvoiceFooter = styled.div`
  color: #919191;
`;

export const InvoiceSection = styled.div`
  margin-top: 5px;
  margin-bottom: 13px;
  padding: 11px 0px 3px 0px;
`;

export const InvoiceItemTitle = styled.span`
  margin-right: 5px;
  font-weight: 500;
  color: #000000;
`;

export const InvoiceItem = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
`;

export const InvoiceItemValue = styled.span`
  text-align: right;
`;

export const MenuActionItem = styled.div`
  padding: 10px 15px !important;
  cursor: pointer;
  text-align: left;
`;
