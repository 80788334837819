import styled from 'styled-components';

export const Topbar = styled.div`
  left: 0px;
  top: 0px;
  border-bottom: 1px solid #e8e8e8;
  background: #ffffff;
  padding: 13px 40px;
  display: flex;
  justify-content: space-between;

  width: ${(props) => (props.width ? props.width : '')};
`;

export const Logo = styled.img`
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;

  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
`;

export const AccountInfo = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  margin-right: 10px;
`;

export const UserInfoName = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #494949;
`;

export const UserInfoCompany = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #a3a3a3;
`;

export const Avatar = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  display: inline-block;
  height: 35px;
  border-radius: 30px;

  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
`;
export const TopbarDiv = styled.div``;
