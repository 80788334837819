import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
  Page,
  BackIcon,
  Section,
  TextLink,
} from '../../CoreStyles/GeneralStyles';
import UserInfoCard from './UserInfoCard';
import BackButtonSvg from '../../images/back-nav-button.svg';
import UserProjCard from './UserProjCard';
import PayHistoryCard from './PayHistoryCard';
import PayMethodsCard from './PayMethodsCard';
import CreditsCard from './CreditsCard';
import { Default, Mobile } from '../Devices';

import BackButton from '../Common/BackButton';

import { GET_DATA_FOR_USER } from '../../GraphQl/Queries/usersQueries';
import { isAllowed, OrgContext } from '../../Utils';

export default function UserInfo() {
  const orgData = useContext(OrgContext);
  const [userInfo, setUserInfo] = useState({});

  const { userId } = useParams();

  const { data: userData } = useQuery(GET_DATA_FOR_USER, {
    variables: { id: userId },
  });

  useEffect(() => {
    if (userData) {
      console.log('userData is ', userData);
      setUserInfo(userData.user);
    }
  }, [userData]);

  return (
    <>
      <Default>
        <Page gridTemplateColumns="300px 20% 20% 20%" doubleColumnLarge typeOne>
          <BackButton path="/users" text="All Clients" />
          <Section padding="0px" style={{ gridArea: 'sidebar' }}>
            <UserInfoCard userInfo={userInfo} />
          </Section>
          <Section padding="0px" style={{ gridArea: 'main' }}>
            <UserProjCard userId={userId} />
            <PayHistoryCard userId={userId} />
            <CreditsCard userId={userId} />
            {isAllowed(
              orgData?.current_user_role,
              'client_management.payment_methods.read'
            ) && <PayMethodsCard />}
          </Section>
        </Page>
      </Default>
      <Mobile>
        <Page singleColumnLarge>
          <Section>
            <TextLink href="/users">
              <BackIcon src={BackButtonSvg} />
            </TextLink>
            <UserInfoCard userInfo={userInfo} />
          </Section>
          <Section>
            <UserProjCard />
            <PayHistoryCard />

            {isAllowed(
              orgData?.current_user_role,
              'client_management.payment_methods.read'
            ) && <PayMethodsCard />}
          </Section>
        </Page>
      </Mobile>
    </>
  );
}
