import styled from 'styled-components';

export const SubTotalAmount = styled.span`
  margin-left: 5px;
  font-weight: bold;
`;
export const CreateInvoiceWrapper = styled.div`
  text-align: right;
`;
export const EnabledText = styled.span`
  color: #222222;
`;
export const Dropdown = styled.select``;
export const DropdownOption = styled.option``;
export const CustomDropDown = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(208 208 208 / 25%);
  border-radius: 6px;
  width: 50%;
  max-width: 360px;
  select {
    border: none;
    width: 100%;
    padding: 7px 17px;
    border-radius: 6px;
    -webkit-appearance: none;
    color: #5e5e5e;
  }
  svg {
    position: absolute;
    right: 11px;
    top: 13px;
  }
`;
export const BlankSpan = styled.span``;
