/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_CREDIT_HISTORY_FOR_USER = gql`
  query creditHistory($userId: String!, $organizationId: String!) {
    getCreditHistoryForUser(userId: $userId, organizationId: $organizationId) {
      id
      amount
      timestamp
      description
      type
    }
  }
`;

export const GET_CREDIT_BALANCE_FOR_USER = gql`
  query creditBalance($userId: String!, $organizationId: String!) {
    getCreditBalanceForUser(userId: $userId, organizationId: $organizationId)
  }
`;
