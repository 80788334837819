/* eslint-disable import/no-named-as-default */
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { dialogState, hideModal } from './state';
import dialogTypes from './model';
import ModalPopUp from '../Common/ModalPopUp';
import AppDialogContent from './Content';

/**
 * AppDialog to walk user through main app actions
 */
export default function AppDialog() {
  const state = useReactiveVar(dialogState);

  return (
    <ModalPopUp
      className={`${
        state.type === dialogTypes.select_method ? 'modal-lg' : ''
      } payment-modal`}
      width={(() => {
        switch (state.type) {
          case dialogTypes.confirm_payment:
            return 500;
          case dialogTypes.select_method:
            return 350;
          case dialogTypes.add_method:
            return 600;
          case dialogTypes.pdf:
            return 800;
          default:
            return 500;
        }
      })()}
      visible={state.modal}
      onCancel={hideModal}
      title={null}
      closeIcon={null}
      header={null}
      closable={false}
      maskClosable={false}
      centered
      isOk={hideModal}
      isCancel={hideModal}
      footer={null}
      content={<AppDialogContent />}
    />
  );
}
