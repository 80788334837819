import styled, { css } from 'styled-components';

export const ProjectRowContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 15% 30% 15% 10%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ProjectRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

export const ProjectRowFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProjectNameTitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  color: #292929;
  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  width: 87%;
`;

export const ProjectName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AmountValue = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #939393;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const StatusContainer = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;

  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : 'auto'};

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

  ${(props) =>
    props.green &&
    css`
      color: #48a039;
    `}

  ${(props) =>
    props.blue &&
    css`
      color: #0075ff;
    `}

        ${(props) =>
    props.gray &&
    css`
      color: #969696;
    `}

  ${(props) =>
    props.orange &&
    css`
      color: #ff9f2d;
    `}
`;

export const StatusBubble = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff9f2d;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;

  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
    `}

  ${(props) =>
    props.green &&
    css`
      background: #48a039;
    `}

  ${(props) =>
    props.blue &&
    css`
      background: #0075ff;
    `}

        ${(props) =>
    props.gray &&
    css`
      background: #969696;
    `}

  ${(props) =>
    props.orange &&
    css`
      background: #ff9f2d;
    `}
`;

export const ProgressTitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;

  color: #292929;

  width: 300px;
`;

export const ViewMoreButton = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;

  color: #0d6cff;
  cursor: pointer;
  text-align: right;

  justify-content: flex-end;
  align-items: center;
`;

export const ViewMoreText = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 3px;

  &:hover {
    color: #7800ef;
  }
`;

export const ViewMoreArrow = styled.img`
  height: 10px;
  margin-left: 2px;
  margin-top: auto;
  margin-bottom: auto;
`;
export const BlankDiv = styled.div``;
export const ProjectRowSpan = styled.span`
  margin-right: ${(props) => props.marginRight || ''};
`;
