/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { React } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
// import { React } from 'react';
// import { BrowserRouter as Router, Switch } from 'react-router-dom';
// import Dashboard from './Components/Dashboard';
// import Project from './Components/Project';
// import Topbar from './Components/Topbar';
import Signup from './Components/Auth/Signup';
import Login from './Components/Auth/Login';
import ForgotPassword from './Components/Auth/ForgotPassword';
import PublicRoute from './Routes/PublicRoute';
import Routes from './Routes';
import 'ant-design-pro/dist/ant-design-pro.css';
import './App.less';
// import UserManagement from './Components/UserManagement';
// import UserInfo from './Components/UserManagement/UserInfo';

import { GET_ORG_FROM_SUBDOMAIN } from './GraphQl/Queries/organizationsQuery';
import { getOrgSubdomain, OrgContext } from './Utils';

function App() {
  const { data } = useQuery(GET_ORG_FROM_SUBDOMAIN, {
    variables: { subdomain: getOrgSubdomain() },
  });

  const organization = data?.organizationFromSubdomain;

  if (!organization) {
    console.error('Error loading organization');
    return null;
  }

  return (
    <OrgContext.Provider value={organization}>
      <div className="App">
        <Router>
          <Switch>
            <PublicRoute
              component={() => <Login orgData={organization} />}
              path="/login"
              exact
            />
            <PublicRoute
              component={() => <Signup orgData={organization} />}
              path="/signup"
              exact
            />
            <PublicRoute
              component={() => <ForgotPassword orgData={organization} />}
              path="/forgotpassword"
              exact
            />
            <Routes />
            {/* <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/project">
            <Topbar />
            <Project />
          </Route>
          <Route exact path="/users">
            <Topbar />
            <UserManagement fromUserManagement={fromUserManagement} />
          </Route>
          <Route path="/users/:user">
            <Topbar />
            <UserInfo userInfo={values} />
          </Route>
          <Route path="/">
            <Topbar />
            <Dashboard />
          </Route> */}
          </Switch>
        </Router>
      </div>
    </OrgContext.Provider>
  );
}

export default App;
