import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import _ from 'lodash';
import {
  Input,
  Label,
  Button,
  InputGroup,
  SeparateGroup,
} from '../../CoreStyles/GeneralStyles';

import { InvoiceWrapper } from '../Invoice/UpdateInvoiceStyle';
import { CreateInvoiceWrapper, EnabledText } from './NewInvoiceStyle';
import CREATE_INVOICE_MUTATION from '../../GraphQl/Mutations/createInvoice';
import { CREATE_RECURRING_INVOICE } from '../../GraphQl/Mutations/invoiceMutations';
import createNotification from '../Common/Notifications';
import ErrorPage from '../Common/ErrorPage';

import { id } from '../../Utils';

import DropdownMenu from '../Generics/Dropdown';
import DatePicker from '../Generics/DatePicker';
import LineItemEditor from '../Invoice/LineItemEditor';

import { GET_PROJECTS_DATA } from '../../GraphQl/Queries/projectQueries';

import { INVOICE_STATUS_OPTIONS } from '../../Enums';

import RecurringSettingsPanel from '../Invoice/RecurringInvoice/RecurringSettingsPanel';

import { generateRrule } from '../../Utils/RruleUtils';

function NewInvoice({ projectInfoData, onClose, onCreation }) {
  const [lineItems, setLineItems] = useState([]);

  const [newInvoice, setNewInvoice] = useState({
    id: id(),
    due_date: new Date(),
    project_id: projectInfoData?.project?.id,
    date_created: new Date(),
    billing_start: new Date(),
  });

  const [recurringRule, setRecurringRule] = useState({
    freq: 'monthly',
    bymonthday: 1,
    interval: 1,
    bysetpos: 1,
    byweekday_temp: 'monday',
    bymonth: 1,
    month_type: 'on_day',
    year_type: 'on_day',
  });

  const [recurringEnd, setRecurringEnd] = useState('never');

  function updateRecurringRule(key, newValue) {
    const tempRule = _.cloneDeep(recurringRule);
    tempRule[key] = newValue;

    setRecurringRule(tempRule);
  }

  const updateInvoice = (field, value) => {
    const invoiceClone = _.cloneDeep(newInvoice);

    invoiceClone[field] = value;

    setNewInvoice(invoiceClone);
  };

  const [createInvoice, { error, loading: staticLoading }] = useMutation(
    CREATE_INVOICE_MUTATION,
    {
      onCompleted: () => {
        createNotification('success', 'Invoice Created');
        onCreation(newInvoice.status);
        // Reset everything
        setNewInvoice({
          id: id(),
          name: '',
          due_date: new Date(),
          project_id: projectInfoData?.project?.id,
          date_created: new Date(),
        });

        setLineItems([]);

        onClose();
      },
      onError: (errors) => {
        ErrorPage(errors);
      },
      refetchQueries: [
        {
          query: GET_PROJECTS_DATA,
          variables: {
            id: projectInfoData?.project?.id,
          },
        },
      ],
    }
  );

  const [createRecurringInvoice, { loading: recurringLoading }] = useMutation(
    CREATE_RECURRING_INVOICE,
    {
      onCompleted: () => {
        createNotification('success', 'Invoice Created');

        // Reset everything
        setNewInvoice({
          id: id(),
          name: '',
          due_date: new Date(),
          project_id: projectInfoData?.project?.id,
          date_created: new Date(),
        });

        setLineItems([]);
        onClose();
      },
      onError: (errors) => {
        ErrorPage(errors);
      },
    }
  );

  const handleCreateInvoice = (e) => {
    e.preventDefault();

    const newInvoiceClone = _.cloneDeep(newInvoice);

    newInvoiceClone.line_items = lineItems;
    newInvoiceClone.amount =
      lineItems.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.quantity * currentValue.rate,
        0
      ) * 100;

    newInvoiceClone.last_status_change_date = new Date();

    console.log('clone is ', newInvoiceClone);

    if (newInvoiceClone.recurring) {
      createRecurringInvoice({
        variables: {
          newInvoice: {
            name: newInvoiceClone.name,
            billing_start: newInvoiceClone.billing_start,
            recurrence: generateRrule({
              recurringRule,
              recurringEnd,
              startDate: newInvoiceClone.billing_start,
            }),
            line_items: newInvoiceClone.line_items,
            enabled: true,
            amount: newInvoiceClone.amount,
            project_id: newInvoiceClone.project_id,
            date_created: newInvoiceClone.date_created,
          },
        },
      });
    } else {
      createInvoice({
        variables: newInvoiceClone,
      });
    }

    if (error) {
      console.log(error);
    }
  };

  const processing = recurringLoading || staticLoading;

  return (
    <>
      <InputGroup>
        <Label>Invoice Title</Label>
        <Input
          type="text"
          name="invoice_title"
          placeholder="April 2021 title"
          onChange={(e) => updateInvoice('name', e.target.value)}
          value={newInvoice?.name}
        />
      </InputGroup>
      <SeparateGroup backgroundEnabled={newInvoice?.recurring} whiteBg>
        <InputGroup>
          <Label>Recurring Invoice</Label>
          <Switch
            checked={newInvoice?.recurring}
            onChange={(checked) => {
              updateInvoice('recurring', checked);
            }}
          />
          <EnabledText> Enabled </EnabledText>
        </InputGroup>

        <InputGroup>
          {newInvoice?.recurring ? (
            <>
              <SeparateGroup backgroundEnabled withBorder>
                <InputGroup>
                  <Label>Starting On</Label>
                  <DatePicker
                    value={newInvoice?.billing_start}
                    updateValue={(date) => {
                      updateInvoice('billing_start', date);
                    }}
                  />
                </InputGroup>
              </SeparateGroup>
              <RecurringSettingsPanel
                recurringEnd={recurringEnd}
                recurringRule={recurringRule}
                updateRecurringRule={updateRecurringRule}
                setRecurringEnd={setRecurringEnd}
              />
            </>
          ) : (
            <>
              <InputGroup>
                <Label>Invoice Date</Label>

                <DatePicker
                  value={newInvoice?.due_date}
                  updateValue={(date) => {
                    updateInvoice('due_date', date);
                  }}
                />
              </InputGroup>
            </>
          )}
        </InputGroup>
      </SeparateGroup>
      {!newInvoice?.recurring && (
        <InputGroup>
          <Label>Status</Label>
          <DropdownMenu
            value={newInvoice?.status}
            options={INVOICE_STATUS_OPTIONS}
            onChange={(newValue) => {
              updateInvoice('status', newValue);
            }}
          />
        </InputGroup>
      )}

      <LineItemEditor lineItems={lineItems} setLineItems={setLineItems} />
      <InvoiceWrapper>
        <CreateInvoiceWrapper>
          <Button loading={processing} primary onClick={handleCreateInvoice}>
            {processing && <LoadingOutlined />} Create Invoice
          </Button>
        </CreateInvoiceWrapper>
      </InvoiceWrapper>
    </>
  );
}

NewInvoice.propTypes = {
  projectInfoData: PropTypes.element,
  onClose: PropTypes.func,
  onCreation: PropTypes.func,
};
NewInvoice.defaultProps = {
  projectInfoData: null,
  onClose: null,
  onCreation: null,
};

export default NewInvoice;
