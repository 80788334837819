import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  CogIcon,
  OfficeBuildingIcon,
  ViewGridAddIcon,
  IdentificationIcon,
  MailIcon,
} from '@heroicons/react/outline';
import {
  H2Heading,
  SettingsSideBar,
  SettingsSideBarItem,
  SettingsSideBarText,
} from './style';
import { Page, Section } from '../../CoreStyles/GeneralStyles';
import BackButton from '../Common/BackButton';
import PlatformSettings from './PlatformSettings';
import CompanySettings from './CompanySettings';
import ManageTeam from './ManageTeam/ManageTeam';
import Integrations from './Integrations';
import EmailSequence from './EmailSequence/EmailSequence';
import { isAllowed, OrgContext } from '../../Utils';

export default function Settings() {
  const orgData = useContext(OrgContext);
  const [pageSelected, setPageSelected] = useState('platform-settings');

  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();

  useEffect(() => {
    if (query.get('page')) {
      setPageSelected(query.get('page'));
    } else {
      setPageSelected('platform-settings');
    }
  }, [query]);

  return (
    <>
      <Page
        singleColumnLarge
        style={{ gridTemplateColumns: '250px 50%' }}
        gridTemplateColumns="300px 20% 20% 20%"
        typeOne
      >
        {/* <h1 className="d-mob-none">Platform Settings</h1> */}
        <BackButton text="Dashboard" path="/" />
        {isAllowed(orgData?.current_user_role, 'workspace_settings.read') && (
          <>
            <Section padding="0px" style={{ gridArea: 'sidebar' }}>
              <H2Heading>Platform Settings</H2Heading>
              <SettingsSideBar>
                <Link to="/workspace-settings?page=platform-settings">
                  <SettingsSideBarItem
                    className={`settings-sidebar-item${
                      pageSelected === 'platform-settings' ? ' selected' : ''
                    }`}
                  >
                    <CogIcon className="settings-sidebar-icon" />
                    <SettingsSideBarText>Platform Settings</SettingsSideBarText>
                  </SettingsSideBarItem>
                </Link>

                <Link to="/workspace-settings?page=company-settings">
                  <SettingsSideBarItem
                    className={`settings-sidebar-item${
                      pageSelected === 'company-settings' ? ' selected' : ''
                    }`}
                  >
                    <OfficeBuildingIcon className="settings-sidebar-icon" />
                    <SettingsSideBarText>Company Settings</SettingsSideBarText>
                  </SettingsSideBarItem>
                </Link>
                <Link to="/workspace-settings?page=team-settings">
                  <SettingsSideBarItem
                    className={`settings-sidebar-item${
                      pageSelected === 'team-settings' ? ' selected' : ''
                    }`}
                  >
                    <IdentificationIcon className="settings-sidebar-icon" />
                    <SettingsSideBarText>Manage Team</SettingsSideBarText>
                  </SettingsSideBarItem>
                </Link>
                <Link to="/workspace-settings?page=email-settings">
                  <SettingsSideBarItem
                    className={`settings-sidebar-item${
                      pageSelected === 'email-settings' ? ' selected' : ''
                    }`}
                  >
                    <MailIcon className="settings-sidebar-icon" />
                    <SettingsSideBarText>Email Sequence</SettingsSideBarText>
                  </SettingsSideBarItem>
                </Link>
                <Link to="/workspace-settings?page=integrations-settings">
                  <SettingsSideBarItem
                    className={`settings-sidebar-item${
                      pageSelected === 'integrations-settings'
                        ? ' selected'
                        : ''
                    }`}
                  >
                    <ViewGridAddIcon className="settings-sidebar-icon" />
                    <SettingsSideBarText>Integrations</SettingsSideBarText>
                  </SettingsSideBarItem>
                </Link>
              </SettingsSideBar>
            </Section>
            <div style={{ gridArea: 'main' }}>
              {pageSelected === 'platform-settings' && <PlatformSettings />}
              {pageSelected === 'company-settings' && <CompanySettings />}
              {pageSelected === 'team-settings' && <ManageTeam />}
              {pageSelected === 'integrations-settings' && <Integrations />}
              {pageSelected === 'email-settings' && <EmailSequence />}
            </div>
          </>
        )}

        {!isAllowed(orgData?.current_user_role, 'workspace_settings.read') && (
          <div>Access denied</div>
        )}
      </Page>
    </>
  );
}
