/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { currencyFormatter } from '../../../../Utils';

const styles = StyleSheet.create({
  container: {
    marginTop: '50px',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  shaded: {
    backgroundColor: '#f5f5f5',
  },
  columnText: {
    fontFamily: 'Open Sans',
    fontSize: 11,
    flexGrow: 0,
    color: '#222222',
    fontWeight: '400',
    textDecoration: 'none',
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    borderBottomWidth: 0.5,
    borderBottomColor: '#8191A1',
    borderBottomStyle: 'solid',
    paddingBottom: '7px',
    paddingTop: '7px',
    paddingLeft: '8px',
  },
  descriptionColumn: {
    width: '60%',
  },
  quantityColumn: {
    width: '5%',
    paddingLeft: '5px',
  },
  unitPriceColumn: {
    width: '15%',
  },
  amountColumn: {
    width: '20%',
    textAlign: 'right',
    paddingRight: '5px',
  },
});

export default function LineItems({ invoice }) {
  const subtotal = invoice.line_items.reduce(
    (acc, item) => acc + item.quantity * item.rate * 100,
    0
  );
  let amountDue = subtotal;

  const summaryItems = [{ key: 'Subtotal', value: subtotal }];

  if (invoice.adjustments) {
    invoice.adjustments.forEach((adjustment) => {
      if (adjustment.type === 'discount') {
        amountDue -= adjustment.amount;
      } else if (adjustment.type === 'fee') {
        amountDue += adjustment.amount;
      }

      summaryItems.push({
        key: `${adjustment.description} (${adjustment.percentage}%)`,
        value: adjustment.amount,
      });
    });
  }

  summaryItems.push({ key: 'Amount Due', value: amountDue });

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={{ ...styles.columnText, ...styles.descriptionColumn }}>
          Description
        </Text>
        <Text style={{ ...styles.columnText, ...styles.quantityColumn }}>
          Qty
        </Text>
        <Text style={{ ...styles.columnText, ...styles.unitPriceColumn }}>
          Unit Price
        </Text>
        <Text style={{ ...styles.columnText, ...styles.amountColumn }}>
          Amount
        </Text>
      </View>

      {invoice.line_items?.map((lineItem, index) => {
        const rowStyle =
          index % 2 === 0 ? { ...styles.row, ...styles.shaded } : styles.row;
        return (
          <View key={lineItem.description} style={rowStyle}>
            <Text style={{ ...styles.columnText, ...styles.descriptionColumn }}>
              {lineItem.description}
            </Text>
            <Text style={{ ...styles.columnText, ...styles.quantityColumn }}>
              {lineItem.quantity}
            </Text>
            <Text style={{ ...styles.columnText, ...styles.unitPriceColumn }}>
              {lineItem.rate}
            </Text>
            <Text style={{ ...styles.columnText, ...styles.amountColumn }}>
              {currencyFormatter.format(
                lineItem.quantity * lineItem.rate * 100
              )}
            </Text>
          </View>
        );
      })}

      {summaryItems.map(({ key, value }, index) => {
        const textStyle =
          (invoice.line_items.length + index) % 2 === 0
            ? { ...styles.columnText, ...styles.shaded }
            : styles.columnText;

        return (
          <View key={key} style={styles.row}>
            <Text style={styles.descriptionColumn} />
            <Text style={styles.quantityColumn} />
            <Text style={{ ...textStyle, ...styles.unitPriceColumn }}>
              {key}
            </Text>
            <Text style={{ ...textStyle, ...styles.amountColumn }}>
              {currencyFormatter.format(value)}
            </Text>
          </View>
        );
      })}
    </View>
  );
}

LineItems.propTypes = {
  invoice: PropTypes.object.isRequired,
};
