import styled from 'styled-components';

export const InputContainer = styled.div`
  margin-bottom: 15px;
`;

export const InputTitle = styled.div`
  font-weight: 400;
  color: #373737;
  margin-bottom: 5px;
  font-size: 13px;
`;

export const TextField = styled.input`
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;

  width: 100%;

  &:focus {
    outline: none;
    border: 1px solid #014dff;
  }
`;
