/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { SectionTitle, SectionContent } from '../../CoreStyles/GeneralStyles';
import {
  InvoicesHeader,
  InvoicesSection,
  EmptyInvoices,
} from '../Project/ProjectStyle';
import { InvoiceCard } from './InvoiceCard';
import { INVOICE_STATUS_OPTIONS } from '../../Enums';

export const InvoiceSection = ({
  invoices,
  role,
  type,
  openSections,
  setOpenSections,
  fees,
}) => {
  const items = _.groupBy(invoices, 'status')[type]?.sort((a, b) => {
    if (type === 'pending_approval') {
      return a.due_date - b.due_date;
    }

    // Change this to created date
    return a.due_date - b.due_date;
  });

  const expanded = openSections.includes(type);

  if (type === 'refunded' && (!items || items.length === 0)) {
    return null;
  }

  if (type === 'processing' && (!items || items.length === 0)) {
    return null;
  }

  return (
    <InvoicesSection>
      <InvoicesHeader
        style={{
          justifyContent: 'normal',
        }}
      >
        {!expanded && <ChevronRightIcon className="section-chevron" />}{' '}
        {expanded && <ChevronDownIcon className="section-chevron" />}
        <SectionTitle
          color={INVOICE_STATUS_OPTIONS[type].color}
          onClick={() => {
            if (expanded) {
              // Remove it
              setOpenSections(openSections.slice().filter((e) => e !== type));
            } else {
              const openSectionsCopy = openSections.slice();
              openSectionsCopy.push(type);
              setOpenSections(openSectionsCopy);
            }
          }}
          marginBottom="0px"
        >
          {INVOICE_STATUS_OPTIONS[type].section_title} ({items?.length || 0})
        </SectionTitle>
      </InvoicesHeader>
      {expanded && (
        <SectionContent>
          {items &&
            items.map((item) => (
              <InvoiceCard
                Invoice={item}
                key={item.id}
                role={role || 'viewer'}
                fees={fees}
              />
            ))}

          {!items && <EmptyInvoices>Nothing Yet</EmptyInvoices>}
        </SectionContent>
      )}
    </InvoicesSection>
  );
};

InvoiceSection.propTypes = {
  invoices: PropTypes.array,
  role: PropTypes.string,
  type: PropTypes.string,
  openSections: PropTypes.array,
  setOpenSections: PropTypes.func,
  fees: PropTypes.element,
};

InvoiceSection.defaultProps = {
  invoices: [],
  role: null,
  type: null,
  openSections: ['pending_approval'],
  setOpenSections: null,
  fees: null,
};

export default InvoiceSection;
