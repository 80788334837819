/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const AutoPayerInfo = styled.div``;

export const AutoPayMethod = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AutoPayFooter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const EnrollBody = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const EnrollDescription = styled.div`
  display: flex;
  align-items: top;
  font-size: 16px;
  color: #339d00;
`;

export const EnrollImage = styled.img`
  height: 25px;
  margin-right: 10px;
`;
