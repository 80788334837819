/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
// import PropTypes from 'prop-types';
import { PlusSmIcon } from '@heroicons/react/solid';
import { message } from 'antd';
import { SpinnerCircular } from 'spinners-react';
import _ from 'lodash';
import {
  // EmailH2,
  AddStepContainer,
  AddStepBorderContainer,
  AddStepBorder,
  AddStepButtonContainer,
  AddStepButton,
} from './EmailSequenceStyle';

import {
  PageWrapper,
  Button,
  LoadingContainer,
  LoadingText,
} from '../../../CoreStyles/GeneralStyles';

import EmailSequenceCard from './EmailSequenceCard';

import { GET_EMAILS_FOR_PROJECT } from '../../../GraphQl/Queries/emailSequenceQueries';

import { H2Heading } from '../style';
import {
  UPDATE_EMAIL,
  CREATE_EMAIL,
  DELETE_EMAIL,
} from '../../../GraphQl/Mutations/emailMutations';

// import ErrorPage from '../Common/ErrorPage';

function EmailSequence() {
  const [emails, setEmails] = useState([]);

  const { error, loading, data, refetch } = useQuery(GET_EMAILS_FOR_PROJECT, {
    variables: { projectId: '1' },
  });

  const [emailsDeleted, setEmailsDeleted] = useState([]);
  const [originalEmails, setOriginalEmails] = useState([]);

  useEffect(() => {
    console.log('loading is ', loading);
  }, [loading]);

  console.log(error, loading, data, 'alskdjlaskdjjasd');

  useEffect(() => {
    if (data?.emails) {
      setEmails(data.emails);
      setOriginalEmails(data.emails);
    }
  }, [data]);

  const [updateEmailMutation] = useMutation(UPDATE_EMAIL, {
    onCompleted: () => {
      console.log('success');
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
  });

  const [createEmailMutation] = useMutation(CREATE_EMAIL, {
    onCompleted: () => {
      console.log('success');
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
  });

  const [deleteEmailMutation] = useMutation(DELETE_EMAIL, {
    onCompleted: () => {
      console.log('success');
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
    update(cache, el) {
      const deletedId = el.data?.deleteEmail;
      const allEmails = cache.readQuery({
        query: GET_EMAILS_FOR_PROJECT,
        variables: { projectId: '1' },
      });

      cache.writeQuery({
        query: GET_EMAILS_FOR_PROJECT,
        variables: { projectId: '1' },
        data: {
          emails: allEmails?.emails.filter((t) => t?.id !== deletedId),
        },
      });

      cache.gc();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const promises = [];

    // First, let's send any updates
    emails.forEach((email) => {
      // If there is an id, this is an update and not a creation
      if (email.id) {
        promises.push(
          new Promise((resolve, reject) => {
            updateEmailMutation({
              variables: email,
            })
              .then((result) => {
                console.log('INNNER, ', result);
                resolve();
              })
              .catch(() => {
                reject(); // <-- OK
              });
          })
        );
      }
    });

    // Now let's handle any deletions
    emailsDeleted.forEach((email) => {
      promises.push(
        new Promise((resolve, reject) => {
          deleteEmailMutation({
            variables: email,
          })
            .then((result) => {
              console.log('INNER DELETE, ', result);
              resolve();
            })
            .catch(() => {
              reject(); // <-- OK
            });
        })
      );
    });

    // Now let's do any creation
    emails.forEach((email) => {
      let exists = false;

      originalEmails.forEach((originalEmail) => {
        if (originalEmail.id === email.id) {
          exists = true;
        }
      });

      if (!exists) {
        promises.push(
          new Promise((resolve, reject) => {
            createEmailMutation({
              variables: email,
            })
              .then((result) => {
                console.log('INNNER, ', result);
                resolve();
              })
              .catch(() => {
                reject(); // <-- OK
              });
          })
        );
      }
    });

    Promise.allSettled(promises).then((results) => {
      results.forEach((result) => console.log(result.status));
      refetch();
      message.success('ALL SAVED');
    });
  };

  const deleteEmail = useCallback(
    (index) => {
      const emailToDelete = emails[index];

      setEmailsDeleted([...emailsDeleted, emailToDelete]);

      const newEmails = [...emails];
      newEmails.splice(index, 1);

      setEmails(newEmails);
    },
    [emails]
  );

  const updateEmail = useCallback(
    (id, field, newValue) => {
      const newEmails = _.cloneDeep(emails);

      newEmails.forEach((email) => {
        if (email.id === id) {
          email[field] = newValue;
        }
      });

      console.log('new value is ', newValue);

      setEmails(newEmails);
    },
    [emails]
  );

  console.log('emails are ', emails);

  return (
    <PageWrapper>
      <H2Heading>Email Sequence Editor</H2Heading>
      {loading && (
        <PageWrapper>
          <LoadingContainer>
            <LoadingText>Loading Email Sequences</LoadingText>
            <SpinnerCircular
              size={50}
              thickness={81}
              speed={162}
              color="rgba(12, 108, 255, 1)"
              secondaryColor="rgba(0, 0, 0, 0.33)"
            />
          </LoadingContainer>
        </PageWrapper>
      )}
      {_.cloneDeep(emails)
        .sort((a, b) => a.send_at - b.send_at)
        .map((email, index) => (
          <div key={email.id}>
            <EmailSequenceCard
              key={email.id}
              deleteEmail={deleteEmail}
              index={index}
              email={email}
              updateEmail={updateEmail}
            />
          </div>
        ))}
      {!loading && (
        <>
          <AddStepContainer>
            <AddStepBorderContainer>
              <AddStepBorder />
            </AddStepBorderContainer>
            <AddStepButtonContainer>
              <AddStepButton
                onClick={() => {
                  // Add email to index
                  const newEmails = [...emails, { projectId: '1' }];
                  setEmails(newEmails);
                }}
              >
                <PlusSmIcon
                  className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Add Step</span>
              </AddStepButton>
            </AddStepButtonContainer>
          </AddStepContainer>

          <Button onClick={handleSubmit}>Save Changes</Button>
        </>
      )}
    </PageWrapper>
  );
}

EmailSequence.propTypes = {};

EmailSequence.defaultProps = {};

export default EmailSequence;
