/* eslint-disable dot-notation */
import React, { useState, useEffect } from 'react';
// import { Button } from 'antd';
// import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
// import { Space, Dropdown } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { LoadingOutlined } from '@ant-design/icons';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import {
  Input,
  Label,
  Button,
  InputGroup,
  SeparateGroup,
  TextArea,
} from '../../CoreStyles/GeneralStyles';
import {
  InvoiceWrapper,
  BlankSpan,
  InvoiceMessage,
} from './UpdateInvoiceStyle';
import DropdownMenu from '../Generics/Dropdown';
import UPDATE_INVOICE from '../../GraphQl/Mutations/updateInvoices';
import {
  DELETE_INVOICE,
  LOG_STATUS_CHANGE,
} from '../../GraphQl/Mutations/invoiceMutations';
import CREATE_PAYMENT_MUTATION from '../../GraphQl/Mutations/createPayment';
import createNotification from '../Common/Notifications';
import ErrorPage from '../Common/ErrorPage';

import DatePicker from '../Generics/DatePicker';
import CurrencyInput from '../Generics/Input/CurrencyInput';

import LineItemEditor from './LineItemEditor';

import {
  GET_PROJECTS_DATA,
  GET_PROJECT_PAYERS,
} from '../../GraphQl/Queries/projectQueries';
import { GET_INVOICE_PAYMENTS } from '../../GraphQl/Queries/invoiceQuery';

import { INVOICE_STATUS_OPTIONS, OFFLINE_PAYMENT_METHODS } from '../../Enums';

function UpdateInvoices({ Invoice, onClose }) {
  const [invoiceTitle, setInvoiceTitle] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [status, setStatus] = useState('');

  const [offlinePayment, setOfflinePayment] = useState({});

  const [lineItems, setLineItems] = useState([]);

  const [createPayment] = useMutation(CREATE_PAYMENT_MUTATION);

  function updateOfflinePayment(key, value) {
    const tempOfflinePayment = _.cloneDeep(offlinePayment);

    tempOfflinePayment[key] = value;

    setOfflinePayment(tempOfflinePayment);
  }

  const { data: existingPayments } = useQuery(GET_INVOICE_PAYMENTS, {
    variables: { id: Invoice.id },
  });

  const { data: payerData } = useQuery(GET_PROJECT_PAYERS, {
    variables: { id: Invoice.project_id },
  });

  console.log(
    'existing payments: ',
    Invoice.name,
    existingPayments?.getPaymentsForInvoice
  );

  const [updateInvoice, { error, loading }] = useMutation(UPDATE_INVOICE, {
    onCompleted: async () => {
      // If we changed status to completed, create an offline payment
      if (status === 'complete') {
        const offlinePaymentData = {
          amount: offlinePayment.amount * 100,
          payment_method: offlinePayment.type,
          invoice: Invoice.id,
          offline: true,
          credits: 0,
          memo: offlinePayment.memo,
        };

        await createPayment({
          variables: {
            input: offlinePaymentData,
          },
        });

        createNotification('success', 'Invoice Updated');
        onClose();
      } else {
        createNotification('success', 'Invoice Updated');
        onClose();
      }
    },
    onError: (errors) => {
      ErrorPage(errors);
    },
    refetchQueries: [
      {
        query: GET_PROJECTS_DATA,
        variables: {
          id: Invoice.project_id,
        },
      },
    ],
  });

  const [logStatusChange] = useMutation(LOG_STATUS_CHANGE, {
    onCompleted: () => {
      console.log('logged status change');
    },
    onError: (errors) => {
      ErrorPage(errors);
    },
  });

  const [deleteInvoiceMutation] = useMutation(DELETE_INVOICE, {
    onCompleted: () => {
      createNotification('success');
      onClose();
    },
    onError: (errors) => {
      ErrorPage(errors);
    },
    refetchQueries: [
      {
        query: GET_PROJECTS_DATA,
        variables: {
          id: Invoice.project_id,
        },
      },
    ],
  });

  useEffect(async () => {
    if (Invoice) {
      await setInvoiceTitle(Invoice.name);
      await setStatus(Invoice.status);
      await setDueDate(new Date(Invoice.due_date));
      await setLineItems(Invoice.line_items);
      //   await setDueDate(new Date(formatedDate));
      setStatus(Invoice.status);
    }
  }, [Invoice]);

  const handleUpdateInvoice = (e) => {
    e.preventDefault();

    let newInvoiceDate = Invoice.invoice_date;

    // If status changed, let's log a status change
    if (Invoice.status !== status) {
      logStatusChange({
        variables: {
          id: Invoice.id,
        },
      });

      // If status is being changed to pending, update invoice date
      if (status === 'pending_approval') {
        newInvoiceDate = new Date();
      }
    }

    updateInvoice({
      variables: {
        id: Invoice.id,
        name: invoiceTitle,
        due_date: dueDate,
        invoice_date: newInvoiceDate,
        status,
        line_items: lineItems.map((lineItem) => {
          const lineItemCopy = _.cloneDeep(lineItem);
          delete lineItemCopy['__typename'];

          return lineItemCopy;
        }),
        amount:
          lineItems.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.quantity * currentValue.rate,
            0
          ) * 100,
      },
    });
    if (error) {
      console.log(error);
    }
  };
  const handleDeleteInvoice = (e) => {
    e.preventDefault();
    // deleteUserInvoice(e);
    deleteInvoiceMutation({ variables: { id: Invoice.id } });
  };

  const payerOptions = {};

  const collaboratorsMap = {};

  if (payerData?.project?.payers) {
    payerData?.project?.payers.forEach((user) => {
      payerOptions[user.id] = {
        title: user.name,
      };

      collaboratorsMap[user.id] = user;
    });
  }

  return (
    <>
      {(Invoice.status === 'complete' || Invoice.status === 'refunded') && (
        <SeparateGroup backgroundEnabled>
          <InvoiceMessage style={{ margin: '0px' }}>
            <ExclamationCircleIcon
              style={{ height: '45px', marginRight: '10px' }}
            />
            Only the invoice title, invoice date and line item descriptions can
            be edited once an invoice has been marked as complete or refunded
          </InvoiceMessage>
        </SeparateGroup>
      )}
      <InputGroup>
        <Label>Invoice Title</Label>
        <Input
          type="text"
          name="invoice_title"
          placeholder="April 2021 title"
          onChange={(e) => setInvoiceTitle(e.target.value)}
          value={invoiceTitle}
        />
      </InputGroup>
      <InputGroup>
        <Label>Invoice Date</Label>

        <DatePicker
          value={dueDate}
          updateValue={(date) => {
            console.log('oh yes ', date);
            setDueDate(date);
          }}
        />
      </InputGroup>
      <InputGroup>
        <Label>Status</Label>
        <DropdownMenu
          value={status}
          options={INVOICE_STATUS_OPTIONS}
          onChange={(newValue) => {
            setStatus(newValue);
          }}
          disabled={
            Invoice.status === 'complete' || Invoice.status === 'refunded'
          }
        />
      </InputGroup>

      {Invoice.status !== 'complete' && status === 'complete' && (
        <SeparateGroup backgroundEnabled>
          <InvoiceMessage>
            <ExclamationCircleIcon
              style={{ minWidth: '25px', width: '25px', marginRight: '10px' }}
            />
            Marking this as complete means clients will not be allowed to pay
            this invoice through the system and you will have to manually log an
            offline payment below.
          </InvoiceMessage>
          <InputGroup>
            <Label>Payment Method</Label>
            <DropdownMenu
              value={offlinePayment?.type}
              options={OFFLINE_PAYMENT_METHODS}
              onChange={(newValue) => {
                updateOfflinePayment('type', newValue);
              }}
            />
          </InputGroup>
          <InputGroup>
            <Label>Payer</Label>
            <DropdownMenu
              value={offlinePayment?.user_id}
              options={payerOptions}
              onChange={(newValue) => {
                updateOfflinePayment('user_id', newValue);
              }}
            />
          </InputGroup>
          <InputGroup>
            <Label>Amount</Label>
            <CurrencyInput
              onChange={(e) =>
                // Restrict credits used to be >= 0 and <= Invoice.amount
                updateOfflinePayment(
                  'amount',
                  Math.abs(parseFloat(e.target.value, 10))
                )
              }
              value={offlinePayment.amount}
              placeholder="0.00"
              customStyle={{ width: '150px' }}
              minValue={0}
            />
          </InputGroup>
          <InputGroup>
            <Label>Memo/Notes</Label>
            <TextArea
              maxWidth="400px"
              placeholder="This is where you can enter check number and other info"
              rows={4}
              name="offline_payment_memo"
              value={offlinePayment?.memo}
              onChange={(e) => {
                updateOfflinePayment('memo', e.target.value);
              }}
            />
          </InputGroup>
        </SeparateGroup>
      )}

      <LineItemEditor
        lineItems={lineItems}
        setLineItems={setLineItems}
        editable={
          Invoice.status !== 'complete' && Invoice.status !== 'refunded'
        }
      />
      <InvoiceWrapper>
        <BlankSpan>
          <Button
            color="red"
            thin
            fontWeight="bold"
            onClick={handleDeleteInvoice}
            disabled={
              Invoice.status === 'complete' || Invoice.status === 'refunded'
            }
          >
            Delete Invoice
          </Button>
        </BlankSpan>
        <BlankSpan>
          <Button loading={loading} onClick={handleUpdateInvoice} primary>
            {loading && <LoadingOutlined />} Update Invoice
          </Button>
        </BlankSpan>
      </InvoiceWrapper>
    </>
  );
}

UpdateInvoices.propTypes = {
  Invoice: PropTypes.element,
  onClose: PropTypes.func,
};
UpdateInvoices.defaultProps = {
  Invoice: null,
  onClose: null,
};

export default UpdateInvoices;
