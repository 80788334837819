import { gql } from '@apollo/client';

export const GET_EMAILS_FOR_PROJECT = gql`
  query getEmailsForProject($projectId: String!) {
    emails(projectId: $projectId) {
      id
      body
      subject
      send_at
      late_fee
      late_fee_enabled
    }
  }
`;

export default GET_EMAILS_FOR_PROJECT;
