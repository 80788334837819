import styled from 'styled-components';

export const SummaryTitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 4px;
  /* identical to box height */

  color: #000000;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

export const SummarySecondaryText = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* identical to box height */

  color: #a3a3a3;
  margin-bottom: 8px;
`;

export const SummaryAmount = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #48a039;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
export const SummaryCardWrapper = styled.div`
  width: 100%;
`;
