import styled from 'styled-components';

export const FooterRight = styled.div`
  text-align: right;
`;
export const SwitchWrapper = styled.div`
  margin-bottom: 15px;
`;
export const BlankDiv = styled.div``;
export const BlankSpan = styled.span``;

export default { FooterRight, SwitchWrapper, BlankDiv, BlankSpan };
