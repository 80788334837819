/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const PaymentMethodSelectorContainer = styled.div`
  background: #ffffff;
  border: 2px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px 10px 15px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    border: 2px solid #0d6cff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(216 224 255 / 25%);
  }
`;

export const PaymentMethodInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentMethodText = styled.div`
  margin-left: 10px;
`;

export const ChargeTitle = styled.div`
  font-size: 15px;
  color: #3a3a3a;
`;
export const ChargeAmount = styled.div`
  font-size: 16px;
  color: #000000;
`;

export const CreditsSection = styled.div`
  margin-left: 5px;
  display: flex;
  flex-direction: column;
`;

export const CreditsToggleArea = styled.div``;

export const ChargeSummaryLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const PayButton = styled.div`
  background: #0d6cff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgb(238 238 238 / 42%);
  border-radius: 8px;
  padding: 13px 21px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  text-align: center;
  margin-bottom: 13px;
  opacity: ${(props) => (props.loading ? '0.5' : '1')};
  &:hover {
    background-color: ${(props) => (props.loading ? '#0d6cff' : '#000000')};
  }
`;

export const FinePrintText = styled.div`
  color: #7a7a7a;
`;
