/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { message, Slider } from 'antd';
import Cropper from 'react-easy-crop';
import { Button } from '../../../CoreStyles/GeneralStyles';

// import './Objects.css';

export default function ImagePicker({ width, height, shape, upload }) {
  const fileInput = useRef(null);

  const [localImage, setLocalImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, pixels) => {
    setCroppedAreaPixels(pixels);
  }, []);

  const handleFileInput = ({ target }) => {
    if (target?.files?.length === 1) {
      const file = target.files[0];
      const newUrl = URL.createObjectURL(file);
      setLocalImage(newUrl);
    }
  };

  const handleBack = () => {
    setLocalImage(null);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      // eslint-disable-next-line no-use-before-define
      const croppedImage = await getCroppedImg(
        localImage,
        croppedAreaPixels,
        0
      );

      upload(croppedImage);
    } catch (e) {
      message.error(e.message);
    }
  }, [localImage, croppedAreaPixels]);

  return (
    <PickerContainer>
      <Header>{!localImage ? 'Upload File' : 'Crop Image'}</Header>

      {!localImage && (
        <div style={{ textAlign: 'center' }}>
          <input
            style={{ display: 'none' }}
            type="file"
            ref={fileInput}
            accept="image/*"
            onChange={handleFileInput}
          />
          <ChooseFileButton onClick={() => fileInput.current.click()}>
            Choose a File
          </ChooseFileButton>
        </div>
      )}

      {localImage && (
        <CropperContainer>
          <CropperArea height={height}>
            <Cropper
              cropShape={shape}
              showGrid={false}
              image={localImage}
              crop={crop}
              zoom={zoom}
              cropSize={{ width, height }}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </CropperArea>
          <Slider value={zoom} min={1} max={3} step={0.1} onChange={setZoom} />
          <CropperButtons>
            <BackButton secondary onClick={handleBack}>
              Back
            </BackButton>
            <Button primary onClick={showCroppedImage}>
              Continue
            </Button>
          </CropperButtons>
        </CropperContainer>
      )}
    </PickerContainer>
  );
}

const PickerContainer = styled.div`
  width: 500px;
  background-color: ;
  color: ;
`;

const CropperContainer = styled.div`
  position: relative;
`;

const CropperArea = styled.div`
  height: ${(props) => props.height}px;
  position: relative;
`;

const CropperButtons = styled.div`
  width: 100%;
  text-align: right;
`;

const Header = styled.div`
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #2b2b2b;
`;

const BackButton = styled(Button)`
  margin-right: 5px;
`;

const ChooseFileButton = styled(Button)`
  margin-bottom: 20px;
`;

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export const getRadianAngle = (degreeValue) => (degreeValue * Math.PI) / 180;

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/png');
  });
}
