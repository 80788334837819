import styled from 'styled-components';

export const PaymentMethodSelectorContainer = styled.div`
  background: #ffffff;
  border: 2px solid #e8e8e8;
  box-sizing: border-box;

  border-radius: 10px;
  padding: 15px 10px 15px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;

  &:hover {
    border: 2px solid #0d6cff;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(216 224 255 / 25%);
  }
`;

export const PaymentMethodSelectorContainerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const PaymentMethodInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentMethodText = styled.div`
  margin-left: 10px;
`;

export const PaymentMethodImage = styled.img`
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 36px;
  border-radius: 2px;
`;
