/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { ChevronDownIcon } from '@heroicons/react/solid';

import { useDropdown } from './useDropdown';

const DropdownMenu = ({
  value,
  options,
  onChange,
  style,
  disabled,
  titleKey,
  searchEnabled,
  button,
}) => {
  const [dropdownRef, isOpen, setIsOpen] = useDropdown();

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div
      className={`dropdown ${disabled && ' disabled'}`}
      ref={dropdownRef}
      style={style || {}}
    >
      <div
        onClick={() => {
          if (!disabled) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {button && button}
        {!button && (
          <button
            type="button"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={`dropbtn ${disabled ? 'disabled' : ''}`}
            style={style || {}}
          >
            {value && options[value]
              ? titleKey
                ? options[value][titleKey]
                : options[value].title
              : 'Click to Choose'}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
          </button>
        )}
      </div>

      {isOpen && (
        <div id="myDropdown" className="dropdown-content">
          {searchEnabled && (
            <div className="dropdown-search">
              <input
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                className="dropdown-search-input"
              />
            </div>
          )}

          <div className="dropdown-options">
            {options === null ||
              (Object.keys(options).length === 0 && (
                <div>No Options Available</div>
              ))}
            {Object.keys(options).map((key, index) => {
              const option = options[key];

              if (
                titleKey &&
                option[titleKey]
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              ) {
                return (
                  <div
                    key={key}
                    role="button"
                    tabIndex={index}
                    onKeyDown={() => {
                      onChange(key);
                      setIsOpen(false);
                    }}
                    onClick={() => {
                      onChange(key);
                      setIsOpen(false);
                    }}
                  >
                    {titleKey && option[titleKey]}
                    {!titleKey && option.title}
                  </div>
                );
              }

              if (
                !titleKey ||
                (searchEnabled &&
                  option.title
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()))
              ) {
                return (
                  <div
                    key={key}
                    role="button"
                    tabIndex={index}
                    onKeyDown={() => {
                      onChange(key);
                      setIsOpen(false);
                    }}
                    onClick={() => {
                      onChange(key);
                      setIsOpen(false);
                    }}
                  >
                    {titleKey && option[titleKey]}
                    {!titleKey && option.title}
                  </div>
                );
              }

              return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

DropdownMenu.propTypes = {
  value: PropTypes.element,
  options: PropTypes.element,
  onChange: PropTypes.func,
  style: PropTypes.element,
  disabled: PropTypes.bool,
  titleKey: PropTypes.string,
  searchEnabled: PropTypes.bool,
  button: PropTypes.element,
};

DropdownMenu.defaultProps = {
  value: null,
  options: null,
  onChange: null,
  style: null,
  disabled: false,
  titleKey: null,
  searchEnabled: false,
  button: null,
};

export default DropdownMenu;
