import styled, { css } from 'styled-components';

import { Card } from '../../../CoreStyles/GeneralStyles';

export const AcccountSettingsCard = styled(Card)`
  width: 100%;

  margin-bottom: 40px;
  @media (max-width: 980px) {
    width: 90%;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Form = styled.form`
  margin: 20px 0;
  padding: 0 30px;
  width: 60%;

  @media (max-width: 980px) {
    padding: 0px;
    width: 100%;
    margin-bottom: 0px;
  }
`;
export const ImageWrapper = styled.div`
  display: 'flex';
  align-items: 'center';
`;
export const UploadedImage = styled.span`
  padding: 5px;
`;
export const ProfileImage = styled.img`
  border-radius: ${(props) => props.borderRadius || ''};
`;

export const PlatformButton = styled.button`
  color: '#C0C0C0';
  margin-left: '20px';
  border: none;
`;
export const AccountPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-left: 15%; */
  /* margin-right: 15%; */
  align-items: center;
  padding-top: 10px;

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

export const HeaderWrapper = styled.div`
  margin-left: 25%;
  margin-top: 20px;
  @media (max-width: 980px) {
    margin-left: 5%;
  }
  @media (max-width: 768px) {
    margin-left: 0%;
    padding: 0 15px;
  }
`;
export const H2Heading = styled.h2``;

export const AccountDeleteIcon = styled.span`
  margin-right: 10px;
  font-size: 18px;
`;

export const SettingsGroup = styled.div`
  display: grid;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  grid-template-columns: 1fr 1fr 1fr;
  box-sizing: border-box;
  column-gap: 16px;
  row-gap: 16px;

  @media (max-width: 767px) {
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  border-top: ${(props) => props.borderTop || '1px solid #e5e7eb;'};
  border-bottom: ${(props) => props.borderBottom || '1px solid #e5e7eb;'};
`;

export const SettingsGroupTitle = styled.div`
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

export const SettingsGroupContent = styled.div`
  display: flex;
  grid-column-end: span 2;
  grid-column-start: span 2;
`;

export const SettingsGroupContentPrimary = styled.div`
  flex-grow: 1;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SettingsGroupContentActions = styled.div`
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SettingsCardHeader = styled.div`
  margin-bottom: 25px;
  margin-top: 10px;

  ${(props) =>
    props.withImage &&
    css`
      display: flex;
      align-items: flex-start;
    `}
`;

export const SettingsCardTitle = styled.div`
  font-size: 18px;
  color: rgb(17, 24, 39);
  margin-bottom: 4px;
`;

export const SettingsCardDescription = styled.div`
  font-size: 14px;
  color: rgb(107, 114, 128);
`;

// Payment Setting Stuff

export const PaymentMethodContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 24px;
  border-radius: 5px;
  margin-bottom: 14px;
  background-color: #f5f5f5;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const PaymentMethodInfo = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const PaymentMethodActions = styled.div`
  display: block;
  flex-shrink: 0;
  height: 38px;
  margin-left: 24px;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: 767px) {
    margin-left: 0px;
  }
`;

export const PaymentMethodImage = styled.img`
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 36px;
  border-radius: 2px;
`;

export const PaymentMethodInfoData = styled.div`
  display: block;
  margin-left: 16px;

  @media (max-width: 767px) {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const PaymentMethodInfoDataTitle = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const PaymentMethodInfoDataSecondary = styled.div`
  display: flex;
  margin-top: 2px;
  font-size: 14px;
  line-height: 20px;
  color: rgb(75, 85, 99);
`;

export const PaymentMethodActionMenuItem = styled.div`
  padding: 10px 15px !important;
  cursor: pointer;
  text-align: left;
`;

export const PaymentMethodDefaultIndiciator = styled.span`
  margin-left: 10px;
  color: #0065ff;
  padding: 3px 8px;
  font-weight: 500;
  border-radius: 6px;
  font-size: 12px;
  background-color: #ddeafd;
`;
