import React from 'react';
import { PropTypes } from 'prop-types';

import ModalPopUp from '../../Common/ModalPopUp';

import { ModalHeader, ModalTitle, ModalCloseButton } from './ActionModalStyle';

export function ActionModal({ visible, setVisible, content, title }) {
  console.log('content is ', content);
  return (
    <ModalPopUp
      className="modal-lg"
      title={null}
      closeIcon={null}
      header={null}
      closable={false}
      maskClosable
      centered
      visible={visible}
      isOk={() => setVisible(false)}
      isCancel={() => setVisible(false)}
      footer={null}
      content={
        <div>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <ModalCloseButton onClick={() => setVisible(false)}>
              Close
            </ModalCloseButton>
          </ModalHeader>

          {content}
        </div>
      }
    />
  );
}

ActionModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  title: PropTypes.element,
  content: PropTypes.element,
};

ActionModal.defaultProps = {
  visible: false,
  setVisible: null,
  title: null,
  content: null,
};

export default ActionModal;
