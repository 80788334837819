import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
// import Skeleton from 'react-loading-skeleton';
// import { Spin } from 'antd';
// import { propTypes } from 'react-bootstrap/esm/Image';

import { SearchIcon } from '@heroicons/react/solid';
import {
  Button,
  SectionTitle,
  Section,
  Page,
  SearchBox,
  SearchInput,
  SectionContent,
  SearchIconContainer,
  SectionCarousel,
  FloatingButton,
  // SearchHeader,
  SearchBoxMobile,
  SearchInputMobile,
  SectionTitleWithSearch,
  // TableHeaderContainer,
} from '../../CoreStyles/GeneralStyles';
import SearchIconSvg from '../../images/search-icon.svg';
import {
  DashboardHeader,
  ProjectHeaderContainer,
  ProjectHeaderData,
} from './DashboardStyle';
import SummaryCard from './Summary/SummaryCard';
import ProjectRowCard from './ProjectRowCard';

import { Mobile, Default } from '../Devices';
import { GET_USER_PROJECTS } from '../../GraphQl/Queries/usersQueries';
import NewProject from './NewProject';
import ModalPopUp from '../Common/ModalPopUp';

import { vocabulary } from '../../Enums';
import { OrgContext, isAllowed } from '../../Utils';

export const Dashboard = () => {
  const { data, loading, error } = useQuery(GET_USER_PROJECTS, {
    fetchPolicy: 'cache-and-network',
  });

  const [newProjectVisible, setNewProjectVisible] = useState(false);

  const handleNewProject = () => {
    setNewProjectVisible(true);
  };
  const handleCancleProject = (value) => {
    setNewProjectVisible(value);
  };

  const userId = data?.currentUser?.id;

  const [searchQuery, setSearchQuery] = useState('');

  const organization = useContext(OrgContext);

  console.log(error, loading, data, 'khkh876');
  return (
    <Page singleColumnLarge>
      {organization?.current_user_role !== 'client' && (
        <DashboardHeader>
          <SearchBox margin="0px 15px 0px 0px">
            <SearchIconContainer>
              <SearchIcon className="search-icon" />
            </SearchIconContainer>
            <SearchInput
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              placeholder={`Search ${
                vocabulary[organization?.projects_vocabulary]?.plural_cap ||
                'Projects'
              }`}
            />
          </SearchBox>
          {isAllowed(organization?.current_user_role, 'projects.create') && (
            <Button primary onClick={handleNewProject}>
              New{' '}
              {vocabulary[organization?.projects_vocabulary]?.singular_cap ||
                'Project'}
            </Button>
          )}
        </DashboardHeader>
      )}

      {/* <div style={{ clear: 'right' }} /> */}

      {isAllowed(organization?.current_user_role, 'summary.read') && (
        <>
          <Default>
            <Section columnMarginTop="25px">
              <SectionTitle>Summary</SectionTitle>

              <SectionContent grid>
                <SummaryCard userId={userId} type="TOTAL_COLLECTED" />
                <SummaryCard userId={userId} type="AMOUNT_OWED" />
                <SummaryCard userId={userId} type="IN_PROGRESS" />
                <SummaryCard userId={userId} type="RECENT_ACTIVITY" />
              </SectionContent>
            </Section>
          </Default>

          <Mobile>
            <Section>
              <SectionTitle>Summary</SectionTitle>

              <SectionCarousel>
                <SummaryCard userId={userId} type="TOTAL_COLLECTED" />
                <SummaryCard userId={userId} type="AMOUNT_OWED" />
                <SummaryCard userId={userId} type="IN_PROGRESS" />
                <SummaryCard userId={userId} type="RECENT_ACTIVITY" />
              </SectionCarousel>
            </Section>
          </Mobile>
        </>
      )}

      <Section>
        <Mobile>
          <SectionTitleWithSearch>
            <SectionTitle withSearch>
              {' '}
              {vocabulary[organization?.projects_vocabulary]?.plural_cap ||
                'Projects'}
            </SectionTitle>
            <SearchBoxMobile>
              <SearchInputMobile placeholder="Search" />
              <SearchIcon src={SearchIconSvg} />
            </SearchBoxMobile>
          </SectionTitleWithSearch>
        </Mobile>

        <Default>
          <SectionTitle>
            {vocabulary[organization?.projects_vocabulary]?.plural_cap ||
              'Projects'}
          </SectionTitle>
        </Default>

        <ProjectHeaderContainer>
          <ProjectHeaderData>Name</ProjectHeaderData>
          <ProjectHeaderData>Team</ProjectHeaderData>
          <ProjectHeaderData>Status</ProjectHeaderData>
          <ProjectHeaderData>Progress</ProjectHeaderData>
        </ProjectHeaderContainer>
        {data &&
        data?.currentUser &&
        data?.currentUser?.projects &&
        data?.currentUser?.projects?.length !== 0 ? (
          data.currentUser.projects
            .filter((project) =>
              project?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            )
            .map((item) => (
              <ProjectRowCard
                projectItem={item}
                id={item.id}
                key={item.id}
                current_user_role={organization?.current_user_role}
                projects_vocabulary={organization?.projects_vocabulary}
              />
            ))
        ) : (
          // <Skeleton count={12} height={20} />
          <ProjectRowCard />
        )}
      </Section>
      <Mobile>
        <FloatingButton onClick={handleNewProject}>
          + New Project
        </FloatingButton>
      </Mobile>

      <Default>
        <ModalPopUp
          className="common-modal"
          width="35%"
          title={`New ${
            vocabulary[organization?.projects_vocabulary]?.singular_cap ||
            'Project'
          }`}
          // closeIcon="Close"
          // closable={newProject}
          // maskClosable={false}
          centered
          visible={newProjectVisible}
          isOk={handleNewProject}
          isCancel={() => handleCancleProject(false)}
          footer={null}
          content={
            <NewProject
              handleCancleProject={handleCancleProject}
              projects_vocabulary={organization?.projects_vocabulary}
              userId={userId}
            />
          }
        />
      </Default>
      <Mobile>
        <ModalPopUp
          className="common-modal"
          width="99%"
          title={`New ${
            vocabulary[organization?.projects_vocabulary]?.singular_cap ||
            'Project'
          }`}
          // closeIcon="Close"
          // closable={newProject}
          // maskClosable={false}
          centered
          visible={newProjectVisible}
          isOk={handleNewProject}
          isCancel={() => handleCancleProject(false)}
          footer={null}
          content={
            <NewProject
              handleCancleProject={handleCancleProject}
              projects_vocabulary={organization?.projects_vocabulary}
            />
          }
        />
      </Mobile>
    </Page>
  );
};
Dashboard.propTypes = {};
Dashboard.defaultProps = {};

export default Dashboard;
