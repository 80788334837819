import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Button } from 'antd';
import { useQuery } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { ArrowCircleLeftIcon } from '@heroicons/react/outline';
import CreditCardForm from './CreditCardForm';
import GET_CURRENT_USER from '../../../../GraphQl/Queries/usersQueries';
import { hideModal, setDialogType } from '../../state';
import {
  ModalBackButton,
  ModalHeader,
  ModalTitle,
} from '../UserPaymentModalStyle';
import types from '../../model';

export default function AddCreditCard() {
  // TODO - Move this to environment variable
  const stripePromise = loadStripe('pk_test_4zrJJLrlwnldGJKRnbe1DUMc');
  const { loading, data } = useQuery(GET_CURRENT_USER);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ModalHeader>
        <ModalTitle>
          <ModalBackButton onClick={() => setDialogType(types.add_method)}>
            <ArrowCircleLeftIcon className="modal-back-icon" />
            <span>Back</span>
          </ModalBackButton>
          Add Credit Card
        </ModalTitle>
      </ModalHeader>

      <Elements stripe={stripePromise}>
        <CreditCardForm
          ccReveived={async (value) => {
            // TODO: Handle adding credit card
            console.log('ccReceived', value);
            return value;
          }}
          user={data.currentUser}
        >
          <Button className="add-payment-cancel-button" onClick={hideModal}>
            Cancel
          </Button>
        </CreditCardForm>
      </Elements>
    </>
  );
}
