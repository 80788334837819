import styled from 'styled-components';

export const BlankDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentMethodImage = styled.img`
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 36px;
  border-radius: 2px;
`;

export const SelectCardText = styled.span`
  margin-left: 9px;
`;
export const IconWrapper = styled.span`
  margin-left: 9px;
  margin-top: 9px;
`;
export const BlankSpan = styled.span``;

export default { BlankDiv, SelectCardText, IconWrapper, BlankSpan };
