/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import {
  InputContainer,
  CurrencyField,
  InputCurrencyItem,
  InputTrailingItemText,
} from './TrailingInputStyle';

const CurrencyInput = ({
  onChange,
  value,
  placeholder,
  customStyle,
  minValue,
}) => (
  <InputContainer style={customStyle}>
    <CurrencyField
      type="number"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      min={minValue}
    />
    <InputCurrencyItem>
      <InputTrailingItemText>$</InputTrailingItemText>
    </InputCurrencyItem>
  </InputContainer>
);

CurrencyInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  customStyle: PropTypes.element,
  minValue: PropTypes.number,
};

CurrencyInput.defaultProps = {
  onChange: null,
  value: null,
  placeholder: null,
  customStyle: null,
  minValue: 0,
};

export default CurrencyInput;
