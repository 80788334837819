/* eslint-disable dot-notation */
import React, { useState, useEffect } from 'react';
// import { Button } from 'antd';
// import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
// import { Space, Dropdown } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import {
  Input,
  Label,
  Button,
  InputGroup,
  SeparateGroup,
} from '../../../CoreStyles/GeneralStyles';
import {
  DELETE_INVOICE,
  UPDATE_RECURRING_INVOICE,
} from '../../../GraphQl/Mutations/invoiceMutations';
import createNotification from '../../Common/Notifications';
import DatePicker from '../../Generics/DatePicker';

import LineItemEditor from '../LineItemEditor';

import {
  generateRrule,
  extractRuleFromInvoice,
} from '../../../Utils/RruleUtils';

import RecurringSettingsPanel from './RecurringSettingsPanel';

function Editor({ Invoice, onClose }) {
  const [invoiceTitle, setInvoiceTitle] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const [lineItems, setLineItems] = useState([]);

  const [recurringRule, setRecurringRule] = useState({
    freq: 'monthly',
    bymonthday: 1,
    interval: 1,
    bysetpos: 1,
    byweekday_temp: 'monday',
    bymonth: 1,
    month_type: 'on_day',
    year_type: 'on_day',
  });

  const [recurringEnd, setRecurringEnd] = useState('never');

  function updateRecurringRule(key, newValue) {
    const tempRule = _.cloneDeep(recurringRule);
    tempRule[key] = newValue;

    setRecurringRule(tempRule);
  }

  const [updateInvoice, { error, loading }] = useMutation(
    UPDATE_RECURRING_INVOICE,
    {
      onCompleted: () => {
        createNotification('success', 'Invoice Updated');
        onClose();
      },
      onError: (errors) => {
        console.log('errors: ', errors);
      },
    }
  );

  const [deleteInvoiceMutation] = useMutation(DELETE_INVOICE, {
    onCompleted: () => {
      createNotification('success');
      onClose();
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
  });

  useEffect(async () => {
    if (Invoice) {
      await setInvoiceTitle(Invoice.name);
      await setStartDate(new Date(Invoice.billing_start));
      await setLineItems(Invoice.line_items);

      const { convertedRule, recurringEnd: recurringEndTemp } =
        extractRuleFromInvoice(Invoice);

      await setRecurringRule(convertedRule);
      await setRecurringEnd(recurringEndTemp);
    }
  }, [Invoice]);

  const handleUpdateInvoice = (e) => {
    e.preventDefault();
    updateInvoice({
      variables: {
        id: Invoice.id,
        updatedInvoice: {
          name: invoiceTitle,
          billing_start: startDate,
          line_items: lineItems.map((lineItem) => {
            const lineItemCopy = _.cloneDeep(lineItem);
            delete lineItemCopy['__typename'];
            return lineItemCopy;
          }),
          recurrence: generateRrule({
            recurringRule,
            recurringEnd,
            startDate,
          }),
          amount:
            lineItems.reduce(
              (accumulator, currentValue) =>
                accumulator + currentValue.quantity * currentValue.rate,
              0
            ) * 100,
        },
      },
    });
    if (error) {
      console.log(error);
    }
  };
  const handleDeleteInvoice = (e) => {
    e.preventDefault();
    // deleteUserInvoice(e);
    deleteInvoiceMutation({ variables: { id: Invoice.id } });
  };

  return (
    <>
      <InputGroup>
        <Label>Invoice Title</Label>
        <Input
          type="text"
          name="invoice_title"
          placeholder="April 2021 title"
          onChange={(e) => setInvoiceTitle(e.target.value)}
          value={invoiceTitle}
        />
      </InputGroup>

      <InputGroup>
        <Label>Starting On</Label>
        <DatePicker
          value={startDate}
          updateValue={(date) => {
            setStartDate(date);
          }}
        />
      </InputGroup>
      <SeparateGroup backgroundEnabled whiteBg>
        <RecurringSettingsPanel
          recurringEnd={recurringEnd}
          recurringRule={recurringRule}
          updateRecurringRule={updateRecurringRule}
          setRecurringEnd={setRecurringEnd}
        />
      </SeparateGroup>

      <LineItemEditor lineItems={lineItems} setLineItems={setLineItems} />

      <Button color="red" thin fontWeight="bold" onClick={handleDeleteInvoice}>
        Delete Invoice
      </Button>

      <Button onClick={handleUpdateInvoice} loading={loading} primary>
        {loading && <LoadingOutlined />} Update Recurring Invoice
      </Button>
    </>
  );
}

Editor.propTypes = {
  Invoice: PropTypes.element,
  onClose: PropTypes.func,
};
Editor.defaultProps = {
  Invoice: null,
  onClose: null,
};

export default Editor;
