import merge from 'lodash/merge';
import development from './development';
import local from './local';
import stag from './stag';
import prod from './prod';

const settings = {};
export default (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'localhost':
    case 'local':
      return merge(local, settings);
    case 'development':
    case 'dev':
      return merge(development, settings);
    case 'staging':
    case 'stag':
      return merge(stag, settings);
    case 'production':
    case 'prod':
      return merge(prod, settings);
    default:
      return merge(development, settings);
  }
})();
