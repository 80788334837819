// import { Button } from 'antd';
import React from 'react';
import {
  Card,
  PageWrapper,
  SectionTitle,
  Button,
} from '../../CoreStyles/GeneralStyles';
import {
  IntegrationHeader,
  IntegrationDescription,
  BlankSpan,
  IntegrationTitle,
  IntegrationMeta,
  IntegrationsGrid,
  IntegrationImage,
} from './IntegrationStyles';
// import PropTypes from 'prop-types'
import QuickbooksLogo from '../../images/qb_thumb.png';
import ZapierLogo from '../../images/zapier_logo.svg';
import XeroLogo from '../../images/xero_logo.png';
import FreshbooksLogo from '../../images/freshbooks_logo.png';
import SalesForceLogo from '../../images/salesforce_logo.png';

function Integrations() {
  return (
    <PageWrapper>
      <SectionTitle>Integrations</SectionTitle>
      <IntegrationsGrid>
        <Card>
          <IntegrationHeader>
            <IntegrationImage src={ZapierLogo} />
            <BlankSpan>
              <Button secondary>Connect</Button>
            </BlankSpan>
          </IntegrationHeader>
          <IntegrationMeta>
            <IntegrationTitle>Zapier</IntegrationTitle>
            <IntegrationDescription>
              Zapier is a tool that helps you automate repetitive tasks between
              AloaPay and other apps—no code necessary.
            </IntegrationDescription>
          </IntegrationMeta>
        </Card>

        <Card>
          <IntegrationHeader>
            <IntegrationImage src={QuickbooksLogo} />
            <BlankSpan>
              <Button secondary>Connect</Button>
            </BlankSpan>
          </IntegrationHeader>
          <IntegrationMeta>
            <IntegrationTitle>Quickbooks</IntegrationTitle>
            <IntegrationDescription>
              Integrate with Quickbooks so you can keep your books clean. No
              manual reconciling and no lost data - it’s that easy.
            </IntegrationDescription>
          </IntegrationMeta>
        </Card>

        <Card>
          <IntegrationHeader>
            <IntegrationImage src={XeroLogo} />
            <BlankSpan>
              <Button secondary>Connect</Button>
            </BlankSpan>
          </IntegrationHeader>
          <IntegrationMeta>
            <IntegrationTitle>Xero</IntegrationTitle>
            <IntegrationDescription>
              Integrate with Xero so you can keep your books clean. No manual
              reconciling and no lost data - it’s that easy.
            </IntegrationDescription>
          </IntegrationMeta>
        </Card>

        <Card>
          <IntegrationHeader>
            <IntegrationImage src={FreshbooksLogo} />
            <BlankSpan>
              <Button secondary>Connect</Button>
            </BlankSpan>
          </IntegrationHeader>
          <IntegrationMeta>
            <IntegrationTitle>Freshbooks</IntegrationTitle>
            <IntegrationDescription>
              Integrate with Freshbooks so you can keep your books clean. No
              manual reconciling and no lost data - it’s that easy.
            </IntegrationDescription>
          </IntegrationMeta>
        </Card>

        <Card>
          <IntegrationHeader>
            <IntegrationImage src={SalesForceLogo} />
            <BlankSpan>
              <Button secondary>Connect</Button>
            </BlankSpan>
          </IntegrationHeader>
          <IntegrationMeta>
            <IntegrationTitle>SalesForce</IntegrationTitle>
            <IntegrationDescription>
              SalesForce lets you get more out of your data. Tie your invoicing
              to your CRM to keep your back-office more organized.
            </IntegrationDescription>
          </IntegrationMeta>
        </Card>
      </IntegrationsGrid>
    </PageWrapper>
  );
}

// Integrations.propTypes = {

// }

export default Integrations;
