// import React from 'react';
import { notification } from 'antd';

export const createNotification = (type, value) => {
  switch (type) {
    case 'success':
      notification[type]({
        message: value || 'Success',
      });
      break;
    case 'error':
      notification[type]({
        message: 'Error',
        description: `${value}`,
      });
      break;
    case 'warning':
      notification[type]({
        message: 'Warning',
        description: `${value}`,
      });
      break;
    default:
      break;
  }
};
export default createNotification;
