/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

import { PropTypes } from 'prop-types';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Button, Page, Input } from '../../CoreStyles/GeneralStyles';
import { emailValidation, passwordValidation } from '../../Utils';
import { createNotification } from '../Common/Notifications';
import auth from '../../Auth';
import ErrorPage from '../Common/ErrorPage';
import {
  InputContainer,
  InputTitle,
  // TextField,
} from '../../CoreStyles/InputStyles';
import ErrorWrapper from './SignupStyle';
import {
  AuthLogo,
  AuthContainer,
  H1,
  InfoContainer,
  PasswordContainer,
  CardContainer,
  CardContainerLeft,
  CardContainerRight,
  PromoContainer,
  FooterContainer,
  PoweredbyContainer,
  PoweredByLogo,
  AuthCard,
  MobilePoweredbyContainer,
  AuthButtonContainer,
  AuthLink,
} from './AuthStyle';

import AloaPayLogoWhite from '../../images/aloapay-logo-white.svg';
import AloaPayLogoDark from '../../images/aloapay-logo.svg';
import CREATE_USER_MUTATION from '../../GraphQl/Mutations/CreateUserMutations';

export default function SignUp({ orgData }) {
  const history = useHistory();

  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    confirmpassword: '',
    errors: {
      name: '',
      email: '',
      password: '',
      confirmpassword: '',
    },
  });

  const loginUser = async () => {
    try {
      await auth.login(state.email, state.password);
      history.push('/');
      createNotification('success', `Welcome ${state.name}`);
    } catch (error) {
      createNotification('error', `${state.name}`);
    }
  };

  const [createUser, { error }] = useMutation(CREATE_USER_MUTATION, {
    onCompleted: (data) => {
      // Once we create a user, let's redeem all invites then log them in
      const newUser = data?.createUser;

      console.log('the data returned is ', newUser);

      loginUser();
    },
    onError: (errors) => {
      ErrorPage(errors);
    },
  });

  const validateForm = (stateValue) => {
    const valueCheck =
      stateValue.name.length !== 0 &&
      stateValue.email.length !== 0 &&
      stateValue.password.length !== 0 &&
      stateValue.confirmpassword.length !== 0;
    let valid = true;
    Object.values(stateValue.errors).forEach((val) => {
      if (val?.length === 0 || val.match(/Correct,Password/)) {
        valid = valid && true;
      } else {
        valid = valid && false;
      }
    });
    const finalValid = valid && valueCheck;
    return finalValid;
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    const { errors } = state;
    try {
      switch (name) {
        case 'name':
          errors.name =
            value.length < 5 ? 'Name must be at least 5 characters long!' : '';
          break;
        case 'email':
          errors.email = !emailValidation(value)
            ? 'Please use valid email.'
            : '';
          break;
        case 'password':
          errors.password = !passwordValidation(value)
            ? 'Please use at least one number and one special character..'
            : '';
          break;
        case 'confirmpassword':
          errors.confirmpassword =
            !passwordValidation(value) && !(value === state.password)
              ? 'Your password is not matched.'
              : 'Correct,Password';
          break;
        default:
          break;
      }
    } catch (e) {
      alert(e);
    }
    setState({ ...state, errors, [name]: value });

    // setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm(state)) {
      createUser({
        variables: {
          // id: `_${Math.random().toString(36).substr(2, 9)}`,
          name: state.name,
          email: state.email,
          password: state.password,
        },
      });
      if (error) {
        console.log(error);
      }
    } else {
      createNotification(
        'warning',
        'PLease fill all form field first without any error.'
      );
    }
  };
  // const handleReset = (e) => {
  //     console.log(e, 'sdjkfh');
  // };
  return (
    <Page backgroundColor="#F3F3F4" centered>
      <AuthContainer>
        <AuthLogo src={orgData?.platform_logo_thumb} />
        <AuthCard margin="auto" padding="35px 45px">
          <CardContainer>
            <CardContainerLeft>
              <H1>Sign Up</H1>
              <InfoContainer>
                <InputContainer>
                  <InputTitle>Name</InputTitle>
                  {/* <TextField /> */}
                  <Input
                    placeholder="John doe"
                    type="text"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                  />
                  {state.errors?.name?.length > 0 && (
                    <ErrorWrapper>{state.errors?.name}</ErrorWrapper>
                  )}
                </InputContainer>
                <InputContainer>
                  <InputTitle>Email</InputTitle>
                  {/* <TextField /> */}
                  <Input
                    placeholder="john@gmail.com"
                    type="text"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                  />
                  {state.errors?.email?.length > 0 && (
                    <ErrorWrapper>{state.errors?.email}</ErrorWrapper>
                  )}
                </InputContainer>
              </InfoContainer>

              <PasswordContainer>
                <InputContainer>
                  <InputTitle>Password</InputTitle>
                  {/* <TextField type="password" /> */}
                  <Input
                    placeholder="securepass@123"
                    type="password"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                  />
                  {state.errors?.password?.length > 0 && (
                    <ErrorWrapper>{state.errors?.password}</ErrorWrapper>
                  )}
                </InputContainer>
                <InputContainer>
                  <InputTitle>Confirm Password</InputTitle>
                  {/* <TextField type="password" /> */}
                  <Input
                    placeholder="securepass@123"
                    type="password"
                    name="confirmpassword"
                    value={state.confirmpassword}
                    onChange={handleChange}
                  />
                  {state.errors?.confirmpassword?.length > 0 &&
                  state.errors.confirmpassword.match(/Correct,Password/) ? (
                    <p style={{ color: 'green' }}>
                      {state.errors.confirmpassword}
                    </p>
                  ) : (
                    <ErrorWrapper>{state.errors?.confirmpassword}</ErrorWrapper>
                  )}
                  {/* {state.password === state.confirmpassword &&
                                        state.confirmpassword.length !== 0 && (
                                            <p style={{ color: 'green' }}>
                                                Correct,Password
                                            </p>
                                        )} */}
                </InputContainer>
              </PasswordContainer>

              <AuthButtonContainer>
                <Button marginTop="10px" onClick={handleSubmit}>
                  Create Account
                </Button>
                <AuthLink href="/login">Sign In instead</AuthLink>
              </AuthButtonContainer>
            </CardContainerLeft>
            <CardContainerRight>
              {orgData?.signup_message_text && (
                <PromoContainer>{orgData?.signup_message_text}</PromoContainer>
              )}
              {!orgData?.signup_message_text && (
                <PromoContainer>
                  {orgData?.platform_name} uses AloaPay for secure and
                  simplified billing
                </PromoContainer>
              )}
              <FooterContainer>
                <PoweredbyContainer>
                  Powered by
                  <a href="https://aloapay.com">
                    <PoweredByLogo src={AloaPayLogoWhite} />
                  </a>
                </PoweredbyContainer>
              </FooterContainer>
            </CardContainerRight>
          </CardContainer>
        </AuthCard>
        <MobilePoweredbyContainer>
          Powered by
          <a href="https://aloapay.com">
            <PoweredByLogo src={AloaPayLogoDark} />
          </a>
        </MobilePoweredbyContainer>
      </AuthContainer>
    </Page>
  );
}

SignUp.propTypes = {
  orgData: PropTypes.any,
};
