import styled from 'styled-components';

export const DashboardHeader = styled.div`
  float: right;
  display: flex;
  margin-right: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ProjectHeaderContainer = styled.div`
  width: 100%;
  padding: 10px 24px 13px 20px;
  display: grid;
  grid-template-columns: 30% 15% 30% 15% 10%;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const ProjectHeaderData = styled.div``;
