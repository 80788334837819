/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import {
  InputContainer,
  InputField,
  InputTrailingItem,
  InputTrailingItemText,
} from './TrailingInputStyle';

const TrailingInput = ({ onChange, value, placeholder, width, onBlur }) => (
  <InputContainer width={width}>
    <InputField
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      onBlur={onBlur}
    />
    <InputTrailingItem>
      <InputTrailingItemText>%</InputTrailingItemText>
    </InputTrailingItem>
  </InputContainer>
);

TrailingInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  onBlur: PropTypes.func,
};

TrailingInput.defaultProps = {
  onChange: null,
  value: null,
  placeholder: null,
  width: '100%',
  onBlur: null,
};

export default TrailingInput;
