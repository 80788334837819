/* eslint-disable react/forbid-prop-types */
import { React } from 'react';
import PropTypes from 'prop-types';

import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import { getChartData, getColorForIndex } from './ChartUtils';

function PieChart({ data, valueOptions, groupBy }) {
  console.log('data is ', data);

  const chartData = getChartData({
    data: data?.invoices,
    groupBy,
    valueOptions,
  });

  console.log('chartData: ', chartData);

  return (
    <Pie
      plugins={[ChartDataLabels]}
      data={{
        labels: _.map(chartData, 'title'),
        datasets: [
          {
            label: 'Sum of Amounts',
            data: _.map(chartData, 'value'),
            backgroundColor: chartData.map((index) => getColorForIndex(index)),
          },
        ],
      }}
    />
  );
}

PieChart.propTypes = {
  data: PropTypes.any,
  valueOptions: PropTypes.any,
  groupBy: PropTypes.string,
};

PieChart.defaultProps = {
  data: null,
  valueOptions: {},
  groupBy: null,
};

export default PieChart;
