/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */

import _ from 'lodash';
import moment from 'moment';

export function getValuesForType(type, objects, key) {
  if (type === 'sum') {
    return _.sumBy(objects, key);
  }

  if (type === 'average') {
    return _.meanBy(objects, key);
  }

  return 0;
}

export function getValueForKey(objects, key, keyType) {
  if (!key || !objects[0]) {
    return 0;
  }

  if (keyType === 'date') {
    return moment(new Date(objects[0][key])).startOf('day').toDate();
  }

  return objects[0][key];
}

export function getChartData({ data, groupBy, valueOptions }) {
  let newData = [];

  if (data) {
    newData = _(data)
      .groupBy((item) => {
        if (valueOptions.xKeyType === 'date') {
          return moment(_.get(item, groupBy.value)).startOf('day').format();
        }

        return _.get(item, groupBy.value);
      })
      .map((objects, key) => ({
        title: key,
        name: key,
        value: getValuesForType(valueOptions.type, objects, valueOptions.key),
        y: getValuesForType(valueOptions.type, objects, valueOptions.key),
        x: getValueForKey(objects, valueOptions.xKey, valueOptions.xKeyType),
      }))
      .sortBy((item) => {
        if (valueOptions.xKeyType === 'date') {
          return moment(item.x).startOf('day').toDate();
        }

        return item.x;
      })
      .value();
  }

  return newData;
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getColorForIndex(index) {
  const colors = [
    '#ee8434ff',
    '#c95d63ff',
    '#ae8799ff',
    '#717ec3ff',
    '#496ddbff',
    '#416165ff',
    '#0b3948ff',
    '#474973ff',
    '#161b33ff',
    '#0d0c1dff',
  ];
  return colors[index % 10];
}

export function editChartFilter({ data, groupBy, valueOptions }) {
  let newData = [];

  if (data) {
    newData = _(data)
      .groupBy(groupBy.value)
      .map((objects, key) => ({
        title: key,
        name: key,
        value: getValuesForType(valueOptions.type, objects, valueOptions.key),
        y: getValuesForType(valueOptions.type, objects, valueOptions.key),
        x: getValueForKey(objects, valueOptions.xKey, valueOptions.xKeyType),
      }))
      .value();
  }

  return newData;
}
