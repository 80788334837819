import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import styled from 'styled-components';
import {
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
} from '../Payments/UserPaymentModalStyle';
import { hideModal } from '../state';
import Receipt from './receipt';
import Invoice from './invoice';
// import Resume from './resume';
import { Button } from '../../../CoreStyles/GeneralStyles';
import { GET_INVOICE_PAYMENTS } from '../../../GraphQl/Queries/invoiceQuery';
import { OrgContext } from '../../../Utils';

export default function Viewer({ type, invoice, close }) {
  const organization = useContext(OrgContext);

  const { data: payments } = useQuery(GET_INVOICE_PAYMENTS, {
    variables: { id: invoice?.id },
  });

  const fileName =
    type === 'invoice'
      ? `Invoice-${invoice.id}.pdf`
      : `Receipt-${invoice.id}.pdf`;

  const document = () => {
    if (type === 'receipt') {
      return (
        <Receipt
          organization={organization}
          invoice={invoice}
          payments={payments?.getPaymentsForInvoice}
        />
      );
    }
    if (type === 'invoice') {
      return (
        <Invoice
          organization={organization}
          invoice={invoice}
          payments={payments?.getPaymentsForInvoice}
        />
      );
    }
    return null;
  };

  return (
    <div>
      <ModalHeader>
        <ModalTitle>{type === 'invoice' ? 'Invoice' : 'Receipt'}</ModalTitle>
        <PDFDownloadLink
          style={{ flexGrow: 0, marginRight: '15px' }}
          document={document()}
          fileName={fileName}
        >
          {({ loading }) =>
            loading ? 'Loading...' : <DownloadButton>Download</DownloadButton>
          }
        </PDFDownloadLink>
        <ModalCloseButton onClick={close}>Close</ModalCloseButton>
      </ModalHeader>

      {/* TODO: Make height responsive for different screens */}
      <PDFViewer width="100%" height="800px">
        {document()}
      </PDFViewer>
    </div>
  );
}

Viewer.propTypes = {
  type: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  close: PropTypes.func,
};

Viewer.defaultProps = {
  close: () => hideModal(),
};

const DownloadButton = styled(Button)`
  padding-top: 5px;
  padding-bottom: 5px;
`;
