/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import { Button } from '../../../CoreStyles/GeneralStyles';

function Menu({ isOpen, setIsOpen, children, button, style }) {
  const node = useRef();

  const handleClickOutside = (e) => {
    console.log('clicking anywhere');
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown" ref={node} style={style}>
      {!button && (
        <Button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          secondary
        >
          Edit
        </Button>
      )}

      {button && button}

      {isOpen && (
        <div id="myDropdown" className="dropdown-content">
          {children}
        </div>
      )}
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.any,
  button: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  style: PropTypes.any,
};

Menu.defaultProps = {
  children: null,
  button: null,
  isOpen: false,
  setIsOpen: null,
  style: null,
};

export default Menu;
