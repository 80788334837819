/* eslint-disable react/forbid-prop-types */
import { React, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import moment from 'moment';
import { createNotification } from '../../Common/Notifications';
import {
  TableCell,
  TableChip,
  TableUser,
  TableUserImageContainer,
  TableUserInfo,
  TableUserInfoPrimary,
  TableUserInfoSecondary,
  ManageTeamEditButton,
} from './ManageTeamStyle';

import { PaymentMethodActionMenuItem } from '../Account Settings/AccountSettingsStyle';

import Menu from '../../Generics/Menu';
import Avatar from '../../Generics/Avatar';
import ChangeRoleModal from './ChangeRoleModal';

import {
  REVOKE_INVITE_FROM_ORG,
  REMOVE_USER_FROM_ORG,
} from '../../../GraphQl/Mutations/orgMutations';
import { GET_ORGANIZATION_TEAM } from '../../../GraphQl/Queries/organizationsQuery';

const roles = {
  admin: {
    id: 'admin',
    name: 'Administrator',
    description: 'Best for business owners and company administrators',
  },
  sales: {
    id: 'sales',
    name: 'Sales',
    description:
      'Best for employees who need to create and update data regularly',
  },
  analyst: {
    id: 'analyst',
    name: 'Analyst',
    description:
      'Best for people who need full access to data, but do not need to create',
  },
  support: {
    id: 'support',
    name: 'Support Specialist',
    description:
      'Best for employees who regularly refund payments and respond to disputes',
  },
};

function TeamRow({ person }) {
  const [editMenuOpened, setEditMenuOpened] = useState(false);

  const [changeRoleOpen, setChangeRoleOpen] = useState(false);

  const [revokeInviteMutation] = useMutation(REVOKE_INVITE_FROM_ORG, {
    onCompleted: () => {
      createNotification('success', 'Invite Revoked');
    },
    refetchQueries: [
      {
        query: GET_ORGANIZATION_TEAM,
        variables: {
          id: '1',
        },
      },
    ],
  });

  const [removeUserMutation] = useMutation(REMOVE_USER_FROM_ORG, {
    onCompleted: () => {
      createNotification('success', 'User Removed');
    },
    refetchQueries: [
      {
        query: GET_ORGANIZATION_TEAM,
        variables: {
          id: '1',
        },
      },
    ],
  });

  return (
    <tr>
      <ChangeRoleModal
        isVisible={changeRoleOpen}
        setIsVisible={setChangeRoleOpen}
        user={person}
      />
      <TableCell>
        <TableUser>
          <TableUserImageContainer>
            <Avatar user={person} marginRight="10px" />
          </TableUserImageContainer>
          <TableUserInfo>
            <TableUserInfoPrimary>{person.name}</TableUserInfoPrimary>
            <TableUserInfoSecondary>{person.email}</TableUserInfoSecondary>
          </TableUserInfo>
        </TableUser>
      </TableCell>
      <TableCell>
        {person.last_login && moment(person.last_login).fromNow()}
      </TableCell>
      <TableCell>
        {person.invite && <TableChip blue>Invite Sent</TableChip>}
        {!person.invite && person.last_login && <TableChip>Active</TableChip>}
        {!person.invite && !person.last_login && (
          <TableChip blue>Inactive</TableChip>
        )}
      </TableCell>
      <TableCell>
        {roles[person.role || 'invlaid']?.name || 'Invalid'}
      </TableCell>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Menu
          button={
            <ManageTeamEditButton
              onClick={() => {
                setEditMenuOpened(!editMenuOpened);
              }}
            >
              Edit
            </ManageTeamEditButton>
          }
          isOpen={editMenuOpened}
          setIsOpen={setEditMenuOpened}
        >
          {!person.invite && (
            <>
              <PaymentMethodActionMenuItem
                onClick={() => {
                  setChangeRoleOpen(true);
                  setEditMenuOpened(false);
                }}
              >
                Edit Role
              </PaymentMethodActionMenuItem>
              <PaymentMethodActionMenuItem
                onClick={() => {
                  removeUserMutation({
                    variables: { userId: person.id, orgId: '1' },
                  });
                }}
              >
                Remove from Team
              </PaymentMethodActionMenuItem>
            </>
          )}

          {person.invite && (
            <>
              <PaymentMethodActionMenuItem
                onClick={() => {
                  revokeInviteMutation({ variables: { id: person.id } });
                }}
              >
                Revoke Invite
              </PaymentMethodActionMenuItem>
            </>
          )}
        </Menu>
      </td>
    </tr>
  );
}

TeamRow.propTypes = {
  person: PropTypes.object,
};

TeamRow.defaultProps = {
  person: null,
};

export default TeamRow;
