/* eslint-disable func-names */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';

import './Avatar.css';

const getInitials = function (string) {
  if (string == null || string === '') {
    return '👋';
  }
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export function Avatar({ user, marginRight }) {
  if (user.image_thumb) {
    return (
      <img
        style={{ marginRight }}
        alt="user-avatar"
        className="avatar-container"
        src={user.image_thumb}
      />
    );
  }

  return (
    <span className="avatar-initials-container" style={{ marginRight }}>
      <span className="avatar-initials-text">{getInitials(user.name)}</span>
    </span>
  );
}
Avatar.propTypes = {
  user: PropTypes.object,
  marginRight: PropTypes.string,
};
Avatar.defaultProps = {
  user: {},
  marginRight: '0px',
};
export default Avatar;
