import styled from 'styled-components';

export const InvoiceContainer = styled.div`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  margin: ${(props) => (props.margin ? props.margin : '')};
`;

export const InputBg = styled.span`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  margin: ${(props) => (props.margin ? props.margin : '')};
  display: inline-block;
  /* input */
`;

export const InvoiceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const InvoiceHeaderLeft = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const InvoiceHeaderRight = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
`;

export const InvoiceBody = styled.div`
  // margin-top: 20px;
  margin-bottom: 18px;
  font-family: Avenir Next;
`;

export const InvoiceFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InvoiceFooterLeft = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
`;

export const InvoiceFooterRight = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  text-align: right;
`;

export const InvoiceAmount = styled.div`
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 400;
`;

export const PaymentRowContainer = styled.div`
  // display: grid;
  // grid-template-columns: 25% 15% 30% 20% 10%;

  // @media (max-width: 768px) {
  //     display: flex;
  //     flex-direction: column;
  // }
  display: flex;
  width: 100%;
`;
export const ViewMoreArrow = styled.img`
  height: 10px;
  margin-left: 2px;
  margin-top: auto;
  margin-bottom: auto;
`;
export const PaymentIcon = styled.img`
  max-width: 35px;
  /* border-radius: 50px; */
  margin-right: 10px;
`;
export const PaymentConfirmationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 15px;
  font-size: 15px;
`;
// export const PaymentConfirmationSpan = styled.span`
//   margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
//   margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
//   margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
//   margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
//   text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
//   color: ${(props) => (props.color ? props.color : '')};
//   font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
//   font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
//   display: ${(props) => (props.display ? props.display : '')};
//   position: ${(props) => (props.position ? props.position : '')};
// `;
export const PaymentCalculation = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
`;
export const PaymentMethodsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  margin: 5px;
`;
export const InvoiceH3 = styled.h3``;
export const InvoiceP = styled.p``;
export const PaymentConfirmationWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const Input = styled.input``;
export const InvoiceBlankDiv = styled.div``;
export const PaymentText = styled.span`
  color: #94939a;
`;
export const InvoiceCradSpan = styled.span`
  margin-left: ${(props) => props.marginLeft || ''};
  margin-right: ${(props) => props.marginRight || ''};
`;
export const PaymentAuthText = styled.span`
  text-align: center;
  display: block;
  margin-top: 13px;
`;
