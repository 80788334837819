import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import moment from 'moment';
import { Card, Button } from '../../CoreStyles/GeneralStyles';
import { SkeletonWrapper, UserCards, ProjectInfodiv } from './ProjectInfoStyle';
import { ProjectPrimaryText, ProjectSecondaryText } from './ProjectStyle';
import UserCard from './UserCard';
import { OrgContext, isAllowed } from '../../Utils';

import { vocabulary } from '../../Enums';

function ProjectInfo({
  projectInfoData,
  setProjectEditorVisible,
  projectVocab,
  projectRole,
}) {
  console.log(projectInfoData?.project?.users, 'getrere');

  const orgData = useContext(OrgContext);

  const orgRole = orgData?.current_user_role || 'client';

  return (
    <>
      <Card width="100%" padding="22px 20px 13px 25px;">
        {projectInfoData ? (
          <>
            <ProjectPrimaryText marginBottom="7px">
              {projectInfoData?.project?.name}
            </ProjectPrimaryText>
            <ProjectSecondaryText>
              {moment(projectInfoData?.project?.date_started).format(
                'MMM D, YYYY'
              )}
            </ProjectSecondaryText>
          </>
        ) : (
          <ProjectInfodiv marginBottom="15px">
            <ProjectInfodiv>
              <Skeleton count={2} width={100} height={10} />
            </ProjectInfodiv>
          </ProjectInfodiv>
        )}
        {projectInfoData?.project?.users?.length !== 0 ? (
          projectInfoData?.project?.users?.map((user) => (
            <UserCards key={`${user.id}__project_info`}>
              <UserCard user={user} />
            </UserCards>
          ))
        ) : (
          <>
            {[1, 2, 3, 4].map(() => (
              <SkeletonWrapper>
                <ProjectInfodiv>
                  <Skeleton
                    circle
                    width={40}
                    height={40}
                    style={{ marginRight: '5px' }}
                  />
                </ProjectInfodiv>
                <ProjectInfodiv>
                  <Skeleton width={100} />
                  <Skeleton width={80} />
                </ProjectInfodiv>
              </SkeletonWrapper>
            ))}
          </>
        )}
        {projectInfoData?.project?.user_invites.map((invite) => (
          <UserCards key={`${invite.id}__project_info`}>
            <UserCard user={invite} type="invite" />
          </UserCards>
        ))}
        {projectRole === 'admin' || isAllowed(orgRole, 'projects.edit') ? (
          <Button
            thin
            float="right"
            onClick={() => {
              setProjectEditorVisible(true);
            }}
          >
            Edit {vocabulary[projectVocab]?.singular_cap || 'Project'}
          </Button>
        ) : null}
      </Card>
    </>
  );
}

ProjectInfo.propTypes = {
  projectInfoData: PropTypes.element,
  setProjectEditorVisible: PropTypes.func,
  projectVocab: PropTypes.string,
  projectRole: PropTypes.string,
};
ProjectInfo.defaultProps = {
  projectInfoData: null,
  setProjectEditorVisible: null,
  projectVocab: 'projects',
  projectRole: 'viewer',
};

export default ProjectInfo;
