/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useParams } from 'react-router-dom';
import PaymentMethodEditor from '../Common/PaymentMethodEditor';

import { Card, SectionTitle } from '../../CoreStyles/GeneralStyles';

import { Default, Mobile } from '../Devices';

export default function PayMethodsCard() {
  const { userId } = useParams();

  return (
    <>
      <Default>
        <SectionTitle marginTop="3%" marginBottom="1%">
          Payment Methods
        </SectionTitle>
        <Card display="grid" padding="20px 25px" marginBottom="15%">
          <PaymentMethodEditor userId={userId} />
        </Card>
      </Default>
      <Mobile>
        <SectionTitle marginTop="3%" marginBottom="1%">
          Payment Methods
        </SectionTitle>
      </Mobile>
    </>
  );
}

PayMethodsCard.propTypes = {};

PayMethodsCard.defaultProps = {};
