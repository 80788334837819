export default {
  admin: {
    info: {
      name: 'Admin',
      key: 'admin',
      description: 'Best for business owners and company administrators',
    },
    permissions: {
      summary: {
        read: true,
      },
      projects: {
        create: true,
        edit: true,
        delete: true,
        invoices: {
          create: true,
          edit: true,
        },
      },
      workspace_settings: {
        read: true,
        platform_settings: {
          read: true,
          edit: true,
        },
        company_settings: {
          read: true,
          edit: true,
        },
        team_management: {
          read: true,
          edit: true,
          invite: {
            edit: true,
          },
        },
        email_sequence: {
          read: true,
          edit: true,
        },
        integrations: {
          read: true,
          edit: true,
        },
      },
      client_management: {
        read: true,
        edit: true,
        invite: {
          edit: true,
        },
        edit_info: {
          edit: true,
        },
        change_email: {
          edit: true,
        },
        password_reset: {
          edit: true,
        },
        remove_user: {
          edit: true,
        },
        adjust_credits: {
          edit: true,
        },
        payment_methods: {
          read: true,
          edit: true,
        },
        issue_refunds: {
          edit: true,
        },
      },
    },
  },
  support: {
    info: {
      name: 'Support',
      key: 'support',
      description:
        'Best for employees who regularly refund payments and respond to disputes',
    },
    permissions: {
      summary: {
        read: true,
      },
      projects: {
        create: true,
        edit: true,
        delete: true,
        invoices: {
          create: true,
          edit: true,
        },
      },
      workspace_settings: {
        read: false,
      },
      client_management: {
        read: true,
        edit: true,
        invite: {
          edit: true,
        },
        edit_info: {
          edit: true,
        },
        change_email: {
          edit: true,
        },
        password_reset: {
          edit: true,
        },
        remove_user: {
          edit: true,
        },
        adjust_credits: {
          edit: true,
        },
        payment_methods: {
          read: true,
          edit: true,
        },
        issue_refunds: {
          edit: true,
        },
      },
    },
  },
  sales: {
    info: {
      name: 'Sales',
      key: 'sales',
      description:
        'Best for employees who need to create and update data regularly',
    },
    permissions: {
      summary: {
        read: true,
      },
      projects: {
        create: true,
        edit: true,
        delete: true,
        invoices: {
          create: true,
          edit: true,
        },
      },
      client_management: {
        read: true,
        invite: {
          edit: true,
        },
        adjust_credits: {
          edit: true,
        },
      },
    },
  },
  analyst: {
    info: {
      name: 'Analyst',
      key: 'analyst',
      description:
        'Best for people who need full access to data, but do not need to edit',
    },
    permissions: {
      summary: {
        read: true,
      },
      client_management: {
        read: true,
      },
    },
  },
  client: {
    info: {
      name: 'Client',
      key: 'client',
      description: 'Users that are going to making payments',
    },
    permissions: {},
  },
};
