import styled from 'styled-components';
// import { Input } from '../../CoreStyles/GeneralStyles';

export const EmailH2 = styled.h2``;
// export const EmailSpan = styled.span`
//   color: ${(props) => (props.color ? props.color : '#737373')};
//   cursor-pointer: ${(props) =>
//     props.cursorPointer ? props.cursorPointer : ''};
// `;
export const Dropdown = styled.select``;
export const DropdownOption = styled.option``;
export const EmailEditorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const EmailEditorHeaderTitle = styled.div`
  font-size: 18px;
`;
export const CustomDropDown = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(208 208 208 / 25%);
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  select {
    border: none;
    width: 100%;
    padding: 7px 17px;
    border-radius: 6px;
    -webkit-appearance: none;
    color: #5e5e5e;
  }
  svg {
    position: absolute;
    right: 11px;
    top: 13px;
  }
`;
export const SeqnList = styled.div`
  margin-top: 30px;
  input,
  textarea {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(208 208 208 / 25%);
    border-radius: 6px;
    padding: 7px 17px;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : '')};
    width: ${(props) => (props.Width ? props.Width : '')};
  }
`;

export const EmailInput = styled.input``;
export const EmailTextArea = styled.textarea``;
export const EmailSpan = styled.span``;

export const AddStepContainer = styled.div`
  position: relative;
  padding: 20px;
`;

export const AddStepBorderContainer = styled.div`
  align-items: center;
  border-style: solid;
  border-width: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  left: 0;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
`;

export const AddStepBorder = styled.div`
  border-style: solid;
  border-width: 1px 0 0;
  box-sizing: border-box;
  outline: none;
  width: 100%;
`;

export const AddStepButtonContainer = styled.div`
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  outline: none;
  position: relative;
`;

export const AddStepButton = styled.button`
  align-items: center;
  appearance: button;
  background-color: #fff;
  background-image: none;
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: #374151;
  cursor: pointer;
  display: inline-flex;
  font-family: 'Avenir Next';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0.375rem 1rem;
  text-transform: none;
  touch-action: manipulation;

  &:hover {
    background-color: #f8f8f8;
  }
`;
