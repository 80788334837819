import React from 'react';
import { ErrorPageWrapper, ErrorSpan } from './ErrorPageStyles';

export default function ErrorPage(error) {
  return (
    <ErrorPageWrapper>
      <ErrorSpan fontSize="20px">{error}</ErrorSpan>
    </ErrorPageWrapper>
  );
}
