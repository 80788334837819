/* eslint-disable dot-notation */
import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Switch } from 'antd';
import _ from 'lodash';
import { XIcon } from '@heroicons/react/solid';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import {
  Input,
  Label,
  // BlankSpan,
  // BlankDiv,
  P,
  Button,
  CardIcon,
  PaymentMethodOption,
  InputGroup,
  SeparateGroup,
  PaymentMethodOptionTitle,
  SameLineInput,
  InlineMessage,
} from '../../CoreStyles/GeneralStyles';
import {
  CollaboratorEditor,
  FooterWrapper,
  CollabaratorDropDown,
  BlankSpan,
  BlankDiv,
  EnabledText,
  Collaborator,
  CollaboratorName,
} from './ProjectSettingStyle';
import {
  UPDATE_PROJECT_MUTATION,
  DELETE_PROJECT,
  ADD_USER_TO_PROJECT,
  UPDATE_USER_ROLE_FOR_PROJECT,
  REMOVE_USER_FROM_PROJECT,
  INVITE_USER_TO_PROJECT,
  REVOKE_INVITE_FROM_PROJECT,
} from '../../GraphQl/Mutations/updateProjectMutation';
import { GET_PROJECTS_DATA } from '../../GraphQl/Queries/projectQueries';
import createNotification from '../Common/Notifications';
import { GET_USERS_BY_EMAIL } from '../../GraphQl/Queries/usersQueries';
import ErrorPage from '../Common/ErrorPage';

import Avatar from '../Generics/Avatar';

import TrailingInput from '../Generics/Input/TrailingInput';

import mastercardLogo from '../../images/cards/dark/2.png';

import DropdownMenu from '../Generics/Dropdown';
import DatePicker from '../Generics/DatePicker';

import { vocabulary, projectRoles } from '../../Enums';

// import ADD_USER_MUTATION from '../../GraphQl/Mutations/userMutations';

function ProjectSettings({ projectInfoData, handleClose, projectVocab }) {
  console.log(projectInfoData, 'hghasdhgas');

  const [projectName, setProjectName] = useState('');
  const [dateStarted, setDateStarted] = useState(null);

  const [emailSettings, setEmailSettings] = useState({
    completion_emails_enabled: false,
    reminder_emails_enabled: false,
  });

  const [autoPaySettings, setAutoPaySettings] = useState({
    enabled: false,
  });

  const [feeSettings, setFeeSettings] = useState({
    early_pay: {
      enabled: false,
    },
    late_pay: {
      enabled: false,
    },
  });

  const [collaboratorToAdd, setCollaboratorToAdd] = useState({});

  const [removeUserFromProject] = useMutation(REMOVE_USER_FROM_PROJECT, {
    onCompleted: () => {
      createNotification('success', 'User Removed');
    },
    refetchQueries: [
      {
        query: GET_PROJECTS_DATA,
        variables: {
          id: projectInfoData?.project?.id,
        },
      },
    ],
  });

  const [updateUserRoleForProject] = useMutation(UPDATE_USER_ROLE_FOR_PROJECT, {
    onCompleted: () => {
      createNotification('success', 'Role Updated');
    },
    refetchQueries: [
      {
        query: GET_PROJECTS_DATA,
        variables: {
          id: projectInfoData?.project?.id,
        },
      },
    ],
  });

  const [addUserToProject] = useMutation(ADD_USER_TO_PROJECT, {
    onCompleted: () => {
      createNotification('success', 'User Added');
    },
    refetchQueries: [
      {
        query: GET_PROJECTS_DATA,
        variables: {
          id: projectInfoData?.project?.id,
        },
      },
    ],
  });

  const [inviteUserToProject] = useMutation(INVITE_USER_TO_PROJECT, {
    onCompleted: () => {
      createNotification('success', 'Invite Sent');
    },
    refetchQueries: [
      {
        query: GET_PROJECTS_DATA,
        variables: {
          id: projectInfoData?.project?.id,
        },
      },
    ],
  });

  const [revokeInviteFromProject] = useMutation(REVOKE_INVITE_FROM_PROJECT, {
    onCompleted: () => {
      createNotification('success', 'Invite Revoked');
    },
    refetchQueries: [
      {
        query: GET_PROJECTS_DATA,
        variables: {
          id: projectInfoData?.project?.id,
        },
      },
    ],
  });

  const [getUser, { loading, data }] = useLazyQuery(GET_USERS_BY_EMAIL);

  const history = useHistory();
  useEffect(async () => {
    if (projectInfoData) {
      await setProjectName(projectInfoData?.project?.name);
      await setDateStarted(new Date(projectInfoData?.project?.date_started));
      await setEmailSettings(projectInfoData?.project?.email_settings);
      await setAutoPaySettings(projectInfoData?.project?.auto_pay_settings);
      await setFeeSettings(projectInfoData?.project?.fees);

      const collaboratorsTemp = [];
      projectInfoData?.project?.users.map((user) =>
        collaboratorsTemp.push(user)
      );
    }
  }, []);

  useEffect(() => {
    if (!loading && data) {
      console.log('load');
      if (data.findUserByEmail) {
        // The user exists, so let's add them to the project

        console.log('got new data: ', data.findUserByEmail);

        const newCollaborator = _.cloneDeep(data.findUserByEmail);

        newCollaborator.role = collaboratorToAdd.role;

        addUserToProject({
          variables: {
            userId: newCollaborator.id,
            role: collaboratorToAdd.role,
            projectId: projectInfoData?.project?.id,
          },
        });

        //   collaboratorsTemp.push(newCollaborator);

        //     setCollaborators(collaboratorsTemp);

        setCollaboratorToAdd({
          email: '',
          role: null,
        });
      } else {
        // User not found, let's invite them?
        console.log('user does not exist');

        inviteUserToProject({
          variables: {
            email: collaboratorToAdd.email,
            role: collaboratorToAdd.role,
            projectId: projectInfoData?.project?.id,
            invitedBy: '1',
          },
        });

        setCollaboratorToAdd({
          email: '',
          role: null,
        });
      }
    }
  }, [loading, data]);

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    onCompleted: () => {
      createNotification('success', 'Project Saved');
    },
    onError: (errors) => {
      ErrorPage(errors);
    },
  });

  const handleCompletionSwitch = (checked) => {
    const emailSettingsClone = _.cloneDeep(emailSettings || {});

    emailSettingsClone.completion_emails_enabled = checked;

    if (emailSettingsClone['__typename']) {
      delete emailSettingsClone['__typename'];
    }

    updateProject({
      variables: {
        id: projectInfoData?.project?.id,
        email_settings: emailSettingsClone,
      },
    });

    setEmailSettings(emailSettingsClone);
  };

  const handleReminderSwitch = (checked) => {
    const emailSettingsClone = _.cloneDeep(emailSettings || {});

    emailSettingsClone.reminder_emails_enabled = checked;

    if (emailSettingsClone['__typename']) {
      delete emailSettingsClone['__typename'];
    }

    updateProject({
      variables: {
        id: projectInfoData?.project?.id,
        email_settings: emailSettingsClone,
      },
    });

    setEmailSettings(emailSettingsClone);
  };

  const updateFeeSettingsLocal = (type, field, value) => {
    const feeSettingsClone = _.cloneDeep(feeSettings || {});

    if (feeSettingsClone[type]) {
      feeSettingsClone[type][field] = value;
    } else {
      feeSettingsClone[type] = {};
      feeSettingsClone[type][field] = value;
    }

    if (feeSettingsClone['__typename']) {
      delete feeSettingsClone['__typename'];
    }

    if (
      feeSettingsClone['early_pay'] &&
      feeSettingsClone['early_pay']['__typename']
    ) {
      delete feeSettingsClone['early_pay']['__typename'];
    }

    if (
      feeSettingsClone['late_pay'] &&
      feeSettingsClone['late_pay']['__typename']
    ) {
      delete feeSettingsClone['late_pay']['__typename'];
    }
    if (
      feeSettingsClone['auto_pay'] &&
      feeSettingsClone['auto_pay']['__typename']
    ) {
      delete feeSettingsClone['auto_pay']['__typename'];
    }

    updateProject({
      variables: {
        id: projectInfoData?.project?.id,
        fees: feeSettingsClone,
      },
    });

    setFeeSettings(feeSettingsClone);
  };

  const updateAutoPaySettings = (field, value) => {
    const autoPaySettingsClone = _.cloneDeep(autoPaySettings || {});

    autoPaySettingsClone[field] = value;
    if (autoPaySettingsClone['__typename']) {
      delete autoPaySettingsClone['__typename'];
    }

    updateProject({
      variables: {
        id: projectInfoData?.project?.id,
        auto_pay_settings: autoPaySettingsClone,
      },
    });

    setAutoPaySettings(autoPaySettingsClone);
  };

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      createNotification('success', 'Project Deleted');
      handleClose();
      history.push('/');
    },
    onError: (errors) => {
      ErrorPage(errors);
    },
  });

  const payerOptions = {};
  const paymentMethodOptions = {};
  const collaboratorsMap = {};

  projectInfoData?.project?.payers.forEach((user) => {
    payerOptions[user.id] = {
      title: user.name,
    };

    collaboratorsMap[user.id] = user;
  });

  if (autoPaySettings?.payer && collaboratorsMap[autoPaySettings.payer]) {
    collaboratorsMap[autoPaySettings.payer].payment_methods.forEach(
      (paymentMethod) => {
        // For now
        paymentMethodOptions[paymentMethod.id] = {
          title: (
            <PaymentMethodOption style={{ display: 'flex' }}>
              {' '}
              <CardIcon src={mastercardLogo} />
              <PaymentMethodOptionTitle>
                {paymentMethod.type} ***** {paymentMethod.last4}
              </PaymentMethodOptionTitle>
            </PaymentMethodOption>
          ),
        };
      }
    );
  }

  // Let's filter the available project roles. If there is already an owner, then we
  // can remove it from the options. There can only be 1 project owner
  const filteredProjectRoles = _.cloneDeep(projectRoles);
  const currentRoles = projectInfoData?.project?.users.map((user) => user.role);
  if (currentRoles.includes('owner')) {
    // Let's remove it
    delete filteredProjectRoles['owner'];
  }

  return (
    <BlankDiv>
      <SeparateGroup backgroundEnabled>
        <InputGroup>
          <Label>
            {vocabulary[projectVocab]?.singular_cap || 'Project'} Name
          </Label>
          <Input
            type="text"
            placeholder="Aloa Paid Ads"
            name="project_title"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </InputGroup>

        <InputGroup>
          {' '}
          <Label>Start Date</Label>
          <DatePicker
            value={dateStarted}
            updateValue={(date) => {
              setDateStarted(date);
            }}
          />
        </InputGroup>
      </SeparateGroup>

      <SeparateGroup backgroundEnabled>
        <Label className="title-project">Collaborators</Label>
        {projectInfoData?.project?.users.map((user) => (
          <CollaboratorEditor>
            <Collaborator>
              <Avatar user={user} marginRight="10px" />
              <CollaboratorName>{user.name}</CollaboratorName>
            </Collaborator>

            <div style={{ display: 'flex' }}>
              <DropdownMenu
                value={user.role}
                options={
                  user.role === 'owner' ? projectRoles : filteredProjectRoles
                }
                onChange={(newValue) => {
                  /*
                  const collaboratorsTemp = _.cloneDeep(collaborators);

                  collaboratorsTemp[index].role = newValue;

                  setCollaborators(collaboratorsTemp); */

                  updateUserRoleForProject({
                    variables: {
                      userId: user.id,
                      projectId: projectInfoData?.project?.id,
                      role: newValue,
                    },
                  });
                }}
              />
              <XIcon
                onClick={() => {
                  /*
                  const collaboratorsTemp = _.cloneDeep(collaborators);

                  collaboratorsTemp.splice(index, 1);

                  setCollaborators(collaboratorsTemp); */

                  removeUserFromProject({
                    variables: {
                      userId: user.id,
                      projectId: projectInfoData?.project?.id,
                    },
                  });
                }}
                style={{ marginLeft: '10px' }}
                className="trash-icon"
              />
            </div>
          </CollaboratorEditor>
        ))}
        {projectInfoData?.project?.user_invites.map((user) => (
          <CollaboratorEditor>
            <Collaborator>
              <Avatar user={user} marginRight="10px" />
              <CollaboratorName>{user.email} (invite sent)</CollaboratorName>
            </Collaborator>

            <div style={{ display: 'flex' }}>
              <DropdownMenu
                value={user.role}
                options={filteredProjectRoles}
                onChange={(newValue) => {
                  /*
                  const collaboratorsTemp = _.cloneDeep(collaborators);

                  collaboratorsTemp[index].role = newValue;

                  setCollaborators(collaboratorsTemp); */

                  updateUserRoleForProject({
                    variables: {
                      userId: user.id,
                      projectId: projectInfoData?.project?.id,
                      role: newValue,
                    },
                  });
                }}
              />
              <XIcon
                onClick={() => {
                  /*
                  const collaboratorsTemp = _.cloneDeep(collaborators);

                  collaboratorsTemp.splice(index, 1);

                  setCollaborators(collaboratorsTemp); */

                  revokeInviteFromProject({
                    variables: {
                      id: user.id,
                    },
                  });
                }}
                style={{ marginLeft: '10px' }}
                className="trash-icon"
              />
            </div>
          </CollaboratorEditor>
        ))}

        <Label>Add Collaborator</Label>
        <Input
          type="text"
          name="user_email"
          placeholder="User email"
          value={collaboratorToAdd.email}
          onChange={(e) => {
            const collaboratorToAddTemp = _.cloneDeep(collaboratorToAdd);

            collaboratorToAddTemp.email = e.target.value;

            setCollaboratorToAdd(collaboratorToAddTemp);
          }}
          marginBottom="15px"
        />
        <CollabaratorDropDown>
          <DropdownMenu
            value={collaboratorToAdd.role}
            options={filteredProjectRoles}
            onChange={(newValue) => {
              const collaboratorToAddTemp = _.cloneDeep(collaboratorToAdd);

              collaboratorToAddTemp.role = newValue;

              setCollaboratorToAdd(collaboratorToAddTemp);
            }}
          />

          <BlankSpan>
            <Button
              marginLeft="10px"
              onClick={() => {
                getUser({ variables: { email: collaboratorToAdd.email } });
              }}
              primary
            >
              Add User
            </Button>
          </BlankSpan>
        </CollabaratorDropDown>
      </SeparateGroup>

      <SeparateGroup backgroundEnabled>
        <Label className="title-project">Notification Settings</Label>

        <InputGroup>
          <Switch
            checked={emailSettings?.completion_emails_enabled || false}
            onChange={handleCompletionSwitch}
          />
          <BlankSpan>Completion Emails</BlankSpan>
        </InputGroup>
        <InputGroup>
          <Switch
            checked={emailSettings?.reminder_emails_enabled || false}
            onChange={handleReminderSwitch}
          />
          <BlankSpan>Reminder Emails</BlankSpan>
        </InputGroup>
      </SeparateGroup>

      <SeparateGroup backgroundEnabled>
        <Label className="title-project">AutoPay</Label>
        <InputGroup>
          <P marginBottom="10px">
            If enabled, the selected payer will be charged automatically once a
            milestone is marked as Pending Approval
          </P>
          <InputGroup>
            <Switch
              checked={autoPaySettings?.enabled || false}
              onChange={(checked) => {
                updateAutoPaySettings('enabled', checked);

                if (!checked) {
                  updateFeeSettingsLocal('auto_pay', 'enabled', checked);
                }
              }}
            />
            <EnabledText> Enabled </EnabledText>
          </InputGroup>
        </InputGroup>

        {autoPaySettings?.enabled && (
          <>
            <InputGroup>
              <Label marginTop="10px">Select Payer</Label>
              <DropdownMenu
                value={autoPaySettings?.payer}
                options={payerOptions}
                onChange={(newValue) => {
                  updateAutoPaySettings('payer', newValue);
                }}
              />
            </InputGroup>

            <InputGroup>
              <Label>Select Payment Method</Label>
              <DropdownMenu
                style={{ width: '300px' }}
                value={autoPaySettings?.payment_method}
                options={paymentMethodOptions}
                onChange={(newValue) => {
                  updateAutoPaySettings('payment_method', newValue);
                }}
              />
            </InputGroup>
          </>
        )}
      </SeparateGroup>

      <SeparateGroup backgroundEnabled>
        <Label className="title-project">AutoPay Incentives</Label>
        <InputGroup>
          <P marginBottom="10px">
            If enabled, discounts will be applied to invoices when a user pays
            via AutoPay. This discount will override any early payment discounts
            (below)
          </P>
          <InputGroup>
            <Switch
              checked={feeSettings?.auto_pay?.enabled || false}
              onChange={(checked) =>
                updateFeeSettingsLocal('auto_pay', 'enabled', checked)
              }
            />
            <EnabledText> Enabled </EnabledText>
          </InputGroup>
        </InputGroup>

        {feeSettings?.auto_pay?.enabled && (
          <>
            <InputGroup>
              <Label marginTop="10px">Reduce invoice by</Label>
              <TrailingInput
                placeholder="5.0"
                width="100px"
                value={feeSettings?.auto_pay?.fee}
                onChange={(e) =>
                  updateFeeSettingsLocal(
                    'auto_pay',
                    'fee',
                    parseInt(e.target.value, 10)
                  )
                }
              />
            </InputGroup>
          </>
        )}
      </SeparateGroup>

      <SeparateGroup backgroundEnabled>
        <Label className="title-project">Early Pay Incentives</Label>
        {feeSettings?.auto_pay?.enabled && (
          <InlineMessage thin>
            <ExclamationCircleIcon
              style={{ minWidth: '25px', width: '25px', marginRight: '10px' }}
            />
            AutoPay incentives are enabled. If a user pays via AutoPay the
            AutoPay incentive will be applied and the Early Pay incentive will
            be ignored.
          </InlineMessage>
        )}
        <InputGroup>
          <P marginBottom="10px">
            If enabled, discounts will be applied to invoices when paid before a
            certain date. This is the opposite of late fee incentives (below)
          </P>
          <InputGroup>
            <Switch
              checked={feeSettings?.early_pay?.enabled || false}
              onChange={(checked) =>
                updateFeeSettingsLocal('early_pay', 'enabled', checked)
              }
            />
            <EnabledText> Enabled </EnabledText>
          </InputGroup>
        </InputGroup>

        {feeSettings?.early_pay?.enabled && (
          <>
            <InputGroup>
              <Label marginTop="10px">Reduce invoice by</Label>
              <TrailingInput
                placeholder="5.0"
                width="100px"
                value={feeSettings?.early_pay?.fee}
                onChange={(e) =>
                  updateFeeSettingsLocal(
                    'early_pay',
                    'fee',
                    parseInt(e.target.value, 10)
                  )
                }
              />
            </InputGroup>
            <InputGroup>
              <Label marginTop="10px">If invoice is paid within</Label>
              <SameLineInput>
                <Input
                  type="number"
                  name="every_field"
                  onChange={(e) =>
                    updateFeeSettingsLocal(
                      'early_pay',
                      'days',
                      parseInt(e.target.value, 10)
                    )
                  }
                  value={feeSettings?.early_pay?.days}
                  width="80px"
                  marginRight="10px"
                  placeholder="7"
                />
                <BlankSpan>days of the invoice date</BlankSpan>
              </SameLineInput>
            </InputGroup>
          </>
        )}
      </SeparateGroup>

      <SeparateGroup backgroundEnabled>
        <Label className="title-project">Late Fee Incentives</Label>
        <InputGroup>
          <P marginBottom="10px">
            If enabled, a fee will be applied to invoices when paid after a
            certain date. This is the opposite of early pay incentives (above)
          </P>
          <InputGroup>
            <Switch
              checked={feeSettings?.late_pay?.enabled || false}
              onChange={(checked) =>
                updateFeeSettingsLocal('late_pay', 'enabled', checked)
              }
            />
            <EnabledText> Enabled </EnabledText>
          </InputGroup>
        </InputGroup>

        {feeSettings?.late_pay?.enabled && (
          <>
            <InputGroup>
              <Label marginTop="10px">Increase invoice by</Label>
              <TrailingInput
                placeholder="5.0"
                width="100px"
                value={feeSettings?.late_pay?.fee}
                onChange={(e) =>
                  updateFeeSettingsLocal(
                    'late_pay',
                    'fee',
                    parseInt(e.target.value, 10)
                  )
                }
              />
            </InputGroup>
            <InputGroup>
              <Label marginTop="10px">If invoice is paid</Label>
              <SameLineInput>
                <Input
                  type="number"
                  name="every_field"
                  onChange={(e) =>
                    updateFeeSettingsLocal(
                      'late_pay',
                      'days',
                      parseInt(e.target.value, 10)
                    )
                  }
                  value={feeSettings?.late_pay?.days}
                  width="80px"
                  marginRight="10px"
                  placeholder="7"
                />
                <BlankSpan>days past the due date</BlankSpan>
              </SameLineInput>
            </InputGroup>
          </>
        )}
      </SeparateGroup>

      <FooterWrapper>
        <BlankSpan>
          <Button
            onClick={() => {
              deleteProject({
                variables: {
                  id: projectInfoData?.project?.id,
                },
              });
            }}
            thin
            fontWeight="bold"
            color="red"
          >
            Archive {vocabulary[projectVocab]?.singular_cap || 'Project'}
          </Button>
        </BlankSpan>
      </FooterWrapper>
    </BlankDiv>
  );
}

ProjectSettings.propTypes = {
  projectInfoData: PropTypes.element,
  handleClose: PropTypes.func,
  projectVocab: PropTypes.string,
};
ProjectSettings.defaultProps = {
  projectInfoData: null,
  handleClose: null,
  projectVocab: 'projects',
};

export default ProjectSettings;
