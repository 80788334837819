import React, { useState } from 'react';
import { Popover } from 'antd';
import { PropTypes } from 'prop-types';
import ImagePickerContent from './Content';

export default function ImagePicker({
  width,
  height,
  shape,
  upload,
  children,
}) {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      content={
        <ImagePickerContent
          shape={shape}
          width={width}
          height={height}
          upload={(file) => {
            upload(file);
            setVisible(false);
          }}
        />
      }
      destroyTooltipOnHide
      visible={visible}
      trigger={['click']}
      onVisibleChange={setVisible}
      placement="bottom"
    >
      {children}
    </Popover>
  );
}

ImagePicker.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  shape: PropTypes.string,
  upload: PropTypes.func,
  children: PropTypes.element,
};

ImagePicker.defaultProps = {
  width: 0,
  height: 0,
  shape: null,
  upload: () => {},
  children: null,
};
