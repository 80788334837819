/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const DELETE_INVOICE = gql`
  mutation deleteInvoice($id: String!) {
    deleteInvoice(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_RECURRING_INVOICE = gql`
  mutation deleteRecurringInvoice($id: String!) {
    deleteRecurringInvoice(id: $id) {
      id
      name
    }
  }
`;

export const LOG_STATUS_CHANGE = gql`
  mutation logInvoiceStatusChange($id: String!) {
    logInvoiceStatusChange(id: $id) {
      id
      last_status_change_date
    }
  }
`;

export const UPDATE_RECURRING_INVOICE = gql`
  mutation updateRecurringInvoice(
    $id: String!
    $updatedInvoice: UpdateRecurringInvoiceInput
  ) {
    updateRecurringInvoice(id: $id, input: $updatedInvoice) {
      id
      name
      amount
      billing_start
      enabled
      date_created
      recurrence
      line_items {
        id
        rate
        quantity
        description
      }
    }
  }
`;

export const CREATE_RECURRING_INVOICE = gql`
  mutation createRecurringInvoice($newInvoice: CreateRecurringInvoiceInput) {
    createRecurringInvoice(input: $newInvoice) {
      id
      name
      amount
      billing_start
      enabled
      date_created
      recurrence
      line_items {
        id
        rate
        quantity
        description
      }
    }
  }
`;

export const UPDATE_RECURRING_INVOICE_STATUS = gql`
  mutation updateRecurringInvoice($id: String!, $enabled: Boolean!) {
    updateRecurringInvoice(id: $id, input: { enabled: $enabled }) {
      id
      enabled
    }
  }
`;
