import { gql } from '@apollo/client';

export const CREATE_EMAIL = gql`
  mutation createEmail(
    $projectId: String!
    $send_at: Int
    $subject: String
    $body: String
    $late_fee: Float
    $late_fee_enabled: Boolean
  ) {
    createEmail(
      input: {
        project_id: $projectId
        send_at: $send_at
        subject: $subject
        body: $body
        late_fee: $late_fee
        late_fee_enabled: $late_fee_enabled
      }
    ) {
      id
      body
      subject
      send_at
      late_fee
      late_fee_enabled
    }
  }
`;

export const DELETE_EMAIL = gql`
  mutation deleteEmail($id: String!) {
    deleteEmail(id: $id)
  }
`;

export const UPDATE_EMAIL = gql`
  mutation updateEmail(
    $id: String!
    $project_id: String
    $send_at: Int
    $subject: String
    $body: String
    $late_fee: Float
    $late_fee_enabled: Boolean
  ) {
    updateEmail(
      id: $id
      input: {
        project_id: $project_id
        send_at: $send_at
        subject: $subject
        body: $body
        late_fee: $late_fee
        late_fee_enabled: $late_fee_enabled
      }
    ) {
      id
      body
      subject
      send_at
      late_fee
      late_fee_enabled
    }
  }
`;

export default CREATE_EMAIL;
