/* eslint-disable react/forbid-prop-types */
import { React, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { ShieldCheckIcon } from '@heroicons/react/outline';
import { RadioGroup } from '@headlessui/react';
import ModalPopUp from '../../Common/ModalPopUp';

import { isMobile } from '../../Devices';

import {
  SettingsCardTitle,
  SettingsCardDescription,
  SettingsCardHeader,
} from '../Account Settings/AccountSettingsStyle';

import { UPDATE_USER_ROLE_FOR_ORG } from '../../../GraphQl/Mutations/orgMutations';

import { Button, ButtonWrapper } from '../../../CoreStyles/GeneralStyles';

const roles = {
  admin: {
    id: 'admin',
    name: 'Administrator',
    description: 'Best for business owners and company administrators',
  },
  sales: {
    id: 'sales',
    name: 'Sales',
    description:
      'Best for employees who need to create and update data regularly',
  },
  analyst: {
    id: 'analyst',
    name: 'Analyst',
    description:
      'Best for people who need full access to data, but do not need to edit',
  },
  support: {
    id: 'support',
    name: 'Support Specialist',
    description:
      'Best for employees who regularly refund payments and respond to disputes',
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ChangeRoleModal({ isVisible, setIsVisible, user }) {
  const [selected, setSelected] = useState(user.role || 'support');

  const [updateUserRoleMutation] = useMutation(UPDATE_USER_ROLE_FOR_ORG, {
    onCompleted: () => {
      console.log('success');
      setIsVisible(false);
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
    update(cache, { data: { updateUserRole } }) {
      const newRole = updateUserRole.role;

      console.log('data is ', updateUserRole);

      cache.writeFragment({
        id: cache.identify(user),
        fragment: gql`
          fragment UserRole on User {
            role
          }
        `,
        data: { role: newRole },
      });
    },
  });

  return (
    <ModalPopUp
      className="common-modal"
      width={isMobile() ? '90%' : '50%'}
      title={null}
      visible={isVisible}
      isCancel={() => {
        setIsVisible(false);
      }}
      footer={null}
      style={{ top: isMobile() ? '10px' : '40px', maxWidth: '600px' }}
      centered={false}
      content={
        <div>
          <SettingsCardHeader withImage>
            <ShieldCheckIcon
              className="modal-icon"
              style={{ color: '#0064fb', backgroundColor: '#0469ff29' }}
            />
            <div>
              <SettingsCardTitle>Edit Role</SettingsCardTitle>

              <SettingsCardDescription>
                You are changing the role for {user.name} ({user.email})
              </SettingsCardDescription>
            </div>
          </SettingsCardHeader>

          <RadioGroup value={selected} onChange={setSelected}>
            <RadioGroup.Label className="sr-only">Choose Role</RadioGroup.Label>
            <div className="bg-white rounded-md -space-y-px">
              {Object.values(roles).map((role, settingIdx) => (
                <RadioGroup.Option
                  key={role.id}
                  value={role.id}
                  className={({ checked }) =>
                    classNames(
                      settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                      settingIdx === Object.keys(roles).length - 1
                        ? 'rounded-bl-md rounded-br-md'
                        : '',
                      checked
                        ? 'bg-indigo-50 border-indigo-200 z-10'
                        : 'border-gray-200',
                      'relative border p-4 flex cursor-pointer focus:outline-none'
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked
                            ? 'bg-indigo-600 border-transparent'
                            : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                          'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <div className="ml-3 flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(
                            checked ? 'text-indigo-900' : 'text-gray-900',
                            'block text-sm font-medium'
                          )}
                        >
                          {role.name}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={classNames(
                            checked ? 'text-indigo-700' : 'text-gray-500',
                            'block text-sm'
                          )}
                        >
                          {role.description}
                        </RadioGroup.Description>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <ButtonWrapper>
            <Button
              onClick={() => {
                console.log('updating ID: ', user.id);
                updateUserRoleMutation({
                  variables: {
                    userId: user.id,
                    orgId: '1',
                    role: selected,
                  },
                });
              }}
              marginLeft="14px"
              primary
            >
              Update Role
            </Button>
            <Button
              onClick={() => {
                setIsVisible(false);
              }}
              secondary
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
      }
    />
  );
}

ChangeRoleModal.propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  user: PropTypes.object,
};

ChangeRoleModal.defaultProps = {
  isVisible: null,
  setIsVisible: null,
  user: {},
};
