/* eslint-disable react/forbid-prop-types */
import { React } from 'react';
import PropTypes from 'prop-types';

import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import { getChartData, getColorForIndex } from './ChartUtils';

function DoughnutChart({ data, valueOptions, groupBy }) {
  console.log('data is ', data);
  console.log('groupBySir: ', groupBy);

  const chartData = getChartData({
    data: data?.invoices,
    groupBy,
    valueOptions,
  });

  console.log('chartData: ', chartData);

  return (
    <Doughnut
      plugins={[ChartDataLabels]}
      data={{
        labels: _.map(chartData, 'title'),
        datasets: [
          {
            label: 'Sum of Amounts',
            data: _.map(chartData, 'value'),
            backgroundColor: chartData.map((value, index) =>
              getColorForIndex(index)
            ),
          },
        ],
      }}
      options={{
        plugins: {
          datalabels: {
            color: '#ffffff',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
            },
          },
        },
      }}
    />
  );
}

DoughnutChart.propTypes = {
  data: PropTypes.any,
  valueOptions: PropTypes.any,
  groupBy: PropTypes.string,
};

DoughnutChart.defaultProps = {
  data: null,
  valueOptions: {},
  groupBy: null,
};

export default DoughnutChart;
