import { gql } from '@apollo/client';

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $id: String!
    $name: String
    $phone: String
    $company: String
    $default_payment_method: String
  ) {
    updateUser(
      id: $id
      input: {
        name: $name
        phone: $phone
        company: $company
        default_payment_method: $default_payment_method
      }
    ) {
      id
      name
      email
      phone
      company
      default_payment_method
    }
  }
`;

export const UPDATE_USER_IMAGE = gql`
  mutation updateUserImage($id: String!, $file: Upload!) {
    updateUserImage(id: $id, file: $file) {
      link
      thumb_link
    }
  }
`;

export const SET_LAST_LOGIN = gql`
  mutation setLastLoginForUser($last_login: Timestamp!) {
    setLastLoginForUser(last_login: $last_login) {
      id
      name
      email
      last_login
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($id: String!, $input: UpdatePasswordInput) {
    updatePassword(id: $id, input: $input) {
      id
      name
      email
      last_login
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation updateUserEmail($id: String!, $email: String!) {
    updateUser(id: $id, input: { email: $email }) {
      id
      name
      email
      last_login
    }
  }
`;

export default UPDATE_USER_MUTATION;
