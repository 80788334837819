import styled from 'styled-components';

import { AuthLink } from './AuthStyle';

export const SignUpLinkContainer = styled.div`
  margin-top: 19px;
  font-size: 12px;
`;

export const SignUpLink = styled(AuthLink)`
  margin-left: 5px;
  font-size: 12px;
`;
