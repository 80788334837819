import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
  height: 'auto';
  width: '100%';
  margin: 'auto';
  display: 'flex';
  text-align: 'center';
  justify-items: 'center';
`;

export const ErrorSpan = styled.span`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
`;
