import { gql } from '@apollo/client';

export const UPDATE_PROJECT_MUTATION = gql`
  mutation updateProject(
    $id: String!
    $name: String
    $organization_id: String
    $date_started: Date
    $auto_pay_settings: AutoPaySettingsInput
    $email_settings: EmailSettingsInput
    $users: [ProjectUserInput]
    $fees: InvoiceFeesInput
  ) {
    updateProject(
      id: $id
      input: {
        organization_id: $organization_id
        name: $name
        date_started: $date_started
        auto_pay_settings: $auto_pay_settings
        email_settings: $email_settings
        users: $users
        fees: $fees
      }
    ) {
      id
      name
      date_started
      auto_pay_settings {
        enabled
        payer
        payment_method
      }
      email_settings {
        completion_emails_enabled
        reminder_emails_enabled
      }
      fees {
        early_pay {
          enabled
          fee
          days
        }
        late_pay {
          enabled
          fee
          days
        }
        auto_pay {
          enabled
          fee
          days
        }
      }
      users {
        name
        id
        role
      }
      organization {
        id
        name
        entity_type
        platform_name
        platform_logo
        subdomain
        custom_domain
        team {
          id
          name
        }
        users {
          id
          name
          email
        }
      }
    }
  }
`;

export const UPDATE_AUTOPAY_SETTINGS = gql`
  mutation updateProject(
    $id: String!
    $auto_pay_settings: AutoPaySettingsInput!
  ) {
    updateProject(id: $id, input: { auto_pay_settings: $auto_pay_settings }) {
      id
      auto_pay_settings {
        enabled
        payer
        payment_method
      }
    }
  }
`;

export const UPDATE_INVOICE_SORTING = gql`
  mutation updateProject($id: String!, $invoice_order: InvoiceOrderInput!) {
    updateProject(id: $id, input: { invoice_order: $invoice_order }) {
      id
      invoice_order {
        direction
        sort_by
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation createProject(
    $name: String
    $organization_id: String
    $date_started: Date
    $email_settings: EmailSettingsInput
    $users: [ProjectUserInput]
  ) {
    createProject(
      input: {
        organization_id: $organization_id
        name: $name
        date_started: $date_started
        email_settings: $email_settings
        users: $users
      }
    ) {
      id
      name
      date_started
      auto_pay_settings {
        enabled
        payer
        payment_method
      }
      email_settings {
        completion_emails_enabled
        reminder_emails_enabled
      }
      users {
        name
        id
        role
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: String!) {
    deleteProject(id: $id) {
      id
      name
      isDeleted
    }
  }
`;

export const ADD_USER_TO_PROJECT = gql`
  mutation addUserToProject(
    $userId: String!
    $projectId: String!
    $role: String!
  ) {
    addUserToProject(userId: $userId, project_id: $projectId, role: $role) {
      id
      user_id
      project_id
      createdAt
      role
    }
  }
`;

export const UPDATE_USER_ROLE_FOR_PROJECT = gql`
  mutation updateUserRoleForProject(
    $userId: String!
    $projectId: String!
    $role: String!
  ) {
    updateUserRoleForProject(
      userId: $userId
      project_id: $projectId
      role: $role
    ) {
      id
      user_id
      project_id
      createdAt
      role
    }
  }
`;

export const REMOVE_USER_FROM_PROJECT = gql`
  mutation removeUserFromProject($userId: String!, $projectId: String!) {
    removeUserFromProject(userId: $userId, project_id: $projectId) {
      id
      name
    }
  }
`;

export const INVITE_USER_TO_PROJECT = gql`
  mutation inviteUserToProject(
    $email: String!
    $projectId: String!
    $role: String!
    $invitedBy: String!
  ) {
    inviteUserToProject(
      email: $email
      project_id: $projectId
      role: $role
      invitedBy: $invitedBy
    ) {
      id
      project_id
      email
      createdAt
      invited_by_user
      role
    }
  }
`;

export const REVOKE_INVITE_FROM_PROJECT = gql`
  mutation revokeInviteFromProject($id: String!) {
    revokeInviteFromProject(id: $id) {
      id
      project_id
      email
      createdAt
      invited_by_user
      role
    }
  }
`;

export default UPDATE_PROJECT_MUTATION;
