/* eslint-disable no-shadow */
/* eslint-disable dot-notation */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import {
  Card,
  PaymentMethodOption,
  PaymentMethodOptionTitle,
  PaymentMethodImage,
  Button,
} from '../../CoreStyles/GeneralStyles';
import Menu from '../Generics/Menu';
import DropdownMenu from '../Generics/Dropdown';
import { cardOptions } from '../../Enums';
import {
  GET_AUTOPAY_SETTINGS,
  GET_PROJECT_FEES,
} from '../../GraphQl/Queries/projectQueries';
import GET_CURRENT_USER from '../../GraphQl/Queries/usersQueries';
import { UPDATE_AUTOPAY_SETTINGS } from '../../GraphQl/Mutations/updateProjectMutation';
import {
  AutoPayerInfo,
  AutoPayMethod,
  AutoPayFooter,
} from './AutoPayCardStyle';
import {
  InvoiceTitle,
  InvoiceStatusActive,
  InvoiceStatusPaused,
  InvoiceHeader,
  InvoiceHeaderLeft,
  InvoiceHeaderRight,
  MenuActionItem,
  InvoiceSection,
} from '../Invoice/RecurringInvoice/RecurringInvoiceStyle';
import BankIcon from '../../images/bank_icon.svg';
import createNotification from '../Common/Notifications';
import { showConfirmAutoPay } from '../AppDialog/state';

function AutoPayCard({ payers, projectId }) {
  console.log('payers are ', payers);

  const [updateAutoPaySettings] = useMutation(UPDATE_AUTOPAY_SETTINGS, {
    onCompleted: () => {
      createNotification('success', 'AutoPay Settings Updated');
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
  });

  const { loading: autoPayLoading, data: projectData } = useQuery(
    GET_AUTOPAY_SETTINGS,
    {
      variables: {
        id: projectId,
      },
    }
  );

  const { data: feesData } = useQuery(GET_PROJECT_FEES, {
    variables: {
      id: projectId,
    },
  });

  const { loading, data: userData } = useQuery(GET_CURRENT_USER);

  const [editMenuActive, setEditMenuActive] = useState(false);

  const payerOptions = {};

  const collaboratorsMap = {};

  if (payers) {
    payers.forEach((user) => {
      payerOptions[user.id] = {
        title: user.name,
      };

      collaboratorsMap[user.id] = user;
    });
  }

  const [paymentMethodOptions, setPaymentMethodOptions] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    const autoPaySettings = projectData?.project?.auto_pay_settings;

    const paymentMethodOptionsTemp = {};

    if (autoPaySettings?.payer && collaboratorsMap[autoPaySettings.payer]) {
      collaboratorsMap[autoPaySettings.payer].payment_methods.forEach(
        (paymentMethod) => {
          // For now
          paymentMethodOptionsTemp[paymentMethod.id] = {
            title: (
              <PaymentMethodOption style={{ display: 'flex' }}>
                {' '}
                {paymentMethod.type === 'card' && (
                  <PaymentMethodImage
                    src={cardOptions[paymentMethod.card_brand].icon}
                    alt="Visa Icon"
                  />
                )}
                {paymentMethod.type === 'bank_account' && (
                  <PaymentMethodImage src={BankIcon} alt="Visa Icon" />
                )}
                <PaymentMethodOptionTitle>
                  {paymentMethod.type === 'card' &&
                    cardOptions[paymentMethod.card_brand].title}
                  {paymentMethod.type === 'bank_account' && 'Bank'} *****{' '}
                  {paymentMethod.last4}
                </PaymentMethodOptionTitle>
              </PaymentMethodOption>
            ),
          };
        }
      );
    }

    setPaymentMethodOptions(paymentMethodOptionsTemp);
  }, [projectData?.project?.auto_pay_settings]);
  useEffect(() => {
    const autoPaySettings = projectData?.project?.auto_pay_settings;

    if (paymentMethodOptions) {
      setPaymentMethod(
        paymentMethodOptions[autoPaySettings?.payment_method]?.title
      );
    }
  }, [paymentMethodOptions, projectData?.project?.auto_pay_settings]);

  const updateAutoPaySettingsLocal = (field, value) => {
    const autoPaySettingsClone = _.cloneDeep(
      projectData?.project?.auto_pay_settings || {}
    );

    autoPaySettingsClone[field] = value;
    if (autoPaySettingsClone['__typename']) {
      delete autoPaySettingsClone['__typename'];
    }

    updateAutoPaySettings({
      variables: {
        id: projectId,
        auto_pay_settings: autoPaySettingsClone,
      },
    });
  };

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  useEffect(() => {
    if (
      userData?.currentUser?.payment_methods &&
      userData?.currentUser?.payment_methods.length !== 0
    ) {
      setSelectedPaymentMethod(userData?.currentUser?.payment_methods[0]);
    }
  }, [userData?.currentUser]);

  if (loading || autoPayLoading) {
    return null;
  }

  const autoPaySettings = projectData?.project?.auto_pay_settings;

  if (!autoPaySettings || !autoPaySettings?.enabled) {
    // Not enabled
    return (
      <Card width="100%" padding="22px 20px 0px 25px;" marginBottom="20px">
        <InvoiceHeader>
          <InvoiceHeaderLeft>
            <InvoiceTitle>AutoPay</InvoiceTitle>

            <InvoiceStatusPaused>Inactive</InvoiceStatusPaused>
          </InvoiceHeaderLeft>
        </InvoiceHeader>
        <InvoiceSection>
          {feesData?.project?.fees?.auto_pay?.enabled && (
            <AutoPayerInfo>
              Get a {feesData?.project?.fees?.auto_pay?.fee}% discount on all
              future invoices when you enroll in AutoPay. No commitment, opt-out
              anytime.
            </AutoPayerInfo>
          )}
          {!feesData?.project?.fees?.auto_pay?.enabled && (
            <AutoPayerInfo>
              Save time and peace of mind with AutoPay. No commitment, opt-out
              anytime.
            </AutoPayerInfo>
          )}

          <AutoPayFooter>
            <Button
              onClick={() =>
                showConfirmAutoPay({
                  selectedPaymentMethod,
                  projectId,
                  user: userData?.currentUser,
                  updateAutoPaySettings,
                })
              }
              thin
            >
              Set up AutoPay
            </Button>
          </AutoPayFooter>
        </InvoiceSection>
      </Card>
    );
  }

  return (
    <Card width="100%" padding="22px 20px 0px 25px;" marginBottom="20px">
      <InvoiceHeader>
        <InvoiceHeaderLeft>
          <InvoiceTitle>AutoPay</InvoiceTitle>
          {autoPaySettings.enabled && (
            <InvoiceStatusActive>Active</InvoiceStatusActive>
          )}

          {!autoPaySettings.enabled && (
            <InvoiceStatusPaused>Paused</InvoiceStatusPaused>
          )}
        </InvoiceHeaderLeft>
        {userData?.currentUser?.id === autoPaySettings.payer && (
          <InvoiceHeaderRight>
            <Menu
              style={{ width: '100%' }}
              button={
                <DotsVerticalIcon
                  onClick={() => {
                    // We are editing
                    setEditMenuActive(!editMenuActive);
                  }}
                  className="more-dots-vertical"
                />
              }
              isOpen={editMenuActive}
              setIsOpen={setEditMenuActive}
            >
              {autoPaySettings.enabled && (
                <MenuActionItem
                  onClick={() => {
                    updateAutoPaySettingsLocal('enabled', false);
                  }}
                >
                  Deactivate AutoPay
                </MenuActionItem>
              )}
            </Menu>
          </InvoiceHeaderRight>
        )}
      </InvoiceHeader>
      <InvoiceSection>
        <AutoPayerInfo>
          {collaboratorsMap[autoPaySettings.payer]?.name} is set as the payer,
          only they can view and modify autopay settings
        </AutoPayerInfo>
        {userData?.currentUser?.id === autoPaySettings.payer && (
          <AutoPayMethod>
            {paymentMethod}
            <DropdownMenu
              button={<Button thin>Change</Button>}
              value={autoPaySettings?.payment_method}
              options={paymentMethodOptions}
              onChange={(id) => {
                updateAutoPaySettingsLocal('payment_method', id);
                setPaymentMethod(paymentMethodOptions[id].title);
              }}
            />
          </AutoPayMethod>
        )}
      </InvoiceSection>
    </Card>
  );
}

AutoPayCard.propTypes = {
  payers: PropTypes.array,
  projectId: PropTypes.string,
};
AutoPayCard.defaultProps = {
  payers: null,
  projectId: null,
};

export default AutoPayCard;
