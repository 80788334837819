/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { Card } from '../../../CoreStyles/GeneralStyles';

export const DILCard = styled(Card)`
  width: 100%;
  padding: 35px 30px 15px 30px;
  font-size: 14px;

  margin-bottom: 20px;
  @media (max-width: 980px) {
    width: 80%;
    margin-bottom: 20px;
    padding: 30px 30px 15px 30px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    padding: 30px 30px 15px 30px;
  }
`;

export const DILTitle = styled.div`
  font-size: 19px;
  color: #2c2c2c;
  display: flex;
  align-items: center;
`;

export const DILCardDueDate = styled.div`
  font-size: 14px;
  color: #646464;
  margin-bottom: 5px;
`;

export const DILViewMore = styled.div`
  display: flex;
  align-items: center;
`;

export const DILCardAmount = styled.div`
  color: #2c2c2c;
  font-size: 18px;
`;

export const DILBody = styled.div`
  margin-top: 17px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const DILDetails = styled.table`
  width: 100%;
`;

export const DILLineItems = styled.tbody`
  margin-bottom: 10px;
`;

export const DILCharge = styled.tr`
  padding-right: 5px;
  padding-left: 5px;
  color: #646464;
`;

export const DILHeader = styled.thead`
  padding-right: 5px;
  padding-left: 5px;
  color: #4000ff;
  margin-bottom: 6px;
`;

export const DILChargeDescription = styled.td`
  font-size: 14px;
  flex: 0.6;
  text-align: left;
  padding: 5px;
`;

export const DILQuantity = styled.td`
  font-size: 14px;
  flex: 0.1;
  text-align: right;
  padding: 5px;
`;

export const DILRate = styled.td`
  font-size: 14px;
  flex: 0.1;
  text-align: right;
  padding: 5px;
`;

export const DILTotal = styled.td`
  font-size: 14px;
  flex: 0.1;
  text-align: right;
  padding: 5px;
`;

export const DILTotalLine = styled.tr`
  margin-top: 10px;
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

export const DILCardHeader = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const DILPageHeader = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const DILStatus = styled.div`
  background-color: #d1fae5;
  border-radius: 6px;
  border-spacing: 2px;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #065f46;
  display: inline-flex;
  font-family: 'Avenir Next';
  font-size: 13px;
  font-variant: tabular-nums;
  font-weight: 500;
  line-height: 30px;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  margin-top: 5px;

  ${(props) =>
    props.color === 'green' &&
    css`
      color: rgb(6, 95, 70);
      background-color: rgb(209, 250, 229);
    `}

  ${(props) =>
    props.color === 'blue' &&
    css`
      color: rgb(55, 48, 163);
      background-color: rgb(224, 231, 255);
    `}

      ${(props) =>
    props.color === 'red' &&
    css`
      color: rgb(153, 27, 27);
      background-color: rgb(254, 226, 226);
    `}

        ${(props) =>
    props.color === 'orange' &&
    css`
      color: rgb(146, 64, 14);
      background-color: rgb(254, 243, 199);
    `}

    ${(props) =>
    props.color === 'gray' &&
    css`
      color: rgb(31, 41, 55);
      background-color: rgb(243, 244, 246);
    `}

    ${(props) =>
    props.color === 'purple' &&
    css`
      color: rgb(55, 48, 163);
      background-color: rgb(91, 33, 182);
    `}
`;

export const DILButton = styled.div`
  background: #0d6cff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgb(238 238 238 / 42%);
  border-radius: 8px;
  padding: 13px 21px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  text-align: center;
  margin-bottom: 13px;

  ${(props) =>
    props.primary &&
    css`
      &:hover {
        background-color: #2f81ff;
      }
    `}

  ${(props) =>
    props.black &&
    css`
      background: #000000;
      &:hover {
        background-color: #0d6cff;
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: #ffffff;
      border: 1px solid rgb(209, 213, 219);
      color: rgb(55, 65, 81);
      &:hover {
        background-color: #f6f6f6;
      }
    `}
`;
