import styled from 'styled-components';

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
`;

export const SubTotalAmount = styled.span`
  font-family: Avenir Next;
  margin-left: 5px;
  font-weight: 500;
`;
export const InvoiceWrapper = styled.div`
  text-align: right;
  margin-top: 20px;
`;

export const BlankSpan = styled.span``;
export default { FooterWrapper, SubTotalAmount, InvoiceWrapper, BlankSpan };
export const EnabledText = styled.span`
  color: #222222;
`;
export const Dropdown = styled.select``;
export const DropdownOption = styled.option``;

export const InvoiceMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const CustomDropDown = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(208 208 208 / 25%);
  border-radius: 6px;
  width: 50%;
  max-width: 360px;
  select {
    border: none;
    width: 100%;
    padding: 7px 17px;
    border-radius: 6px;
    -webkit-appearance: none;
    color: #5e5e5e;
  }
  svg {
    position: absolute;
    right: 11px;
    top: 13px;
  }
`;
