/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_LINK_TOKEN = gql`
  mutation GetLinkTokenMutation($userId: String, $redirectUri: String) {
    getLinkToken(userId: $userId, redirectUri: $redirectUri) {
      expiration
      link_token
      request_id
    }
  }
`;
