import styled from 'styled-components';

export const ProjectPrimaryText = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};

  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  color: #000000;
`;

export const ProjectSecondaryText = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};

  color: #a3a3a3;
`;

export const SecondaryTextSapn = styled.span`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  color: #a6b3c7;
`;

export const SortButton = styled.div`
  background-color: #f1f1f1;
  border-radius: 6px;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
`;

export const InvoicesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const InvoicesSection = styled.div`
  margin-bottom: 25px;
`;

export const EmptyInvoices = styled.div`
  padding: 8px 20px;
  color: #acacac;
  font-size: 18px;
`;
