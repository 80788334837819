/* eslint-disable react/prop-types */
import { React, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import Settings from '../Components/Settings/Settings';

import Project from '../Components/Project';
import Dashboard from '../Components/Dashboard';
import PrivateRoute from './PrivateRoute';
import Topbar from '../Components/Topbar';
import AccountSettings from '../Components/Settings/Account Settings/AccountSettings';
import BillingHistory from '../Components/Settings/BillingHistory';
import SummaryPage from '../Components/Dashboard/Summary/SummaryPage';
import UserManagement from '../Components/UserManagement';
import UserInfo from '../Components/UserManagement/UserInfo';
import AuthCallbackRoute from './AuthRoute';
import AppDialog from '../Components/AppDialog';
import PlaidRedirect from '../Components/PlaidRedirect';
import DeepLinkedInvoice from '../Components/Invoice/DeepLinkedInvoice';

import { SET_LAST_LOGIN } from '../GraphQl/Mutations/updateUserMutation';

export default function Routes() {
  const [values, setValues] = useState({});

  const fromUserManagement = (dataFromChild) => {
    if (values !== null) {
      setValues(dataFromChild);
    }
  };

  const [setLastLogin] = useMutation(SET_LAST_LOGIN, {
    onCompleted: () => {
      console.log('logged sir');
    },
    onError: (errors) => {
      console.log('error logging: ', errors);
    },
  });

  useEffect(() => {
    console.log('WE ARE SETTING LAST LOGIN');
    setLastLogin({
      variables: {
        last_login: new Date(),
      },
    });
  }, []);

  return (
    <div>
      <Topbar />
      <AppDialog />

      <PrivateRoute component={() => <Dashboard />} path="/" exact />
      <PrivateRoute
        component={() => <Settings />}
        path="/workspace-settings"
        exact
      />
      <PrivateRoute component={Project} path="/project" exact />
      <PrivateRoute component={Project} path="/project/:id" exact />

      <PrivateRoute component={DeepLinkedInvoice} path="/invoice/:id" exact />

      <PrivateRoute
        component={() => <AccountSettings />}
        path="/account-settings"
        exact
      />
      <PrivateRoute
        component={() => <BillingHistory />}
        path="/payment-history"
        exact
      />
      <PrivateRoute component={SummaryPage} path="/summary-page/:type" exact />
      <PrivateRoute
        component={() => (
          <UserManagement fromUserManagement={fromUserManagement} />
        )}
        path="/users"
        exact
      />
      <PrivateRoute
        component={() => <UserInfo />}
        path="/users/:userId"
        exact
      />
      <PrivateRoute component={PlaidRedirect} path="/plaid-oauth" />
      <AuthCallbackRoute />
    </div>
  );
}
