import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowCircleLeftIcon,
  CreditCardIcon,
  LibraryIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';
import {
  BankAccountTextWrapper,
  FooterText,
  FooterWrapper,
  ProcessingFeeText,
  PaymentMethodOptionsContainer,
  PaymentMethodOptionContainer,
} from './AddNewPaymentStyle';
import { CardList } from '../../Project/indexStyle';
import {
  ModalHeader,
  ModalTitle,
  ModalBackButton,
} from './UserPaymentModalStyle';
import { showAddBankAccount, showAddCreditCard } from '../state';

function AddNewPayment({ onBack }) {
  return (
    <>
      <>
        <ModalHeader>
          <ModalTitle>
            <ModalBackButton onClick={() => onBack()}>
              <ArrowCircleLeftIcon className="modal-back-icon" />
              <span>Back</span>
            </ModalBackButton>
            Add Payment Method
          </ModalTitle>
        </ModalHeader>

        <PaymentMethodOptionsContainer>
          <PaymentMethodOptionContainer
            onClick={() => showAddBankAccount()}
            width="50%"
            textAlign="center"
          >
            <CardList padding="30px 20px">
              <LibraryIcon className="add-payment-method-icon" />

              <BankAccountTextWrapper>Bank Account</BankAccountTextWrapper>
              <ProcessingFeeText>Free (No processing fee)</ProcessingFeeText>
            </CardList>
          </PaymentMethodOptionContainer>
          <PaymentMethodOptionContainer
            onClick={() => showAddCreditCard()}
            width="50%"
            textAlign="center"
          >
            <CardList padding="30px 20px">
              <CreditCardIcon className="add-payment-method-icon" />
              <BankAccountTextWrapper>Credit Card</BankAccountTextWrapper>
              <ProcessingFeeText>2.9% + 30¢ processing fee</ProcessingFeeText>
            </CardList>
          </PaymentMethodOptionContainer>
        </PaymentMethodOptionsContainer>
      </>
      <>
        <FooterWrapper>
          <LockClosedIcon className="lock-icon" />

          <FooterText>
            We’ve partnered with Stripe to process Credit Cards/ACH Transfers
            and Plaid to handle banking information. None of this information is
            stored on our servers.
          </FooterText>
        </FooterWrapper>
      </>
    </>
  );
}

AddNewPayment.propTypes = {
  onBack: PropTypes.func,
};

AddNewPayment.defaultProps = {
  onBack: null,
};

export default AddNewPayment;
