import styled from 'styled-components';

export const InputContainer = styled.div`
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  margin-top: 0.25rem;
  outline: none;
  position: relative;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgb(208 208 208 / 25%);

  &:focus-within {
    border: 1px solid #177afa;
  }

  width: ${(props) => (props.width ? props.width : '')};
`;

export const InputField = styled.input`
  appearance: none;
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #333;
  display: block;
  font-family: 'Avenir Next';
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0 3rem 0 1.75rem;
  width: 100%;
  padding: 10px 30px 10px 10px;
`;

export const CurrencyField = styled.input`
  appearance: none;
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #333;
  display: block;
  font-family: 'Avenir Next';
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0 3rem 0 1.75rem;
  width: 100%;
  padding: 10px 10px 10px 30px;
`;

export const InputTrailingItem = styled.div`
  align-items: center;
  border-style: solid;
  border-width: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  outline: none;
  padding-right: 0.75rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
`;

export const InputCurrencyItem = styled.div`
  align-items: center;
  border-style: solid;
  border-width: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  outline: none;
  padding-right: 0.75rem;
  pointer-events: none;
  position: absolute;
  left: 0.75rem;
  top: 0;
`;

export const InputTrailingItemText = styled.span`
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #6b7280;
  font-size: 0.875rem;
  line-height: 1.25rem;
  outline: none;
`;
