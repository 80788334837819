import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Card, Button } from '../../../CoreStyles/GeneralStyles';
import Menu from '../../Generics/Menu';
import {
  UPDATE_RECURRING_INVOICE_STATUS,
  DELETE_RECURRING_INVOICE,
} from '../../../GraphQl/Mutations/invoiceMutations';
import { currencyFormatter, isAllowed, OrgContext } from '../../../Utils';
import {
  InvoiceTitle,
  InvoiceStatusActive,
  InvoiceStatusPaused,
  InvoiceHeader,
  InvoiceFooter,
  InvoiceSection,
  InvoiceItemTitle,
  InvoiceItem,
  InvoiceHeaderLeft,
  InvoiceHeaderRight,
  MenuActionItem,
  InvoiceItemValue,
} from './RecurringInvoiceStyle';
import Editor from './Editor';
import ModalPopUp from '../../Common/ModalPopUp';
import { isMobile } from '../../Devices';
import createNotification from '../../Common/Notifications';

import { getValuesFromInvoice } from '../../../Utils/RruleUtils';

function RecurringInvoice({ invoice, role }) {
  const orgData = useContext(OrgContext);
  const [expanded, setExpanded] = useState(false);
  const [editMenuActive, setEditMenuActive] = useState(false);
  const [editInvoice, setEditInvoice] = useState(false);

  const [updateInvoiceStatus] = useMutation(UPDATE_RECURRING_INVOICE_STATUS, {
    onCompleted: () => {
      createNotification('success', 'Invoice Updated');
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
  });

  const [deleteInvoiceMutation] = useMutation(DELETE_RECURRING_INVOICE, {
    onCompleted: () => {
      createNotification('success', 'Recurring invoice deleted');
    },
  });

  const { nextInvoiceDate, ruleString } = getValuesFromInvoice(invoice);

  return (
    <>
      <Card width="100%" padding="22px 20px 0px 25px;" marginBottom="20px">
        <InvoiceHeader>
          <InvoiceHeaderLeft>
            <InvoiceTitle>{invoice.name}</InvoiceTitle>
            {invoice.enabled && (
              <InvoiceStatusActive>Active</InvoiceStatusActive>
            )}

            {!invoice.enabled && (
              <InvoiceStatusPaused>Paused</InvoiceStatusPaused>
            )}
          </InvoiceHeaderLeft>

          {(role === 'admin' ||
            role === 'owner' ||
            isAllowed(
              orgData?.current_user_role,
              'projects.invoices.edit'
            )) && (
            <InvoiceHeaderRight>
              <Menu
                style={{ width: '100%' }}
                button={
                  <DotsVerticalIcon
                    onClick={() => {
                      // We are editing
                      setEditMenuActive(!editMenuActive);
                    }}
                    className="more-dots-vertical"
                  />
                }
                isOpen={editMenuActive}
                setIsOpen={setEditMenuActive}
              >
                {invoice.enabled && (
                  <MenuActionItem
                    onClick={() => {
                      updateInvoiceStatus({
                        variables: {
                          id: invoice.id,
                          enabled: false,
                        },
                      });
                    }}
                  >
                    Pause Invoice
                  </MenuActionItem>
                )}
                {!invoice.enabled && (
                  <MenuActionItem
                    onClick={() => {
                      updateInvoiceStatus({
                        variables: {
                          id: invoice.id,
                          enabled: true,
                        },
                      });
                    }}
                  >
                    Enable Invoice
                  </MenuActionItem>
                )}
                <MenuActionItem
                  onClick={() => {
                    setEditInvoice(true);
                  }}
                >
                  Edit Invoice
                </MenuActionItem>
                <MenuActionItem
                  onClick={() => {
                    deleteInvoiceMutation({
                      variables: {
                        id: invoice.id,
                      },
                    });
                  }}
                >
                  Delete Invoice
                </MenuActionItem>
              </Menu>
            </InvoiceHeaderRight>
          )}
        </InvoiceHeader>

        <InvoiceSection>
          <InvoiceFooter>
            {expanded && (
              <>
                <InvoiceItem>
                  <InvoiceItemTitle>Next Invoice</InvoiceItemTitle>{' '}
                  <InvoiceItemValue>
                    {' '}
                    {invoice.enabled ? (
                      <>
                        {nextInvoiceDate !== 'None' ? (
                          <span>
                            {currencyFormatter.format(invoice.amount)} on{' '}
                            {nextInvoiceDate}
                          </span>
                        ) : (
                          <>N/A (Complete)</>
                        )}
                      </>
                    ) : (
                      <>N/A (Inactive)</>
                    )}
                  </InvoiceItemValue>
                </InvoiceItem>
                <InvoiceItem>
                  <InvoiceItemTitle>Start Date</InvoiceItemTitle>{' '}
                  <InvoiceItemValue>
                    {' '}
                    {moment(invoice.billing_start).format('MMM D, YYYY')}
                  </InvoiceItemValue>
                </InvoiceItem>

                <InvoiceItem>
                  <InvoiceItemTitle>Frequency</InvoiceItemTitle>{' '}
                  <InvoiceItemValue>{ruleString}</InvoiceItemValue>
                </InvoiceItem>
              </>
            )}

            <Button
              onClick={() => {
                setExpanded(!expanded);
              }}
              thin
              withRightIcon
              centered
              padding="5px 15px"
            >
              {expanded && <span>View Less</span>}
              {!expanded && <span>View More</span>}
              {expanded && <ChevronUpIcon className="thin-button-right-icon" />}
              {!expanded && (
                <ChevronDownIcon className="thin-button-right-icon" />
              )}
            </Button>
          </InvoiceFooter>
        </InvoiceSection>
      </Card>
      <ModalPopUp
        className="common-modal"
        width={isMobile() ? '90%' : '60%'}
        style={{ maxWidth: '600px', top: isMobile() ? '10px' : '50px' }}
        title="Update Recurring Invoice"
        closeIcon="Close"
        closable={editInvoice}
        // maskClosable={false}
        centered
        visible={editInvoice}
        isOk={() => setEditInvoice(false)}
        isCancel={() => setEditInvoice(false)}
        footer={null}
        content={
          <Editor
            Invoice={invoice}
            onClose={() => {
              setEditInvoice(false);
            }}
          />
        }
      />
    </>
  );
}

RecurringInvoice.propTypes = {
  invoice: PropTypes.element,
  role: PropTypes.string,
};
RecurringInvoice.defaultProps = {
  invoice: null,
  role: 'client',
};

export default RecurringInvoice;
