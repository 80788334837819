/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import { PlusCircleIcon } from '@heroicons/react/outline';
import Skeleton from 'react-loading-skeleton';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import _ from 'lodash';
import { currencyFormatter, isAllowed, OrgContext } from '../../Utils';
import ActionModal from '../Generics/ActionModal';
import DropdownMenu from '../Generics/Dropdown';
import CurrencyInput from '../Generics/Input/CurrencyInput';

import { ModalFooter } from '../../CoreStyles/ModalStyles';

import {
  SectionTitle,
  Button,
  Card,
  InputGroup,
  Label,
  TextArea,
} from '../../CoreStyles/GeneralStyles';

import {
  ProjectRow,
  ProjectName,
  ProjectRole,
  ProjectMeta,
} from './UserProjCardStyle';
import { GET_CREDIT_HISTORY_FOR_USER } from '../../GraphQl/Queries/creditQueries';
import { ADJUST_CREDIT_BALANCE } from '../../GraphQl/Mutations/creditMutations';

export default function CreditHistory({ viewType, userId }) {
  const orgData = useContext(OrgContext);
  const [addCreditsActive, setAddCreditsActive] = useState(false);

  const [adjustmentObject, setAdjustmentObject] = useState({
    type: 'credit',
  });

  const { loading, data } = useQuery(GET_CREDIT_HISTORY_FOR_USER, {
    variables: { userId, organizationId: '1' },
  });

  function updateAdjustmentObject(key, value) {
    const tempAdjustmentObject = _.cloneDeep(adjustmentObject);

    tempAdjustmentObject[key] = value;

    setAdjustmentObject(tempAdjustmentObject);
  }

  const [adjustCreditBalance] = useMutation(ADJUST_CREDIT_BALANCE, {
    onCompleted: () => {
      setAdjustmentObject({
        type: 'credit',
      });
      setAddCreditsActive(false);
    },

    refetchQueries: [
      {
        query: GET_CREDIT_HISTORY_FOR_USER,
        variables: {
          userId,
          organizationId: '1',
        },
      },
    ],
  });

  return (
    <>
      <SectionTitle marginTop="3%" marginBottom="1%">
        Credit History
      </SectionTitle>
      <Card display="grid" padding="20px 25px" marginBottom="20px">
        {!loading &&
          (!data?.getCreditHistoryForUser ||
            data?.getCreditHistoryForUser?.length === 0) && (
            <div>No credits to display</div>
          )}

        {!loading &&
          data?.getCreditHistoryForUser?.length !== 0 &&
          data?.getCreditHistoryForUser.map((creditHistory) => (
            <ProjectRow>
              <ProjectMeta>
                <ProjectName>
                  {creditHistory.amount > 0 && <span>+</span>}
                  {creditHistory.amount < 0 && <span>-</span>}
                  {currencyFormatter.format(Math.abs(creditHistory.amount))}
                </ProjectName>
                <ProjectRole>{creditHistory.description}</ProjectRole>
              </ProjectMeta>
              <ProjectRole>
                {moment(creditHistory.timestamp).format('MMM Do YYYY (h:mm a)')}
              </ProjectRole>
            </ProjectRow>
          ))}

        {loading && <Skeleton count={3} />}

        {viewType !== 'client' &&
          isAllowed(
            orgData?.current_user_role,
            'client_management.adjust_credits.edit'
          ) && (
            <Button
              onClick={() => {
                setAddCreditsActive(true);
              }}
              thin
              withRightIcon
              centered
            >
              <PlusCircleIcon className="thin-button-left-icon" />
              Add/Remove Credits
            </Button>
          )}
      </Card>

      <ActionModal
        visible={addCreditsActive}
        setVisible={setAddCreditsActive}
        content={
          <div>
            <InputGroup>
              <Label>Adjustment Type</Label>
              <DropdownMenu
                value={adjustmentObject.type}
                options={{
                  credit: {
                    title: 'Credit (increase)',
                  },
                  debit: {
                    title: 'Debit (decrease)',
                  },
                }}
                onChange={(newValue) => {
                  updateAdjustmentObject('type', newValue);
                }}
              />
            </InputGroup>

            <InputGroup>
              <Label>Amount</Label>
              <CurrencyInput
                onChange={(e) =>
                  // Restrict credits used to be >= 0 and <= Invoice.amount
                  updateAdjustmentObject(
                    'amount',
                    Math.abs(parseFloat(e.target.value, 10))
                  )
                }
                value={adjustmentObject.amount}
                placeholder="0.00"
                customStyle={{ width: '150px' }}
                minValue={0}
              />
            </InputGroup>
            <InputGroup>
              <Label>Description</Label>
              <TextArea
                maxWidth="400px"
                placeholder="Thank you for referring that client!"
                rows={6}
                value={adjustmentObject.description}
                onChange={(e) =>
                  // Restrict credits used to be >= 0 and <= Invoice.amount
                  updateAdjustmentObject('description', e.target.value)
                }
              />
            </InputGroup>

            <ModalFooter>
              <Button
                onClick={() => {
                  adjustCreditBalance({
                    variables: {
                      credit_balance_input: {
                        to_user: userId,
                        amount:
                          adjustmentObject.type === 'credit'
                            ? adjustmentObject.amount * 100
                            : adjustmentObject.amount * 100 * -1,
                        description: adjustmentObject.description,
                        organization_id: '1',
                        type: adjustmentObject.type,
                        timestamp: new Date(),
                      },
                    },
                  });
                }}
              >
                Apply Credit Adjustment
              </Button>
            </ModalFooter>
          </div>
        }
        title={<div>Adjust Credit Balance</div>}
      />
    </>
  );
}
