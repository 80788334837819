/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  Input,
  Label,
  Button,
  InputGroup,
} from '../../CoreStyles/GeneralStyles';
import {
  BlankDiv,
  BlankSpan,
  FooterRight,
  SwitchWrapper,
} from './NewProjectStyle';
import createNotification from '../Common/Notifications';

import { vocabulary } from '../../Enums';

import { CREATE_PROJECT } from '../../GraphQl/Mutations/updateProjectMutation';

function NewProject(props) {
  const history = useHistory();

  const { handleCancleProject, projects_vocabulary, userId } = props;
  const [projectTitle, setProjectTitle] = useState('');

  const [emailSettings, setEmailSettings] = useState({
    completion_emails_enabled: false,
    reminder_emails_enabled: false,
  });

  const handleCompletionSwitch = (checked) => {
    const emailSettingsClone = _.cloneDeep(emailSettings || {});

    emailSettingsClone.completion_emails_enabled = checked;

    setEmailSettings(emailSettingsClone);
  };

  const handleReminderSwitch = (checked) => {
    const emailSettingsClone = _.cloneDeep(emailSettings || {});

    emailSettingsClone.reminder_emails_enabled = checked;

    setEmailSettings(emailSettingsClone);
  };

  const [createProject] = useMutation(CREATE_PROJECT, {
    onCompleted: (data) => {
      console.log('WE HAVE THIS NEW DATA: ', data.createProject);

      history.push(`/project/${data.createProject?.id}`);
      createNotification('success', 'Project Created');
      handleCancleProject(false);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    createProject({
      variables: {
        name: projectTitle,
        organization_id: '1',
        email_settings: emailSettings,
        date_started: new Date(),
        users: [
          {
            id: userId,
            role: 'owner',
          },
        ],
      },
    });
    console.log(e);
  };

  return (
    <BlankDiv>
      <InputGroup>
        <Label>
          {vocabulary[projects_vocabulary]?.singular_cap || 'Project'} Title
        </Label>
        <Input
          type="text"
          name="project_title"
          value={projectTitle}
          onChange={(e) => setProjectTitle(e.target.value)}
        />
      </InputGroup>

      <InputGroup>
        <Label>Email Settings</Label>
        <SwitchWrapper>
          <Switch
            checked={emailSettings?.completion_emails_enabled || false}
            onChange={handleCompletionSwitch}
          />
          <BlankSpan>Completion Emails</BlankSpan>
        </SwitchWrapper>
        <SwitchWrapper>
          <Switch
            checked={emailSettings?.reminder_emails_enabled || false}
            onChange={handleReminderSwitch}
          />
          <BlankSpan>Reminder Emails</BlankSpan>
        </SwitchWrapper>
      </InputGroup>

      <FooterRight>
        <BlankSpan>
          <Button thin color="grey" onClick={() => handleCancleProject(false)}>
            Close
          </Button>
        </BlankSpan>
        <BlankSpan>
          <Button primary onClick={handleSubmit}>
            Create {vocabulary[projects_vocabulary]?.singular_cap || 'Project'}
          </Button>
        </BlankSpan>
      </FooterRight>
    </BlankDiv>
  );
}

NewProject.propTypes = {
  handleCancleProject: PropTypes.func,
  projects_vocabulary: PropTypes.string,
  userId: PropTypes.string,
};
NewProject.defaultProps = {
  handleCancleProject: () => {},
  projects_vocabulary: 'projects',
  userId: null,
};

export default NewProject;
