/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { showAddMethod, dialogState } from '../state';
import { Button } from '../../../CoreStyles/GeneralStyles';
import {
  BlankDiv,
  SelectCardText,
  PaymentMethodImage,
} from './SelectPaymentStyle';
import {
  ProfileList,
  CardList,
  MethodListMain,
  InputSpan,
} from '../../Project/indexStyle';
import { CheckIcon } from '../../../assets/svg';
import VisaIcon from '../../../images/cards/dark/1.png';
import AmexIcon from '../../../images/cards/dark/22.png';
import DinersIcon from '../../../images/cards/dark/10.png';
import DiscoverIcon from '../../../images/cards/dark/14.png';
import JCBIcon from '../../../images/cards/dark/16.png';
import MasterCardIcon from '../../../images/cards/dark/2.png';
import UnionPayIcon from '../../../images/cards/dark/25.png';
import UnknownIcon from '../../../images/cards/dark/24.png';
import BankIcon from '../../../images/bank_icon.svg';

const CARD_OPTIONS = {
  amex: {
    title: 'American Express',
    icon: AmexIcon,
  },
  diners: {
    title: 'Diners Club',
    icon: DinersIcon,
  },
  discover: {
    title: 'Discover',
    icon: DiscoverIcon,
  },
  jcb: {
    title: 'JCB',
    icon: JCBIcon,
  },
  mastercard: {
    title: 'MasterCard',
    icon: MasterCardIcon,
  },
  unionpay: {
    title: 'UnionPay',
    icon: UnionPayIcon,
  },
  visa: {
    title: 'Visa',
    icon: VisaIcon,
  },
  unknown: {
    title: 'Unknown',
    icon: UnknownIcon,
  },
};

export default function SelectPaymentMethod({
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  user,
}) {
  const state = useReactiveVar(dialogState);

  return (
    <div>
      {user?.payment_methods?.length !== 0 ? (
        user?.payment_methods?.map((paymentMethod) => (
          <MethodListMain id={paymentMethod.id}>
            <input
              type="radio"
              name="paymentoption"
              value="Debit •••••• 4852"
              checked={selectedPaymentMethod?.id === paymentMethod.id}
              onChange={() => {
                state.callback(paymentMethod);
                setSelectedPaymentMethod(paymentMethod);
              }}
            />
            <InputSpan marginLeft="14px">
              <CheckIcon />
            </InputSpan>
            <CardList methodList>
              <ProfileList cursor="pointer" marginLeft="15px">
                <BlankDiv>
                  {paymentMethod.type === 'card' && (
                    <PaymentMethodImage
                      src={CARD_OPTIONS[paymentMethod.card_brand].icon}
                      alt="Visa Icon"
                    />
                  )}

                  {paymentMethod.type === 'bank_account' && (
                    <PaymentMethodImage src={BankIcon} alt="Visa Icon" />
                  )}

                  <SelectCardText>
                    {paymentMethod.type === 'card' &&
                      CARD_OPTIONS[paymentMethod.card_brand].title}
                    {paymentMethod.type === 'bank_account' && 'Bank'} Ending in{' '}
                    {paymentMethod.last4}
                  </SelectCardText>
                </BlankDiv>
              </ProfileList>
            </CardList>
          </MethodListMain>
        ))
      ) : (
        <div>Empty</div>
      )}

      {/* TODO: Add callback for add credit card to come back after setting new selected payment method */}
      <Button thin withRightIcon centered onClick={() => showAddMethod()}>
        Add payment method
        <ArrowRightIcon className="thin-button-right-icon" />
      </Button>
    </div>
  );
}

SelectPaymentMethod.propTypes = {
  user: PropTypes.element,
  setSelectedPaymentMethod: PropTypes.func,
  selectedPaymentMethod: PropTypes.element,
};

SelectPaymentMethod.defaultProps = {
  user: null,
  selectedPaymentMethod: null,
  setSelectedPaymentMethod: null,
};
