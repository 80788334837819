import auth0 from 'auth0-js';
import settings from './Config/settings';

const TOKEN_KEY = 'jwt';

class Auth {
  constructor() {
    this.callbackUri = `${window.location.origin}/callback?`;

    this.auth0 = new auth0.WebAuth({
      domain: settings.auth0.domain,
      clientID: settings.auth0.clientId,
      redirectUri: this.callbackUri,
      responseType: 'token id_token',
      scope: 'openid profile email',
    });
  }

  login(username, password, fromSignup = false) {
    if (fromSignup) {
      this.auth0 = new auth0.WebAuth({
        domain: settings.auth0.domain,
        clientID: settings.auth0.clientId,
        redirectUri: `${
          window.location.origin
        }/callback?path=/eula?signup=true&ref=${process.env.REACT_APP_MANAGE_URL.replace(
          'http://',
          ''
        ).replace('https://', '')}`,
        responseType: 'token id_token',
        scope: 'openid profile email',
      });
    }

    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          realm: 'Username-Password-Authentication',
          username,
          password,
        },
        async (error, authResult) => {
          if (authResult?.accessToken && authResult?.idToken) {
            localStorage.setItem(TOKEN_KEY, authResult);
            resolve(authResult);
          } else if (error) {
            reject(Error(error.description));
          }
        }
      );
    });
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    localStorage.removeItem(TOKEN_KEY);

    this.auth0.logout({
      clientID: settings.auth0.clientId,
      returnTo: `${window.location.origin}/login`,
    });
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (error, authResult) => {
        if (authResult?.accessToken && authResult?.idToken) {
          localStorage.setItem(TOKEN_KEY, authResult);
          resolve(authResult);
        } else if (error.code === 'login_required') {
          localStorage.removeItem(TOKEN_KEY);
          resolve({});
        } else {
          reject(error);
        }
      });
    });
  }

  handleAuthentication({ history, location }) {
    return new Promise((resolve, reject) => {
      if (/access_token|id_token|error/.test(location.hash)) {
        this.auth0.parseHash(async (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            localStorage.setItem(TOKEN_KEY, authResult);
            resolve(authResult);
          } else if (err) {
            console.error(err);
            reject(
              Error('Error parsing authentication credentials. Redirecting...')
            );
            setTimeout(() => history.push('/'), 1000);
          }
        });
      } else {
        reject(
          Error('Error parsing authentication credentials. Redirecting...')
        );
        setTimeout(() => history.push('/'), 1000);
      }
    });
  }

  sendResetPasswordEmail(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email,
        },
        (err, res) => {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        }
      );
    });
  }
}

// Create singleton for default export
export default new Auth();
