import styled from 'styled-components';

export const UserContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const UserAvatar = styled.img`
  height: 35px;
  border-radius: 50%;
  margin-right: 9px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const UserInfoContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const UserName = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #222222;
`;

export const UserRole = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  /* identical to box height */

  color: #a0a0a0;
`;
