/* eslint-disable react/prop-types */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, forwardRef } from 'react';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';

import DatePickerIcon from '../../../images/date-picker-icon.svg';
import BackIcon from '../../../images/back-small-arrow.svg';
import NextIcon from '../../../images/next-small-arrow.svg';

import './PickerStyles.css';

const CustomDatePicker = ({ value, updateValue }) => {
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  const DateButton = forwardRef(({ value, onClick }, ref) => (
    <div
      className="date-picker-button"
      onClick={(event) => {
        event.stopPropagation();
        setDatePickerIsOpen(!datePickerIsOpen);
      }}
      ref={ref}
    >
      {(value == null || value === '') && (
        <span className="date-picker-text-placeholder">Select Date</span>
      )}

      {value != null && value !== '' && (
        <span className="date-picker-text">
          {moment(value).format('MMM Do YYYY')}
        </span>
      )}

      <img className="date-picker-icon" src={DatePickerIcon} />
    </div>
  ));

  const [date, setDate] = useState(new Date());

  return (
    <DatePicker
      onClickOutside={() => {
        setDatePickerIsOpen(false);
      }}
      open={datePickerIsOpen}
      customInput={<DateButton />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="date-picker-header">
          <img
            className="back-small-arrow"
            src={BackIcon}
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          />

          <div className="date-picker-header-title">
            {moment(date).format('MMMM YYYY')}
          </div>

          <img
            className="next-small-arrow"
            src={NextIcon}
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          />
        </div>
      )}
      calendarClassName="beautiful-cal"
      disabledKeyboardNavigation
      showPopperArrow={false}
      showDisabledMonthNavigation
      selected={value}
      onChange={(date) => {
        updateValue(date);

        setDatePickerIsOpen(false);
      }}
      placeholderText="Select a date between today and 5 days in the future"
    >
      <div className="calendar-buttons">
        <div
          onClick={() => {
            updateValue(new Date());

            setDatePickerIsOpen(false);
          }}
          className="calendar-button"
        >
          Today
        </div>

        {value != null && value !== '' && (
          <div
            onClick={() => {
              updateValue(null);

              setDatePickerIsOpen(false);
            }}
            className="calendar-button"
          >
            Remove Date
          </div>
        )}
      </div>
    </DatePicker>
  );
};

export default CustomDatePicker;
