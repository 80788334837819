/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
// import { useMutation } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/outline';
import {
  Input,
  Label,
  Card,
  InputGroup,
  TextArea,
  SeparateGroup,
  SameLineInput,
} from '../../../CoreStyles/GeneralStyles';
import {
  // EmailH2,
  EmailEditorHeader,
  EmailSpan,
  EmailEditorHeaderTitle,
} from './EmailSequenceStyle';

import TrailingInput from '../../Generics/Input/TrailingInput';

function EmailSequenceCard({ index, deleteEmail, email, updateEmail }) {
  const handleSwitch = (checked) => {
    updateEmail(email.id, 'late_fee_enabled', checked);
  };

  return (
    <Card width="100%" padding="30px 30px 0px 30px" marginBottom="25px">
      <EmailEditorHeader>
        <EmailEditorHeaderTitle>Email #{index + 1}</EmailEditorHeaderTitle>
        <TrashIcon
          onClick={() => {
            deleteEmail(index);
          }}
          className="card-delete-button"
        />
      </EmailEditorHeader>
      <InputGroup>
        <Label cursorPointer="cursor">Send</Label>
        <SameLineInput>
          <Input
            type="number"
            placeholder="5"
            value={email?.send_at}
            width="80px"
            marginRight="10px"
            onChange={(e) =>
              updateEmail(email.id, 'send_at', parseInt(e.target.value, 10))
            }
          />
          <div>Days after pending approval</div>
        </SameLineInput>
      </InputGroup>

      <InputGroup>
        {' '}
        <Label>Subject</Label>
        <Input
          maxWidth="400px"
          type="text"
          name="subject"
          placeholder="Gentle Reminder: Your invoice is pending approval"
          value={email?.subject}
          onChange={(e) => updateEmail(email.id, 'subject', e.target.value)}
        />
      </InputGroup>

      <InputGroup>
        <Label>Body</Label>
        <TextArea
          maxWidth="400px"
          name="body"
          placeholder="Hi {{client-name}},
            Just a gentle reminder that {{invoice-name}} is still pending your approval.
             There is also a 5% late fee if invoice is 1 week overdue!
            Please approve the milestone so we can continue development."
          value={email?.body}
          rows={6}
          onChange={(e) => updateEmail(email.id, 'body', e.target.value)}
        />
      </InputGroup>
      <SeparateGroup backgroundEnabled={email?.late_fee_enabled || false}>
        <InputGroup>
          <Label>Display late fee in Email</Label>
          <Switch checked={email?.late_fee_enabled} onChange={handleSwitch} />
          {email?.late_fee_enabled ? (
            <EmailSpan>Enable</EmailSpan>
          ) : (
            <EmailSpan>Disable</EmailSpan>
          )}
        </InputGroup>
        {email?.late_fee_enabled ? (
          <TrailingInput
            placeholder="5.0"
            width="150px"
            value={email?.late_fee}
            onBlur={() => {
              // Now let's save the string as a float
              updateEmail(email.id, 'late_fee', parseFloat(email.late_fee));
            }}
            onChange={(e) => updateEmail(email.id, 'late_fee', e.target.value)}
          />
        ) : null}
      </SeparateGroup>
    </Card>
  );
}

EmailSequenceCard.propTypes = {
  index: PropTypes.number,
  deleteEmail: PropTypes.func,
  email: PropTypes.any,
  updateEmail: PropTypes.func,
};

EmailSequenceCard.defaultProps = {
  index: null,
  deleteEmail: null,
  email: null,
  updateEmail: null,
};

export default EmailSequenceCard;
