export default {
  url: 'https://aloapay.dev/',
  auth0: {
    domain: 'login.aloa.co',
    clientId: 't4gBl_HE5L0-6HhYly0CFJnb1llSRMrk',
  },
  apolloClient: {
    uri: 'https://aa82a1dwm2.execute-api.us-west-2.amazonaws.com/dev',
  },
};
