import { gql } from '@apollo/client';

export const CREATE_PAYMENT_MUTATION = gql`
  mutation createPayment($input: PaymentInput!) {
    createPayment(input: $input) {
      invoice
      id
      status
      amount
    }
  }
`;
export default CREATE_PAYMENT_MUTATION;
