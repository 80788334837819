import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font, Page, View, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import BillingInfo from './BillingInfo';
import Summary from './Summary';
import LineItems from './LineItems';
import Message from './Message';
import Footer from './Footer';

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  body: {
    flexGrow: 1,
  },
});

Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

export default function Invoice({ invoice, organization, payments }) {
  return (
    <Document
      author={organization.name}
      title={`Invoice-${invoice.id.toUpperCase()}`}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
          <Header organization={organization} invoice={invoice} />
          <BillingInfo />
          <Summary invoice={invoice} />
          <LineItems
            organization={organization}
            invoice={invoice}
            payments={payments}
          />
          <Message />
        </View>
        <Footer organization={organization} invoice={invoice} />
      </Page>
    </Document>
  );
}

Invoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
};
