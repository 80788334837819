/* eslint-disable react/forbid-prop-types */
import { React, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { UserAddIcon } from '@heroicons/react/outline';
import { RadioGroup } from '@headlessui/react';
import ModalPopUp from '../../Common/ModalPopUp';

import { createNotification } from '../../Common/Notifications';

import { isMobile } from '../../Devices';

import {
  SettingsCardTitle,
  SettingsCardDescription,
  SettingsCardHeader,
} from '../Account Settings/AccountSettingsStyle';

import { INVITE_USER_TO_ORG } from '../../../GraphQl/Mutations/orgMutations';

import {
  GET_ORGANIZATION_TEAM,
  GET_ORGANIZATION_CLIENTS,
  GET_ORGANIZATION_MEMBERS,
} from '../../../GraphQl/Queries/organizationsQuery';

import {
  Button,
  ButtonWrapper,
  Input,
  InputGroup,
  Label,
} from '../../../CoreStyles/GeneralStyles';

const roles = {
  admin: {
    id: 'admin',
    name: 'Administrator',
    description: 'Best for business owners and company administrators',
  },
  sales: {
    id: 'sales',
    name: 'Sales',
    description:
      'Best for employees who need to create and update data regularly',
  },
  analyst: {
    id: 'analyst',
    name: 'Analyst',
    description:
      'Best for people who need full access to data, but do not need to create',
  },
  support: {
    id: 'support',
    name: 'Support Specialist',
    description:
      'Best for employees who regularly refund payments and respond to disputes',
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function InviteUserModal({
  isVisible,
  setIsVisible,
  hardcodedRole,
}) {
  const [selected, setSelected] = useState('support');
  const [email, setEmail] = useState('');

  const [currentUserEmails, setCurrentUserEmails] = useState([]);

  console.log('currentUSerEmails: ', currentUserEmails);

  const { data: orgUsersData } = useQuery(GET_ORGANIZATION_MEMBERS, {
    variables: { id: '1' },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (orgUsersData?.organization?.members) {
      let tempEmailsUsers = orgUsersData?.organization?.members.map(
        (user) => user.email
      );

      if (orgUsersData?.organization?.member_invites) {
        const tempEmailsInvites = orgUsersData?.organization?.member_invites.map(
          (invite) => invite.email
        );

        tempEmailsUsers = [...tempEmailsUsers, ...tempEmailsInvites];
      }

      setCurrentUserEmails(tempEmailsUsers);
    }
  }, [orgUsersData]);

  console.log('currentUserEmails: ', currentUserEmails);

  const [inviteUserToOrg] = useMutation(INVITE_USER_TO_ORG, {
    onCompleted: () => {
      console.log('success');
      setIsVisible(false);
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
    refetchQueries: [
      {
        query: GET_ORGANIZATION_TEAM,
        variables: {
          id: '1',
        },
      },
      {
        query: GET_ORGANIZATION_CLIENTS,
        variables: {
          id: '1',
        },
      },
    ],
  });

  return (
    <ModalPopUp
      className="common-modal"
      width={isMobile() ? '90%' : '50%'}
      title={null}
      visible={isVisible}
      isCancel={() => {
        setIsVisible(false);
      }}
      footer={null}
      style={{ top: isMobile() ? '10px' : '40px', maxWidth: '600px' }}
      centered={false}
      content={
        <div>
          <SettingsCardHeader withImage>
            <UserAddIcon
              className="modal-icon"
              style={{ color: '#0064fb', backgroundColor: '#0469ff29' }}
            />
            <div>
              {!hardcodedRole && (
                <SettingsCardTitle>Invite Team Member</SettingsCardTitle>
              )}

              {hardcodedRole && (
                <SettingsCardTitle>Invite Client</SettingsCardTitle>
              )}

              {!hardcodedRole && (
                <SettingsCardDescription>
                  You are inviting a team member to your organization
                </SettingsCardDescription>
              )}

              {hardcodedRole && (
                <SettingsCardDescription>
                  You are inviting a client to your organization
                </SettingsCardDescription>
              )}
            </div>
          </SettingsCardHeader>

          <InputGroup>
            <Label>Email of user to invite</Label>
            <Input
              type="text"
              name="email"
              placeholder="Example: josh@growthbird.co"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </InputGroup>

          {hardcodedRole == null && (
            <RadioGroup value={selected} onChange={setSelected}>
              <RadioGroup.Label className="sr-only">
                Choose Role
              </RadioGroup.Label>
              <div className="bg-white rounded-md -space-y-px">
                {Object.values(roles).map((role, settingIdx) => (
                  <RadioGroup.Option
                    key={role.id}
                    value={role.id}
                    className={({ checked }) =>
                      classNames(
                        settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        settingIdx === Object.keys(roles).length - 1
                          ? 'rounded-bl-md rounded-br-md'
                          : '',
                        checked
                          ? 'bg-indigo-50 border-indigo-200 z-10'
                          : 'border-gray-200',
                        'relative border p-4 flex cursor-pointer focus:outline-none'
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked
                              ? 'bg-indigo-600 border-transparent'
                              : 'bg-white border-gray-300',
                            active
                              ? 'ring-2 ring-offset-2 ring-indigo-500'
                              : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <div className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? 'text-indigo-900' : 'text-gray-900',
                              'block text-sm font-medium'
                            )}
                          >
                            {role.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(
                              checked ? 'text-indigo-700' : 'text-gray-500',
                              'block text-sm'
                            )}
                          >
                            {role.description}
                          </RadioGroup.Description>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          )}

          <ButtonWrapper>
            <Button
              onClick={() => {
                if (currentUserEmails.includes(email)) {
                  createNotification(
                    'error',
                    `${email} is already part of this org!`
                  );
                } else {
                  inviteUserToOrg({
                    variables: {
                      orgId: '1',
                      role: hardcodedRole || selected,
                      email,
                    },
                  });
                }
              }}
              marginLeft="14px"
              primary
            >
              Send Invite
            </Button>
            <Button
              onClick={() => {
                setIsVisible(false);
              }}
              secondary
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
      }
    />
  );
}

InviteUserModal.propTypes = {
  isVisible: PropTypes.bool,
  setIsVisible: PropTypes.func,
  hardcodedRole: PropTypes.string,
};

InviteUserModal.defaultProps = {
  isVisible: false,
  setIsVisible: null,
  hardcodedRole: null,
};
