/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQuery } from '@apollo/client';
import { message, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  CreditsSection,
  ChargeSummaryLine,
  PayButton,
  FinePrintText,
  ChargeTitle,
  ChargeAmount,
  CreditsToggleArea,
} from './ConfirmPaymentScreenStyle';

import {
  SeparateGroup,
  InputGroup,
  Label,
} from '../../../CoreStyles/GeneralStyles';

import CurrencyInput from '../../Generics/Input/CurrencyInput';
import PaymentMethodSelector from '../../Common/PaymentMethodSelector';

import './Payment.css';
import {
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
} from './UserPaymentModalStyle';
import { currencyFormatter, calculateFees } from '../../../Utils';
import CREATE_PAYMENT_MUTATION from '../../../GraphQl/Mutations/createPayment';
import UPDATE_INVOICE from '../../../GraphQl/Mutations/updateInvoices';
import { GET_PROJECT_FEES } from '../../../GraphQl/Queries/projectQueries';
import {
  hideModal,
  setInline,
  showConfirmPayment,
  showSelectMethod,
} from '../state';

function ConfirmPaymentScreen({
  Invoice,
  isInline,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  user,
}) {
  const [createPayment] = useMutation(CREATE_PAYMENT_MUTATION);
  const [updateInvoice] = useMutation(UPDATE_INVOICE);

  const { data: feesData } = useQuery(GET_PROJECT_FEES, {
    variables: {
      id: Invoice.project_id,
    },
  });

  const [processing, setProcessing] = useState(false);

  const [creditsUsed, setCreditsUsed] = useState(0);

  const [useCredits, setUseCredits] = useState(false);

  // Calculate fees for this invoice
  const feeObject = calculateFees(feesData?.project?.fees, Invoice);

  const adjustments = [];
  // If feeObject is of type early, add a discount adjustment
  if (feeObject?.type === 'early') {
    adjustments.push({
      type: 'discount',
      amount: feeObject.value,
      percentage: feeObject.percentage,
      description: 'Early Pay Incentive',
    });
  } else if (feeObject?.type === 'late') {
    adjustments.push({
      type: 'fee',
      amount: feeObject.value,
      percentage: feeObject.percentage,
      description: 'Late Fee',
    });
  }

  return (
    <>
      {!isInline && (
        <ModalHeader>
          <ModalTitle>Confirm Payment</ModalTitle>
          <ModalCloseButton onClick={hideModal}>Close</ModalCloseButton>
        </ModalHeader>
      )}

      <div>
        <PaymentMethodSelector
          selectedPaymentMethod={selectedPaymentMethod}
          user={user}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          onClick={() => {
            // Before we navigate away, we need to set the state to be inline
            setInline(isInline);
            showSelectMethod(
              {
                invoice: Invoice,
              },
              () => {
                showConfirmPayment(Invoice);
              }
            );
          }}
        />

        <SeparateGroup
          backgroundEnabled={useCredits}
          lightBg={isInline}
          whiteBg={!isInline}
          style={{ marginTop: '20px' }}
        >
          <CreditsSection>
            <CreditsToggleArea>
              <Switch
                checked={useCredits}
                onChange={(checked) => {
                  setUseCredits(checked);
                }}
                size="small"
              />
              Apply credits (
              {currencyFormatter.format(user?.credit_balance || 0)} available)
            </CreditsToggleArea>

            {useCredits && (
              <InputGroup style={{ marginTop: '10px', marginBottom: '0px' }}>
                <Label>Amount of credits to use</Label>
                <CurrencyInput
                  onChange={(e) =>
                    // Restrict credits used to be >= 0 and <= Min(Invoice.amount, user?.credit_balance)
                    setCreditsUsed(
                      Math.max(
                        Math.min(
                          parseInt(e.target.value, 10),
                          Math.min(
                            user?.credit_balance / 100,
                            Invoice.amount / 100
                          )
                        ),
                        0
                      )
                    )
                  }
                  value={creditsUsed}
                  placeholder="0.00"
                />
              </InputGroup>
            )}
          </CreditsSection>
        </SeparateGroup>

        <SeparateGroup
          backgroundEnabled
          lightBg={isInline}
          whiteBg={!isInline}
          style={{ marginTop: '20px' }}
        >
          {(useCredits || feeObject) && (
            <ChargeSummaryLine>
              <ChargeTitle style={{ color: '#747474' }}>
                Total Invoice Amount
              </ChargeTitle>
              <ChargeAmount style={{ color: '#747474' }}>
                {currencyFormatter.format(Invoice.amount || 0)}
              </ChargeAmount>
            </ChargeSummaryLine>
          )}

          {feeObject && feeObject.type === 'late' && (
            <ChargeSummaryLine>
              <ChargeTitle style={{ color: '#747474' }}>
                Late fee ({feeObject.percentage}%)
              </ChargeTitle>
              <ChargeAmount style={{ color: '#747474' }}>
                {currencyFormatter.format(feeObject.value || 0)}
              </ChargeAmount>
            </ChargeSummaryLine>
          )}

          {feeObject && feeObject.type === 'early' && (
            <ChargeSummaryLine>
              <ChargeTitle style={{ color: '#747474' }}>
                Early pay ({feeObject.percentage}%)
              </ChargeTitle>
              <ChargeAmount style={{ color: '#747474' }}>
                - {currencyFormatter.format(feeObject.value || 0)}
              </ChargeAmount>
            </ChargeSummaryLine>
          )}

          {useCredits && (
            <ChargeSummaryLine>
              <ChargeTitle style={{ color: '#747474' }}>
                Credits Used
              </ChargeTitle>
              <ChargeAmount style={{ color: '#747474' }}>
                - {currencyFormatter.format(creditsUsed * 100 || 0)}
              </ChargeAmount>
            </ChargeSummaryLine>
          )}

          <ChargeSummaryLine style={{ marginBottom: '0px' }}>
            <ChargeTitle>Amount to be charged</ChargeTitle>
            <ChargeAmount>
              {currencyFormatter.format(
                (feeObject ? feeObject.newAmount : Invoice.amount) -
                  (useCredits ? creditsUsed || 0 : 0) * 100 || 0
              )}
            </ChargeAmount>
          </ChargeSummaryLine>
        </SeparateGroup>

        <PayButton
          loading={processing}
          onClick={async () => {
            setProcessing(true);
            const mutation = await createPayment({
              variables: {
                input: {
                  invoice: Invoice.id,
                  payment_method: selectedPaymentMethod?.id,
                  credits: creditsUsed * 100 || 0,
                  amount:
                    (feeObject ? feeObject.newAmount : Invoice.amount) -
                      (useCredits ? creditsUsed || 0 : 0) * 100 || 0,
                },
              },
            });

            setProcessing(false);
            if (mutation.data?.createPayment?.status === 'succeeded') {
              message.success('Payment succeeded!');
              updateInvoice({
                variables: {
                  id: Invoice.id,
                  status: 'complete',
                  adjustments,
                },
              });
            } else if (mutation.data?.createPayment?.status === 'pending') {
              message.success('Thanks for your payment!');
              updateInvoice({
                variables: {
                  id: Invoice.id,
                  status: 'processing',
                  adjustments,
                },
              });
            } else {
              message.error('Error paying invoice, please try again later.');
            }
            hideModal();
          }}
        >
          {processing && <LoadingOutlined />} Pay{' '}
          {currencyFormatter.format(
            (feeObject ? feeObject.newAmount : Invoice.amount) -
              (useCredits ? creditsUsed || 0 : 0) * 100 || 0
          )}
        </PayButton>
        {!isInline && (
          <FinePrintText>
            By clicking “Agree & Pay” you approve the invoice and authorize
            release of payment
          </FinePrintText>
        )}
      </div>
    </>
  );
}

ConfirmPaymentScreen.propTypes = {
  Invoice: PropTypes.object,
  isInline: PropTypes.bool,
  selectedPaymentMethod: PropTypes.object,
  user: PropTypes.object,
  setSelectedPaymentMethod: PropTypes.func,
};

ConfirmPaymentScreen.defaultProps = {
  Invoice: null,
  isInline: false,
  selectedPaymentMethod: {},
  user: null,
  setSelectedPaymentMethod: null,
};

export default ConfirmPaymentScreen;
