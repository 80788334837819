import styled from 'styled-components';

export const CollaboratorEditor = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Collaborator = styled.div`
  display: flex;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const CollaboratorAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const CollaboratorName = styled.span`
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
`;

export const CollabaratorWrapper = styled.span`
  float: left;
  width: 35%;
  padding-right: 10px;
`;
export const CollabaratorDropDown = styled.div``;
export const FooterWrapper = styled.div`
  text-align: right;
  margin-top: 20px;
`;

export const EnabledText = styled.span`
  color: #222222;
`;
export const BlankSpan = styled.span``;
export const BlankDiv = styled.div``;

export default {
  CollaboratorEditor,
  CollabaratorWrapper,
  CollabaratorDropDown,
  FooterWrapper,
  BlankSpan,
  BlankDiv,
  EnabledText,
};
