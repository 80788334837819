import { gql } from '@apollo/client';

export const createSetupIntentMutation = gql`
  mutation createSetupIntent($customerId: String) {
    createSetupIntent(customerId: $customerId) {
      clientSecret
    }
  }
`;

export const addPlaidPaymentMethodMutation = gql`
  mutation addPlaidPaymentMethod($token: String!, $accountId: String!) {
    addPlaidPaymentMethod(token: $token, accountId: $accountId) {
      id
      type
      last4
      bank_name
    }
  }
`;
