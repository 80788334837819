/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';
import DatePicker from '../../Generics/DatePicker';

import {
  Input,
  Label,
  InputGroup,
  SameLineInput,
  SeparateGroup,
} from '../../../CoreStyles/GeneralStyles';
import DropdownMenu from '../../Generics/Dropdown';

import {
  monthsShort,
  setPositions,
  dayOptionsWhole,
  dayOptions,
} from '../../../Enums';

function RecurringSettingsPanel({
  recurringRule,
  updateRecurringRule,
  recurringEnd,
  setRecurringEnd,
}) {
  return (
    <>
      <SeparateGroup backgroundEnabled withBorder>
        <InputGroup>
          <Label>Repeat</Label>
          <DropdownMenu
            value={recurringRule.freq}
            options={{
              yearly: {
                title: 'Yearly',
              },
              monthly: {
                title: 'Monthly',
              },
              weekly: {
                title: 'Weekly',
              },
            }}
            onChange={(newValue) => {
              updateRecurringRule('freq', newValue);
            }}
          />
        </InputGroup>
        {recurringRule.freq === 'yearly' && (
          <InputGroup>
            <Radio.Group
              onChange={(e) => {
                updateRecurringRule('year_type', e.target.value);
              }}
              value={recurringRule.year_type}
            >
              <Space direction="vertical">
                <Radio value="on_day" style={{ marginBottom: '10px' }}>
                  <SameLineInput>
                    <div style={{ marginRight: '10px' }}>On</div>
                    <DropdownMenu
                      value={recurringRule.bymonth}
                      disabled={recurringRule.year_type !== 'on_day'}
                      style={{ width: '100px', minWidth: '100px' }}
                      options={monthsShort}
                      onChange={(newValue) => {
                        updateRecurringRule('bymonth', parseInt(newValue, 10));
                      }}
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <Input
                        type="number"
                        onChange={(e) =>
                          updateRecurringRule(
                            'bymonthday',
                            parseInt(e.target.value, 10)
                          )
                        }
                        value={recurringRule.bymonthday}
                        width="80px"
                        marginLeft="10px"
                        min={1}
                        max={31}
                        disabled={recurringRule.year_type !== 'on_day'}
                      />
                    </div>
                  </SameLineInput>
                </Radio>
                <Radio value="on_the">
                  {' '}
                  <SameLineInput>
                    <div style={{ marginRight: '10px' }}>On the</div>

                    <DropdownMenu
                      value={recurringRule.bysetpos}
                      disabled={recurringRule.year_type !== 'on_the'}
                      style={{ width: '100px', minWidth: '100px' }}
                      options={setPositions}
                      onChange={(newValue) => {
                        updateRecurringRule('bysetpos', parseInt(newValue, 10));
                      }}
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <DropdownMenu
                        value={recurringRule.byweekday_temp}
                        disabled={recurringRule.year_type !== 'on_the'}
                        style={{ width: '150px', minWidth: '150px' }}
                        options={dayOptions}
                        onChange={(newValue) => {
                          updateRecurringRule('byweekday_temp', newValue);
                        }}
                      />
                    </div>
                    <div style={{ marginRight: '10px', marginLeft: '10px' }}>
                      Of
                    </div>
                    <DropdownMenu
                      value={recurringRule.bysetpos}
                      disabled={recurringRule.year_type !== 'on_the'}
                      style={{ width: '100px', minWidth: '100px' }}
                      options={monthsShort}
                      onChange={(newValue) => {
                        updateRecurringRule('bysetpos', parseInt(newValue, 10));
                      }}
                    />
                  </SameLineInput>
                </Radio>
              </Space>
            </Radio.Group>
          </InputGroup>
        )}
        {recurringRule.freq === 'monthly' && (
          <>
            <InputGroup>
              <Label>Every</Label>
              <SameLineInput>
                <Input
                  type="number"
                  onChange={(e) =>
                    updateRecurringRule(
                      'interval',
                      parseInt(e.target.value, 10)
                    )
                  }
                  value={recurringRule.interval}
                  width="80px"
                  marginRight="10px"
                  min={1}
                  max={31}
                />
                <div>Month(s)</div>
              </SameLineInput>
            </InputGroup>

            <InputGroup>
              <Radio.Group
                onChange={(e) => {
                  updateRecurringRule('month_type', e.target.value);
                }}
                value={recurringRule.month_type}
              >
                <Space direction="vertical">
                  <Radio value="on_day" style={{ marginBottom: '10px' }}>
                    <SameLineInput>
                      <div style={{ marginRight: '10px' }}>On day</div>
                      <Input
                        type="number"
                        onChange={(e) =>
                          updateRecurringRule(
                            'bymonthday',
                            parseInt(e.target.value, 10)
                          )
                        }
                        value={recurringRule.bymonthday}
                        width="80px"
                        marginLeft="10px"
                        min={1}
                        max={31}
                        disabled={recurringRule.month_type !== 'on_day'}
                      />
                    </SameLineInput>
                  </Radio>
                  <Radio value="on_the">
                    {' '}
                    <SameLineInput>
                      <div style={{ marginRight: '10px' }}>On the</div>
                      <DropdownMenu
                        style={{ width: '100px', minWidth: '100px' }}
                        value={recurringRule.bysetpos}
                        disabled={recurringRule.month_type !== 'on_the'}
                        options={setPositions}
                        onChange={(newValue) => {
                          updateRecurringRule(
                            'bysetpos',
                            parseInt(newValue, 10)
                          );
                        }}
                      />
                      <div style={{ marginLeft: '10px' }}>
                        <DropdownMenu
                          style={{ width: '150px', minWidth: '150px' }}
                          value={recurringRule.byweekday_temp}
                          disabled={recurringRule.month_type !== 'on_the'}
                          options={dayOptions}
                          onChange={(newValue) => {
                            updateRecurringRule('byweekday_temp', newValue);
                          }}
                        />
                      </div>
                    </SameLineInput>
                  </Radio>
                </Space>
              </Radio.Group>
            </InputGroup>
          </>
        )}
        {recurringRule.freq === 'weekly' && (
          <>
            <InputGroup>
              <Label>Every</Label>
              <SameLineInput>
                <Input
                  type="number"
                  onChange={(e) =>
                    updateRecurringRule(
                      'interval',
                      parseInt(e.target.value, 10)
                    )
                  }
                  value={recurringRule.interval}
                  width="80px"
                  marginRight="10px"
                  min={1}
                  max={31}
                />
                <div>Week(s)</div>
              </SameLineInput>
            </InputGroup>

            <InputGroup>
              <SameLineInput>
                <div style={{ marginRight: '10px' }}>On</div>
                <DropdownMenu
                  value={recurringRule.byweekday_temp}
                  options={dayOptionsWhole}
                  onChange={(newValue) => {
                    updateRecurringRule('byweekday_temp', newValue);
                  }}
                />
              </SameLineInput>
            </InputGroup>
          </>
        )}
      </SeparateGroup>

      <SeparateGroup backgroundEnabled withBorder>
        <InputGroup>
          <Label>End</Label>
          <DropdownMenu
            value={recurringEnd}
            options={{
              never: {
                title: 'Never',
              },
              on_date: {
                title: 'On date',
              },
              count: {
                title: 'After',
              },
            }}
            onChange={(newValue) => {
              setRecurringEnd(newValue);
            }}
          />
        </InputGroup>

        {recurringEnd === 'on_date' && (
          <InputGroup>
            <Label>End on</Label>
            <DatePicker
              value={recurringRule.until}
              updateValue={(date) => {
                updateRecurringRule('until', date);
              }}
            />
          </InputGroup>
        )}

        {recurringEnd === 'count' && (
          <SameLineInput>
            <div style={{ marginRight: '10px' }}>After</div>
            <Input
              type="number"
              onChange={(e) =>
                updateRecurringRule('count', parseInt(e.target.value, 10))
              }
              value={recurringRule.count}
              width="80px"
              marginRight="10px"
              min={1}
              max={31}
            />
            <div style={{ marginLeft: '10px' }}>sent invoices</div>
          </SameLineInput>
        )}
      </SeparateGroup>
    </>
  );
}

RecurringSettingsPanel.propTypes = {
  recurringRule: PropTypes.element,
  updateRecurringRule: PropTypes.func,
  recurringEnd: PropTypes.element,
  setRecurringEnd: PropTypes.func,
};
RecurringSettingsPanel.defaultProps = {
  recurringRule: null,
  updateRecurringRule: null,
  recurringEnd: null,
  setRecurringEnd: null,
};

export default RecurringSettingsPanel;
