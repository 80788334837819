import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
} from '../Payments/UserPaymentModalStyle';
import { Label, InputGroup, TextArea } from '../../../CoreStyles/GeneralStyles';
import DropdownMenu from '../../Generics/Dropdown';
import {
  PayButton,
  FinePrintText,
} from '../Payments/ConfirmPaymentScreenStyle';
import { hideModal } from '../state';

// TODO: This doesn't actually create a dispute rn
export default function CreateDispute({ Invoice }) {
  console.log('Dispute invoice is: ', Invoice);

  const [disputeReason, setDisputeReason] = useState('duplicate');

  return (
    <div>
      <ModalHeader>
        <ModalTitle>Dispute Payment</ModalTitle>
        <ModalCloseButton onClick={hideModal}>Close</ModalCloseButton>
      </ModalHeader>
      <InputGroup>
        <Label>Dispute Category</Label>
        <DropdownMenu
          value={disputeReason}
          onChange={setDisputeReason}
          options={{
            duplicate: {
              title: 'Duplicate Charge',
            },
            fraudulent: {
              title: 'Fraudulent Charge',
            },
            not_received: {
              title: 'Product Not Received',
            },
            unacceptable: {
              title: 'Product Unacceptable',
            },
          }}
        />
      </InputGroup>

      <InputGroup>
        <Label>Comments</Label>
        <TextArea
          width="100%"
          maxWidth="100%"
          type="text"
          name="invoice_title"
          placeholder="This was a duplicate charge. I paid for this item on (Date)"
        />
      </InputGroup>

      <PayButton>Continue with Dispute</PayButton>
      {/* TODO: Replace GrowthBird text here with org name */}
      <FinePrintText>
        By clicking &apos;Continue with Dispute&apos;, GrowthBird will be
        notified that a case has been opened. They will have 3 days to respond.
      </FinePrintText>
    </div>
  );
}

CreateDispute.propTypes = {
  Invoice: PropTypes.element,
};

CreateDispute.defaultProps = {
  Invoice: null,
};
