/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import {
  SummaryAmount,
  SummaryTitle,
  SummarySecondaryText,
  SummaryCardWrapper,
} from './SummaryCardStyle';
import { Card } from '../../../CoreStyles/GeneralStyles';

import { currencyFormatter, getQuarterRange } from '../../../Utils';

import { Mobile, Default } from '../../Devices';
import GET_SUMMARY_DATA from '../../../GraphQl/Queries/summaryQuery';

const dateRangeOptions = {
  'last-7': {
    title: 'Last 7 Days',
    fromDate: moment().subtract(7, 'd').toDate(),
    toDate: new Date(),
  },
  'last-14': {
    title: 'Last 14 Days',
    fromDate: moment().subtract(14, 'd').toDate(),
    toDate: new Date(),
  },
  'last-30': {
    title: 'Last 30 Days',
    fromDate: moment().subtract(30, 'd').toDate(),
    toDate: new Date(),
  },
  'last-60': {
    title: 'Last 60 Days',
    fromDate: moment().subtract(60, 'd').toDate(),
    toDate: new Date(),
  },
  'this-quarter': {
    title: `This Quarter (Q${moment().quarter()})`,
    fromDate: getQuarterRange(moment().quarter()).start.toDate(),
    toDate: getQuarterRange(moment().quarter()).end.toDate(),
  },
  'this-year': {
    title: `This Year (${moment().year()})`,
    fromDate: moment().startOf('year').toDate(),
    toDate: new Date(),
  },
};

export default function SummaryCard(props) {
  const { type, userId } = props;

  console.log('loaded summary card');

  const { error, loading, data } = useQuery(GET_SUMMARY_DATA, {
    variables: {
      type,
      startDate:
        dateRangeOptions[
          type === 'RECENT_ACTIVITY' ? 'last-14' : 'this-quarter'
        ].fromDate,
      endDate:
        dateRangeOptions[
          type === 'RECENT_ACTIVITY' ? 'last-14' : 'this-quarter'
        ].toDate,
      userId,
    },
    fetchPolicy: 'cache-and-network',
  });

  console.log(error, loading, data, 'truyturuyr');
  const summaryData = data?.summary;

  const types = {
    TOTAL_COLLECTED: {
      title: 'Total Collected',
      time_range: `Q${moment().quarter()}`,
    },
    AMOUNT_OWED: {
      title: 'Amout Owed',
      secondary_units: 'Invoices',
    },
    IN_PROGRESS: {
      title: 'In Progress',
      secondary_units: 'Invoices',
    },
    RECENT_ACTIVITY: {
      title: 'Recent Activity',
      time_range: 'Last 14 Days',
    },
  };

  return (
    <SummaryCardWrapper>
      <Default>
        {summaryData ? (
          <Link to={`/summary-page/${type}`}>
            <Card hover width="100%" padding="20px 20px">
              <SummaryTitle>{types[type]?.title || 'INVALID'}</SummaryTitle>
              {types[type]?.secondary_units && (
                <SummarySecondaryText>
                  {summaryData?.number} {types[type]?.secondary_units}
                </SummarySecondaryText>
              )}
              {types[type]?.time_range && (
                <SummarySecondaryText>
                  {types[type]?.time_range}
                </SummarySecondaryText>
              )}

              <SummaryAmount>
                {currencyFormatter.format(summaryData?.value)}
              </SummaryAmount>
            </Card>
          </Link>
        ) : (
          <Card width="100%" padding="20px 20px">
            <SummaryTitle>
              <Skeleton width={100} />
            </SummaryTitle>
            <SummarySecondaryText>
              <Skeleton width={60} />
            </SummarySecondaryText>
            <SummaryAmount>
              <Skeleton width={100} />
            </SummaryAmount>
          </Card>
        )}
      </Default>
      <Mobile>
        <Link to={`/summary-page/${type}`}>
          <Card marginRight="19px" width="185px" padding="10px 15px">
            <SummaryTitle>{types[type]?.title || 'INVALID'}</SummaryTitle>
            <SummarySecondaryText>
              {summaryData?.number} {summaryData?.number_units}
            </SummarySecondaryText>
            <SummaryAmount>
              {currencyFormatter.format(summaryData?.value)}
            </SummaryAmount>
          </Card>
        </Link>
      </Mobile>
    </SummaryCardWrapper>
  );
}
SummaryCard.propTypes = {
  type: PropTypes.string,
  userId: PropTypes.string,
};
SummaryCard.defaultProps = {
  type: '',
  userId: '',
};
