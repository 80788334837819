import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query user {
    currentUser {
      id
      name
      email
      customer_id
      image
      image_thumb
      last_login
      phone
      company
      credit_balance(organizationId: "1")
      default_payment_method
      payment_methods {
        id
        last4
        type
        exp_month
        exp_year
        card_brand
        bank_name
      }
      projects {
        id
        name
        total_size
        total_invoices_complete
        total_invoices_count
        current_user_role
        status {
          status
          total_amount
          invoice_count
        }
        users {
          id
          name
          image
          image_thumb
        }
      }
      organizations {
        id
        name
        entity_type
        platform_name
        platform_logo
        subdomain
      }
    }
  }
`;

export const GET_DATA_FOR_USER = gql`
  query getDataForUser($id: String!) {
    user(id: $id) {
      id
      name
      email
      customer_id
      image
      image_thumb
      last_login
      phone
      company
      total_spend
      credit_balance(organizationId: "1")
      projects {
        id
        name
        requested_user_role
      }
    }
  }
`;

export const GET_TOPBAR_DATA = gql`
  query userTopbarData {
    currentUser {
      id
      name
      email
      image
      image_thumb
      company
      credit_balance(organizationId: "1")
      organizations {
        platform_name
        platform_logo
        platform_logo_thumb
        name
        current_user_role
      }
    }
  }
`;

export const GET_USER_PAYMENT_METHODS = gql`
  query userPaymentMethods {
    currentUser {
      payment_methods {
        id
        last4
        type
        exp_month
        exp_year
        card_brand
        bank_name
      }
    }
  }
`;

export const GET_PAYMENT_METHODS_FOR_USER = gql`
  query userPaymentMethods($id: String!) {
    user(id: $id) {
      default_payment_method
      payment_methods {
        id
        last4
        type
        exp_month
        exp_year
        card_brand
        bank_name
      }
    }
  }
`;

export const GET_USER_PROJECTS = gql`
  query userProjects {
    currentUser {
      id
      projects {
        id
        name
        total_size
        total_invoices_complete
        total_invoices_count
        current_user_role
        status {
          status
          total_amount
          invoice_count
        }
        users {
          id
          name
          image_thumb
        }
      }
    }
  }
`;

export const LOAD_USER_ROLE_FOR_PROJECT = gql`
  query userProjectRole {
    currentUser {
      role
    }
  }
`;

export const GET_USERS_BY_EMAIL = gql`
  query findUserByEmail($email: String!) {
    findUserByEmail(email: $email) {
      id
      name
      email
      image
      customer_id
      payment_methods {
        id
        last4
        type
        exp_month
        exp_year
        card_brand
        bank_name
      }
    }
  }
`;

export const GET_PAYMENTS_FOR_USER = gql`
  query getPaymentsForUser($userId: String!) {
    getPaymentsForUser(userId: $userId) {
      id
      invoice
      date
      user_id
      status
      charges {
        id
        payment_id
        external_id
        payment_method
        amount
        status
      }
      payment_method
      amount
      credits_used
      total_spend
      credits_charged
    }
  }
`;

export const GET_PROJECTS_FOR_USER = gql`
  query getProjectsForUser($userId: String!) {
    user(id: $userId) {
      projects {
        id
        name
        requested_user_role
      }
    }
  }
`;

export default GET_CURRENT_USER;
