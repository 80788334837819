/* eslint-disable react/forbid-prop-types */
import { React } from 'react';
import PropTypes from 'prop-types';

import { getValuesForType } from './ChartUtils';

import { AmountTitle } from '../SummaryPageStyles';

import { currencyFormatter } from '../../../../Utils';

function Amount({ data, valueOptions }) {
  console.log('data is ', data);

  return (
    <div>
      {valueOptions.displayType === 'currency' &&
        currencyFormatter.format(
          getValuesForType(
            valueOptions.type,
            data?.invoices,
            valueOptions.key
          ) || 0
        )}

      {valueOptions.title && <AmountTitle> {valueOptions.title}</AmountTitle>}
    </div>
  );
}

Amount.propTypes = {
  data: PropTypes.any,
  valueOptions: PropTypes.any,
};

Amount.defaultProps = {
  data: null,
  valueOptions: {},
};

export default Amount;
