import styled from 'styled-components';

export const IntegrationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
`;
export const IntegrationDescription = styled.div`
  font-size: 14px;
`;

export const IntegrationTitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 10px;
`;

export const IntegrationMeta = styled.div`
  margin: 10px;
`;

export const IntegrationsGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  column-gap: 30px;
  row-gap: 35px;

  margin-bottom: 30px;
`;

export const IntegrationImage = styled.img`
  height: 35px;
`;

export const BlankDiv = styled.div``;
export const BlankSpan = styled.span``;
export default { IntegrationHeader, IntegrationDescription, BlankDiv };
