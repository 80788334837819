/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */

import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Page, Input } from '../../CoreStyles/GeneralStyles';
import { emailValidation } from '../../Utils';
import {
  InputContainer,
  InputTitle,
  // TextField,
} from '../../CoreStyles/InputStyles';
import {
  AuthLogo,
  AuthContainer,
  H1,
  InfoContainer,
  CardContainer,
  CardContainerLeft,
  CardContainerRight,
  PromoContainer,
  FooterContainer,
  PoweredbyContainer,
  PoweredByLogo,
  AuthCard,
  MobilePoweredbyContainer,
  AuthButtonContainer,
  P,
  AuthLink,
} from './AuthStyle';

import AloaPayLogoWhite from '../../images/aloapay-logo-white.svg';
import AloaPayLogoDark from '../../images/aloapay-logo.svg';

export default function ForgotPassword({ orgData }) {
  const [state, setState] = React.useState({
    email: '',
    errors: {
      email: '',
    },
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const { errors } = state;
    try {
      switch (name) {
        case 'email':
          errors.email = !emailValidation(value)
            ? 'Please use valid email.'
            : '';
          break;

        default:
          break;
      }
    } catch (er) {
      alert(er);
    }
    setState({ ...state, errors, [name]: value });
  };
  const handleSubmit = () => {};
  return (
    <Page backgroundColor="#F3F3F4" centered>
      <AuthContainer>
        <AuthLogo src={orgData?.platform_logo_thumb} />
        <AuthCard margin="auto" padding="35px 45px">
          <CardContainer>
            <CardContainerLeft>
              <H1>Forgot your password</H1>

              <InfoContainer>
                <InputContainer>
                  <P>
                    Please enter the email address you would like your password
                    reset information sent to.
                  </P>
                  <InputTitle>Enter email address</InputTitle>
                  {/* <TextField /> */}
                  <Input
                    placeholder="abc@gmail.com"
                    type="text"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                  />
                  {state.errors?.email?.length > 0 && (
                    <p style={{ color: 'red' }}>{state.errors?.email}</p>
                  )}
                </InputContainer>
              </InfoContainer>
              <AuthButtonContainer>
                <Button marginTop="10px" onClick={handleSubmit}>
                  Request reset link
                </Button>
                <AuthLink href="/login">Back to login</AuthLink>
              </AuthButtonContainer>
            </CardContainerLeft>
            <CardContainerRight>
              {orgData?.login_message_text && (
                <PromoContainer>{orgData?.login_message_text}</PromoContainer>
              )}
              {!orgData?.login_message_text && (
                <PromoContainer>
                  {orgData?.platform_name} uses AloaPay for secure and
                  simplified billing
                </PromoContainer>
              )}
              <FooterContainer>
                <PoweredbyContainer>
                  Powered by
                  <a href="https://aloapay.com">
                    <PoweredByLogo src={AloaPayLogoWhite} />
                  </a>
                </PoweredbyContainer>
              </FooterContainer>
            </CardContainerRight>
          </CardContainer>
        </AuthCard>
        <MobilePoweredbyContainer>
          Powered by
          <a href="https://aloapay.com">
            <PoweredByLogo src={AloaPayLogoDark} />
          </a>
        </MobilePoweredbyContainer>
      </AuthContainer>
    </Page>
  );
}

ForgotPassword.propTypes = {
  orgData: PropTypes.any,
};
