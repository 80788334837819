import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
    fontSize: 12,
    fontFamily: 'Open Sans',
    marginTop: '15px',
  },
  header: {
    fontFamily: 'Open Sans',
    color: '#666666',
  },
  billingInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    fontSize: 10,
  },
  text: {
    flexGrow: 0,
  },
});

export default function BillingInfo() {
  return (
    <View style={styles.container}>
      <View style={styles.billingInfoRow}>
        <Text style={styles.header}>Paid by</Text>
      </View>
      <View style={styles.billingInfoRow}>
        <Text style={styles.text}>Kelly White</Text>
      </View>
      <View style={styles.billingInfoRow}>
        <Text style={styles.text}>kelly.w@propertygoddess.com</Text>
      </View>
      <View style={styles.billingInfoRow}>
        <Text style={styles.text}>123 N Main Street</Text>
      </View>
      <View style={styles.billingInfoRow}>
        <Text style={styles.text}>Seattle, WA 98121</Text>
      </View>
    </View>
  );
}
