/* eslint-disable import/prefer-default-export */
import { RRule, rrulestr } from 'rrule';
import _ from 'lodash';
import moment from 'moment';

export const generateStringFromArrayofWeekdays = (array) => {
  if (_.isEqual(array, [RRule.MO.weekday])) {
    return 'monday';
  }
  if (_.isEqual(array, [RRule.TU.weekday])) {
    return 'tuesday';
  }
  if (_.isEqual(array, [RRule.WE.weekday])) {
    return 'wednesday';
  }
  if (_.isEqual(array, [RRule.TH.weekday])) {
    return 'thursday';
  }
  if (_.isEqual(array, [RRule.FR.weekday])) {
    return 'friday';
  }
  if (_.isEqual(array, [RRule.SA.weekday])) {
    return 'saturday';
  }
  if (_.isEqual(array, [RRule.SU.weekday])) {
    return 'sunday';
  }

  if (
    _.isEqual(array, [
      RRule.SU.weekday,
      RRule.MO.weekday,
      RRule.TU.weekday,
      RRule.WE.weekday,
      RRule.TH.weekday,
      RRule.FR.weekday,
      RRule.SA.weekday,
    ])
  ) {
    return 'day';
  }

  if (_.isEqual(array, [RRule.SU.weekday, RRule.SA.weekday])) {
    return 'weekend_day';
  }

  if (
    _.isEqual(array, [
      RRule.MO.weekday,
      RRule.TU.weekday,
      RRule.WE.weekday,
      RRule.TH.weekday,
      RRule.FR.weekday,
    ])
  ) {
    return 'weekday';
  }

  return '';
};

export const generateArrayOfWeekdayRulesFromString = (string) => {
  if (string === 'monday') {
    return [RRule.MO];
  }

  if (string === 'tuesday') {
    return [RRule.TU];
  }

  if (string === 'wednesday') {
    return [RRule.WE];
  }

  if (string === 'thursday') {
    return [RRule.TH];
  }

  if (string === 'friday') {
    return [RRule.FR];
  }

  if (string === 'saturday') {
    return [RRule.SA];
  }

  if (string === 'sunday') {
    return [RRule.SU];
  }

  if (string === 'day') {
    return [
      RRule.SU,
      RRule.MO,
      RRule.TU,
      RRule.WE,
      RRule.TH,
      RRule.FR,
      RRule.SA,
    ];
  }

  if (string === 'weekday') {
    return [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
  }

  if (string === 'weekend_day') {
    return [RRule.SU, RRule.SA];
  }

  return [];
};

export const generateRrule = ({ recurringRule, recurringEnd, startDate }) => {
  const options = {};
  if (recurringRule.freq === 'yearly') {
    options.freq = RRule.YEARLY;
    if (recurringRule.yearly_type === 'on_day') {
      options.bymonth = recurringRule.bymonth;
      options.bymonthday = recurringRule.bymonthday;
    }

    if (recurringRule.yearly_type === 'on_the') {
      options.bymonth = recurringRule.bymonth;
      options.bysetpos = recurringRule.bysetpos;
      options.byweekday = generateArrayOfWeekdayRulesFromString(
        recurringRule.byweekday_temp
      );
    }
  }

  if (recurringRule.freq === 'monthly') {
    options.freq = RRule.MONTHLY;

    options.interval = recurringRule.interval;

    if (recurringRule.month_type === 'on_day') {
      options.bymonthday = recurringRule.bymonthday;
    }

    if (recurringRule.month_type === 'on_the') {
      options.bysetpos = recurringRule.bysetpos;
      options.byweekday = generateArrayOfWeekdayRulesFromString(
        recurringRule.byweekday_temp
      );
    }
  }

  if (recurringRule.freq === 'weekly') {
    options.freq = RRule.WEEKLY;

    options.interval = recurringRule.interval;
    options.byweekday = generateArrayOfWeekdayRulesFromString(
      recurringRule.byweekday_temp
    );
  }

  if (recurringEnd === 'count') {
    options.count = recurringRule.count;
  }

  if (recurringEnd === 'on_date') {
    options.until = recurringRule.until;
  }

  options.dtstart = startDate;

  const rule = new RRule(options);

  console.log('rule: ', rule.toString());

  return [rule.toString()];
};

export const extractRuleFromInvoice = (invoice) => {
  let recurringEnd = 'never';

  if (invoice.recurrence) {
    const ruleObject = rrulestr(invoice.recurrence.join('/n')).options;
    const convertedRule = _.cloneDeep(ruleObject);

    console.log('convertedRule: ', convertedRule);

    if (ruleObject.freq === RRule.YEARLY) {
      convertedRule.freq = 'yearly';

      if (ruleObject.bysetpos != null) {
        // This is on_the
        convertedRule.yearly_type = 'on_the';
        convertedRule.byweekday_temp = generateStringFromArrayofWeekdays(
          ruleObject.byweekday
        );
      } else {
        convertedRule.yearly_type = 'on_day';
      }
    }

    if (ruleObject.freq === RRule.MONTHLY) {
      convertedRule.freq = 'monthly';

      if (ruleObject.bysetpos != null) {
        // This is on_the
        convertedRule.month_type = 'on_the';
        convertedRule.byweekday_temp = generateStringFromArrayofWeekdays(
          ruleObject.byweekday
        );
      } else {
        convertedRule.month_type = 'on_day';
      }
    }

    if (ruleObject.freq === RRule.WEEKLY) {
      convertedRule.freq = 'weekly';
      convertedRule.byweekday_temp = generateStringFromArrayofWeekdays(
        ruleObject.byweekday
      );
    }

    if (ruleObject.count !== null) {
      recurringEnd = 'count';
    }

    if (ruleObject.until !== null) {
      recurringEnd = 'on_date';
    }

    return { convertedRule, recurringEnd };
  }

  // Return the default
  return {
    convertedRule: {
      freq: 'monthly',
      bymonthday: 1,
      interval: 1,
      bysetpos: 1,
      byweekday_temp: 'monday',
      bymonth: 1,
      month_type: 'on_day',
      year_type: 'on_day',
    },
    recurringEnd,
  };
};

export const getValuesFromInvoice = (invoice) => {
  const { recurringEnd } = extractRuleFromInvoice(invoice);

  const rule = RRule.fromString(invoice.recurrence[0]);

  // Get the next invoice date
  let nextInvoiceDate = 'None';

  if (rule.after(new Date())) {
    nextInvoiceDate = moment(rule.after(new Date())).format('MMM D, YYYY');
  }

  let lastInvoiceDate = 'Never';

  // Get the last invoice date
  if (recurringEnd === 'on_date' || recurringEnd === 'count') {
    const allDates = rule.all();

    // Return the last one
    lastInvoiceDate = allDates[allDates.length - 1];
    lastInvoiceDate = moment(lastInvoiceDate).format('MMM D, YYYY');
  }

  // Convert rule to string
  const ruleString =
    rule.toText().charAt(0).toUpperCase() + rule.toText().slice(1);

  return {
    nextInvoiceDate,
    lastInvoiceDate,
    ruleString,
  };
};
