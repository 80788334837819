/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Menu from '../../Generics/Menu';
import {
  PaymentMethodContainer,
  PaymentMethodInfo,
  PaymentMethodActions,
  PaymentMethodImage,
  PaymentMethodInfoData,
  PaymentMethodInfoDataTitle,
  PaymentMethodInfoDataSecondary,
  PaymentMethodActionMenuItem,
  PaymentMethodDefaultIndiciator,
} from '../../Settings/Account Settings/AccountSettingsStyle';

import { cardOptions } from '../../../Enums';

import BankIcon from '../../../images/bank_icon.svg';
import { GET_PAYMENT_METHODS_FOR_USER } from '../../../GraphQl/Queries/usersQueries';

import UPDATE_USER_MUTATION from '../../../GraphQl/Mutations/updateUserMutation';
import createNotification from '../Notifications';
// TODO: this should not be here

export default function PaymentMethod({
  paymentMethod,
  default_payment_method,
  userId,
}) {
  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      createNotification('success', 'Default payment method changed');
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
    refetchQueries: [
      {
        query: GET_PAYMENT_METHODS_FOR_USER,
        variables: {
          id: userId,
        },
      },
    ],
  });

  const [editMenuActive, setEditMenuActive] = useState(false);
  return (
    <PaymentMethodContainer>
      <PaymentMethodInfo>
        {paymentMethod.type === 'card' && (
          <PaymentMethodImage
            src={cardOptions[paymentMethod.card_brand].icon}
            alt="Visa Icon"
          />
        )}

        {paymentMethod.type === 'bank_account' && (
          <PaymentMethodImage src={BankIcon} alt="Visa Icon" />
        )}

        <PaymentMethodInfoData>
          <PaymentMethodInfoDataTitle>
            {paymentMethod.type === 'card' &&
              cardOptions[paymentMethod.card_brand].title}
            {paymentMethod.type === 'bank_account' && 'Bank'} Ending in{' '}
            {paymentMethod.last4}
            {default_payment_method === paymentMethod.id && (
              <PaymentMethodDefaultIndiciator>
                Default
              </PaymentMethodDefaultIndiciator>
            )}
          </PaymentMethodInfoDataTitle>
          {paymentMethod.type === 'card' && (
            <PaymentMethodInfoDataSecondary>
              Expires{' '}
              {`${paymentMethod.exp_month}/${paymentMethod.exp_year % 100}`}
            </PaymentMethodInfoDataSecondary>
          )}
          {paymentMethod.type === 'bank_account' && (
            <PaymentMethodInfoDataSecondary>
              {paymentMethod.bank_name}
            </PaymentMethodInfoDataSecondary>
          )}
        </PaymentMethodInfoData>
      </PaymentMethodInfo>
      <PaymentMethodActions>
        <Menu isOpen={editMenuActive} setIsOpen={setEditMenuActive}>
          {default_payment_method !== paymentMethod.id && (
            <PaymentMethodActionMenuItem
              onClick={() => {
                updateUser({
                  variables: {
                    id: userId,
                    default_payment_method: paymentMethod.id,
                  },
                });
              }}
            >
              Set as Default
            </PaymentMethodActionMenuItem>
          )}

          <PaymentMethodActionMenuItem>Remove</PaymentMethodActionMenuItem>
        </Menu>
      </PaymentMethodActions>
    </PaymentMethodContainer>
  );
}

PaymentMethod.propTypes = {
  paymentMethod: PropTypes.element,
  default_payment_method: PropTypes.string,
  userId: PropTypes.string,
};

PaymentMethod.defaultProps = {
  paymentMethod: null,
  default_payment_method: null,
  userId: null,
};
