/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import { dialogState, setDialogType, update } from './state';
import dialogTypes from './model';
import AddNewPayment from './Payments/AddNewPayment';
import GET_CURRENT_USER from '../../GraphQl/Queries/usersQueries';
import ConfirmPaymentScreen from './Payments/ConfirmPaymentScreen';
import AddCreditCard from './Payments/AddCreditCard';
import AddBankAccount from './Payments/AddBankAccount';
import Refund from './Refunds/CreateRefund';
import ConfirmAutoPay from './AutoPay/ConfirmAutoPay';
import CreateDispute from './Disputes/CreateDispute';
import PDFViewer from './PDFs';

/**
 * AppDialog to walk user through main app actions
 */
export default function AppDialogContent({ inlineContext }) {
  const state = useReactiveVar(dialogState);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const { data: userData, refetch } = useQuery(GET_CURRENT_USER);

  useEffect(() => {
    if (state?.context?.paymentMethod) {
      setSelectedPaymentMethod(state.context.paymentMethod);
    } else if (userData?.currentUser?.default_payment_method) {
      const defaultPaymentMethodId =
        userData?.currentUser?.default_payment_method;
      const paymentMethods = userData?.currentUser?.payment_methods;

      const defaultPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.id === defaultPaymentMethodId
      );

      if (!defaultPaymentMethod) {
        setSelectedPaymentMethod(selectedPaymentMethod ?? paymentMethods[0]);
      } else {
        setSelectedPaymentMethod(defaultPaymentMethod);
      }
    } else if (
      userData?.currentUser?.payment_methods &&
      userData?.currentUser?.payment_methods.length !== 0
    ) {
      setSelectedPaymentMethod(
        selectedPaymentMethod ?? userData?.currentUser?.payment_methods[0]
      );
    }
  }, [userData?.currentUser]);

  // We render this content inline on the deep link invoice screen, and the context
  // is not passed through the reactive var. So we can check if the inlineContext is set
  // and render the content accordingly.
  const isInline = inlineContext != null;
  const invoice = isInline ? inlineContext.invoice : state.context?.invoice;

  return (
    <>
      {(state.type === dialogTypes.confirm_payment ||
        (isInline && !state.type)) && (
        <ConfirmPaymentScreen
          Invoice={invoice}
          isInline={isInline}
          setModalStatus={setDialogType}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setVisible={(visible) => update({ visible })}
          user={userData?.currentUser}
        />
      )}

      {state.type === dialogTypes.invoice_action && (
        // TODO: Invoice Actions
        <div>Invoice Action</div>
      )}

      {state.type === dialogTypes.use_credits && (
        // TODO: Do we need this? This might just be embedded in the payments
        <div>Use Credits</div>
      )}

      {state.type === dialogTypes.add_method && (
        <AddNewPayment onBack={state.callback} />
      )}

      {state.type === dialogTypes.add_credit_card && <AddCreditCard />}

      {state.type === dialogTypes.add_bank_account && (
        <AddBankAccount
          onComplete={(paymentMethod) => {
            // I need to add something to kick the user back to the auto pay screen if it was auto pay
            // right now defaulting to the payment method screen
            setDialogType(dialogTypes.confirm_payment);
            refetch();
            if (paymentMethod) {
              setSelectedPaymentMethod(paymentMethod);
            }
          }}
        />
      )}

      {state.type === dialogTypes.refund && <Refund invoice={invoice} />}

      {state.type === dialogTypes.confirm_autopay && (
        <ConfirmAutoPay
          selectedPaymentMethod={selectedPaymentMethod}
          projectId={state.context.projectId}
          user={state.context.user}
          updateAutoPaySettings={state.context.updateAutoPaySettings}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
      )}

      {state.type === dialogTypes.dispute && (
        <CreateDispute invoice={invoice} />
      )}

      {state.type === dialogTypes.pdf && (
        <PDFViewer type={state.context.type} invoice={invoice} />
      )}
    </>
  );
}

AppDialogContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inlineContext: PropTypes.object,
};

AppDialogContent.defaultProps = {
  inlineContext: null,
};
