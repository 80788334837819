/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DropdownArrow } from '../../assets/svg';
import ModalPopUp from '../Common/ModalPopUp';
import {
  Topbar,
  Logo,
  AccountInfo,
  UserInfo,
  Avatar,
  UserInfoCompany,
  UserInfoName,
  TopbarDiv,
} from './TopbarStyle';
// import { Default, Mobile } from '../Devices';
import { GET_TOPBAR_DATA } from '../../GraphQl/Queries/usersQueries';
import auth from '../../Auth';
// import { TextLink } from '../../CoreStyles/GeneralStyles';

import GetHelp from './GetHelp';

import { currencyFormatter, OrgContext, isAllowed } from '../../Utils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TopBar = () => {
  const { data } = useQuery(GET_TOPBAR_DATA);

  const [helpVisible, setHelpVisible] = useState(false);

  const [state, setState] = useState({
    userName: data && data?.currentUser?.name,
    orgId: '',
    alldata: data?.currentUser,
  });

  useEffect(() => {
    setState({
      ...state,
      userName: data && data?.currentUser?.name,
    });
  }, []);

  const accountPath = window?.location?.pathname?.match(
    /^\/account-settings\/*$/
  );

  const paymentHistoryPath = window?.location?.pathname?.match(
    /^\/payment-history\/*$/
  );

  const workspacePath = window?.location?.pathname?.match(
    /^\/workspace-settings\/*$/
  );

  const usersPath = window?.location?.pathname?.match(/^\/users\/*$/);

  const history = useHistory();

  const orgData = useContext(OrgContext);

  return (
    <>
      <ModalPopUp
        className="payment-modal"
        width="50%"
        // closeIcon="Close"
        // closable={newProject}
        // maskClosable={false}
        title={null}
        closeIcon={null}
        header={null}
        closable={false}
        maskClosable={false}
        centered
        visible={helpVisible}
        isOk={() => {
          setHelpVisible(false);
        }}
        isCancel={() => setHelpVisible(false)}
        footer={null}
        content={
          <GetHelp
            onClose={() => {
              setHelpVisible(false);
            }}
          />
        }
      />
      <Topbar className="nav-hidden">
        <Logo src={orgData.platform_logo_thumb} />
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button>
              <AccountInfo>
                <UserInfo>
                  <UserInfoName>{data?.currentUser?.name}</UserInfoName>
                  <UserInfoCompany>
                    {data?.currentUser?.company}
                  </UserInfoCompany>
                </UserInfo>
                <Avatar src={data?.currentUser?.image_thumb} />
                <DropdownArrow className="dropdown-icon" />
              </AccountInfo>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-non z-50">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        history.push('/account-settings');
                      }}
                      className={classNames(
                        active || accountPath
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-700',
                        'block px-4 py-2 text-sm cursor-pointer'
                      )}
                    >
                      Account settings
                    </div>
                  )}
                </Menu.Item>

                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        history.push('/payment-history');
                      }}
                      className={classNames(
                        active || paymentHistoryPath
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-700',
                        'block px-4 py-2 text-sm cursor-pointer'
                      )}
                    >
                      Payment History
                    </div>
                  )}
                </Menu.Item>

                {isAllowed(
                  orgData?.current_user_role,
                  'workspace_settings.read'
                ) && (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          history.push('/workspace-settings');
                        }}
                        className={classNames(
                          active || workspacePath
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Workspace Settings
                      </div>
                    )}
                  </Menu.Item>
                )}

                {isAllowed(
                  orgData?.current_user_role,
                  'client_management.read'
                ) && (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          history.push('/users');
                        }}
                        className={classNames(
                          active || usersPath
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                      >
                        Manage Clients
                      </div>
                    )}
                  </Menu.Item>
                )}

                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        setHelpVisible(true);
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm cursor-pointer'
                      )}
                    >
                      Get Help
                    </div>
                  )}
                </Menu.Item>
              </div>
              <div className="px-4 py-3">
                <p className="text-sm">Credit Balance</p>
                <p className="text-sm font-medium text-gray-900 truncate">
                  {currencyFormatter.format(
                    data?.currentUser?.credit_balance || 0
                  )}
                </p>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        auth.logout();
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                    >
                      Sign out
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </Topbar>
      <TopbarDiv className="mb-header d-mob-block d-none">
        <TopbarDiv className="header-mob">
          <Avatar src="https://storage.googleapis.com/hermes-50f48.appspot.com/bM2j3eZl6kMIfIBQTf64v0pZkHt1/n8otzwhvpccpqcmk6ghri.jpg" />
          <TopbarDiv>
            <Logo src={orgData.platform_logo_thumb} />
          </TopbarDiv>
          <TopbarDiv>
            <a href="/">Get Help</a>
          </TopbarDiv>
        </TopbarDiv>
      </TopbarDiv>
    </>
  );
};

TopBar.propTypes = {};
TopBar.defaultProps = {};
export default TopBar;
