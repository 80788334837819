import React from 'react';
import { Redirect } from 'react-router-dom';
import { getStateAfterOauth } from '../api/plaid/helpers';
import {
  showConfirmPayment,
  showAddBankAccount,
  hideModal,
  setInline,
} from './AppDialog/state';

export default function PlaidRedirect() {
  const previousState = getStateAfterOauth();

  if (previousState) {
    let callback = hideModal;

    if (previousState.invoice) {
      callback = (paymentMethod) =>
        showConfirmPayment(previousState.invoice, paymentMethod);
    }

    if (previousState.path.includes('/invoice/')) {
      setInline(true);
    }

    showAddBankAccount(previousState.linkToken, window.location.href, callback);

    return <Redirect to={previousState.path} />;
  }

  return <Redirect to="/" />;
}
