import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Button,
  InputGroup,
  Label,
  TextArea,
} from '../../../CoreStyles/GeneralStyles';
import {
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
} from '../Payments/UserPaymentModalStyle';
import DropdownMenu from '../../Generics/Dropdown';
import CurrencyInput from '../../Generics/Input/CurrencyInput';
import { ModalFooter } from '../../../CoreStyles/ModalStyles';
import { hideModal } from '../state';

export default function CreateRefund({ invoice }) {
  const [refundObject, setRefundObject] = useState({
    amount: invoice.amount / 100,
  });

  function updateRefundObject(key, value) {
    const refundObjectTemp = _.cloneDeep(refundObject);

    refundObjectTemp[key] = value;

    setRefundObject(refundObjectTemp);
  }

  return (
    <div>
      <ModalHeader>
        <ModalTitle>Refund Invoice</ModalTitle>
        <ModalCloseButton onClick={hideModal}>Close</ModalCloseButton>
      </ModalHeader>

      <InputGroup>
        <Label>Amount</Label>
        <CurrencyInput
          onChange={(e) =>
            // Restrict credits used to be >= 0 and <= Invoice.amount
            updateRefundObject(
              'amount',
              Math.abs(parseFloat(e.target.value, 10))
            )
          }
          value={refundObject.amount}
          placeholder="0.00"
          customStyle={{ width: '150px' }}
          minValue={0}
        />
      </InputGroup>

      <InputGroup>
        <Label>Reason</Label>
        <DropdownMenu
          value={refundObject.type}
          options={{
            duplicate: {
              title: 'Duplicate',
            },
            fraudulent: {
              title: 'Fraudulent',
            },
            requested: {
              title: 'Requested by customer',
            },
            other: {
              title: 'Other',
            },
          }}
          onChange={(newValue) => {
            updateRefundObject('type', newValue);
          }}
        />
      </InputGroup>

      <InputGroup>
        <Label>Notes</Label>
        <TextArea
          maxWidth="400px"
          placeholder="Thank you for referring that client!"
          rows={6}
          value={refundObject.notes}
          onChange={(e) =>
            // Restrict credits used to be >= 0 and <= Invoice.amount
            updateRefundObject('notes', e.target.value)
          }
        />
      </InputGroup>

      <ModalFooter>
        <Button
          onClick={() => {
            console.log('create refund!');
          }}
        >
          Refund
        </Button>
      </ModalFooter>
    </div>
  );
}

CreateRefund.propTypes = {
  invoice: PropTypes.element,
};

CreateRefund.defaultProps = {
  invoice: null,
};
