import styled, { css } from 'styled-components';

// export const TopButton = styled.button`
//   background: none;
//   border: none;
//   box-sizing: border-box;
//   box-shadow: none;
//   border-radius: 5px;
//   font-family: Avenir Next;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   text-align: center;
//   cursor: pointer;
//   outline: none;
//   margin-bottom: ${(props) =>
//     props.marginBottom ? props.marginBottom : '26px'};
//   svg {
//     width: 16px;
//     height: 16px;
//     margin-right: 10px;
//   }
// `;
export const MainConatiner = styled.div`
  font-family: Avenir Next;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (max-width: 1199px) {
    margin-left: 10%;
    margin-right: 10%;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: 991px) {
    margin-left: 5%;
    margin-right: 5%;
  }
  @media screen and (max-width: 767px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;
export const CommonRow = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  @media screen and (max-width: 1199px) {
    margin-left: -20px;
    margin-right: -20px;
  }
`;
export const CommonRow1 = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
`;
export const CommonCol = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  ${(props) =>
    props.col4 &&
    css`
      width: 30%;
    `}
  ${(props) =>
    props.col8 &&
    css`
      width: 70%;
    `}
  @media screen and (max-width:1199px) {
    padding-left: 20px;
    padding-right: 20px;
    ${(props) =>
      props.col4 &&
      css`
        width: 35%;
      `}
    ${(props) =>
      props.col8 &&
      css`
        width: 65%;
      `}
  }
`;

export const CommonCol1 = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  width: ${(props) => (props.width ? props.width : '')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '')};
  @media screen and (max-width: 757px) {
    ${(props) =>
      props.width === '50%' &&
      css`
        width: 100%;
        margin-bottom: 25px;
      `}
  }
`;
export const PageTitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  margin-bottom: 15px;
  color: #262626;

  @media (max-width: 768px) {
    font-size: 17px;
    margin-bottom: 10px;
  }

  ${(props) =>
    props.withSearch &&
    css`
      margin-top: auto;
      margin-bottom: auto;
    `}
`;
export const ProfileList = styled.div`
  font-family: Avenir Next;
  display: flex;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  cursor: ${(props) => (props.cursor ? props.cursor : '')};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
`;
// export const BlankDiv = styled.div`
//   margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
//   margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
//   padding-bottom: ${(props) =>
//     props.paddingBottom ? props.paddingBottom : '0'};
//   padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '0')};
//   width: ${(props) => (props.width ? props.width : '')};
// `;
export const CommonBtn = styled.button`
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(207 207 207 / 25%);
  border-radius: 10px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 14px 12px;
  margin: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background: #ffffff;
  color: #313131;
  border: 1px solid #d8d8d8;
  width: 100%;
  ${(props) =>
    props.blue &&
    css`
      background: #0d6cff;
      color: #fff;
    `}
  width: ${(props) => (props.width ? props.width : '')};
`;
export const CardList = styled.div`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(165, 165, 165, 0.25);
  border-radius: 10px;
  padding: ${(props) => (props.padding ? props.padding : '16px 18px')};
  width: ${(props) => (props.width ? props.width : '')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '')};
  @media screen and (max-width: 767px) {
    border-radius: 7px;
  }

  cursor: pointer;
  ${(props) =>
    props.methodList &&
    css`
      border: none;
      box-shadow: none;
      padding: 16px 18px 16px 30px;
      margin-right: ${() => (props.marginRight ? props.marginRight : '0')};
      margin-left: ${() => (props.marginLeft ? props.marginLeft : '0')};
    `}

  &:hover {
    background-color: #ececec;

    .add-payment-method-icon {
      color: #2b2b2b;
      background-color: #f9f9f9;
    }
  }
`;
export const MethodListMain = styled.div`
  position: relative;
  input {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  input:checked ~ div {
    background: rgba(13, 108, 255, 0.08);
  }
  input:checked ~ span > svg {
    opacity: 1;
  }

  input:not(checked) ~ div {
    &:hover {
      background: rgba(13, 108, 255, 0.08);
    }
  }
`;
export const InputSpan = styled.span`
  position: absolute;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  top: 20px;
  background: #ffffff;
  border: 1px solid #c5c5c5;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  svg {
    fill: #fff;
    width: 17px;
    height: 17px;
    position: relative;
    left: -1px;
    top: -1px;
    opacity: 0;
  }
`;
export const IconBg = styled.span`
  background: #e0e0e0;
  border-radius: 6.25px;
  display: inline-block;
  padding: 4px 8px;
`;
export const CustomCheckbox = styled.div`
  position: relative;
  input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  span {
    margin-left: 8px;
    padding-left: 20px;
  }
  span:after {
    content: '';
    position: absolute;
    height: 17px;
    width: 17px;
    left: 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    top: 3px;
  }
  input:checked ~ span:after {
    background: #0d6cff;
    border-color: #0d6cff;
  }
  span:before {
    content: '';
    position: absolute;
    left: 3px;
    height: 4px;
    width: 10px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    top: 9px;
    opacity: 0;
    z-index: 1;
  }
  input:checked ~ span:before {
    opacity: 1;
  }
`;
export const NewInvoiceWrapper = styled.div`
  text-align: right;
`;
export const PayeeWrapper = styled.div`
  padding-bottom: 10px;
`;
export const SkeletonDiv = styled.div``;
export const SkeletonSpan = styled.span`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
`;
