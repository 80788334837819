import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 10px 0 20px 0;
  @media (max-width: 767px) {
    padding: 5px 0 30px;
  }
`;

export const SettingsPageLayout = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: 20px;
`;

export const SettingsSideBar = styled.div``;

export const SettingsSideBarItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-top: 4px;
  cursor: pointer;

  border-radius: 9px;

  &:hover {
    background-color: #ffffff;
  }
`;

export const SettingsSideBarIcon = styled.div``;

export const SettingsSideBarText = styled.div`
  font-weight: 400;
  color: rgb(17, 24, 39);
  margin-left: 12px;
  font-size: 14px;
`;

export const SwitchWrapper = styled.div`
  margin: 0 20px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #737373;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const SwitchWrapperMob = styled.div`
  @media screen and (max-width: 767px) {
    margin: 15px 0px 0px 0px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #737373;
    margin-bottom: 15px;
  }
`;
export const ErrorWrapper = styled.div`
  color: red;
`;
export const H2Heading = styled.h2`
  font-size: 18px;
  margin-bottom: 0px;
`;
export const HeaderWrapper = styled.div`
  margin-left: 15%;
  margin-top: 20px;
`;
