import styled from 'styled-components';
import { Card } from '../../CoreStyles/GeneralStyles';

export const PlatformSettingsCard = styled(Card)`
  display: block;
  padding: 0px;

  margin-bottom: 40px;
  @media (max-width: 980px) {
    width: 90%;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const PlatformSettingsContent = styled.div`
  padding: 15px 30px 0px 30px;
`;

export const Form = styled.form`
  margin: 20px 0;
`;
export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const UploadedImage = styled.span`
  padding: 3px;
`;
export const PlatformLogo = styled.img``;

export const PlatformButton = styled.button`
  color: '#C0C0C0';
  margin-left: '20px';
  border: 'none';
`;
export const SwitchText = styled.span``;

export const Dropdown = styled.select``;
export const DropdownOption = styled.option``;
export const CustomDropDown = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(208 208 208 / 25%);
  border-radius: 6px;
  width: 50%;
  max-width: 360px;
  select {
    border: none;
    width: 100%;
    padding: 7px 17px;
    border-radius: 6px;
    -webkit-appearance: none;
    color: #5e5e5e;
  }
  svg {
    position: absolute;
    right: 11px;
    top: 13px;
  }
`;

export const EmptyImage = styled.div`
  border-radius: 8px;
  border-style: dashed !important;
  border: 1px solid;
  text-align: center;
  padding-top: 20px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  color: #aaa;
`;
