import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Card,
  TableNameTitle,
  BlueText,
  Label,
  TextValue,
  Button,
} from '../../CoreStyles/GeneralStyles';
import { currencyFormatter, isAllowed, OrgContext } from '../../Utils';
import { createNotification } from '../Common/Notifications';
import Menu from '../Generics/Menu';

import { PaymentMethodActionMenuItem } from '../Settings/Account Settings/AccountSettingsStyle';

import { UserNameLabel, UserMetaContainer } from './UserInfoCardStyle';

import Avatar from '../Generics/Avatar';

import { REMOVE_USER_FROM_ORG } from '../../GraphQl/Mutations/orgMutations';

import { Default, Mobile } from '../Devices';

import EditUserInfo from './EditUserInfo';

export default function UserInfoCard({ userInfo }) {
  const orgData = useContext(OrgContext);
  const [editMenuActive, setEditMenuActive] = useState(false);
  const [updateUserActive, setUpdateUserActive] = useState(false);

  const [removeFromOrg] = useMutation(REMOVE_USER_FROM_ORG, {
    onCompleted: () => {
      console.log('success');
      createNotification('success', 'User Removed');
      setEditMenuActive(false);
    },
    onError: (errors) => {
      console.log('errors: ', errors);
    },
  });

  if (userInfo == null) {
    return null;
  }
  return (
    <>
      <Default>
        <Card
          style={{ position: 'sticky', top: '30px' }}
          padding="20px 30px"
          width="100%"
        >
          <UserMetaContainer>
            <Avatar user={userInfo} marginRight="10px" />

            <UserNameLabel>{userInfo?.name}</UserNameLabel>
          </UserMetaContainer>

          {userInfo?.company && (
            <>
              <Label color="#6b7280" marginTop="3%">
                Company
              </Label>
              <TextValue>{userInfo?.company}</TextValue>
            </>
          )}

          <Label color="#6b7280" marginTop="3%">
            Email
          </Label>
          <TextValue color="#2f81ff">{userInfo?.email}</TextValue>
          {userInfo?.phone && (
            <>
              <Label color="#6b7280" marginTop="3%">
                Phone
              </Label>
              <TextValue color="#2f81ff">{userInfo?.phone}</TextValue>
            </>
          )}

          <Label color="#6b7280" marginTop="3%">
            Total Spent
          </Label>
          <TextValue color="#008503">
            {' '}
            {currencyFormatter.format(userInfo?.total_spend || 0)}
          </TextValue>

          <Label color="#6b7280" marginTop="3%">
            Credit Balance
          </Label>
          <TextValue color="#008503">
            {' '}
            {currencyFormatter.format(userInfo?.credit_balance || 0)}
          </TextValue>
          <EditUserInfo
            editing={updateUserActive}
            setEditing={setUpdateUserActive}
            userInfo={userInfo}
          />
          {isAllowed(orgData?.current_user_role, 'client_management.edit') && (
            <Menu
              style={{ width: '100%' }}
              button={
                <Button
                  thin
                  float="right"
                  padding="0px"
                  onClick={() => {
                    // We are editing
                    setEditMenuActive(!editMenuActive);
                  }}
                >
                  Edit
                </Button>
              }
              isOpen={editMenuActive}
              setIsOpen={setEditMenuActive}
            >
              {isAllowed(
                orgData?.current_user_role,
                'client_management.edit_info.edit'
              ) && (
                <PaymentMethodActionMenuItem
                  onClick={() => {
                    setUpdateUserActive(true);
                  }}
                >
                  Edit info
                </PaymentMethodActionMenuItem>
              )}

              {isAllowed(
                orgData?.current_user_role,
                'client_management.change_email.edit'
              ) && (
                <PaymentMethodActionMenuItem>
                  Change Email
                </PaymentMethodActionMenuItem>
              )}

              {isAllowed(
                orgData?.current_user_role,
                'client_management.password_reset.edit'
              ) && (
                <PaymentMethodActionMenuItem>
                  Reset password
                </PaymentMethodActionMenuItem>
              )}

              {isAllowed(
                orgData?.current_user_role,
                'client_management.remove_user.edit'
              ) && (
                <PaymentMethodActionMenuItem
                  onClick={() => {
                    removeFromOrg({
                      variables: {
                        userId: userInfo?.id,
                        orgId: '1',
                      },
                    });
                  }}
                >
                  Remove from organization
                </PaymentMethodActionMenuItem>
              )}
            </Menu>
          )}
        </Card>
      </Default>
      <Mobile>
        <Card display="grid" marginTop="30px" padding="7% 0 7% 7%" width="100%">
          <Card
            width="100%"
            marginBottom="1px"
            padding="0px 0px 5px 0px"
            noBorder
          >
            <Avatar
              maxLength={3}
              excessItemsStyle={{
                color: '#000000',
                backgroundColor: '#F0F0F0',
                fontSize: '10px',
              }}
              size="large"
            />
            <TableNameTitle
              marginLeft="15px"
              display="inline-block"
              fontSize="20px"
              fontWeight="600"
            >
              {userInfo.customerName}
            </TableNameTitle>
            <TableNameTitle
              fontSize="14px"
              fontWeight="500"
              marginLeft="47px"
              marginTop="-7%"
            >
              {userInfo.company}
            </TableNameTitle>
          </Card>
          <BlueText marginTop="5px">{userInfo.email}</BlueText>
          <BlueText marginTop="5px">{userInfo.phone}</BlueText>
        </Card>
      </Mobile>
    </>
  );
}

UserInfoCard.propTypes = {
  userInfo: PropTypes.objectOf(PropTypes.string),
};

UserInfoCard.defaultProps = {
  userInfo: null,
};
