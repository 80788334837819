import styled from 'styled-components';

export const CompanyForm = styled.form`
  margin: 20px 0;
`;

export const ContactSupportMessage = styled.div``;
export const EmailLink = styled.a`
  color: blue;
`;

export default { CompanyForm };
