/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const UserNameLabel = styled.div`
  font-size: 25px;
`;

export const UserMetaContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
