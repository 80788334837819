import { gql } from '@apollo/client';

export const CREATE_INVOICE_MUTATION = gql`
  mutation createInvoice(
    $project_id: String
    $name: String
    $amount: Int
    $due_date: Timestamp
    $invoice_date: Timestamp
    $status: String
    $description: String
    $decline_reason: String
    $recurring: Boolean
    $net_d: Int
    $date_created: Timestamp
    $line_items: [UpdateLineItemInput]
    $last_status_change_date: Timestamp
  ) {
    createInvoice(
      input: {
        name: $name
        amount: $amount
        due_date: $due_date
        invoice_date: $invoice_date
        status: $status
        description: $description
        decline_reason: $decline_reason
        recurring: $recurring
        net_d: $net_d
        date_created: $date_created
        line_items: $line_items
        project_id: $project_id
        last_status_change_date: $last_status_change_date
      }
    ) {
      id
      name
      amount
      due_date
      invoice_date
      status
      description
      decline_reason
      project_id
      recurring
      net_d
      date_created
      last_status_change_date
      line_items {
        id
        quantity
        rate
        description
      }
    }
  }
`;
export default CREATE_INVOICE_MUTATION;
