/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowRightIcon,
} from '@heroicons/react/outline';

import { Card, SectionTitle, Button } from '../../CoreStyles/GeneralStyles';

import {
  ProjectRow,
  ProjectName,
  ProjectRole,
  ViewButton,
  ProjectMeta,
} from './UserProjCardStyle';
import { projectRoles } from '../../Enums';
import { GET_PROJECTS_FOR_USER } from '../../GraphQl/Queries/usersQueries';

export default function UserProjCard({ userId }) {
  const history = useHistory();

  const { loading, data } = useQuery(GET_PROJECTS_FOR_USER, {
    variables: { userId },
  });

  const [expanded, setExpanded] = useState(false);

  console.log('DAAATA ', data);

  return (
    <>
      <SectionTitle marginBottom="1%">Projects</SectionTitle>

      <Card width="100%" padding="20px 25px">
        {!loading &&
          (!data?.user?.projects || data?.user?.projects?.length === 0) && (
            <div>No Projects</div>
          )}

        {!loading &&
          data?.user?.projects?.length !== 0 &&
          data?.user?.projects
            ?.slice(0, expanded ? data?.user?.projects?.length : 3)
            .map((project) => (
              <ProjectRow>
                <ProjectMeta>
                  <ProjectName>{project.name}</ProjectName>
                  <ProjectRole>
                    {projectRoles[project.requested_user_role]?.title}
                  </ProjectRole>
                </ProjectMeta>

                <ViewButton
                  onClick={() => {
                    history.push(`/project/${project.id}`);
                  }}
                >
                  View Project
                  <ArrowRightIcon className="thin-button-right-icon" />
                </ViewButton>
              </ProjectRow>
            ))}

        {loading && <Skeleton count={3} />}

        {!loading && data?.user?.projects && data?.user?.projects?.length > 3 && (
          <Button
            onClick={() => {
              setExpanded(!expanded);
            }}
            thin
            withRightIcon
            centered
          >
            {expanded && <span>View Less</span>}
            {!expanded && <span>View More</span>}
            {expanded && <ChevronUpIcon className="thin-button-right-icon" />}
            {!expanded && (
              <ChevronDownIcon className="thin-button-right-icon" />
            )}
          </Button>
        )}
      </Card>
    </>
  );
}

UserProjCard.propTypes = {
  userId: PropTypes.string,
};

UserProjCard.defaultProps = {
  userId: null,
};
