import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';

import { SearchIcon } from '@heroicons/react/solid';

import {
  Button,
  SectionTitle,
  Section,
  Page,
  SearchBox,
  SearchInput,
  SearchWideMobile,
  SearchInputMobile,
  SectionActions,
  SectionTitleHeader,
  SearchIconContainer,
} from '../../CoreStyles/GeneralStyles';

import BackButton from '../Common/BackButton';

import InviteUserModal from '../Settings/ManageTeam/InviteUserModal';

import SearchIconSvg from '../../images/search-icon.svg';
import UsersRowCard from './UsersRowCard';
import UserRowSkeleton from './UserRowSkeleton';
import { Mobile, Default } from '../Devices';
import { GET_ORGANIZATION_CLIENTS } from '../../GraphQl/Queries/organizationsQuery';

import {
  TableCard,
  TableHeader,
  TableHead,
  TableBody,
  Table,
  TableFooter,
  FooterLeft,
} from '../../CoreStyles/TableStyles';

import { isAllowed, OrgContext } from '../../Utils';

export default function UserManagement() {
  const orgData = useContext(OrgContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);

  const [inviteVisible, setInviteVisible] = useState(false);

  const { loading, data } = useQuery(GET_ORGANIZATION_CLIENTS, {
    variables: { id: '1' },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.organization?.clients) {
      const tempUsers = [...data?.organization?.clients];

      if (data?.organization?.client_invites) {
        data?.organization?.client_invites.forEach((invite) => {
          const invitedUser = {
            id: invite.id,
            email: invite.email,
            name: '',
            role: invite.role,
            invite: true,
          };

          tempUsers.push(invitedUser);
        });
      }

      setUsers(tempUsers);
    }
  }, [data]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchedUsers = users.filter((user) =>
    user?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Page gridTemplateColumns="25% 25% 25% 25%" singleColumnWide typeTwo>
      <BackButton path="/" text="Dashboard" />
      {isAllowed(orgData?.current_user_role, 'client_management.read') && (
        <Section padding="0px" style={{ gridArea: 'main' }} width="100%">
          <InviteUserModal
            isVisible={inviteVisible}
            setIsVisible={setInviteVisible}
            hardcodedRole="client"
          />
          <Mobile>
            <SearchWideMobile>
              <SearchInputMobile
                placeholder="Search Users (Clients)"
                onChange={handleSearch}
                value={searchQuery}
                isFocused
              />
              <SearchIcon searchWide src={SearchIconSvg} />
            </SearchWideMobile>
            <SectionTitle marginTop="10%">Users (Clients)</SectionTitle>
            {searchedUsers.map((user, index) => (
              <UsersRowCard user={user} index={index} />
            ))}
          </Mobile>
          <Default>
            <SectionTitleHeader>
              <SectionTitle marginBottom="0px">
                Manage Users (clients)
              </SectionTitle>
              <SectionActions>
                <SearchBox margin="0px 15px 0px 0px">
                  <SearchIconContainer>
                    <SearchIcon className="search-icon" />
                  </SearchIconContainer>
                  <SearchInput
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    placeholder="Search Clients"
                  />
                </SearchBox>
                {isAllowed(
                  orgData?.current_user_role,
                  'client_management.invite.edit'
                ) && (
                  <Button
                    onClick={() => {
                      setInviteVisible(true);
                    }}
                    primary
                  >
                    Invite Client
                  </Button>
                )}
              </SectionActions>
            </SectionTitleHeader>
            <TableCard>
              <Table>
                <TableHead>
                  <tr>
                    <TableHeader
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User
                    </TableHeader>

                    <TableHeader>Company</TableHeader>
                    <TableHeader># Projects</TableHeader>
                    <TableHeader>Total Spend</TableHeader>
                    <TableHeader>Last Active</TableHeader>
                    <TableHeader>Status</TableHeader>
                  </tr>
                </TableHead>
                <TableBody>
                  {!loading &&
                    searchedUsers.map((user, index) => (
                      <UsersRowCard user={user} index={index} />
                    ))}

                  {loading &&
                    [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                      <UserRowSkeleton index={index} />
                    ))}
                </TableBody>
              </Table>
              <TableFooter>
                <FooterLeft>
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">1</span> to{' '}
                    <span className="font-medium">
                      {searchedUsers?.length || 0}
                    </span>{' '}
                    of{' '}
                    <span className="font-medium">
                      {' '}
                      {searchedUsers?.length || 0}
                    </span>{' '}
                    results
                  </p>
                </FooterLeft>
              </TableFooter>
            </TableCard>
          </Default>
        </Section>
      )}

      {!isAllowed(orgData?.current_user_role, 'client_management.read') && (
        <div>Access denied</div>
      )}
    </Page>
  );
}

UserManagement.propTypes = {};

UserManagement.defaultProps = {};
