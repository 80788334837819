import styled from 'styled-components';

export const UserCards = styled.div`
  margin-top: 16px;
`;
export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;
export const ProjectInfodiv = styled.div`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
`;

export default { UserCards, SkeletonWrapper, ProjectInfodiv };
