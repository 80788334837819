import { gql } from '@apollo/client';

export const GET_SUMMARY_DATA = gql`
  query (
    $type: SummaryType
    $startDate: Timestamp
    $endDate: Timestamp
    $userId: String
  ) {
    summary(
      type: $type
      startDate: $startDate
      endDate: $endDate
      userId: $userId
    ) {
      type
      value
      value_units
      startDate
      endDate
      number

      invoices(userId: $userId) {
        id
        name
        amount
        project {
          id
          name
        }
        last_status_change_date
        due_date
        invoice_date
      }
    }
  }
`;
export default GET_SUMMARY_DATA;
