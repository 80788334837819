/* eslint-disable import/no-named-as-default */
import React, { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';
import { PropTypes } from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import {
  Label,
  Input,
  Button,
  InputGroup,
  SeparateGroup,
  ButtonWrapper,
} from '../../../CoreStyles/GeneralStyles';
import { isMobile } from '../../Devices';
import {
  SettingsCardTitle,
  SettingsCardDescription,
  SettingsCardHeader,
} from './AccountSettingsStyle';
import ModalPopUp from '../../Common/ModalPopUp';
import { UPDATE_USER_PASSWORD } from '../../../GraphQl/Mutations/updateUserMutation';
import createNotification from '../../Common/Notifications';
import GET_CURRENT_USER from '../../../GraphQl/Queries/usersQueries';

export default function UpdatePasswordModal({ visible, setEditing }) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { data } = useQuery(GET_CURRENT_USER);

  const [updatePassword] = useMutation(UPDATE_USER_PASSWORD, {
    onCompleted: () => {
      createNotification('success', 'Updated Password');
      setEditing(null);
      setNewPassword('');
      setConfirmPassword('');
    },
    onError: (error) => {
      let errorMessage = error.message;

      if (errorMessage.includes('PasswordStrengthError')) {
        errorMessage = errorMessage.replace('PasswordStrengthError: ', '');
      }

      createNotification('error', errorMessage);
    },
  });

  return (
    <ModalPopUp
      className="common-modal"
      width={isMobile() ? '90%' : '50%'}
      title={null}
      visible={visible}
      isCancel={() => {
        setEditing(null);
      }}
      footer={null}
      content={
        <div>
          <SettingsCardHeader withImage>
            <LockClosedIcon className="modal-icon" />
            <div>
              <SettingsCardTitle>Change Password</SettingsCardTitle>

              <SettingsCardDescription>
                We recommend changing your password every few months
              </SettingsCardDescription>
            </div>
          </SettingsCardHeader>

          {/* <InputGroup>
            <Label>Current Password</Label>
            <Input
              type="password"
              name="current-password"
              placeholder="Your current password"
              onChange={(e) => setCurrentPassword(e.target.value)}
              value={currentPassword}
            />
          </InputGroup */}

          <SeparateGroup>
            <InputGroup>
              <Label>New Password</Label>
              <Input
                type="password"
                name="new-password"
                placeholder="Strong password"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
              />
            </InputGroup>
            <InputGroup>
              <Label>Confirm New Password</Label>
              <Input
                type="password"
                name="confirm-password"
                placeholder="Strong Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
            </InputGroup>
          </SeparateGroup>
          <ButtonWrapper>
            <Button
              marginLeft="14px"
              primary
              onClick={async () => {
                if (newPassword === confirmPassword) {
                  updatePassword({
                    variables: {
                      id: data?.currentUser?.id,
                      input: {
                        newPassword,
                      },
                    },
                  });
                } else {
                  createNotification('error', 'Passwords do not match');
                }
              }}
            >
              Update Password
            </Button>
            <Button
              onClick={() => {
                setEditing(null);
              }}
              secondary
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
      }
    />
  );
}

UpdatePasswordModal.propTypes = {
  visible: PropTypes.bool,
  setEditing: PropTypes.func,
};

UpdatePasswordModal.defaultProps = {
  visible: false,
  setEditing: () => {},
};
