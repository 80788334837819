/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import styled, { css } from 'styled-components';

export const Card = styled.div`
  display: inline-block;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(238, 238, 238, 0.42);
  border-radius: 8px;
  text-align: left;

  display: ${(props) => (props.display ? props.display : 'inline-block')};
  width: ${(props) => (props.width ? props.width : '')};
  padding: ${(props) => (props.padding ? props.padding : '15px 20px')};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};

  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
  ${(props) =>
    props.noBorder &&
    css`
      border: none;
      box-shadow: none;
    `}

  ${(props) =>
    props.hover &&
    css`
        &:hover {
          box-shadow: 2px 3px 4px rgb(195 233 255 / 42%);
          border: 1px solid #ccd6ff;
        `}
       

  @media (max-width: 768px) {
    padding: ${(props) => (props.padding ? props.padding : '17px 20px;')};
  }
`;

export const CardTitle = styled.div`
  font-size: 18px;
  color: rgb(17, 24, 39);
  margin-bottom: 4px;
`;

export const CardHeader = styled.div`
  margin-bottom: 25px;
  margin-top: 10px;

  ${(props) =>
    props.withImage &&
    css`
      display: flex;
      align-items: flex-start;
    `}
`;

export const CardFooter = styled.div`
  display: block;
  padding: 12px 24px;
  text-align: right;
  border-top: 1px solid #ececec;
`;

export const CardDescription = styled.div`
  font-size: 14px;
  color: rgb(107, 114, 128);
`;

export const CardButton = styled.button`
  display: inline-block;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 2px 3px 4px rgba(238, 238, 238, 0.42);
  border-radius: 8px;
  text-align: left;
  cursor: pointer;

  ${(props) =>
    props.noBorder &&
    css`
      border: none;
      box-shadow: none;
    `}
  width: ${(props) => (props.width ? props.width : '')};
  padding: ${(props) => (props.padding ? props.padding : '15px 20px')};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  filter: ${(props) =>
    props.shadow ? 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))' : ''};

  ${(props) =>
    props.hover &&
    css`
      &:hover {
        background-color: #f7f7f8;
      }
    `}

  @media (max-width: 768px) {
    padding: ${(props) => (props.padding ? props.padding : '17px 20px;')};
  }
`;
export const FloatingButton = styled.div`
  background-color: #0d6cff;
  color: #ffffff;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 19px;
  display: inline-block;
  position: fixed;
  bottom: 30px;
  right: 20px;
  cursor: pointer;

  ${(props) =>
    props.relative &&
    css`
      position: relative;
      top: 25px;
      right: 20px;
    `}

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      bottom: 25px;
      right: 20px;
    `}
`;

export const UnorderedList = styled.ul`
  position: absolute;
  background: #ffffff;
  list-style-type: none;
  border: 1px solid #e8e8e8;

  width: ${(props) => (props.width ? props.width : '10%')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
`;

export const RelateList = styled.div`
  position: relative;
  margin: auto;
`;

export const ListItem = styled.li`
  padding: 5% 5%;
  &:hover {
    background-color: #e8e8e8;
  }
`;

export const Button = styled.button`
  background: #0d6cff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: 5px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '13px')};
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  padding: ${(props) => (props.padding ? props.padding : '8px 15px')};
  width: ${(props) => (props.width ? props.width : '')};
  display: ${(props) => (props.display ? props.display : '')};
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  float: ${(props) => (props.float ? props.float : '')};

  opacity: ${(props) => (props.loading ? '0.5' : '1')};

  ${(props) =>
    props.primary &&
    css`
      &:hover {
        background-color: #2f81ff;
      }
    `}

  ${(props) =>
    props.black &&
    css`
      background: #000000;
      &:hover {
        background-color: #0d6cff;
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      background: #ffffff;
      border: 1px solid rgb(209, 213, 219);
      color: rgb(55, 65, 81);
      &:hover {
        background-color: #f6f6f6;
      }
    `}

  ${(props) =>
    props.thin &&
    css`
      background: transparent;
      border: none;
      box-shadow: none;
      color: ${() => props.color || '#0d6cff'};
      font-weight: 400;
      font-size: ${() => (props.fontSize ? props.fontSize : '14px')};
      float: ${() => props.float || ''};
      &:hover {
        color: #000000;
      }
    `}

    ${(props) =>
    props.withRightIcon &&
    css`
      display: flex;
      align-items: center;
      &:hover {
        color: #000000;
      }
    `}
    

    ${(props) =>
    props.dashed &&
    css`
      background: #ffffff;
      border: 1px solid #d8d8d8;
      border-color: #d9d9d9;
      border-style: dashed;
      color: #6a6a6a;
      padding: 4px 15px;
      &:hover {
        border: 1px solid #000000;
        border-style: dashed;
        color: #000000;
      }
    `}

    ${(props) =>
    props.centered &&
    css`
      justify-content: center;
      width: 100%;
    `}
  



      ${(props) =>
    props.hideButton &&
    css`
      visibility: hidden;
    `}

    ${(props) =>
    props.disabled &&
    css`
      color: #c3c3c6;
      cursor: not-allowed;
      &:hover {
        color: #c3c3c6;
      }
    `}

        svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
`;

export const Menu = styled.button`
  outline: none;
  background: none;
  border: none;
  border-style: none;

  float: ${(props) => (props.float ? props.float : '')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
`;

export const MenuItem = styled.button`
  padding: 10px 15px !important;
  cursor: pointer;
  text-align: left;
`;

export const InnerRectangle = styled.div`
  background: #f7f7f8;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const SectionTitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  padding: ${(props) => (props.padding ? props.padding : '')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '15px'};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  color: ${(props) => (props.color ? props.color : '#262626')};

  @media (max-width: 768px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '17px')};
    margin-bottom: ${(props) =>
      props.marginBottom ? props.marginBottom : '10px'};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  }

  ${(props) =>
    props.withSearch &&
    css`
      margin-top: auto;
      margin-bottom: auto;
    `}

  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
`;

export const SectionTitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '20px'};
`;

export const SectionActions = styled.div`
  display: flex;
`;

export const Section = styled.div`
  padding: ${(props) => (props.padding ? props.padding : '20px')};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '')};
  margin-top: ${(props) =>
    props.columnMarginTop ? props.columnMarginTop : ''};

  @media (max-width: 768px) {
    padding: 10px;
  }

  width: ${(props) => (props.width ? props.width : '')};
`;

export const CommonSection = styled.div``;
export const SectionContent = styled.div`
  ${(props) =>
    props.grid &&
    css`
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-gap: 19px;
    `}
`;

export const SectionCarousel = styled.div`
  display: flex;
  overflow-x: auto;

  // We do this so it can go past the edge
  margin-right: -15px;

  padding: 5px 0px;
`;

export const Page = styled.div`
  font-family: Avenir Next;
  flex: 1;

  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ''};

  ${(props) =>
    props.singleColumnLarge &&
    css`
      margin-left: 12%;
      margin-right: 12%;
      padding-top: 30px;

      @media (max-width: 1199px) {
        margin-left: 10%;
        margin-right: 10%;
      }
      @media (max-width: 991px) {
        margin-left: 5%;
        margin-right: 5%;
      }

      @media (max-width: 768px) {
        /* padding-top: 5px;
        margin-left: 15px;
        margin-right: 15px; */
        overflow-x: hidden;
        padding-top: 30px;
        margin-left: 5px;
        margin-right: 5px;
      }
    `}

  ${(props) =>
    props.singleColumnWide &&
    css`
      margin-left: 6%;
      margin-right: 6%;
      padding-top: 30px;
    `}

     ${(props) =>
    props.singleColumnNarrow &&
    css`
      margin-left: 30%;
      margin-right: 30%;
      padding-top: 30px;
    `}

    ${(props) =>
    props.typeOne &&
    css`
      display: grid;
      margin-left: 10%;
      margin-right: 10%;
      padding-top: 30px;

      grid-template-rows: auto;
      grid-column-gap: 40px;
      grid-row-gap: 20px;
      grid-template-areas:
        'header header header header'
        'sidebar main main main'
        'footer footer footer footer';
    `}

    ${(props) =>
    props.typeTwo &&
    css`
      display: grid;
      margin-left: 10%;
      margin-right: 10%;
      padding-top: 30px;

      grid-template-rows: auto;
      grid-column-gap: 40px;
      grid-row-gap: 20px;
      grid-template-areas:
        'header header header header'
        'main main main main';
    `}
      
  ${(props) =>
    props.doubleColumnLarge &&
    css`
      /* margin-left: 15%;
      margin-right: 15%;
      padding-top: 26px; */
      margin-left: ${(marginProps) =>
        marginProps.marginLeft ? marginProps.marginLeft : '15%'};
      margin-right: ${(marginProps) =>
        marginProps.marginRight ? marginProps.marginRight : '15%'};
      padding-top: 30px;
      display: grid;
      grid-template-columns: ${(columnProps) =>
        columnProps.gridTemplateColumns
          ? columnProps.gridTemplateColumns
          : '50% 50%'};
      @media (max-width: 980px) {
        margin-left: 40px;
        margin-right: 40px;
      }
      @media (max-width: 768px) {
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: 100% 100%;
        flex-direction: column;
        padding-top: 15px;
      }
    `}

    ${(props) =>
    props.centered &&
    css`
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
    `}
`;

export const SearchHeader = styled.div`
  float: right;
  display: flex;

  ${(props) =>
    props.users &&
    css`
      margin-right: 2%;
      margin-top: 2%;
    `};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SearchBox = styled.div`
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  outline: none;
  position: relative;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`;

export const SearchBoxMobile = styled.div`
  box-sizing: border-box;
  padding: 6px 13px;
  font-size: 12px;
  width: 200px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-evenly;

  margin-top: auto;
  margin-bottom: auto;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
`;

export const SearchWideMobile = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(207, 207, 207, 0.25);
  border-radius: 5px;
`;

export const SearchInputMobile = styled.input`
  width: 85%;
  font-size: 16px;
  margin-right: 1px;
  margin-top: 2px;
  margin-bottom: auto;
  border: none;
  outline: none;
  text-align: right;
  background-color: transparent;
`;

export const SearchIconContainer = styled.div`
  align-items: center;
  border-style: solid;
  border-width: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  left: 0;
  outline: none;
  padding-left: 0.75rem;
  pointer-events: none;
  position: absolute;
  top: 0;
`;

export const SearchInput = styled.input`
  appearance: textfield;
  background-color: #fff;
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: #333;
  display: block;
  font-family: 'Avenir Next';
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  outline: none;
  outline-offset: -2px;
  overflow: visible;
  padding: 0.5rem 0.75rem 0.5rem 2.5rem;
  width: 100%;
`;

export const PaymentMethodOption = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentMethodOptionTitle = styled.span`
  display: block;
  margin-left: 5px;
  font-size: 14px;
  color: #5d5d5d;
`;

export const PaymentMethodImage = styled.img`
  box-sizing: border-box;
  display: block;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 36px;
  border-radius: 4px;
`;

export const CardIcon = styled.img`
  height: ${(props) => (props.height ? props.height : '20px')};
  width: ${(props) => (props.width ? props.width : '')};

  border-radius: 6px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SearchIcon = styled.img`
  margin-bottom: auto;
  margin-top: auto;

  height: 14px;
  border: none;
  background: none;

  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  display: ${(props) => (props.display ? props.display : '')};
  height: ${(props) => (props.height ? props.height : '')};
  width: ${(props) => (props.width ? props.width : '')};
  float: ${(props) => (props.float ? props.float : '')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};

  ${(props) =>
    props.searchWide &&
    css`
      margin-top: 3%;
      margin-right: 3%;
      float: right;
    `}
`;

export const BackIcon = styled.img`
  margin-top: auto;
  margin-bottom: auto;
  width: 149px;
  height: 25px;
`;

export const SectionTitleWithSearch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props.color ? props.color : '#333333')};
  cursor: ${(props) => (props.cursor ? props.cursor : '')};
  margin-bottom: ${(props) => props.marginBottom || '10px'};
  margin-top: ${(props) => props.marginTop || ''};
`;

export const TextValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props.color ? props.color : '#000000')};
  cursor: ${(props) => (props.cursor ? props.cursor : '')};
  margin-bottom: ${(props) => props.marginBottom || '10px'};
  margin-top: ${(props) => props.marginTop || ''};
`;

export const Input = styled.input`
  margin-bottom: ${(props) => props.marginBottom || ''};
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgb(208 208 208 / 25%);
  padding: 7px 12px;
  max-width: ${(props) => props.maxWidth || '290px'};
  font-size: 14px;
  border-color: rgba(209, 213, 219, 1);
  border-radius: 0.375rem;

  border-color: rgba(209, 213, 219, 1);

  width: ${(props) => props.width || '100%'};

  margin-right: ${(props) => props.marginRight || ''};

  ${(props) =>
    props.disabled &&
    css`
      background-color: #e6e6e6;
      color: rgb(176 176 176);
      cursor: not-allowed;
    `}

  @media (max-width: 767px) {
    max-width: 100%;
  }

  &:focus {
    border: 1px solid #177afa;
  }
`;

export const TextArea = styled.textarea`
  margin-bottom: ${(props) => props.marginBottom || ''};
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgb(208 208 208 / 25%);
  padding: 7px 12px;
  font-size: 14px;
  border-color: rgba(209, 213, 219, 1);
  border-radius: 0.375rem;

  border-color: rgba(209, 213, 219, 1);

  width: ${(props) => props.width || '100%'};

  margin-right: ${(props) => props.marginRight || ''};

  max-width: ${(props) => props.maxWidth || '290px'};

  font-size: 0.875rem;
  line-height: 1.25rem;

  resize: none;

  @media (max-width: 767px) {
    max-width: 100%;
  }

  &:focus {
    border: 1px solid #177afa;
  }
`;

export const InputGroup = styled.div`
  margin-bottom: 20px;
`;

export const SeparateGroup = styled.div`
  max-width: ${(props) => props.maxWidth || '100%'};
  ${(props) =>
    props.backgroundEnabled &&
    css`
      background-color: #f3f4f6;
      border-radius: 7px;
      padding: 17px;
      margin-bottom: 30px;
      ${props.whiteBg &&
      css`
        background-color: #ebebeb;
        margin-bottom: 15px;
      `}
      ${props.withBorder &&
      css`
        background-color: #f7f7f7;
        border: 1px solid #dfdfdf;
        margin-bottom: 15px;
      `}

      ${props.lightBg &&
      css`
        background-color: #f7f7f7;
        margin-bottom: 15px;
      `}
    `}

  ${(props) =>
    props.whiteBg &&
    css`
      margin-bottom: 15px;
    `}
`;

export const SameLineInput = styled.div`
  display: flex;
  align-items: center;
`;

export const SubDomainList = styled.div`
  position: relative;
  max-width: 290px;
  span {
    position: absolute;
    right: 10px;
    top: 7px;
    font-weight: 400;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;
export const FormGroup = styled.div`
  margin-bottom: 30px;
  display: block;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;
export const PageWrapper = styled.div`
  display: block;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 25px;
`;
export const SpinWrapper = styled.div`
  margin-top: 15px;
  /* margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px; */

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const SectionLinkWrapper = styled.div`
  margin-left: 15%;
  margin-top: 30px;
  @media (max-width: 980px) {
    margin-left: 40px;
  }
  @media (max-width: 768px) {
    margin-left: 15px;
  }
`;
export const DivCenter = styled.div`
  text-align: center;
  margin: ${(props) => (props.margin ? props.margin : '')};
`;

export const TopButton = styled.button`
  background: none;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 5px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: ${(props) => (props.color ? props.color : '#0c6cff')};
  padding: ${(props) => (props.padding ? props.padding : '')};

  display: flex;
  svg {
    height: 20px;
    margin-right: 7px;
    padding: 1px;
    border: 1px solid #0d6cff;
    border-radius: 50%;
  }
  display: flex;
  align-items: center;

  &:hover {
    color: #000000;
    svg {
      color: #000000;
      border: 1px solid #000000;
    }
  }
`;

export const TextLink = styled.a`
  color: #000000;

  font-family: Avenir Next;
  font-weight: 500;
  font-size: 14px;
`;

export const TotalSpent = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  color: #48a039;
  font-family: Avenir Next;

  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  color: ${(props) => (props.color ? props.color : '')};
  float: ${(props) => (props.float ? props.float : '')};
`;

export const TableHeaderContainer = styled.div`
  width: 100%;
  padding: 10px 24px 10px 20px;
  display: grid;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;

  grid-template-columns: ${(columnProps) =>
    columnProps.gridTemplateColumns
      ? columnProps.gridTemplateColumns
      : '20% 20% 20% 20% 20%'};

  @media (max-width: 768px) {
  }
`;

export const TableRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

export const TableRowFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableRowContainer = styled.div`
  display: grid;

  grid-template-columns: ${(columnProps) =>
    columnProps.gridTemplateColumns
      ? columnProps.gridTemplateColumns
      : '20% 20% 20% 20% 20%'};

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TableNameTitle = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;

  color: ${(props) => (props.color ? props.color : '#292929')};
  width: ${(props) => (props.width ? props.width : '')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  position: ${(props) => (props.position ? 'relative' : '')};
  top: ${(props) => (props.top ? '2px' : '')};
  display: ${(props) => (props.display ? props.display : '')};
  float: ${(props) => (props.float ? props.float : '')};

  ${(props) =>
    props.company &&
    css`
      margin-left: 0;
      position: static;
    `}

  ${(props) =>
    props.projects &&
    css`
      margin-left: -10px;
      position: static;
    `}
`;

export const MobileUser = styled.div`
  width: 77%;
  float: ${(props) => (props.float ? props.float : '')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
  display: inline;
`;

export const MobileUserInfo = styled.div`
  float: right;
  margin-left: 0px;
`;

export const PaginationRow = styled.div`
  text-align: left;
  margin-bottom: 15%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
`;

export const UserRole = styled.div`
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  /* identical to box height */

  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};

  color: #a0a0a0;
`;

export const BlueText = styled.div`
  color: #0c6cff;
  margin-bottom: auto;
  font-weight: 500;
  font-size: 14px;

  margin-top: ${(props) => (props.marginTop ? props.marginTop : 'auto')};
`;

export const Div = styled.div`
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : ''};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '')};
  width: ${(props) => (props.width ? props.width : '')};
  display: ${(props) => (props.display ? props.display : '')};
  align-items: ${(props) => props.alignItems || ''};
  max-width: ${(props) => props.maxWidth || ''};
`;
export const Table = styled.table`
  width: 100%;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #3c3c3c;
  table-layout: auto;
`;
export const TableHead = styled.thead`
  background: #f7f7f8;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 8px;
`;
export const TableBody = styled.tbody``;
export const Tr = styled.tr``;
export const Th = styled.th``;
export const Td = styled.td``;

export const H2 = styled.h2``;
export const H6 = styled.h6`
  font-size: ${(props) => props.fontSize || ''};
  margin-bottom: ${(props) => props.marginBottom || ''};
`;

export const Span = styled.span`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  color: ${(props) => (props.color ? props.color : '')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '')};
  display: ${(props) => (props.display ? props.display : '')};
  position: ${(props) => (props.position ? props.position : '')};
  margin: ${(props) => (props.margin ? props.margin : '')};
  cursor: ${(props) => (props.cursor ? props.cursor : '')};
  width: ${(props) => (props.width ? props.width : '')};
  float: ${(props) => (props.float ? props.float : '')};
  padding-right: ${(props) => props.paddingRight || ''};
`;

export const P = styled.p`
  color: ${(props) => (props.color ? props.color : '')};
  font-size: ${(props) => props.fontSize || ''};
  margin-bottom: ${(props) => props.marginBottom || ''};
  font-weight: ${(props) => props.fontWeight || ''};
`;

export const TableWrapper = styled.div`
  background: #ffffff;
  border: 1.02823px solid #dddddd;
  box-sizing: border-box;
  border-radius: 8.22581px;
  padding: 15px;
  width: 100%;
  margin: 20px 0;
`;
export const Img = styled.img``;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 50px;
`;

export const LoadingText = styled.div`
  font-size: 20px;
  color: #000000;
  margin-bottom: 20px;
`;

export const InlineMessage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;

  ${(props) =>
    props.thin &&
    css`
      padding: 10px;
      border: 1px solid #838383;
      border-radius: 6px;
    `}
`;

export default {
  Page,
  Card,
  CardButton,
  Button,
  InnerRectangle,
  Section,
  SectionTitle,
  SearchBox,
  SearchInput,
  SectionContent,
  SearchIcon,
  Label,
  Input,
  FormGroup,
  PageWrapper,
  ButtonWrapper,
  SubDomainList,
  SpinWrapper,
  CommonSection,
  DivCenter,
  TopButton,
  TableHeaderContainer,
  MobileUser,
  MobileUserInfo,
  TotalSpent,
  UserRole,
  BlueText,
  RelateList,
  Div,
  Table,
  TableHead,
  TableBody,
  Tr,
  Td,
  Th,
  H2,
  H6,
  Span,
  P,
  TableWrapper,
  Img,
  CardIcon,
  PaymentMethodOption,
};
