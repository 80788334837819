/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import {
  UserContainer,
  UserInfoContainer,
  UserName,
  UserRole,
} from './UserCardStyle';

import Avatar from '../Generics/Avatar';
import { projectRoles } from '../../Enums';

export function UserCard({ user, type }) {
  console.log('user is ', user);
  return (
    <>
      <UserContainer id={user.id}>
        <Avatar user={user} marginRight="10px" />
        <UserInfoContainer>
          {type === 'user' && <UserName>{user.name || user.email}</UserName>}
          {type === 'invite' && <UserName>{user.email} (invite sent)</UserName>}
          <UserRole>
            {projectRoles[user.role]?.title || 'Invalid Role'}
          </UserRole>
        </UserInfoContainer>
      </UserContainer>
    </>
  );
}
UserCard.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
};
UserCard.defaultProps = {
  user: {},
  type: 'user',
};
export default UserCard;
