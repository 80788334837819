/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import PropTypes from 'prop-types';

import { SearchIcon } from '@heroicons/react/solid';

import {
  Button,
  SectionTitle,
  PageWrapper,
  SearchInput,
  SectionActions,
  SectionTitleHeader,
  SearchIconContainer,
  SearchBox,
} from '../../../CoreStyles/GeneralStyles';

import {
  TableCard,
  TableHeader,
  TableHead,
  TableBody,
  Table,
  TableFooter,
  FooterLeft,
} from '../../../CoreStyles/TableStyles';

import { GET_ORGANIZATION_TEAM } from '../../../GraphQl/Queries/organizationsQuery';

import TeamRow from './TeamRow';
import TeamRowSkeleton from './TeamRowSkeleton';
import InviteUserModal from './InviteUserModal';

/*
const people = [
  {
    name: 'Bryan Lin',
    lastLogin: '2 weeks ago',
    department: 'Optimization',
    role: 'Admin',
    email: 'bryan@aloa.co',
    image:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  {
    name: 'Bryan Lin',
    lastLogin: '2 weeks ago',
    department: 'Optimization',
    role: 'Admin',
    email: 'bryan@aloa.co',
    image:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  {
    name: 'Bryan Lin',
    lastLogin: '2 weeks ago',
    department: 'Optimization',
    role: 'Admin',
    email: 'bryan@aloa.co',
    image:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
  },
  // More people...
]; */

export default function ManageTeam({ userId }) {
  const { error, loading, data, refetch } = useQuery(GET_ORGANIZATION_TEAM, {
    variables: { id: '1' },
    fetchPolicy: 'cache-and-network',
  });

  console.log(error, loading, data, '24g2323g23g3');

  const [teamMembers, setTeamMembers] = useState([]);

  const [inviteVisible, setInviteVisible] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (data?.organization?.team) {
      // Let's also get the invites

      const tempUsers = [...data?.organization?.team];

      if (data?.organization?.team_invites) {
        data?.organization?.team_invites.forEach((invite) => {
          const invitedUser = {
            id: invite.id,
            email: invite.email,
            name: '',
            role: invite.role,
            invite: true,
          };

          tempUsers.push(invitedUser);
        });
      }

      setTeamMembers(tempUsers);
    }
  }, [data]);

  return (
    <PageWrapper>
      <SectionTitleHeader>
        <SectionTitle marginBottom="0px">Manage Team</SectionTitle>
        <SectionActions>
          <SearchBox margin="0px 15px 0px 0px">
            <SearchIconContainer>
              <SearchIcon className="search-icon" />
            </SearchIconContainer>
            <SearchInput
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              placeholder="Search Team"
            />
          </SearchBox>
          <Button
            onClick={() => {
              setInviteVisible(true);
            }}
            primary
          >
            Invite
          </Button>
          <InviteUserModal
            refetchQuery={refetch}
            isVisible={inviteVisible}
            setIsVisible={setInviteVisible}
            userId={userId}
          />
        </SectionActions>
      </SectionTitleHeader>
      <TableCard>
        <Table>
          <TableHead>
            <tr>
              <TableHeader
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </TableHeader>
              <TableHeader>Last Active</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Role</TableHeader>
              <TableHeader>
                <span className="sr-only">Edit</span>
              </TableHeader>
            </tr>
          </TableHead>
          <TableBody>
            {!loading &&
              teamMembers
                .filter((person) => {
                  if (
                    person.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    person.email
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ) {
                    return true;
                  }
                  return false;
                })
                .map((person) => <TeamRow key={person.id} person={person} />)}

            {loading && [1, 2, 3, 4, 5].map(() => <TeamRowSkeleton />)}
          </TableBody>
        </Table>
        <TableFooter>
          <FooterLeft>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">1</span> to{' '}
              <span className="font-medium">
                {teamMembers.filter((person) => {
                  if (
                    person.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    person.email
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ) {
                    return true;
                  }
                  return false;
                })?.length || 0}
              </span>{' '}
              of{' '}
              <span className="font-medium">
                {teamMembers.filter((person) => {
                  if (
                    person.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    person.email
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ) {
                    return true;
                  }
                  return false;
                })?.length || 0}
              </span>{' '}
              results
            </p>
          </FooterLeft>
        </TableFooter>
      </TableCard>
    </PageWrapper>
  );
}

ManageTeam.propTypes = {
  userId: PropTypes.string,
};

ManageTeam.defaultProps = {
  userId: false,
};
