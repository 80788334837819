import React from 'react';
import { Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import auth from '../Auth';

const AuthCallbackRoute = () => (
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /login page
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    path="/callback"
    render={(props) => {
      auth
        .handleAuthentication(props)
        .then(() => props.history.push('/'))
        .catch(() => {
          props.history.push('/login');
        });
      return <div />;
    }}
  />
);

AuthCallbackRoute.propTypes = {
  history: PropTypes.instanceOf(History).isRequired,
};

export default AuthCallbackRoute;
