import React from 'react';
import { useQuery } from '@apollo/client';
import { Page, Section } from '../../../CoreStyles/GeneralStyles';

import GET_CURRENT_USER from '../../../GraphQl/Queries/usersQueries';

import BackButton from '../../Common/BackButton';
import PayHistoryCard from '../../UserManagement/PayHistoryCard';
import CreditsCard from '../../UserManagement/CreditsCard';
// import ErrorPage from '../../Common/ErrorPage';

export default function BillingHistory() {
  const { data } = useQuery(GET_CURRENT_USER);

  if (!data?.currentUser?.id) {
    return null;
  }

  return (
    <Page
      gridTemplateColumns="25% 25% 25% 25%"
      singleColumnNarrow
      typeTwo
      style={{ width: '50%', marginRight: 'auto', marginLeft: 'auto' }}
    >
      <BackButton text="Dashboard" path="/" />

      <Section padding="0px" style={{ gridArea: 'main' }} width="100%">
        <PayHistoryCard userId={data?.currentUser?.id} />
        <CreditsCard viewType="client" userId={data?.currentUser?.id} />
      </Section>
    </Page>
  );
}

BillingHistory.propTypes = {};

BillingHistory.defaultProps = {};
