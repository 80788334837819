/* eslint-disable camelcase */
/* eslint-disable dot-notation */
import React, { useEffect, useRef, useState, useContext } from 'react';
import { Switch, message } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { TimezonePicker } from 'baseui/timezonepicker';
import { SpinnerCircular } from 'spinners-react';
import moment from 'moment-timezone';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { ThemeProvider, createTheme, lightThemePrimitives } from 'baseui';
import {
  Label,
  Input,
  InputGroup,
  PageWrapper,
  SubDomainList,
  CardTitle,
  CardHeader,
  SeparateGroup,
  CardFooter,
  Button,
  TextArea,
  LoadingContainer,
  LoadingText,
  SameLineInput,
} from '../../CoreStyles/GeneralStyles';

import DropdownMenu from '../Generics/Dropdown';

import { EnabledText } from '../Invoice/UpdateInvoiceStyle';

import { GET_MY_ORGANIZATION_DATA } from '../../GraphQl/Queries/organizationsQuery';
import {
  UPDATE_ORG_IMAGE,
  UPDATE_ORG_MUTATION,
} from '../../GraphQl/Mutations/orgMutations';

import { ErrorWrapper } from './style';
import {
  ImageWrapper,
  EmptyImage,
  PlatformLogo,
  PlatformSettingsCard,
  PlatformSettingsContent,
} from './PlatformSettingsStyle';
import ImagePicker from '../Common/ImagePicker';
import { OrgContext } from '../../Utils';

const engine = new Styletron();

export default function PlatformSettings() {
  const changesToSave = useRef({});

  const orgContext = useContext(OrgContext);
  const { error, loading, data } = useQuery(GET_MY_ORGANIZATION_DATA, {
    variables: { id: orgContext.id },
  });
  const [updateOrgImage] = useMutation(UPDATE_ORG_IMAGE);
  const [updateOrgMutation] = useMutation(UPDATE_ORG_MUTATION, {
    onCompleted: () => {
      message.success('Settings updated!');
    },
    onError: (errors) => {
      console.error(errors);
    },
  });

  const [organization, setOrganization] = useState({});
  const [logoImage, setLogoImage] = useState('');

  useEffect(() => {
    if (data?.organization) {
      setOrganization(data?.organization);

      if (data.organization?.platform_logo) {
        setLogoImage(data.organization?.platform_logo);
      }
    }
  }, [data]);

  function updateOrgValue(fieldId, newValue) {
    const orgTemp = _.cloneDeep(organization);

    orgTemp[fieldId] = newValue;

    setOrganization(orgTemp);
  }

  const handlePlatformLogo = (file) => {
    const maxfilesize = 2 * 1024 * 1024; // 2 Mb
    const filesize = file?.size;

    if (filesize > maxfilesize) {
      const warning = document.getElementById('lbError');
      warning.innerHTML =
        `Selected file is too large: ${parseFloat(
          filesize / (1024 * 1024)
        ).toFixed(2)} ` +
        `mb` +
        `. Maximum acceptable size is: ${parseFloat(
          maxfilesize / (1024 * 1024)
        ).toFixed(2)} ` +
        `mb`;
    }

    setLogoImage(URL.createObjectURL(file));

    changesToSave.current.image = async () =>
      updateOrgImage({
        variables: { id: data?.organization.id, file },
      });
  };

  const handleChange = async (evt) => {
    const { name, value } = evt.target;
    updateOrgValue(name, value);
  };

  const saveButtonPressed = async () => {
    console.log('org to save: ', organization);

    const orgTemp = _.cloneDeep(organization);
    if (orgTemp.help_options) {
      delete orgTemp.help_options['__typename'];

      Object.keys(orgTemp.help_options).forEach((key) => {
        const help_option = orgTemp.help_options[key];
        const help_optionCopy = _.cloneDeep(help_option);
        delete help_optionCopy['__typename'];

        orgTemp.help_options[key] = help_optionCopy;
      });
    }

    if (orgTemp.timezone) {
      orgTemp.utc_offset = moment.tz(orgTemp.timezone).utcOffset();
    }

    console.log('org to save2: ', orgTemp);

    changesToSave.current.updateOrg = async () =>
      updateOrgMutation({ variables: orgTemp });

    try {
      await Promise.all(
        Object.values(changesToSave.current).map((change) => change())
      );
    } catch (exception) {
      message.error(exception.message);
    }
  };

  if (loading) {
    return (
      <PageWrapper>
        <LoadingContainer>
          <LoadingText>Loading Platform Settings</LoadingText>
          <SpinnerCircular
            size={50}
            thickness={81}
            speed={162}
            color="rgba(12, 108, 255, 1)"
            secondaryColor="rgba(0, 0, 0, 0.33)"
          />
        </LoadingContainer>
      </PageWrapper>
    );
  }
  if (error) {
    return <div>There was an error</div>;
  }

  return (
    <PageWrapper>
      <PlatformSettingsCard>
        <PlatformSettingsContent>
          <CardHeader>
            <CardTitle>General Settings</CardTitle>
          </CardHeader>
          <InputGroup>
            <Label>Platform Name</Label>
            <Input
              type="text"
              placeholder="GrowthBird Pay"
              name="platform_name"
              value={organization.platform_name}
              onChange={handleChange}
            />
          </InputGroup>
          <InputGroup>
            <Label>Platform Logo</Label>
            <ImageWrapper>
              {logoImage ? (
                <PlatformLogo
                  src={logoImage}
                  width={240}
                  height={60}
                  alt="logo"
                />
              ) : (
                <EmptyImage width={240} height={60}>
                  None
                </EmptyImage>
              )}
              <ImagePicker width={240} height={60} upload={handlePlatformLogo}>
                <Button thin>(Change)</Button>
              </ImagePicker>
            </ImageWrapper>
          </InputGroup>

          <SeparateGroup backgroundEnabled={organization.custom_domain_enabled}>
            <InputGroup>
              <Label>Custom Domain</Label>
              <Switch
                checked={organization.custom_domain_enabled}
                onChange={(checked) => {
                  updateOrgValue('custom_domain_enabled', checked);
                }}
              />
              <EnabledText> Enabled </EnabledText>
            </InputGroup>
            <InputGroup>
              {organization.custom_domain_enabled ? (
                <>
                  <InputGroup>
                    <Input
                      disabled={!organization.custom_domain_enabled}
                      type="text"
                      placeholder="GrowthBird Pay"
                      name="custom_domain"
                      value={organization.custom_domain}
                      onChange={handleChange}
                    />

                    {organization.errors?.custom_domain?.length > 0 && (
                      <ErrorWrapper>
                        {organization.errors?.custom_domain}
                      </ErrorWrapper>
                    )}
                  </InputGroup>
                </>
              ) : (
                <InputGroup>
                  <Label>SubDomain</Label>
                  <SubDomainList>
                    <Input
                      type="text"
                      placeholder="GrowthBird Pay"
                      name="subdomain"
                      value={organization.subdomain}
                      onChange={handleChange}
                    />
                    <span>.aloapay.com</span>
                  </SubDomainList>
                </InputGroup>
              )}
            </InputGroup>
          </SeparateGroup>

          <InputGroup>
            <Label>Timezone</Label>
            <div style={{ width: '400px' }}>
              <StyletronProvider value={engine}>
                <ThemeProvider
                  theme={createTheme(lightThemePrimitives, {
                    colors: { inputFill: '#ffffff' },
                  })}
                >
                  <TimezonePicker
                    value={organization.timezone}
                    onChange={({ id }) => updateOrgValue('timezone', id)}
                  />
                </ThemeProvider>
              </StyletronProvider>
            </div>
          </InputGroup>

          <InputGroup>
            <Label>Send Email Reminders at</Label>
            <SameLineInput>
              <DropdownMenu
                style={{ marginRight: '5px' }}
                value={organization.default_email_time}
                options={{
                  0: {
                    title: '12:00am',
                  },
                  5: {
                    title: '5:00am',
                  },
                  6: {
                    title: '6:00am',
                  },
                  7: {
                    title: '7:00am',
                  },
                  8: {
                    title: '8:00am',
                  },
                  9: {
                    title: '9:00am',
                  },
                  10: {
                    title: '10:00am',
                  },
                  11: {
                    title: '11:00am',
                  },
                  12: {
                    title: '12:00am',
                  },
                  13: {
                    title: '1:00pm',
                  },
                  14: {
                    title: '2:00pm',
                  },
                  15: {
                    title: '3:00pm',
                  },
                  16: {
                    title: '4:00pm',
                  },
                  17: {
                    title: '5:00pm',
                  },
                  18: {
                    title: '6:00pm',
                  },
                  19: {
                    title: '7:00pm',
                  },
                }}
                onChange={(newValue) => {
                  updateOrgValue('default_email_time', parseInt(newValue, 10));
                }}
              />
              <div style={{ marginRight: '10px' }}>{organization.timezone}</div>
            </SameLineInput>
          </InputGroup>
        </PlatformSettingsContent>
        <CardFooter>
          <Button
            onClick={() => {
              saveButtonPressed();
            }}
            black
          >
            Save
          </Button>
        </CardFooter>
      </PlatformSettingsCard>

      <PlatformSettingsCard>
        <PlatformSettingsContent>
          <CardHeader>
            <CardTitle>Platform Vocabulary</CardTitle>
          </CardHeader>
          <InputGroup>
            <Label>What should we call Projects</Label>
            <DropdownMenu
              value={organization.projects_vocabulary || 'projects'}
              options={{
                projects: {
                  title: 'Projects',
                },
                engagements: {
                  title: 'Engagements',
                },
                jobs: {
                  title: 'Jobs',
                },
              }}
              onChange={(newValue) => {
                updateOrgValue('projects_vocabulary', newValue);
              }}
            />
          </InputGroup>

          <InputGroup>
            <Label>What should we call Invoices</Label>
            <DropdownMenu
              value={organization.invoices_vocabulary || 'invoices'}
              options={{
                invoices: {
                  title: 'Invoices',
                },
                milestones: {
                  title: 'Milestones',
                },
              }}
              onChange={(newValue) => {
                updateOrgValue('invoices_vocabulary', newValue);
              }}
            />
          </InputGroup>

          <InputGroup>
            <Label>What should we call Credits</Label>
            <DropdownMenu
              value={organization.credits_vocabulary || 'credits'}
              options={{
                credits: {
                  title: 'Credits',
                },
                rewards: {
                  title: 'Rewards',
                },
                points: {
                  title: 'Points',
                },
              }}
              onChange={(newValue) => {
                updateOrgValue('credits_vocabulary', newValue);
              }}
            />
          </InputGroup>
        </PlatformSettingsContent>
        <CardFooter>
          <Button
            onClick={() => {
              saveButtonPressed();
            }}
            black
          >
            Save
          </Button>
        </CardFooter>
      </PlatformSettingsCard>

      <PlatformSettingsCard>
        <PlatformSettingsContent>
          <CardHeader>
            <CardTitle>Login / Sign up Messaging</CardTitle>
          </CardHeader>
          <InputGroup>
            <Label>Login Message Text</Label>
            <TextArea
              maxWidth="400px"
              placeholder="Growthbird uses AloaPay for secure and convenient payments"
              rows={6}
              name="login_message_text"
              value={organization?.login_message_text}
              onChange={handleChange}
            />
          </InputGroup>

          <InputGroup>
            <Label>Sign up Message Text</Label>
            <TextArea
              maxWidth="400px"
              placeholder="Growthbird uses AloaPay for secure and convenient payments"
              rows={6}
              name="signup_message_text"
              value={organization?.signup_message_text}
              onChange={handleChange}
            />
          </InputGroup>
        </PlatformSettingsContent>
        <CardFooter>
          <Button
            onClick={() => {
              saveButtonPressed();
            }}
            black
          >
            Save
          </Button>
        </CardFooter>
      </PlatformSettingsCard>

      <PlatformSettingsCard>
        <PlatformSettingsContent>
          <CardHeader>
            <CardTitle>Help Options</CardTitle>
          </CardHeader>

          <SeparateGroup
            backgroundEnabled={organization.help_options?.email?.enabled}
          >
            <InputGroup>
              <Label>Email</Label>
              <Switch
                checked={organization.help_options?.email?.enabled}
                onChange={(checked) => {
                  const tempOptions = _.cloneDeep(
                    organization.help_options || {
                      email: {},
                      phone: {},
                    }
                  );

                  tempOptions.email.enabled = checked;

                  updateOrgValue('help_options', tempOptions);
                }}
              />
              <EnabledText> Enabled </EnabledText>
            </InputGroup>

            {organization.help_options?.email?.enabled && (
              <InputGroup>
                <Label>Help Email</Label>
                <Input
                  value={organization.help_options?.email?.value}
                  type="text"
                  placeholder="billing@growthbird.com"
                  onChange={(e) => {
                    const tempOptions = _.cloneDeep(
                      organization.help_options || {
                        email: {},
                        phone: {},
                      }
                    );

                    tempOptions.email.value = e.target.value;

                    updateOrgValue('help_options', tempOptions);
                  }}
                />
              </InputGroup>
            )}
          </SeparateGroup>
          <SeparateGroup
            backgroundEnabled={organization.help_options?.phone?.enabled}
          >
            <InputGroup>
              <Label>Phone</Label>
              <Switch
                checked={organization.help_options?.phone?.enabled}
                onChange={(checked) => {
                  const tempOptions = _.cloneDeep(
                    organization.help_options || {
                      email: {},
                      phone: {},
                    }
                  );

                  tempOptions.phone.enabled = checked;

                  updateOrgValue('help_options', tempOptions);
                }}
              />
              <EnabledText> Enabled </EnabledText>
            </InputGroup>

            {organization.help_options?.phone?.enabled && (
              <InputGroup>
                <Label>Help Phone Number</Label>
                <Input
                  value={organization.help_options?.phone?.value}
                  type="text"
                  placeholder="+1 111 111 1111"
                  onChange={(e) => {
                    const tempOptions = _.cloneDeep(
                      organization.help_options || {
                        email: {},
                        phone: {},
                      }
                    );

                    tempOptions.phone.value = e.target.value;

                    updateOrgValue('help_options', tempOptions);
                  }}
                />
              </InputGroup>
            )}
          </SeparateGroup>
        </PlatformSettingsContent>
        <CardFooter>
          <Button
            onClick={() => {
              saveButtonPressed();
            }}
            black
          >
            Save
          </Button>
        </CardFooter>
      </PlatformSettingsCard>
    </PageWrapper>
  );
}
