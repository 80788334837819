/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  // HttpLink,
  from,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { message } from 'antd';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import auth from './Auth';
import settings from './Config/settings';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const errorLink = onError(({ graphqlErrors }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ errorMessage }) =>
      message.error(`Graphql error ${errorMessage}`)
    );
  }
});
const link = from([
  errorLink,
  createUploadLink({
    uri: settings.apolloClient.uri,
  }),
]);

auth
  .checkSession()
  .then((authResult) => {
    const authLink = setContext((_, { headers }) => {
      const token = authResult.idToken;
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache({}),
    });

    ReactDOM.render(
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>,
      document.getElementById('root')
    );
  })
  .catch((error) => {
    message.error(error.message);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
