import { gql } from '@apollo/client';

export const GET_ORGANIZATION_DATA = gql`
  query {
    organizations {
      id
      name
      entity_type
      platform_name
      platform_logo
      subdomain
      custom_domain
      users {
        id
        name
        email
        role
        projects {
          id
          organization {
            id
            name
          }
          name
          invoices {
            id
            name
            amount
            due_date
            invoice_date
            status
            description
            decline_reason
            project_id
            recurring
            net_d
            date_created
          }
        }
      }
      team {
        id
        name
        email
        projects {
          id
          name
        }
      }
    }
  }
`;

export const GET_ORG_FROM_SUBDOMAIN = gql`
  query ($subdomain: String!) {
    organizationFromSubdomain(subdomain: $subdomain) {
      id
      name
      platform_name
      platform_logo
      platform_logo_thumb
      subdomain
      custom_domain
      custom_domain_enabled
      projects_vocabulary
      login_message_text
      signup_message_text
      current_user_role
      timezone
      default_email_time
      help_options {
        email {
          enabled
          value
        }
        phone {
          enabled
          value
        }
      }
    }
  }
`;

export const GET_ORG_CONFIGS = gql`
  query getOrgConfigs($id: String!) {
    organization(id: $id) {
      id
      name
      platform_name
      platform_logo
      platform_logo_thumb
      subdomain
      custom_domain
      custom_domain_enabled
      projects_vocabulary
      login_message_text
      signup_message_text
      current_user_role
      timezone
      default_email_time
      help_options {
        email {
          enabled
          value
        }
        phone {
          enabled
          value
        }
      }
    }
  }
`;

export const GET_MY_ORGANIZATION_DATA = gql`
  query myOrganization($id: String!) {
    organization(id: $id) {
      id
      name
      entity_type
      platform_name
      platform_logo
      platform_logo_thumb
      subdomain
      custom_domain
      custom_domain_enabled
      projects_vocabulary
      login_message_text
      signup_message_text
      timezone
      default_email_time
      help_options {
        email {
          enabled
          value
        }
        phone {
          enabled
          value
        }
      }
      users {
        id
        name
        email
        role
        projects {
          id
          organization {
            id
            name
          }
          name
          invoices {
            id
            name
            amount
            due_date
            invoice_date
            status
            description
            decline_reason
            project_id
            recurring
            net_d
            date_created
          }
        }
      }
      team {
        id
        name
        email
        projects {
          id
          name
        }
      }
      team_invites {
        id
        email
        role
        invited_by_user
        createdAt
      }
    }
  }
`;

export const GET_ORGANIZATION_HELP_DATA = gql`
  query getOrgHelpData($id: String!) {
    organization(id: $id) {
      id
      help_options {
        email {
          enabled
          value
        }
        phone {
          enabled
          value
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_TEAM = gql`
  query getOrgTeam($id: String!) {
    organization(id: $id) {
      team {
        name
        id
        role
        email
        last_login
        image
        image_thumb
      }
      team_invites {
        id
        email
        role
        invited_by_user
        createdAt
      }
    }
  }
`;

export const GET_ORGANIZATION_CLIENTS = gql`
  query getOrgClients($id: String!) {
    organization(id: $id) {
      clients {
        name
        id
        role
        email
        image
        image_thumb
        last_login
        company
        total_spend
        projects {
          id
          name
        }
      }
      client_invites {
        id
        email
        role
        invited_by_user
        createdAt
      }
    }
  }
`;

export const GET_ORGANIZATION_MEMBERS = gql`
  query getAllOrgMembers($id: String!) {
    organization(id: $id) {
      members {
        name
        id
        role
        email
        last_login
        image
        image_thumb
        company
        projects {
          id
          name
        }
      }
      member_invites {
        id
        email
        role
        invited_by_user
        createdAt
      }
    }
  }
`;

export default GET_ORGANIZATION_DATA;
