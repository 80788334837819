/* eslint-disable import/prefer-default-export */

import { currencyFormatter } from '../../../../Utils';

export const pageData = {
  components: {
    a: {
      type: 'chart',
      groupBy: {
        value: 'project.name',
      },
      valueOptions: {
        type: 'sum',
        key: 'amount',
        name: 'Amount',
      },
      chartOptions: {
        chart: {
          type: 'pie',
          polar: false,
        },
        title: {
          text: 'Breakdown by Project',
        },
        legend: {
          layout: 'vertical',
        },
        xAxis: {
          lineWidth: 0,
        },
        tooltip: {
          formatter() {
            return `<b>Amount</b>: <b>${currencyFormatter.format(
              this.y
            )}</b><br/><b>Percent</b>: <b>${this.percentage.toFixed(2)}%</b>`;
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: true,
            innerSize: '60%',
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            animation: false,
          },
        },
      },
    },
    c: {
      type: 'table',
      columns: ['name', 'amount', 'project', 'last_status_change_date'],
    },
    d: {
      type: 'chart',
      groupBy: {
        value: 'last_status_change_date',
        unit: 'month',
      },
      valueOptions: {
        type: 'sum',
        key: 'amount',
        name: 'Amount',
        xKey: 'last_status_change_date',
        xKeyType: 'date',
      },
      chartOptions: {
        chart: {
          type: 'line',
          polar: false,
        },
        title: {
          text: 'Collection over time',
        },
        legend: {
          layout: 'vertical',
        },

        tooltip: {
          formatter() {
            return `<b>Amount</b>: <b>${currencyFormatter.format(
              this.y
            )}</b><br/>`;
          },
        },
        yAxis: {
          title: {
            text: 'Amount Collected',
          },
          softMax: 100000,
          labels: {
            formatter() {
              return `${currencyFormatter.format(this.value)}`;
            },
          },
        },
        xAxis: {
          title: {
            text: 'Weeks',
          },
          tickInterval: 86400000 * 30,
          type: 'datetime',
          startOnTick: true,
          min: Date.UTC(2021, 0, 1),
          max: Date.UTC(2021, 11, 31),
          units: [
            [
              'millisecond', // unit name
              [1, 2, 5, 10, 20, 25, 50, 100, 200, 500], // allowed multiples
            ],
            ['second', [1, 2, 5, 10, 15, 30]],
            ['minute', [1, 2, 5, 10, 15, 30]],
            ['hour', [1, 2, 3, 4, 6, 8, 12]],
            ['day', [1, 2]],
            ['week', [1, 2, 3, 4]],
            ['month', [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12]],
            ['year', [1, 2, 3]],
          ],
        },
      },
    },
  },
  layout: [
    { i: 'd', x: 0, y: 0, w: 33, h: 25 },
    { i: 'a', x: 33, y: 0, w: 17, h: 25 },
    { i: 'c', x: 0, y: 25, w: 50, h: 50 },
  ],
};
