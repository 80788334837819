import { PropTypes } from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from '../Utils';

const PublicRoute = ({ component: Component, ...rest }) => (
  // restricted = false meaning public route
  // restricted = true meaning restricted route

  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) =>
      isLoggedIn() ? (
        <Redirect to="/" />
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      )
    }
  />
);
PublicRoute.propTypes = {
  component: PropTypes.element,
  // restricted: PropTypes.bool,
};
PublicRoute.defaultProps = {
  component: null,
  // restricted: false,
};
export default PublicRoute;
