import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';

// import { Card } from 'antd';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';

import {
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
  HelpOptionsContainer,
  HelpOptionContainer,
  PrimaryText,
  SecondaryText,
  HelpCard,
} from './GetHelpStyle';
import { GET_ORGANIZATION_HELP_DATA } from '../../GraphQl/Queries/organizationsQuery';

function GetHelp({ onClose }) {
  const { data } = useQuery(GET_ORGANIZATION_HELP_DATA, {
    variables: {
      id: '1',
    },
  });

  return (
    <>
      <>
        <ModalHeader>
          <ModalTitle>Get Help</ModalTitle>
          <ModalCloseButton onClick={onClose}>Close</ModalCloseButton>
        </ModalHeader>

        <HelpOptionsContainer>
          {data?.organization?.help_options?.email?.enabled && (
            <HelpOptionContainer width="50%" textAlign="center">
              <HelpCard
                onClick={() => {
                  window.location = 'mailto:xyz@abc.com';
                }}
                hover
                pointer
                padding="30px 20px"
              >
                <MailIcon className="add-payment-method-icon" />

                <PrimaryText>Email Us</PrimaryText>
                <SecondaryText>
                  {data?.organization?.help_options?.email?.value}
                </SecondaryText>
              </HelpCard>
            </HelpOptionContainer>
          )}

          {data?.organization?.help_options?.phone?.enabled && (
            <HelpOptionContainer width="50%" textAlign="center">
              <HelpCard
                onClick={() => {
                  window.location = 'tel:+496170961709';
                }}
                hover
                pointer
                padding="30px 20px"
              >
                <PhoneIcon className="add-payment-method-icon" />
                <PrimaryText>Call Us</PrimaryText>
                <SecondaryText>
                  {data?.organization?.help_options?.phone?.value}
                </SecondaryText>
              </HelpCard>
            </HelpOptionContainer>
          )}
        </HelpOptionsContainer>
      </>
    </>
  );
}

GetHelp.propTypes = {
  onClose: PropTypes.func,
};

GetHelp.defaultProps = {
  onClose: null,
};

export default GetHelp;
