/* eslint-disable import/prefer-default-export */
import { currencyFormatter } from '../../../../Utils';

export const pageData = {
  components: {
    a: {
      type: 'chart',
      groupBy: {
        value: 'project.name',
      },
      valueOptions: {
        type: 'sum',
        key: 'amount',
        name: 'Amount',
      },
      chartOptions: {
        chart: {
          type: 'pie',
          polar: false,
        },
        title: {
          text: 'Breakdown by Project',
        },
        legend: {
          layout: 'vertical',
        },
        xAxis: {
          lineWidth: 0,
        },
        tooltip: {
          formatter() {
            return `<b>Amount</b>: <b>${currencyFormatter.format(
              this.y
            )}</b><br/><b>Percent</b>: <b>${this.percentage.toFixed(2)}%</b>`;
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: true,
            innerSize: '60%',
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            animation: false,
          },
        },
      },
    },
    b: {
      type: 'amount',
      valueOptions: {
        type: 'average',
        key: 'amount',
        displayType: 'currency',
        title: 'Average Invoice Size',
      },
    },
    c: {
      type: 'table',
      columns: ['name', 'amount', 'project', 'due_date'],
    },
  },
  layout: [
    { i: 'a', x: 0, y: 0, w: 33, h: 25 },
    { i: 'b', x: 33, y: 0, w: 17, h: 25 },
    { i: 'c', x: 0, y: 25, w: 50, h: 50 },
  ],
};
