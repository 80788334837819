import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { PencilIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { isMobile } from '../Devices';

import ModalPopUp from '../Common/ModalPopUp';

import createNotification from '../Common/Notifications';

import UPDATE_USER_MUTATION from '../../GraphQl/Mutations/updateUserMutation';

import {
  Label,
  Input,
  Button,
  InputGroup,
  SeparateGroup,
  ButtonWrapper,
} from '../../CoreStyles/GeneralStyles';

import {
  SettingsCardTitle,
  SettingsCardDescription,
  SettingsCardHeader,
} from '../Settings/Account Settings/AccountSettingsStyle';

export default function EditUserInfo({ editing, setEditing, userInfo }) {
  /*
  const [updateImage] = useMutation(UPDATE_USER_IMAGE, {
    onCompleted: () => {
      createNotification('success', 'Image Updated');
    },
  }); */

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      createNotification('success', 'Updated Settings');
      setEditing(false);
    },
  });

  const [userTemp, setUserTemp] = useState(userInfo);

  useEffect(() => {
    if (userInfo) {
      setUserTemp(userInfo);
    }
  }, [userInfo]);

  function updateUserValue(fieldId, newValue) {
    const clonsedUser = _.cloneDeep(userTemp);

    clonsedUser[fieldId] = newValue;

    setUserTemp(clonsedUser);
  }

  return (
    <ModalPopUp
      className="common-modal"
      width={isMobile() ? '90%' : '50%'}
      title={null}
      visible={editing}
      isCancel={() => {
        setEditing(null);
      }}
      footer={null}
      style={{ top: isMobile() ? '10px' : '40px', maxWidth: '400px' }}
      centered={false}
      content={
        <div>
          <SettingsCardHeader withImage>
            <PencilIcon
              className="modal-icon"
              style={{ color: '#0064fb', backgroundColor: '#0469ff29' }}
            />
            <div>
              <SettingsCardTitle>Edit Info</SettingsCardTitle>

              <SettingsCardDescription>
                You are editing the basic info of this user
              </SettingsCardDescription>
            </div>
          </SettingsCardHeader>

          <SeparateGroup>
            <InputGroup>
              <Label>Name</Label>
              <Input
                type="text"
                name="name"
                placeholder="John Doe"
                onChange={(e) => {
                  updateUserValue('name', e.target.value);
                }}
                value={userTemp?.name}
              />
            </InputGroup>
            <InputGroup>
              <Label>Company</Label>
              <Input
                type="text"
                name="company"
                placeholder="AloaLabs, LLC"
                onChange={(e) => {
                  updateUserValue('company', e.target.value);
                }}
                value={userTemp?.company}
              />
            </InputGroup>
            <InputGroup>
              <Label>Phone Number</Label>
              <Input
                type="text"
                name="phone"
                placeholder="111-111-1111"
                onChange={(e) => {
                  updateUserValue('phone', e.target.value);
                }}
                value={userTemp?.phone}
              />
            </InputGroup>
          </SeparateGroup>
          <ButtonWrapper>
            <Button
              onClick={() => {
                console.log('user temp is ', userTemp);
                updateUser({
                  variables: userTemp,
                });
              }}
              marginLeft="14px"
              primary
            >
              Update User
            </Button>
            <Button
              onClick={() => {
                setEditing(null);
              }}
              secondary
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
      }
    />
  );
}

EditUserInfo.propTypes = {
  userInfo: PropTypes.objectOf(PropTypes.string),
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
};

EditUserInfo.defaultProps = {
  userInfo: null,
  editing: false,
  setEditing: null,
};
