import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { SpinnerCircular } from 'spinners-react';
import {
  Label,
  Input,
  InputGroup,
  PageWrapper,
  CardHeader,
  CardTitle,
  LoadingContainer,
  LoadingText,
  CardFooter,
} from '../../CoreStyles/GeneralStyles';

import { ContactSupportMessage, EmailLink } from './CompanySettingsStyle';

import {
  PlatformSettingsCard,
  PlatformSettingsContent,
} from './PlatformSettingsStyle';

import DropdownMenu from '../Generics/Dropdown';

import { GET_MY_ORGANIZATION_DATA } from '../../GraphQl/Queries/organizationsQuery';
import { OrgContext } from '../../Utils';

export default function CompanySettings() {
  const orgContext = useContext(OrgContext);

  const { error, loading, data } = useQuery(GET_MY_ORGANIZATION_DATA, {
    variables: { id: orgContext.id },
  });

  const [organization, setOrganization] = useState({});

  useEffect(() => {
    if (data?.organization) {
      console.log('org data:: ', data?.organization);
      setOrganization(data?.organization);
    }
  }, [data]);

  function updateOrgValue(fieldId, newValue) {
    const orgTemp = _.cloneDeep(organization);

    orgTemp[fieldId] = newValue;

    setOrganization(orgTemp);
  }

  function handleChange(evt) {
    const { name, value } = evt.target;

    updateOrgValue(name, value);
  }

  if (loading) {
    return (
      <PageWrapper>
        <LoadingContainer>
          <LoadingText>Loading Company Settings</LoadingText>
          <SpinnerCircular
            size={50}
            thickness={81}
            speed={162}
            color="rgba(12, 108, 255, 1)"
            secondaryColor="rgba(0, 0, 0, 0.33)"
          />
        </LoadingContainer>
      </PageWrapper>
    );
  }
  if (error) {
    return <div>There was an error</div>;
  }

  return (
    <PageWrapper>
      <PlatformSettingsCard>
        <PlatformSettingsContent>
          <CardHeader>
            <CardTitle>Company Settings</CardTitle>
          </CardHeader>
          <InputGroup>
            <ContactSupportMessage>
              Your information is verified and cannot be changed. Please{' '}
              <EmailLink href="mailto:support@aloapay.com">
                contact support
              </EmailLink>{' '}
              if you need help changing information.{' '}
            </ContactSupportMessage>
          </InputGroup>

          <InputGroup>
            <Label>Company Name</Label>
            <Input
              type="text"
              placeholder="GrowthBird"
              name="name"
              value={organization.name}
              onChange={handleChange}
              disabled
            />
          </InputGroup>
          <InputGroup>
            <Label>Entity Type</Label>
            <DropdownMenu
              disabled
              value={organization.entity_type}
              options={{
                corporation: {
                  title: 'Corporation',
                },
                individual: {
                  title: 'Individual, sole proprietor, or single-member LLC',
                },
                llc: {
                  title: 'Limited liability company (LLC)',
                },
                nonprofit: {
                  title: 'Nonprofit organization',
                },
                partnership: {
                  title: 'Partnership',
                },
              }}
              onChange={(newValue) => {
                updateOrgValue('entity_type', newValue);
              }}
            />
          </InputGroup>
        </PlatformSettingsContent>
        <CardFooter />
      </PlatformSettingsCard>
    </PageWrapper>
  );
}
