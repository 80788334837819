const types = {
  select_method: 'select_method',
  confirm_payment: 'confirm_payment',
  add_method: 'add_method',
  invoice_action: 'invoice_action',
  use_credits: 'use_credits',
  add_credit_card: 'add_credit_card',
  add_bank_account: 'add_bank_account',
  refund: 'refund',
  confirm_autopay: 'confirm_autopay',
  pdf: 'pdf',
};

export default types;
