/* eslint-disable import/no-named-as-default */
import React, { useState } from 'react';
import { AtSymbolIcon } from '@heroicons/react/solid';
import { PropTypes } from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import {
  Label,
  Input,
  Button,
  InputGroup,
  SeparateGroup,
  ButtonWrapper,
} from '../../../CoreStyles/GeneralStyles';
import { isMobile } from '../../Devices';
import {
  SettingsCardTitle,
  SettingsCardDescription,
  SettingsCardHeader,
} from './AccountSettingsStyle';
import ModalPopUp from '../../Common/ModalPopUp';
import { UPDATE_USER_EMAIL } from '../../../GraphQl/Mutations/updateUserMutation';
import createNotification from '../../Common/Notifications';
import GET_CURRENT_USER from '../../../GraphQl/Queries/usersQueries';

export default function UpdateEmailModal({ visible, setEditing }) {
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');

  const { data } = useQuery(GET_CURRENT_USER);

  const [updateEmail] = useMutation(UPDATE_USER_EMAIL, {
    onCompleted: () => {
      createNotification('success', 'Updated Email');
      setEditing(null);
      setNewEmail('');
      setConfirmEmail('');
    },
    onError: (error) => {
      let errorMessage = error.message;
      if (errorMessage.includes('Error:')) {
        errorMessage = errorMessage.replace('Error: ', '');
      }
      createNotification('error', errorMessage);
    },
  });

  return (
    <ModalPopUp
      className="common-modal"
      width={isMobile() ? '90%' : '50%'}
      title={null}
      visible={visible}
      isCancel={() => {
        setEditing(null);
      }}
      footer={null}
      style={{ top: isMobile() ? '10px' : '40px', maxWidth: '400px' }}
      centered={false}
      content={
        <div>
          <SettingsCardHeader withImage>
            <AtSymbolIcon
              className="modal-icon"
              style={{ color: '#0064fb', backgroundColor: '#0469ff29' }}
            />
            <div>
              <SettingsCardTitle>Change Email</SettingsCardTitle>

              <SettingsCardDescription>
                This is the email you will use to log into your AloaPay account
              </SettingsCardDescription>
            </div>
          </SettingsCardHeader>

          {/* <InputGroup>
            <Label>Current Password</Label>
            <Input
              type="password"
              name="current-password"
              placeholder="Your current password"
              //   onChange={(e) => setInvoiceTitle(e.target.value)}
              //  value={invoiceTitle}
            />
          </InputGroup> */}
          <SeparateGroup>
            <InputGroup>
              <Label>New Email</Label>
              <Input
                type="text"
                name="new-password"
                placeholder="Strong password"
                onChange={(e) => setNewEmail(e.target.value)}
                value={newEmail}
              />
            </InputGroup>
            <InputGroup>
              <Label>Confirm New Email</Label>
              <Input
                type="text"
                name="confirm-password"
                placeholder="Strong Password"
                onChange={(e) => setConfirmEmail(e.target.value)}
                value={confirmEmail}
              />
            </InputGroup>
          </SeparateGroup>
          <ButtonWrapper>
            <Button
              marginLeft="14px"
              primary
              onClick={async () => {
                if (newEmail === confirmEmail) {
                  updateEmail({
                    variables: {
                      id: data.currentUser.id,
                      email: newEmail,
                    },
                  });
                } else {
                  createNotification('error', 'Emails do not match');
                }
              }}
            >
              Update Email
            </Button>
            <Button
              onClick={() => {
                setEditing(null);
              }}
              secondary
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </div>
      }
    />
  );
}

UpdateEmailModal.propTypes = {
  visible: PropTypes.bool,
  setEditing: PropTypes.func,
};

UpdateEmailModal.defaultProps = {
  visible: false,
  setEditing: () => {},
};
