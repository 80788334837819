import React, { useCallback, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { usePlaidLink } from 'react-plaid-link';
import PropTypes from 'prop-types';
import { Button } from '../../../../CoreStyles/GeneralStyles';
import {
  getStateAfterOauth,
  saveStateBeforeOauth,
} from '../../../../api/plaid/helpers';
import { clearPaymentOAuthContext } from '../../state';
import { addPlaidPaymentMethodMutation } from '../../../../GraphQl/Mutations/createPaymentMethods';

// TODO: Error handling
export default function PlaidLink({ context, token, setLoading, callback }) {
  const onEvent = useCallback(
    (eventName) => {
      if (eventName === 'OPEN_OAUTH') {
        saveStateBeforeOauth(context, token);
      } else if (eventName === 'FAIL_OAUTH' || eventName === 'CLOSE_OAUTH') {
        getStateAfterOauth();
      } else if (eventName === 'TRANSITION_VIEW') {
        clearPaymentOAuthContext();
      } else if (eventName === 'EXIT') {
        setLoading(false);
      }
    },
    [token, context]
  );

  const fromOAuth = useCallback(
    () => context && context.receivedRedirectUri !== undefined,
    [context]
  );

  const [addPlaidPaymentMethod] = useMutation(addPlaidPaymentMethodMutation, {
    onCompleted: (data) => {
      callback(data.addPlaidPaymentMethod);
    },
    onError: (errors) => {
      if (
        errors.message ===
        'A bank account with that routing number and account number already exists for this customer.'
      ) {
        const errorMessage = `It looks like you already have this bank account saved to your profile. Please verify you have not already added this bank account, or try again. If this issue persists, please contact support.`;
        message.error(errorMessage, 20);
      } else {
        message.error(errors.message, 20);
      }
    },
  });

  const config = {
    // When re-initializing Link after OAuth redirection, the same
    // Link token from the first initialization must be used
    token,
    onSuccess: (plaidToken, metadata) =>
      addPlaidPaymentMethod({
        variables: {
          token: plaidToken,
          accountId: metadata.account_id,
        },
      }),
    onEvent,
  };

  if (fromOAuth()) {
    config.receivedRedirectUri = context.receivedRedirectUri;
  } else if (config.receivedRedirectUri) {
    delete config.receivedRedirectUri;
  }

  const { open, ready } = usePlaidLink(config);

  // this opens link as soon as it's ready
  useEffect(() => {
    if (ready && fromOAuth()) {
      open();
    }
  }, [ready, open]);

  const openPlaid = useCallback(() => {
    if (ready) {
      open();
    }
  }, [ready, open]);

  return (
    <Button primary fontSize="15px" onClick={openPlaid}>
      Connect a Bank Account
    </Button>
  );
}

PlaidLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object,
  token: PropTypes.string,
  setLoading: PropTypes.func,
  callback: PropTypes.func,
};

PlaidLink.defaultProps = {
  context: {},
  token: '',
  setLoading: null,
  callback: () => {},
};
