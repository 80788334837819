/* eslint-disable import/prefer-default-export */
import VisaIcon from './images/cards/dark/1.png';
import AmexIcon from './images/cards/dark/22.png';
import DinersIcon from './images/cards/dark/10.png';
import DiscoverIcon from './images/cards/dark/14.png';
import JCBIcon from './images/cards/dark/16.png';
import MasterCardIcon from './images/cards/dark/2.png';
import UnionPayIcon from './images/cards/dark/25.png';
import UnknownIcon from './images/cards/dark/24.png';

export const vocabulary = {
  projects: {
    singular: 'project',
    singular_cap: 'Project',
    plural: 'projects',
    plural_cap: 'Projects',
  },
  engagements: {
    singular: 'engagement',
    singular_cap: 'Engagement',
    plural: 'engagements',
    plural_cap: 'Engagements',
  },
  jobs: {
    singular: 'job',
    singular_cap: 'Job',
    plural: 'jobs',
    plural_cap: 'Jobs',
  },
};

export const projectRoles = {
  payer: {
    title: 'Payer',
  },
  owner: {
    title: 'Owner',
  },
  viewer: {
    title: 'Viewer',
  },
  admin: {
    title: 'Admin',
  },
};

export const monthsShort = {
  1: {
    title: 'Jan',
  },
  2: {
    title: 'Feb',
  },
  3: {
    title: 'Mar',
  },
  4: {
    title: 'Apr',
  },
  5: {
    title: 'May',
  },
  6: {
    title: 'Jun',
  },
  7: {
    title: 'Jul',
  },
  8: {
    title: 'Aug',
  },
  9: {
    title: 'Sep',
  },
  10: {
    title: 'Oct',
  },
  11: {
    title: 'Nov',
  },
  12: {
    title: 'Dec',
  },
};

export const setPositions = {
  1: {
    title: 'First',
  },
  2: {
    title: 'Second',
  },
  3: {
    title: 'Third',
  },
  4: {
    title: 'Fourth',
  },
  '-1': {
    title: 'Last',
  },
};

export const dayOptions = {
  sunday: {
    title: 'Sunday',
  },
  monday: {
    title: 'Monday',
  },
  tuesday: {
    title: 'Tuesday',
  },
  wednesday: {
    title: 'Wednesday',
  },
  thursday: {
    title: 'Thursday',
  },
  friday: {
    title: 'Friday',
  },
  saturday: {
    title: 'Saturday',
  },
  day: {
    title: 'Day',
  },
  weekday: {
    title: 'Weekday',
  },
  weekend_day: {
    title: 'Weekend Day',
  },
};

export const dayOptionsWhole = {
  sunday: {
    title: 'Sunday',
  },
  monday: {
    title: 'Monday',
  },
  tuesday: {
    title: 'Tuesday',
  },
  wednesday: {
    title: 'Wednesday',
  },
  thursday: {
    title: 'Thursday',
  },
  friday: {
    title: 'Friday',
  },
  saturday: {
    title: 'Saturday',
  },
};

export const cardOptions = {
  amex: {
    title: 'American Express',
    icon: AmexIcon,
  },
  diners: {
    title: 'Diners Club',
    icon: DinersIcon,
  },
  discover: {
    title: 'Discover',
    icon: DiscoverIcon,
  },
  jcb: {
    title: 'JCB',
    icon: JCBIcon,
  },
  mastercard: {
    title: 'MasterCard',
    icon: MasterCardIcon,
  },
  unionpay: {
    title: 'UnionPay',
    icon: UnionPayIcon,
  },
  visa: {
    title: 'Visa',
    icon: VisaIcon,
  },
  unknown: {
    title: 'Unknown',
    icon: UnknownIcon,
  },
};

export const INVOICE_STATUS_OPTIONS = {
  pending_approval: {
    title: 'Pending Approval',
    section_title: 'Pending Approval',
    color: '#ff9f2d',
    colorKey: 'orange',
  },
  in_progress: {
    title: 'In Progress',
    section_title: 'In Progress',
    color: '#0075FF',
    colorKey: 'blue',
  },
  work_pending: {
    title: 'Work Pending',
    section_title: "Haven't Started",
    color: '#969696',
    colorKey: 'gray',
  },
  processing: {
    title: 'Payment Processing',
    section_title: 'Payment Processing',
    color: '#5a2dff',
    colorKey: 'blue',
  },
  complete: {
    title: 'Complete',
    section_title: 'Complete',
    color: '#48a039',
    colorKey: 'green',
  },
  refunded: {
    title: 'Refunded',
    section_title: 'Refunded',
    color: '#5a2dff',
    colorKey: 'purple',
  },
};

export const OFFLINE_PAYMENT_METHODS = {
  credit_card: {
    title: 'Credit Card',
  },
  bank_transfer: {
    title: 'Bank Transfer',
  },
  check: {
    title: 'Check',
  },
  cash: {
    title: 'Cash',
  },
};
