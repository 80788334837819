/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { UserIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import {
  Label,
  Input,
  Button,
  Page,
  Section,
  SectionTitle,
} from '../../../CoreStyles/GeneralStyles';
import PaymentMethodEditor from '../../Common/PaymentMethodEditor';
import GET_CURRENT_USER from '../../../GraphQl/Queries/usersQueries';
import {
  ProfileImage,
  AccountPageWrapper,
  SettingsGroup,
  SettingsGroupTitle,
  SettingsGroupContent,
  SettingsGroupContentPrimary,
  SettingsGroupContentActions,
  SettingsCardTitle,
  SettingsCardDescription,
  SettingsCardHeader,
  AcccountSettingsCard,
} from './AccountSettingsStyle';
import BackButton from '../../Common/BackButton';
import UPDATE_USER_MUTATION, {
  UPDATE_USER_IMAGE,
} from '../../../GraphQl/Mutations/updateUserMutation';
import ErrorPage from '../../Common/ErrorPage';
import createNotification from '../../Common/Notifications';
import { showAddMethod } from '../../AppDialog/state';
import ImagePicker from '../../Common/ImagePicker';
import UpdateEmailModal from './UpdateEmailModal';
import UpdatePasswordModal from './UpdatePasswordModal';

export default function AccountSettings() {
  const { data } = useQuery(GET_CURRENT_USER);

  const [mutate] = useMutation(UPDATE_USER_IMAGE, {
    onCompleted: () => {
      createNotification('success', 'Image Updated');
    },
    refetchQueries: [
      {
        query: GET_CURRENT_USER,
      },
    ],
  });

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      createNotification('success', 'Updated Settings');
    },
    onError: (errors) => {
      ErrorPage(errors);
    },
  });
  const [user, setUser] = useState({});

  useEffect(() => {
    // effect
    // return () => {
    //   cleanup
    // }
    if (data?.currentUser) {
      setUser(data?.currentUser);
    }
  }, [data]);

  // TODO: this gets called like 4 times during upload process??
  const handleProfilePic = (file) => {
    // TODO: this whole thing is messy

    /*

    const maxfilesize = 2 * 1024 * 1024; // 2 Mb
    const filesize = file?.size;

    if (filesize > maxfilesize) {
      const warning = document.getElementById('lbError');
      warning.innerHTML =
        `Selected file is too large: ${parseFloat(
          filesize / (1024 * 1024)
        ).toFixed(2)} ` +
        `mb` +
        `. Maximum acceptable size is: ${parseFloat(
          maxfilesize / (1024 * 1024)
        ).toFixed(2)} ` +
        `mb`;
    } else {
      mutate({ variables: { id: data?.user.id, file: file.originFileObj } });
    } */
    mutate({ variables: { id: user.id, file } });
  };

  function updateUserValue(fieldId, newValue) {
    const userTemp = _.cloneDeep(user);

    userTemp[fieldId] = newValue;

    setUser(userTemp);
  }

  const handleChange = async (evt) => {
    if (evt.file) {
      // await setUser({ profilePic: {} });
      handleProfilePic(evt);
    } else {
      const { name, value } = evt.target;

      updateUserValue(name, value);
    }
  };

  function saveChanges() {
    updateUser({ variables: user });
  }

  const [editing, setEditing] = useState('');

  return (
    <Page
      gridTemplateColumns="25% 25% 25% 25%"
      singleColumnNarrow
      typeTwo
      style={{ width: '50%', marginRight: 'auto', marginLeft: 'auto' }}
    >
      <BackButton text="Dashboard" path="/" />

      <Section padding="0px" style={{ gridArea: 'main' }} width="100%">
        <SectionTitle marginBottom="10px">Account Settings</SectionTitle>
        <AccountPageWrapper>
          <AcccountSettingsCard>
            <SettingsCardHeader>
              <SettingsCardTitle>Your Account</SettingsCardTitle>

              <SettingsCardDescription>
                This information will be displayed publicly so be careful what
                you share.
              </SettingsCardDescription>
            </SettingsCardHeader>

            <>
              <SettingsGroup borderBottom="none">
                <SettingsGroupTitle>
                  <Label color="rgb(107, 114, 128)" marginBottom="0px">
                    Profile Picture
                  </Label>
                </SettingsGroupTitle>

                {user.image_thumb && user.image_thumb?.length !== 0 ? (
                  <SettingsGroupContent>
                    <SettingsGroupContentPrimary>
                      <ProfileImage
                        src={user.image_thumb}
                        alt="img"
                        width="32px"
                        height="32px"
                        borderRadius="100%"
                      />
                    </SettingsGroupContentPrimary>
                    <SettingsGroupContentActions>
                      <ImagePicker
                        shape="round"
                        width={240}
                        height={240}
                        upload={handleProfilePic}
                      >
                        <Button thin>Change</Button>
                      </ImagePicker>
                    </SettingsGroupContentActions>
                  </SettingsGroupContent>
                ) : (
                  <>
                    <SettingsGroupContent>
                      <SettingsGroupContentPrimary>
                        <UserIcon className="avatar-placeholder-settings" />
                      </SettingsGroupContentPrimary>
                      <SettingsGroupContentActions>
                        <Upload
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture"
                          onChange={handleChange}
                          showUploadList={false}
                        >
                          <Button thin>Change</Button>
                        </Upload>
                      </SettingsGroupContentActions>
                    </SettingsGroupContent>
                  </>
                )}
              </SettingsGroup>

              <SettingsGroup borderBottom="none">
                <SettingsGroupTitle>
                  <Label color="rgb(107, 114, 128)" marginBottom="0px">
                    Name
                  </Label>
                </SettingsGroupTitle>
                <SettingsGroupContent>
                  <SettingsGroupContentPrimary>
                    {editing !== 'name' && <span>{user.name}</span>}

                    {editing === 'name' && (
                      <Input
                        type="text"
                        placeholder="John Doe"
                        name="name"
                        value={user.name}
                        onChange={handleChange}
                      />
                    )}
                  </SettingsGroupContentPrimary>
                  <SettingsGroupContentActions>
                    {editing !== 'name' && (
                      <Button
                        onClick={() => {
                          setEditing('name');
                        }}
                        thin
                      >
                        Update
                      </Button>
                    )}

                    {editing === 'name' && (
                      <>
                        <Button
                          onClick={() => {
                            setEditing('');
                          }}
                          thin
                          color="gray"
                          padding="8px 2px"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            setEditing('');
                            saveChanges();
                          }}
                          thin
                        >
                          Save
                        </Button>
                      </>
                    )}
                  </SettingsGroupContentActions>
                </SettingsGroupContent>
              </SettingsGroup>
              <SettingsGroup borderBottom="none">
                <SettingsGroupTitle>
                  <Label color="rgb(107, 114, 128)" marginBottom="0px">
                    Company
                  </Label>
                </SettingsGroupTitle>
                <SettingsGroupContent>
                  <SettingsGroupContentPrimary>
                    {user.company}
                  </SettingsGroupContentPrimary>
                </SettingsGroupContent>
              </SettingsGroup>

              <SettingsGroup borderBottom="none">
                <SettingsGroupTitle>
                  <Label color="rgb(107, 114, 128)" marginBottom="0px">
                    Email
                  </Label>
                </SettingsGroupTitle>
                <SettingsGroupContent>
                  <SettingsGroupContentPrimary>
                    {user.email}
                  </SettingsGroupContentPrimary>
                  <SettingsGroupContentActions>
                    <Button
                      onClick={() => {
                        setEditing('email');
                      }}
                      thin
                    >
                      Update
                    </Button>
                  </SettingsGroupContentActions>
                </SettingsGroupContent>
              </SettingsGroup>

              <SettingsGroup borderBottom="none">
                <SettingsGroupTitle>
                  <Label color="rgb(107, 114, 128)" marginBottom="0px">
                    Password
                  </Label>
                </SettingsGroupTitle>
                <SettingsGroupContent>
                  <SettingsGroupContentPrimary>{}</SettingsGroupContentPrimary>
                  <SettingsGroupContentActions>
                    <Button
                      onClick={() => {
                        setEditing('password');
                      }}
                      thin
                    >
                      Change Password
                    </Button>
                  </SettingsGroupContentActions>
                </SettingsGroupContent>
              </SettingsGroup>
            </>
          </AcccountSettingsCard>

          <AcccountSettingsCard>
            <SettingsCardHeader>
              <SettingsCardTitle>Payment Methods</SettingsCardTitle>

              <SettingsCardDescription>
                Payment methods are used to pay for invoices on the platform
              </SettingsCardDescription>
            </SettingsCardHeader>
            {user.id && <PaymentMethodEditor userId={user.id} />}

            {/* Add callback to add method to come back here */}
            <Button onClick={() => showAddMethod()}>Add Payment Method</Button>
          </AcccountSettingsCard>
        </AccountPageWrapper>
      </Section>

      <UpdateEmailModal visible={editing === 'email'} setEditing={setEditing} />
      <UpdatePasswordModal
        visible={editing === 'password'}
        setEditing={setEditing}
      />
    </Page>
  );
}

AccountSettings.propTypes = {};

AccountSettings.defaultProps = {};
