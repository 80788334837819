/* eslint-disable react/forbid-prop-types */
import { React, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { getChartData } from './ChartUtils';

function CustomChart({ data, valueOptions, groupBy, resize, chartOptions }) {
  console.log('data is ', data);

  const chartComponent = useRef(null);

  const chartData = getChartData({
    data: data?.invoices,
    groupBy,
    valueOptions,
  });

  console.log('chartData: ', chartData);

  const options = _.cloneDeep(chartOptions);

  options.series = [
    {
      name: valueOptions.name,
      data: _.map(chartData),
    },
  ];

  options.credits = {
    enabled: false,
  };

  useEffect(() => {
    const { chart } = chartComponent.current;
    chart.reflow();
  }, [resize]);

  // React

  Highcharts.dateFormats = {
    q(timestamp) {
      const date = new Date(timestamp);
      const quarter = Math.floor(date.getUTCMonth() / 3) + 1;
      console.log(quarter);
      return quarter;
    },
  };

  return (
    <HighchartsReact
      containerProps={{ style: { height: '100%' } }}
      ref={chartComponent}
      highcharts={Highcharts}
      options={options}
    />
  );
}

CustomChart.propTypes = {
  data: PropTypes.any,
  valueOptions: PropTypes.any,
  chartOptions: PropTypes.any,
  groupBy: PropTypes.string,
  resize: PropTypes.any,
};

CustomChart.defaultProps = {
  data: null,
  chartOptions: {},
  valueOptions: {},
  groupBy: null,
  resize: null,
};

export default CustomChart;
