import styled, { css } from 'styled-components';

export const TableCard = styled.div`
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0 0 1px;
  box-sizing: border-box;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

export const Table = styled.table`
  border: 0 solid #e5e7eb;
  border-collapse: collapse;
  border-spacing: 2px;
  box-sizing: border-box;
  color: #333;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 19px;
  min-width: 100%;
  outline: none;
  text-indent: 0;
`;

export const TableHead = styled.thead`
  background-color: #f9fafb;
`;

export const TableHeader = styled.th`
  border-collapse: collapse;
  border-spacing: 2px;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #6b7280;
  font-family: 'Avenir Next';
  font-size: 0.75rem;
  font-variant: tabular-nums;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1rem;
  outline: none;
  padding: 0.75rem 1.5rem;
  text-align: left;
  text-transform: uppercase;
`;

export const TableBody = styled.tbody`
  background-color: #fff;
  border-collapse: collapse;
  border-spacing: 2px;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #333;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 19px;
  outline: none;
`;

export const TableCell = styled.td`
  padding: 1rem 1.5rem;
  white-space: nowrap;
  color: #6b7280;
  font-family: 'Avenir Next';
  font-size: 0.875rem;
`;

export const TableChip = styled.span`
  background-color: #d1fae5;
  border-collapse: collapse;
  border-radius: 9999px;
  border-spacing: 2px;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #065f46;
  display: inline-flex;
  font-family: 'Avenir Next';
  font-size: 0.75rem;
  font-variant: tabular-nums;
  font-weight: 500;
  line-height: 1.25rem;
  outline: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  white-space: nowrap;

  ${(props) =>
    props.blue &&
    css`
      color: rgb(55, 48, 163);
      background-color: rgb(224, 231, 255);
    `}
`;

export const TableUser = styled.div`
  align-items: center;
  border-collapse: collapse;
  border-spacing: 2px;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #6b7280;
  display: flex;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 19px;
  outline: none;
  white-space: nowrap;
`;

export const TableUserImageContainer = styled.div`
  flex-shrink: 0;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-variant: tabular-nums;
  height: 2.5rem;
  line-height: 19px;
  outline: none;
  white-space: nowrap;
  width: 2.5rem;
`;

export const TableUserImage = styled.img`
  border-radius: 9999px;
  border-radius: 9999px;
  border-spacing: 2px;
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #6b7280;
  display: block;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-variant: tabular-nums;
  height: 2.5rem;
  line-height: 19px;
  max-width: 100%;
  outline: none;
  outline: 0;
  vertical-align: middle;
  white-space: nowrap;
  width: 2.5rem;
`;

export const TableUserInfo = styled.div`
  color: #6b7280;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 19px;
  margin-left: 1rem;
  outline: none;
  white-space: nowrap;
`;

export const TableUserInfoPrimary = styled.div`
  color: #111827;
  font-family: 'Avenir Next';
  font-size: 0.875rem;
  font-variant: tabular-nums;
  font-weight: 500;
  line-height: 1.25rem;
  outline: none;
  white-space: nowrap;
`;

export const TableUserInfoSecondary = styled.div`
  color: #6b7280;
  font-family: 'Avenir Next';
  font-size: 0.875rem;
  font-variant: tabular-nums;
  line-height: 1.25rem;
  outline: none;
  white-space: nowrap;
`;

export const TableHeaderIn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    border: none;
  }
  button svg {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: block;
    div {
      width: 100%;
      margin-top: 10px;
    }
    button {
      position: fixed;
      right: 20px;
      margin: auto;
      border-radius: 20px;
      bottom: 20px;
      svg {
        display: inline-block;
        margin-right: 7px;
        position: relative;
        top: 2px;
      }
    }
  }
`;

export const TableFooter = styled.nav`
  align-items: center;
  background-color: #fff;
  border-style: solid;
  border-width: 1px 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  outline: none;
  padding: 0.75rem 1.5rem;
`;

export const FooterLeft = styled.div`
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  display: block;
  outline: none;
`;

export const FooterRight = styled.div`
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 0;
  justify-content: flex-end;
  outline: none;
`;

// export const Table = styled.table`
//   width: 100%;
//   font-family: Avenir Next;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 19px;

//   color: #3c3c3c;
// `;
// export const TableHead = styled.thead`
//   background: #f7f7f8;
//   border: 1px solid #eaeaea;
//   box-sizing: border-box;
//   border-radius: 8px;
// `;
// export const TableBody = styled.tbody``;
// export const Tr = styled.tr``;
// export const Th = styled.th``;
// export const Td = styled.td``;
// export const H2 = styled.h2``;
// export const H6 = styled.h6`
//   font-size: 15px;
//   margin-bottom: 0px;
// `;
// export const Span = styled.span``;
// export const P = styled.p`
//   color: #858585;
//   font-size: 13px;
//   margin-bottom: 0;
// `;
export const AuthorImage = styled.img`
  max-width: 35px;
  /* border-radius: 50px; */
  margin-right: 10px;
`;
export const TeamHeaderRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
// export const TableWrapper = styled.div`
//   background: #ffffff;
//   border: 1.02823px solid #dddddd;
//   box-sizing: border-box;
//   border-radius: 8.22581px;
//   padding: 15px;
//   width: 100%;
//   margin: 20px 0;
// `;
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 5px;
    margin-bottom: 30px;
  }
`;
export const ManageTeamUnorderList = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 0px;
`;
export const ManageTeamList = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 18px;
  }
`;
export const ManageTeamDiv = styled.div``;
export const ManageTeamSpan = styled.span``;

export const ManageTeamMobWrapper = styled.div.attrs((/* props */) => ({
  tabIndex: 0,
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  /* & + img {
    width: 37px;
    height: 37px;
  } */
`;

export const ManageTeamEditButton = styled.span`
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
  color: #4f46e5;
  outline: none;
  cursor: pointer;
`;
